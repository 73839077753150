import useUpdatePublisher from "api/PublisherResource/useUpdatePublisher";
import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { MAX_PDF_SIZE } from "utils";
import * as Yup from "yup";

const UpdatePublisher = ({ publisher }) => {
	const initialValues = {
		name: publisher?.name || "",
		physical_address: publisher?.physical_address || "",
		tax_clearance: null,
		certificate_of_incorporation: null,
		focus_area: publisher?.focus_area || "",
	};

	const validate = Yup.object().shape({
		name: Yup.string().required("Name of publishing company is required"),
		physical_address: Yup.string().required("Address is required"),
		tax_clearance: Yup.mixed()
			.nullable()
			.test("fileSize", `File size should not exceed ${MAX_PDF_SIZE}MB`, (value) => {
				if (!value) return true;
				return value.size / 1024 / 1024 <= MAX_PDF_SIZE;
			})
			.test("fileMimeType", "Unsupported file format", (value) => {
				if (!value) return true;
				return ["application/pdf", "image/jpeg", "image/png", "image/svg+xml"].includes(value.type);
			}),
		certificate_of_incorporation: Yup.mixed()
			.nullable()
			.test("fileSize", `File size should not exceed ${MAX_PDF_SIZE}MB`, (value) => {
				if (!value) return true;
				return value.size / 1024 / 1024 <= MAX_PDF_SIZE;
			})
			.test("fileMimeType", "Unsupported file format", (value) => {
				if (!value) return true;
				return ["application/pdf", "image/jpeg", "image/png", "image/svg+xml"].includes(value.type);
			}),
	});

	const { mutateAsync, isLoading } = useUpdatePublisher(publisher?.id);

	const handleSave = async (data) => {
		await mutateAsync(data);
	};

	return (
		<Formik initialValues={initialValues} validationSchema={validate} onSubmit={(data) => handleSave(data)}>
			{({ setFieldValue, values }) => {
				return (
					<Form className="mt-4 space-y-6">
						<FormField type="text" name="name" label="Name of Publishing Company *" />

						<FormField type="text" name="physical_address" label="Physical Address *" />

						<FormField
							type="file"
							name="tax_clearance"
							value={null}
							label="Tax Clearance"
							style={{
								paddingLeft: `${!values?.tax_clearance ? "120px" : ""} `,
							}}
							onChange={(event) => setFieldValue("tax_clearance", event.target.files[0])}
						/>

						<FormField
							type="file"
							name="certificate_of_incorporation"
							value={null}
							label="Certifiate of Incorporation"
							style={{
								paddingLeft: `${!values?.certificate_of_incorporation ? "190px" : ""} `,
							}}
							onChange={(event) => setFieldValue("certificate_of_incorporation", event.target.files[0])}
						/>

						<FormField type="text" name="focus_area" label="What's your focus area?" />

						<div className=" flex justify-center border-t border-gray-300 pt-2">
							<button
								type="submit"
								className="px-4 py-2 rounded-full bg-akgreenest text-white flex gap-1 items-center justify-center"
							>
								{isLoading ? (
									<>
										Updating
										<AiOutlineLoading3Quarters size={20} className="animate-spin" />
									</>
								) : (
									<>
										Update <IoIosArrowForward size={20} />
									</>
								)}
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

export default UpdatePublisher;
