import photoIcon from "assets/images/photoIcon.png";
import transparentLogo from "assets/images/transparent_logo.png";
import { BsHouseFill } from "react-icons/bs";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "assets/images/dark_logo.png";

const AuthLayout = ({ children, hideCamera }) => {
	const location = useLocation();

	return (
		<div className="min-h-screen flex flex-col sm:flex-row gap-4 sm:gap-10 relative">
			<div className="w-full sm:w-6/12 md:w-5/12 lg:w-4/12 sm:min-h-screen bg-auth2-bg bg-no-repeat sticky top-0 center bg-cover hidden sm:block">
				<div
					className="sm:min-h-screen py-4 flex flex-col justify-center items-center"
					style={{ backgroundColor: "rgba(95, 160, 0, 0.3)" }}
				>
					{hideCamera && (
						<div className="h-32 w-32 rounded-full flex flex-col gap-2 justify-center items-center bg-white">
							<img src={photoIcon} width="40" alt="" />
							<span className="text-xs text-center">
								Click here to add profile picture
							</span>
						</div>
					)}

					<Link to="/">
						<img src={transparentLogo} width="200" alt="Akello" />
					</Link>
				</div>
			</div>

			<div className="w-full sm:w-6/12 md:w-7/12 lg:w-5/12 pl-4 sm:pl-0 min-h-screen flex flex-col sm:justify-center py-6 pr-6  ">
				<div className="mb-2 mx-auto sm:mx-0">
					<Link to="/" className="inline sm:hidden">
						<img src={logo} width="130" alt="Akello" />
					</Link>
					<NavLink
						to="/"
						className="font-cera-black mb-4 uppercase hidden sm:flex items-center gap-1 rounded-full px-3 py-1 border border-akgreenest max-w-max "
					>
						<BsHouseFill size={20} /> Home
					</NavLink>
				</div>

				<div>{children}</div>

				{(function () {
					if (
						location.pathname === "/register" ||
						location.pathname === "/password-reset-options"
					) {
						return (
							<NavLink className="block mt-4 max-w-max " to="/login">
								Already have an account?{" "}
								<span className="font-bold">Log In</span>
							</NavLink>
						);
					}
					if (location.pathname === "/login") {
						return (
							<NavLink className="block mt-4 max-w-max" to="/register">
								Don't have an account?{" "}
								<span className="font-bold">Register</span>
							</NavLink>
						);
					}
				})()}

				{/* {location.pathname === "/register" ? (
					<NavLink className="block mt-4" to="/login">
						Already a member? <span className="font-bold">Log In</span>
					</NavLink>
				) : (
					<NavLink className="block mt-4" to="/register">
						Don't have an account? <span className="font-bold">Register</span>
					</NavLink>
				)} */}
			</div>
		</div>
	);
};

export default AuthLayout;
