import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import useAppStore from "store/appStore";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects, getHost } from "utils";

const useSendPasswordResetLink = () => {
	const originLink = getHost();

	const { setFlashMessage } = useAppStore();

	const { accessToken } = useTokenStore();

	const login = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);
		const response = await instance.post(
			`/auth/send-reset-link?origin=${originLink}/reset-password`,
			body
		);
		return response;
	};

	return useMutation(login, {
		onSuccess: async ({ data: body }) => {
			setFlashMessage(
				"Password reset link has been sent to your email. Check your inbox and follow that link"
			);
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);
		},
	});
};

export default useSendPasswordResetLink;
