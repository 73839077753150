import moment from "moment";
import { Dialog } from "primereact/dialog";
import React from "react";
import { FiX } from "react-icons/fi";

const StackTrace = ({ row }) => {
	const [show, setShow] = React.useState(false);

	return (
		<>
			<button
				type="button"
				className="text-blue-700 flex flex-col gap-2  transition-all hover:font-bold hover:text-xs ease-in-out duration-300"
				onClick={() => setShow(true)}
			>
				<span className="truncate" style={{ maxWidth: 300 }}>
					{row?.exception_message}
				</span>
				<span className="border-b-4 border-blue-400 w-12"></span>
			</button>

			<Dialog
				visible={show}
				onHide={() => setShow(false)}
				showHeader={false}
				contentClassName="font-sans rounded-md  relative"
				contentStyle={{ minWidth: "600px" }}
			>
				<div className="sticky top-4 w-full flex justify-between items-center py-4 z-40 bg-gray-100 px-3 rounded-lg border border-gray-300 shadow-md ">
					<div>
						<p>Error raised on {moment(row?.created_at).format("LLL")}</p>
						<h4
							title={row?.exception_message}
							className="truncate"
							style={{ maxWidth: 700 }}
						>
							{row?.exception_message}
						</h4>
						<p>{row?.exception_class}</p>
					</div>
					<button
						type="button"
						onClick={() => setShow(false)}
						className="absolute -top-2 -right-2 bg-white rounded-full shadow-sm hover:shadow-lg transition-all ease-in-out duration-300 border border-gray-300 p-2"
					>
						<FiX size={30} />
					</button>
				</div>

				<div className="py-4 mt-4 flex flex-col gap-2 px-4">
					{row?.exception_trace?.map((trace, index) => (
						<div key={index} className="flex items-center gap-2 text-xs">
							<span className="rounded-full w-20 px-2 h-6 flex items-center justify-center bg-akgreener text-white">
								Line {trace.line}
							</span>
							<p className="text-xs">{trace.file}</p>
						</div>
					))}
				</div>
			</Dialog>
		</>
	);
};

export default StackTrace;
