const Xpadding = ({ children, className = "" }) => {
  return (
    // <div>
    //   <div className={`px-4 sm:px-4 md:px-10 lg:px-16 xl:px-28 ${className}`}>{children}</div>
    // </div>

    <div
      className={`mx-auto px-4 w-full lg:px-0 md:w-910 lg:w-1000 ${className} `}
    >
      {children}
    </div>
  );
};

export default Xpadding;
