import { activeCurrencies, activePaymentMethods } from "utils";
import create from "zustand";

const currencyStore = (set) => ({
	currency: activeCurrencies.USD.code,
	paymentMethod: activePaymentMethods.paypal.name,

	setCurrency: (currencyToSet) => {
		set({ currency: currencyToSet });
		switch (currencyToSet) {
			case activeCurrencies.ZWL.code:
				set({ paymentMethod: activePaymentMethods.ecocashZwl.name });
				break;
			case activeCurrencies.USD.code:
				set({ paymentMethod: activePaymentMethods.paypal.name });
				break;
			default:
				set({ paymentMethod: activePaymentMethods.ecocashZwl.name });
				break;
		}
	},

	setPaymentMethod: (paymentMethodToSet) => {
		set({ paymentMethod: paymentMethodToSet });
		switch (paymentMethodToSet) {
			case activePaymentMethods.ecocashZwl.name:
				set({ currency: activeCurrencies.ZWL.code });
				break;
			case activePaymentMethods.paypal.name:
				set({ currency: activeCurrencies.USD.code });
				break;
			case activePaymentMethods.ecocashUsd.name:
				set({ currency: activeCurrencies.USD.code });
				break;
			case activePaymentMethods.stars.name:
				set({ currency: activeCurrencies.ZWL.code });
				break;
			default:
				set({ currency: activeCurrencies.ZWL.code });
				break;
		}
	},
});

const useCurrencyStore = create(currencyStore);
export default useCurrencyStore;
