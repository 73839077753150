import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";

const useGenres = (fromPortal = "no") => {
	const { accessToken } = useTokenStore();
	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const url = `book-genres?from_portal=${fromPortal}`;
		const response = await instance.get(url);
		return response;
	};
	return useQuery(["genres", fromPortal], () => fetch(), {
		...fetchConfig,
	});
};

export default useGenres;
