const AccountRegistration = ({ name, email, password }) => {
  return `<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
  <head>
    <title></title>
    <meta charset="utf-8" />
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    <xml><o:OfficeDocumentSettings><o:PixelsPerInch></o:PixelsPerInch><o:AllowPNG /></o:OfficeDocumentSettings></xml>
    <style>
      * {
        box-sizing: border-box;
      }

      body {
        margin: 0;
        padding: 0;
      }

      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: inherit !important;
      }

      #MessageViewBody a {
        color: inherit;
        text-decoration: none;
      }

      p {
        line-height: inherit;
      }

      @media (max-width: 520px) {
        .icons-inner {
          text-align: center;
        }

        .icons-inner td {
          margin: 0 auto;
        }

        .row-content {
          width: 100% !important;
        }

        .stack .column {
          width: 100%;
          display: block;
        }
      }
    </style>
  </head>
  <body
    style="background-color: #ffffff; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none"
  >
    <table
      border="0"
      cellpadding="0"
      cellspacing="0"
      class="nl-container"
      role="presentation"
      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff"
      width="100%"
    >
      <tbody>
        <tr>
          <td>
            <table
              align="center"
              border="0"
              cellpadding="0"
              cellspacing="0"
              class="row row-1"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
              width="100%"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      align="center"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      class="row-content stack"
                      role="presentation"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 500px"
                      width="500"
                    >
                      <tbody>
                        <tr>
                          <td
                            class="column"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              vertical-align: top;
                              padding-top: 5px;
                              padding-bottom: 5px;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                            "
                            width="100%"
                          >
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              class="heading_block"
                              role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
                              width="100%"
                            >
                              <tr>
                                <td style="width: 100%; text-align: center">
                                  <h1
                                    style="
                                      margin: 0;
                                      color: #555555;
                                      font-size: 23px;
                                      font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
                                      line-height: 120%;
                                      text-align: left;
                                      direction: ltr;
                                      font-weight: normal;
                                      letter-spacing: normal;
                                      margin-top: 0;
                                      margin-bottom: 0;
                                    "
                                  >
                                    <strong>Hello ${name},</strong>
                                  </h1>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              align="center"
              border="0"
              cellpadding="0"
              cellspacing="0"
              class="row row-2"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
              width="100%"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      align="center"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      class="row-content stack"
                      role="presentation"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 500px"
                      width="500"
                    >
                      <tbody>
                        <tr>
                          <td
                            class="column"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              vertical-align: top;
                              padding-top: 5px;
                              padding-bottom: 5px;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                            "
                            width="100%"
                          >
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              class="divider_block"
                              role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
                              width="100%"
                            >
                              <tr>
                                <td>
                                  <div align="center">
                                    <table
                                      border="0"
                                      cellpadding="0"
                                      cellspacing="0"
                                      role="presentation"
                                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
                                      width="100%"
                                    >
                                      <tr>
                                        <td
                                          class="divider_inner"
                                          style="font-size: 1px; line-height: 1px; border-top: 4px dotted #a6c8a8"
                                        >
                                          <span> </span>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              align="center"
              border="0"
              cellpadding="0"
              cellspacing="0"
              class="row row-3"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
              width="100%"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      align="center"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      class="row-content stack"
                      role="presentation"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 500px"
                      width="500"
                    >
                      <tbody>
                        <tr>
                          <td
                            class="column"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              vertical-align: top;
                              padding-top: 5px;
                              padding-bottom: 5px;
                              border-top: 0px;
                              border-right: 0px;
                              border-bottom: 0px;
                              border-left: 0px;
                            "
                            width="100%"
                          >
                            <table
                              border="0"
                              cellpadding="10"
                              cellspacing="0"
                              class="text_block"
                              role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word"
                              width="100%"
                            >
                              <tr>
                                <td>
                                  <div style="font-family: sans-serif">
                                    <div
                                      style="
                                        font-size: 14px;
                                        mso-line-height-alt: 16.8px;
                                        color: #555555;
                                        line-height: 1.2;
                                        font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
                                      "
                                    >
                                      <p style="margin: 0; font-size: 14px">
                                        Thank you for registering for Akello Library, one of the many digital products Akello offers to enhance 
                                        the quality of education and knowledge. The Akello library offers a variety of books across multiple knowledge 
                                        domains, which you will be able to access from your device at any time, from anywhere!
                                      </p>
                                      <p style="margin: 0; font-size: 14px; mso-line-height-alt: 16.8px"> </p>
                                      <p style="margin: 0; font-size: 14px">
                                        <span style="color: #2a792e"
                                          ><strong>Your login details to the library are:</strong></span
                                        >
                                      </p>
                                      <ul style="list-style-type: circle; line-height: 120%">
                                        <li>
                                          <span style="color: #2a792e"><strong>Email address: ${email}</strong></span>
                                        </li>
                                        <li>
                                          <span style="color: #2a792e"><strong>Password: ${password}</strong></span>
                                        </li>
                                      </ul>
                                      <p style="margin: 0">Browse and purchase your favorite books now!!</p>
                                      <p style="margin: 0; mso-line-height-alt: 16.8px"> </p>
                                      <p style="margin: 0">Regards,</p>
                                      <p style="margin: 0; mso-line-height-alt: 16.8px"> </p>
                                      <p style="margin: 0">
                                        <span style="font-size: 16px"><strong>Akello team</strong></span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              align="center"
              border="0"
              cellpadding="0"
              cellspacing="0"
              class="row row-4"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt"
              width="100%"
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      align="center"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      class="row-content stack"
                      role="presentation"
                      style="
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        background-color: #c6e8b9;
                        color: #000000;
                        width: 500px;
                      "
                      width="500"
                    >
                      <tbody>
                        <tr>
                          <td
                            class="column"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              vertical-align: top;
                              border-bottom: 2px solid #ffffff;
                              border-left: 2px solid #ffffff;
                              border-right: 2px solid #ffffff;
                              border-top: 2px solid #ffffff;
                            "
                            width="25%"
                          >
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              class="text_block"
                              role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word"
                              width="100%"
                            >
                              <tr>
                                <td
                                  style="
                                    padding-top: 15px;
                                    padding-right: 10px;
                                    padding-bottom: 15px;
                                    padding-left: 10px;
                                  "
                                >
                                  <div style="font-family: sans-serif">
                                    <div
                                      style="
                                        font-size: 14px;
                                        mso-line-height-alt: 16.8px;
                                        color: #555555;
                                        line-height: 1.2;
                                        font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
                                      "
                                    >
                                      <p style="margin: 0; font-size: 14px; text-align: center">
                                        <strong
                                          ><span style="font-size: 15px"
                                            ><a
                                              href="smartlearning.akello.co"
                                              rel="noopener"
                                              style="text-decoration: none; color: #0068a5"
                                              target="_blank"
                                              title="Learn the smart way"
                                              >Akello smart learning</a
                                            ></span
                                          ></strong
                                        >
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td
                            class="column"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              vertical-align: top;
                              border-bottom: 2px solid #ffffff;
                              border-left: 2px solid #ffffff;
                              border-right: 2px solid #ffffff;
                              border-top: 2px solid #ffffff;
                            "
                            width="25%"
                          >
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              class="text_block"
                              role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word"
                              width="100%"
                            >
                              <tr>
                                <td
                                  style="
                                    padding-top: 15px;
                                    padding-right: 10px;
                                    padding-bottom: 15px;
                                    padding-left: 10px;
                                  "
                                >
                                  <div style="font-family: sans-serif">
                                    <div
                                      style="
                                        font-size: 14px;
                                        mso-line-height-alt: 16.8px;
                                        color: #555555;
                                        line-height: 1.2;
                                        font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
                                      "
                                    >
                                      <p style="margin: 0; font-size: 14px; text-align: center">
                                        <strong
                                          ><span style="font-size: 15px"
                                            ><a
                                              href="https://quiz.akello.co/"
                                              rel="noopener"
                                              style="text-decoration: none; color: #0068a5"
                                              target="_blank"
                                              title="Akello quiz"
                                              >Akello Quiz</a
                                            ></span
                                          ></strong
                                        >
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td
                            class="column"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              vertical-align: top;
                              border-bottom: 2px solid #ffffff;
                              border-left: 2px solid #ffffff;
                              border-right: 2px solid #ffffff;
                              border-top: 2px solid #ffffff;
                            "
                            width="25%"
                          >
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              class="text_block"
                              role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word"
                              width="100%"
                            >
                              <tr>
                                <td
                                  style="
                                    padding-top: 15px;
                                    padding-right: 10px;
                                    padding-bottom: 15px;
                                    padding-left: 10px;
                                  "
                                >
                                  <div style="font-family: sans-serif">
                                    <div
                                      style="
                                        font-size: 14px;
                                        mso-line-height-alt: 16.8px;
                                        color: #555555;
                                        line-height: 1.2;
                                        font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
                                      "
                                    >
                                      <p style="margin: 0; font-size: 14px; text-align: center">
                                        <strong
                                          ><span style="font-size: 15px"
                                            ><a
                                              href="https://preschool.akello.co"
                                              rel="noopener"
                                              style="text-decoration: none; color: #0068a5"
                                              target="_blank"
                                              title="Expose your child to quality education"
                                              >Akello Preschool</a
                                            ></span
                                          ></strong
                                        >
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td
                            class="column"
                            style="
                              mso-table-lspace: 0pt;
                              mso-table-rspace: 0pt;
                              font-weight: 400;
                              text-align: left;
                              vertical-align: top;
                              border-bottom: 2px solid #ffffff;
                              border-left: 2px solid #ffffff;
                              border-right: 2px solid #ffffff;
                              border-top: 2px solid #ffffff;
                            "
                            width="25%"
                          >
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              class="text_block"
                              role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word"
                              width="100%"
                            >
                              <tr>
                                <td
                                  style="
                                    padding-top: 15px;
                                    padding-right: 10px;
                                    padding-bottom: 15px;
                                    padding-left: 10px;
                                  "
                                >
                                  <div style="font-family: sans-serif">
                                    <div
                                      style="
                                        font-size: 14px;
                                        mso-line-height-alt: 16.8px;
                                        color: #555555;
                                        line-height: 1.2;
                                        font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
                                      "
                                    >
                                      <p style="margin: 0; font-size: 14px; text-align: center">
                                        <strong
                                          ><span style="font-size: 15px"
                                            ><a
                                              href="https://hub.akello.co"
                                              rel="noopener"
                                              style="text-decoration: none; color: #0068a5"
                                              target="_blank"
                                              title="You want to be a software engineer"
                                              >Akello Hub</a
                                            ></span
                                          ></strong
                                        >
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    
  </body>
</html> `;
};

export default AccountRegistration;
