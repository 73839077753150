import useMoveUserToTrash from "api/UserResource/useMoveUserToTrash";
import { ConfirmPopup } from "primereact/confirmpopup";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiX } from "react-icons/fi";

const DeleteUser = ({ elementId, userId }) => {
  const [visible, setVisible] = React.useState(false);

  const { mutateAsync, isLoading: userIsDeleting } = useMoveUserToTrash(userId);

  const accept = async () => {
    await mutateAsync();
  };

  return (
    <>
      <button
        type="button"
        className="appearance-none border-0"
        onClick={() => setVisible(true)}
        id={elementId}
      >
        {userIsDeleting ? (
          <AiOutlineLoading3Quarters size={20} className="animate-spin" />
        ) : (
          <FiX size={20} />
        )}
      </button>

      <ConfirmPopup
        target={document.getElementById(elementId)}
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete? This action is irreversable"
        icon="pi pi-exclamation-triangle"
        accept={accept}
      />
    </>
  );
};

export default DeleteUser;
