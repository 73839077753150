import InitApi from "api/InitApi";
import { deleteEmptyObjects, fetchConfig } from "utils";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";

const useGetExceptions = (queryParams) => {
	const { accessToken } = useTokenStore();

	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const params = queryParams ? deleteEmptyObjects(queryParams) : null;
		const response = await instance.get("/reported-exceptions", {
			params,
		});
		return response?.data;
	};

	return useQuery(["exceptions", queryParams], () => fetch(), {
		...fetchConfig,
	});
};

export default useGetExceptions;
