import ShowCategory from "admin/GenreCategory/ShowCategory";
import ShowGenre from "admin/GenreCategory/ShowGenre";
import useFetchCategories from "api/CategoryResource/useFetchCategories";
import useGenres from "api/GenreResource/useGenres";
import AdminPageLoader from "components/AdminPageLoader";
import Admin2 from "layouts/Admin2";
import React, { useState } from "react";

const Categories = () => {
	const { data: genres, isLoading: isLoadingGenres } = useGenres("yes");
	const { data: categories, isLoading: isLoadingCategories } =
		useFetchCategories("yes");

	const [searchCollection, setsearchCollection] = useState("");
	const [searchCategory, setsearchCategory] = useState("");

	if (isLoadingGenres || isLoadingCategories) {
		return <AdminPageLoader />;
	}

	return (
		<Admin2>
			<div className="flex flex-col gap-4 w-2/4 ">
				<div className="space-y-4 relative ">
					<h3 className=" sticky top-16 bg-white z-10 text-center">
						Book Categories
					</h3>
					<input
						type="text"
						placeholder="Filter categories"
						value={searchCategory}
						onChange={(e) => setsearchCategory(e.target.value)}
						className="border border-gray-400 w-full p-2 text-center"
					/>
					<div>
						{categories?.data
							?.filter((category) => {
								if (!searchCategory) return true;
								return category.name
									.toLocaleLowerCase()
									.includes(searchCategory.toLocaleLowerCase());
							})
							?.map((category) => (
								<ShowCategory
									key={category.id}
									categoryName={category?.name}
									booksCount={category?.books_count}
									categoryId={category?.id}
								/>
							))}
					</div>
				</div>

				<div className="space-y-4 relative">
					<h3 className=" sticky top-16 bg-white z-10 text-center">
						Book Collections
					</h3>
					<input
						type="text"
						placeholder="Filter collections"
						value={searchCollection}
						onChange={(e) => setsearchCollection(e.target.value)}
						className="border border-gray-400 w-full p-2 text-center"
					/>
					<div>
						{genres?.data?.data
							?.filter((genre) => {
								if (!searchCollection) return true;
								return genre.name
									.toLocaleLowerCase()
									.includes(searchCollection.toLocaleLowerCase());
							})
							?.map((genre) => (
								<ShowGenre
									key={genre.id}
									genreName={genre?.name}
									genreId={genre?.id}
									booksCount={genre?.books_count}
								/>
							))}
					</div>
				</div>
			</div>
		</Admin2>
	);
};

export default Categories;
