import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import useTokenStore from "store/tokenStore";
import toast from "react-hot-toast";

const useDeletePublisher = (publisherId, destructionLevel = "temporary") => {
	const { accessToken } = useTokenStore();
	const queryClient = useQueryClient();

	const destroy = async () => {
		const instance = await InitApi({ accessToken });
		const response = await instance.delete(
			`/publishers/${publisherId}?destruction_level=${destructionLevel}`
		);
		return response?.data;
	};

	return useMutation(destroy, {
		onSuccess: async (data) => {
			queryClient.invalidateQueries("publishers");
			toast.success(data.message);
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);
		},
	});
};

export default useDeletePublisher;
