import React from "react";
import { Form, Formik } from "formik";
import { phoneRegex } from "utils";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import useUpdateInstitution from "api/InstitutionResource/useUpdateInstitution";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";

const UpdateInstitution = ({ institution }) => {
	const initialValues = {
		name: institution?.name || "",
		level: institution?.level || "",
		address: institution?.address || "",
		country: institution?.country || "",
		contact_number: institution?.contact_number || "",
	};

	const validate = Yup.object().shape({
		name: Yup.string().required("Name of institution is required"),
		// level: Yup.string().required("Level is required"),
		// address: Yup.string().required("Address is required"),
		country: Yup.string().required("Country is required"),
		contact_number: Yup.string()
			.required("Contact number is required")
			.matches(phoneRegex, "Invalid phone number")
			.min(10, "Mobile number must have at least characters")
			.max(20, "Mobile number cannot exceed 20 characters"),
	});

	const { mutateAsync, isLoading } = useUpdateInstitution(institution?.id);

	const handleSave = async (data) => {
		await mutateAsync(data);
	};

	return (
		<div>
			<Formik initialValues={initialValues} validationSchema={validate} onSubmit={(data) => handleSave(data)}>
				{({ setFieldValue, values, touched, errors }) => (
					<Form className="mt-4 space-y-6">
						<FormField type="text" name="name" label="Name of Institutuion *" />
						<FormField type="text" name="country" label="Country *" />
						<FormField type="text" name="address" label="Physical Address" />
						<FormField
							name="contact_number"
							type="phone"
							label="Contact Number *"
							placeholder="contact_number"
							value={values.contact_number}
							_onChange={(phone) => {
								if (phone !== "+") values.contact_number = "+" + phone;
								else values.contact_number = phone;
							}}
							error={Boolean(errors.contact_number && touched.contact_number)}
							errorText={errors.contact_number}
						/>
						<FormField
							type="select"
							name="level"
							label="Level"
							options={[
								{ label: "Primary", value: "Primary" },
								{ label: "Secondary", value: "Secondary" },
								{ label: "Tertiary", value: "Tertiary" },
							]?.map((level) => ({
								label: level.label,
								value: level.value,
							}))}
						/>

						<div className=" flex justify-center border-t border-gray-300 pt-2">
							<button
								type="submit"
								className="px-4 py-2 rounded-full bg-akgreenest text-white flex gap-1 items-center justify-center"
							>
								{isLoading ? (
									<>
										Updating
										<AiOutlineLoading3Quarters size={20} className="animate-spin" />
									</>
								) : (
									<>
										Update <IoIosArrowForward size={20} />
									</>
								)}
							</button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default UpdateInstitution;

// address
// :
// null
// contact_number
// :
// "+263735285959"
// country
// :
// "Zimbabwe"
// created_at
// :
// "2022-11-09T10:42:49.000000Z"
// deleted_at
// :
// null
// id
// :
// 5
// level
// :
// "Primary"
// name
// :
// "Leonard Ganyire"
// number_of_users
// :
// null
// type
// :
// "School"
// updated_at
// :
// "2022-11-20T15:52:41.000000Z"
