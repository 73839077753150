import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { reactLocalStorage } from "reactjs-localstorage";
import { deleteEmptyObjects } from "utils";
import useTokenStore from "store/tokenStore";

const useAssignBooksToUsers = () => {
	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	const history = useHistory();

	const save = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);
		const response = await instance.post(`/books/assign`, body);
		return response.data;
	};

	return useMutation(save, {
		onSuccess: async (body) => {
			queryClient.invalidateQueries(["books", "institution"]);
			queryClient.invalidateQueries("myLibrary");
			toast.success(body.message);
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);

			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}
		},
	});
};

export default useAssignBooksToUsers;
