import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import OnNext from "./OnNext";
import OnPrev from "./OnPrev";
import SlideItem1 from "./SlideItem1";
import SlideItem2 from "./SlideItem2";
import SlideItem3 from "./SlideItem3";

const HomeSlider = () => {
  const [index, setIndex] = useState(0);

  function updateIndex(i) {
    if (index !== i) {
      setIndex(i);
    }
  }

  function _onNext() {
    setIndex(index === 2 ? 0 : index + 1);
  }

  function _onPrev() {
    setIndex(index === 0 ? 0 : index - 1);
  }

  return (
    <div className="w-full home-slider relative overflow-hidden">
      <OnNext _onNext={_onNext} />
      <OnPrev _onPrev={_onPrev} />
      <Carousel
        showThumbs={false}
        showStatus={false}
        interval={3800}
        autoPlay
        swipeable
        showIndicators={false}
        showArrows={false}
        infiniteLoop
        selectedItem={index}
        onChange={updateIndex}
      >
        <SlideItem1 />

        <SlideItem2 />

        <SlideItem3 />
      </Carousel>
    </div>
  );
};

export default HomeSlider;
