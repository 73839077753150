import DeleteUser from "admin/Users/DeleteUser";
import EditUser from "admin/Users/EditUser";
import useFetchMyInstitution from "api/InstitutionResource/useFetchMyInstitution";
import useFetchUsers from "api/UserResource/useFetchUsers";
import { ButtonLoader, CustomPaginator, DataTable } from "components";
import Admin from "layouts/Admin";
import { Column } from "primereact/column";
import React, { useState } from "react";
import AddInstitutionUser from "./AddInstitutionUser";
import AssignBooks from "./AssignBooks";
import AssignSubscription from "./AssignSubscription";

const InstitutionUsers = () => {
  //!

  const [firstPage, setFirstPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const { data: institution, isLoading: isInstitutionLoading } =
    useFetchMyInstitution();
  const [roleFilter, setRoleFilter] = React.useState({
    "filter[role_is]": "Student",
  });

  const { data: institutionUsers, isLoading: isLoadingInstitutionUsers } =
    useFetchUsers({
      ...roleFilter,
      "filter[institution_is]": institution?.data?.id,
      page: page,
      rows_per_page: rowsPerPage,
    });

  const cols = [
    { field: "id", header: "ID" },
    { field: "first_name", header: "First Name" },
    { field: "last_name", header: "Last Name" },
    { field: "email", header: "Email" },
    { field: "mobile_number", header: "Mobile Number" },
  ];

  const users = institutionUsers?.data?.records?.map((user) => {
    return {
      id: user.id,
      avatar: user.avatar,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      mobile_number: user.mobile_number,
      telephone: user.telephone,
      role_name: user.role_name,
      formatted_roles: user.role_name?.join(" , "),
    };
  });

  const avatarTemplate = (rowData) => {
    return (
      <div className="flex items-center gap-1">
        <img
          src={rowData?.avatar}
          alt="Avatar"
          className="h-8 w-8 rounded-full"
        />
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex justify-start items-center gap-1">
        <div className="space-y-1">
          <EditUser elementId={`edit-${rowData.id}`} userData={rowData} />
          <DeleteUser elementId={`button-${rowData.id}`} userId={rowData.id} />
        </div>
        <div className="space-y-1">
          {rowData?.role_name?.includes("Student") && (
            <>
              <AssignBooks elementId={`assign-${rowData.id}`} user={rowData} />

              <AssignSubscription
                elementId={`assign-subs-${rowData.id}`}
                user={rowData}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <Admin pageTitle="Institution Users" headerContent={<AddInstitutionUser />}>
      <div className="mb-4 flex gap-3 items-center">
        <span className="font-bold">Show</span>
        <select
          name="role"
          id="role"
          onChange={(e) =>
            setRoleFilter({
              ...roleFilter,
              "filter[role_is]": e.target.value,
            })
          }
          className="outline-none rounded py-1 px-2 border"
        >
          <option value="Student">students</option>
          <option value="Educator">educators</option>
        </select>
      </div>
      {isLoadingInstitutionUsers || isInstitutionLoading ? (
        <div className="max-h-screen flex justify-center">
          <ButtonLoader size={100} />
        </div>
      ) : (
        <div className="space-y-3">
          <DataTable
            data={users}
            cols={cols}
            prepend={<Column body={avatarTemplate} exportable={false} />}
            footer={
              <CustomPaginator
                firstPage={firstPage}
                rowsPerPage={rowsPerPage}
                setFirstPage={setFirstPage}
                setRowsPerPage={setRowsPerPage}
                totalRecords={institutionUsers?.data?.total_records}
                setPage={setPage}
              />
            }
          >
            <Column
              header="Actions"
              body={actionBodyTemplate}
              exportable={false}
            />
            {/* <Column header="Assign" body={avatarTemplate} exportable={false} /> */}
          </DataTable>
        </div>
      )}
    </Admin>
  );

  //!
};

export default InstitutionUsers;
