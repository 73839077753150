import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useChangePassword from "api/UserResource/useChangePassword";

const ChangePassword = () => {
	const initialValues = {
		current_password: "",
		password: "",
		password_confirmation: "",
	};

	const updateSchema = Yup.object().shape({
		current_password: Yup.string().required("Current password is required"),
		password: Yup.string()
			.min(6, "At least 6 characters required")
			.matches(/[a-zA-Z]/, "Must have at least one alphabet character")
			.matches(/[0-9]/, "Must have at least one digit")
			.required("Password required"),
		password_confirmation: Yup.string()
			.oneOf([Yup.ref("password")], "Passwords didn't match")
			.required("Password confirmation required"),
	});

	const { mutateAsync, isLoading } = useChangePassword();

	const handleSave = async (data) => {
		await mutateAsync(data);
	};

	return (
		<>
			<div>
				<div className="">
					<Formik
						initialValues={initialValues}
						validationSchema={updateSchema}
						onSubmit={(data) => handleSave(data)}
					>
						<Form className="space-y-8">
							<p className="p-4 rounded-lg border border-green-300 bg-green-50">
								Note that your password is used for logging in along with your
								email/username. Try to make your password difficult to guess but
								easy to remember for yourself.
							</p>

							<FormField
								type="password"
								name="current_password"
								label="Your current password *"
							/>

							<FormField
								type="password"
								name="password"
								label="New password*"
							/>

							<FormField
								type="password"
								name="password_confirmation"
								label="Confirm new password*"
							/>

							<div className="mt-4 flex items-center">
								<button
									type="submit"
									className="py-3 px-3 bg-akgreen w-full rounded-full text-white flex items-center justify-center gap-2 max-w-max"
								>
									Update password
									{isLoading && (
										<AiOutlineLoading3Quarters
											size={20}
											className="animate-spin"
										/>
									)}
								</button>
							</div>
						</Form>
					</Formik>
				</div>
			</div>
		</>
	);
};

export default ChangePassword;
