import { Container } from "layouts";
import UpdateProfileForm from "./UpdateProfileForm";
// import useProfile from "api/UserResource/useProfile";
import { DesktopHeader } from "components";
// import { Link } from "react-router-dom";
import PaddingX from "layouts/PaddingX";
import useAuthStore from "store/authStore";
import ProfileCard from "./ProfileCard";
import DeleteAccount from "./DeleteAccount";

const Profile = () => {
	const user = useAuthStore((state) => state.userProfile);

	return (
		<Container className="pb-5">
			<DesktopHeader shadow />

			<div className="mt-4">
				<PaddingX>
					<h2 className="font-cera-black text-center mb-4">Profile Information</h2>
					<div className="flex flex-col items-center justify-center gap-8">
						<div>
							<ProfileCard user={user} />
						</div>

						<div style={{ minWidth: 300 }}>
							<UpdateProfileForm user={user} />
							<DeleteAccount />
						</div>
					</div>
				</PaddingX>
			</div>
		</Container>
	);

	//!
};

export default Profile;
