import React from "react";
import { Editor } from "primereact/editor";
import InputError from "components/InputError";

const RichTextEditor = ({ value, onTextChange, error, height }) => {
  const editorHeader = (
    <>
      <span className="ql-formats">
        <select className="ql-size">
          <option value="small"></option>
          <option selected></option>
          <option value="large"></option>
        </select>

        <select className="ql-header">
          <option value="1"></option>
          <option value="2"></option>
          <option selected value="3">
            Heading
          </option>
          <option value="4"></option>
          <option value="5"></option>
        </select>

        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>

        <button className="ql-blockquote" aria-label="Block quote"></button>

        <button
          className="ql-list"
          value="ordered"
          aria-label="Ordered list"
        ></button>
        <button
          className="ql-list"
          value="bullet"
          aria-label="Unordered list"
        ></button>
      </span>
    </>
  );
  return (
    <div>
      <Editor
        style={{ height: height ?? "150px" }}
        value={value}
        onTextChange={onTextChange}
        headerTemplate={editorHeader}
        theme="snow"
      />

      {error && <InputError error={error} />}
    </div>
  );
};

export default RichTextEditor;
