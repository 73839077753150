import useEditCategory from "api/CategoryResource/useEditCategory";
import { TextField } from "components";
import { Form, Formik } from "formik";
import React from "react";
import { FcProcess } from "react-icons/fc";
import { FiEdit } from "react-icons/fi";
import * as Yup from "yup";
import DeleteCategory from "./DeleteCategory";

const ShowCategory = ({ categoryName, booksCount, categoryId }) => {
	//!

	const [showEditForm, setShowEditForm] = React.useState(false);

	const initialValues = {
		name: categoryName,
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Category name is required"),
	});

	const { mutateAsync, isLoading: isEditingCategory } =
		useEditCategory(categoryId);
	const handleSubmit = async (data) => {
		await mutateAsync(data);
		setShowEditForm(false);
	};

	return (
		<div className="bg-akgreener rounded">
			<div className="bg-akgreen rounded mr-2">
				<div className="shadow-sm bg-gray-100 border border-gray-200 rounded px-3 py-3 flex justify-between items-center gap-2 mb-2 mr-6">
					{showEditForm ? (
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={(data) => handleSubmit(data)}
						>
							{() => {
								return (
									<Form className="w-full flex justify-between items-center bg-gray-200 rounded p-1">
										<div className="rounded bg-gray-50 px-3 w-full mx-3">
											<TextField
												type="text"
												name="name"
												inputClasses="bg-gray-50 active rounded w-full outline-none "
												placeholder="Enter book category name"
											/>
										</div>

										<button
											type="submit"
											className="flex px-2 rounded  bg-gradient-to-r border-akgreener text-white from-akgreenest to-akgreen items-center gap-2"
										>
											<span>Update</span>
											{isEditingCategory && (
												<FcProcess
													size={20}
													className="animate-spin bg-gray-200 rounded-full"
												/>
											)}
										</button>
									</Form>
								);
							}}
						</Formik>
					) : (
						<>
							<div
								className="cursor-pointer w-full flex items-center gap-1"
								onClick={() => setShowEditForm(!showEditForm)}
							>
								<span>{categoryName}</span>
								<span className="text-xs font-bold">({booksCount} books)</span>
							</div>
							<div className="flex gap-1 items-center">
								<DeleteCategory
									elementId={`delete_category_${categoryId}`}
									categoryId={categoryId}
								/>

								<button
									className="border-0 transform -rotate-3"
									onClick={() => setShowEditForm(true)}
								>
									<FiEdit size={20} className="text-green-500" />
								</button>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);

	//!
};

export default ShowCategory;
