import { Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";
import FormField from "components/v2/FormField";
import { FiX } from "react-icons/fi";
import { deleteEmptyObjects } from "utils";
import { useEffect, useState } from "react";

const BooksFilter = ({
	visible,
	setVisible,
	publishers,
	genres,
	categories,
	filters,
	countNumberOfFiltersSet = 0,
}) => {
	const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

	useEffect(() => {
		if (countNumberOfFiltersSet > 0) {
			setShowAdvancedSearch(true);
		} else {
			setShowAdvancedSearch(false);
		}
		return () => {
			setShowAdvancedSearch(false);
		};
	}, [countNumberOfFiltersSet]);

	const initialValues = {
		searchTerm: filters.searchTerm,
		categoryIs: filters.categoryIs,
		genreIs: filters?.genreIs?.split("*") || [],
		publisherIs: filters.publisherIs,
		pricingModelIs: filters.pricingModelIs,
		page: filters.page,
		rowsPerPage: filters.rowsPerPage,
		firstPage: filters.firstPage,
	};

	const handleSubmit = (values) => {
		const params = {
			first_page: values.firstPage,
			rows: values.rowsPerPage,
			page: values.page,
			category_is: values.categoryIs,
			genre_is: values.genreIs?.join("*") || "",
			publisher_is: values.publisherIs,
			pricing_model_is: values.pricingModelIs,
			search_term: values.searchTerm,
			open_filters: "",
		};

		setVisible(false);

		const queryParams = deleteEmptyObjects(params);

		filters.changeAllFiltersAtOnce(queryParams);
	};

	const handleClearFilters = () => {
		const params = {
			first_page: "",
			rows: "",
			page: "",
			category_is: "",
			genre_is: "",
			publisher_is: "",
			pricing_model_is: "",
			search_term: "",
			open_filters: "",
		};
		setVisible(false);

		const queryParams = deleteEmptyObjects(params);

		filters.changeAllFiltersAtOnce(queryParams);
	};

	const handleCloseModal = () => {
		setVisible(false);
		filters.changeOpenFilters("");
	};

	return (
		<Dialog
			showHeader={false}
			visible={visible}
			onHide={() => setVisible(false)}
			contentClassName="rounded-md font-sans relative"
			position="top"
		>
			<button onClick={handleCloseModal} className="absolute top-4 right-4">
				<FiX size={25} />
			</button>

			<div style={{ minWidth: 300, maxWidth: 500 }} className="mt-14 pb-2 px-4">
				<p className="font-bold text-base">
					Search and/or apply filters to look for specific book(s)
				</p>

				<Formik initialValues={initialValues} onSubmit={handleSubmit}>
					{({ setFieldValue, values }) => {
						const handleChangeGenre = (value) => {
							if (values.genreIs.includes(value)) {
								setFieldValue(
									"genreIs",
									values.genreIs.filter((genre) => genre !== value)
								);
							} else {
								setFieldValue("genreIs", [...values.genreIs, value]);
							}
						};

						return (
							<Form className="space-y-6 mt-4 pt-2">
								<div className="flex items-center justify-between gap-2">
									<div className="w-full">
										<FormField
											name="searchTerm"
											label="Search title, author, or ISBN"
										/>
									</div>

									{!showAdvancedSearch && values.searchTerm?.length > 0 && (
										<button
											type="submit"
											className="px-2 py-2 rounded-md bg-akgreener text-white transition-all duration-200 ease-in-out hover:bg-akgreen focus:outline-none "
											style={{ width: 100 }}
										>
											Search
										</button>
									)}
								</div>

								<div className="flex flex-col items-start">
									<button
										type="button"
										onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
										className="bg-transparent text-blue-900 text-lg font-bold"
									>
										{showAdvancedSearch ? "Hide" : "Show"} advanced search
									</button>

									{countNumberOfFiltersSet > 0 && !showAdvancedSearch && (
										<button
											type="button"
											onClick={handleClearFilters}
											className="border-2 border-akgreener px-4 py-2 rounded-md"
										>
											Clear filters ({countNumberOfFiltersSet})
										</button>
									)}
								</div>

								<div
									className={`space-y-4 ${
										showAdvancedSearch ? "block" : "hidden"
									}  `}
								>
									<div>
										<p className="font-bold">Filter by book collection(s)</p>
										<div className="grid grid-cols-2 gap-x-4 gap-y-1 mt-2">
											{genres?.map((genre) => (
												<label
													key={genre?.id}
													htmlFor={`genre.${genre?.id}`}
													className={`flex gap-2 items-center px-3 py-2 rounded-md  cursor-pointer transition-all duration-200 ease-in-out bg-gray-200 border-2 ${
														values?.genreIs?.includes(genre?.name)
															? "border-akgreen "
															: "border-transparent hover:border-akgreen  "
													}`}
												>
													<input
														type="checkbox"
														checked={values?.genreIs?.includes(genre?.name)}
														id={`genre.${genre?.id}`}
														name="genreIs"
														value={genre?.name}
														onChange={(e) => handleChangeGenre(e.target.value)}
														className="h-4 w-4 rounded-md border-2 border-transparent focus:ring-2 focus:ring-akgreener focus:ring-offset-2 focus:ring-offset-gray-200 transition-all duration-200 ease-in-out"
													/>
													<span title={genre?.name} className="truncate w-40">
														{genre?.name}
													</span>
												</label>
											)) || []}
										</div>
									</div>

									<select
										name="publisherIs"
										value={values?.publisherIs}
										onChange={(e) =>
											setFieldValue("publisherIs", e.target.value)
										}
										className="w-full h-10 rounded-lg px-2 bg-gray-100"
									>
										<option label="- Filter by publisher -"></option>
										{publishers?.map((publisher) => (
											<option
												key={publisher?.id}
												value={publisher.id?.toString()}
											>
												{publisher.name}
											</option>
										))}
									</select>

									<select
										name="categoryIs"
										value={values?.categoryIs}
										onChange={(e) =>
											setFieldValue("categoryIs", e.target.value)
										}
										className="w-full h-10 rounded-lg px-2 bg-gray-100"
									>
										<option label="- Filter by category -"></option>
										{categories?.map((category) => (
											<option key={category?.name} value={category.name}>
												{category.name}
											</option>
										))}
									</select>

									<select
										name="pricingModelIs"
										value={values?.pricingModelIs}
										onChange={(e) =>
											setFieldValue("pricingModelIs", e.target.value)
										}
										className="w-full h-10 rounded-lg px-2 bg-gray-100"
									>
										<option label="- Filter by pricing model -"></option>
										<option value="sellable">Purchase only</option>
										<option value="subscription">Subscription only</option>
										<option value="free">Free books</option>
										<option value="all">Purchase and subscription</option>
									</select>

									<div className="flex justify-between item-center">
										<button
											type="button"
											onClick={handleCloseModal}
											className="border-2 border-transparent bg-gray-200 text-akgreener px-4 py-2 rounded-md"
										>
											Close
										</button>

										<div className="flex items-center justify-end item-center gap-2">
											{countNumberOfFiltersSet > 0 && (
												<button
													type="button"
													onClick={handleClearFilters}
													className="border-2 border-akgreener px-4 py-2 rounded-md"
												>
													Clear filters ({countNumberOfFiltersSet})
												</button>
											)}

											<button
												type="submit"
												className="border-2 border-transparent bg-akgreener text-white px-4 py-2 rounded-md"
												style={{ width: 100 }}
											>
												Search
											</button>
										</div>
									</div>
								</div>
							</Form>
						);
					}}
				</Formik>
			</div>
		</Dialog>
	);
};

export default BooksFilter;
