import useActivatePublisher from "api/PublisherResource/useActivatePublisher";
import { InputSwitch } from "primereact/inputswitch";
import React, { useState } from "react";

const PublisherStatus = ({ status, publisherId }) => {
	const [checked, setChecked] = useState(Boolean(status));

	const { mutate } = useActivatePublisher(publisherId);

	const handleSwitch = (value) => {
		if (value === true) {
			setChecked(value);
			mutate();
		}
	};

	return (
		<>
			<InputSwitch
				trueValue={true}
				falseValue={false}
				checked={checked}
				onChange={(e) => handleSwitch(e.value)}
			/>
		</>
	);
};

export default PublisherStatus;
