import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import useTokenStore from "store/tokenStore";
import useOverlayStore from "store/overlayStore";
import { deleteEmptyObjects } from "utils";

const useCreateSubjectGroup = () => {
	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	const { setShowCreateSubjectGroups } = useOverlayStore();

	const history = useHistory();

	const save = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);

		const res = await instance.post("/subject-groups", body);
		return res.data;
	};

	return useMutation(save, {
		onSuccess: async ({ data: body }) => {
			queryClient.invalidateQueries("subject-groups");
			setShowCreateSubjectGroups(false);
			toast.success("Subject group created successfully");
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);

			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}
		},
	});
};

export default useCreateSubjectGroup;
