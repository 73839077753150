import useBooks from "api/BookResource/useBooks";
import useCheckIfUserIsSubscribed from "api/SubscriptionsResource/useCheckIfUserIsSubscribed";
import { BookListItem, CustomPaginator, DesktopHeader } from "components";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import { useEffect, useMemo, useState } from "react";
import BooksFilter from "./BooksFilter";
import useBooksFilters from "./useBooksFilters";
import BooksLoader from "./BooksLoader";
import useFetchCategories from "api/CategoryResource/useFetchCategories";
import useFetchPublishers from "api/PublisherResource/useFetchPublishers";
import useGenres from "api/GenreResource/useGenres";
import { IoFilterOutline } from "react-icons/io5";
import BookPaginationLoader from "./BookPaginationLoader";
import HorizontalBookFilter from "./HorizontalBookFilter";
import { deleteEmptyObjects } from "utils";

const Books = () => {
	const filters = useBooksFilters();

	const [showFiltersForm, setShowFiltersForm] = useState(false);

	useEffect(() => {
		if (filters?.openFilters === "true") {
			setShowFiltersForm(true);
		}
		return () => {
			setShowFiltersForm(false);
		};
	}, [filters.openFilters]);

	const handleOpenFilters = () => {
		setShowFiltersForm(true);
		filters.changeOpenFilters("true");
	};

	const countNumberOfFiltersSet = useMemo(
		() =>
			[
				filters.searchTerm,
				filters.categoryIs,
				filters.genreIs,
				filters.publisherIs,
				filters.pricingModelIs,
			]?.filter((filter) => filter !== "").length,
		[filters]
	);

	const { data: isSubscribed, isLoading: isLoadingSubscribed } =
		useCheckIfUserIsSubscribed();
	const { data: categories, isLoading: isLoadingCategories } =
		useFetchCategories();
	const { data: publishers, isLoading: isLoadingPublishers } =
		useFetchPublishers();
	const { data: genres, isLoading: isLoadingGenres } = useGenres();

	const { data: books, isLoading: isLoadingBooks } = useBooks({
		page: filters.page,
		rows_per_page: filters.rowsPerPage,
		"filter[is_active]": filters.isActive,
		"filter[category_is]": filters.categoryIs,
		"filter[genre_is]": filters.genreIs,
		"filter[is_free]": filters.isFree,
		"filter[publisher_is]": filters.publisherIs,
		"filter[pricing_model_is]": filters.pricingModelIs,
		searchTerm: filters.searchTerm,
	});

	const disableRowsPerPageChanger = useMemo(() => {
		if (
			parseInt(filters?.page) === parseInt(books?.data?.total_pages) &&
			parseInt(filters?.page) !== 1
		) {
			return true;
		}
		return false;
	}, [filters?.page, books]);

	const handleChangeRowsPerPage = (value) => {
		if (value === filters?.rowsPerPage) return;
		filters.changeRowsPerPage(value);
	};

	const handleClearFilters = () => {
		const params = {
			first_page: "",
			rows: "",
			page: "",
			category_is: "",
			genre_is: "",
			publisher_is: "",
			pricing_model_is: "",
			search_term: "",
			open_filters: "",
		};

		const queryParams = deleteEmptyObjects(params);

		filters.changeAllFiltersAtOnce(queryParams);
	};

	return (
		<Container className="flex flex-col w-full min-h-screen">
			<DesktopHeader shadow />

			<PaddingX>
				{(function () {
					if (
						isLoadingBooks ||
						isLoadingSubscribed ||
						isLoadingCategories ||
						isLoadingPublishers ||
						isLoadingGenres
					) {
						return (
							<>
								<div className="pt-5 lg:pt-0">
									<BookPaginationLoader />
								</div>

								<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-x-4 gap-y-6 place-items-stretch mt-4">
									{Array.from({ length: 18 }, (_, i) => (
										<BooksLoader key={i} />
									))}
								</div>
							</>
						);
					}

					return (
						<>
							<div className="flex justify-end xs:justify-between items-center pt-2 mt-4">
								<div className="flex gap-4 items-center">
									<div className="hidden sm:block md:hidden">
										<p className="text-base">
											Showing page {filters.page} of {books?.data?.total_pages}{" "}
											page(s)
										</p>
										<p className="font-bold">
											{books?.data?.records?.length || 0}/
											{books?.data?.total_records} books
										</p>
									</div>

									<div className="hidden md:flex items-center gap-4">
										<HorizontalBookFilter
											publishers={publishers?.data}
											categories={categories?.data}
											genres={genres?.data?.data}
											filters={filters}
										/>

										{countNumberOfFiltersSet > 0 && (
											<button
												type="button"
												className="text-xs py-1 border border-red-300 bg-gray-50 h-10 rounded-md px-2 text-red-500 flex items-center"
												onClick={handleClearFilters}
											>
												Clear filters({countNumberOfFiltersSet})
											</button>
										)}
										<button
											className="text-xs text-blue-800 flex flex-col gap-1 transform hover:scale-105 transition-all duration-200 ease-in-out text-left"
											type="button"
											onClick={handleOpenFilters}
										>
											<span>Advanced filters</span>
											<span className="w-16 border-b-4 border-blue-400"></span>
										</button>
									</div>
								</div>

								<div className="flex items-center justify-end gap-2 pr-0">
									<span>Show</span>
									<select
										name="rowsPerPage"
										id="rowsPerPage"
										value={filters.rowsPerPage}
										disabled={disableRowsPerPageChanger}
										onChange={(e) => handleChangeRowsPerPage(e.target.value)}
										className="border border-gray-300 bg-white rounded h-10 px-2"
									>
										<option value="10">10</option>
										<option value="20">20</option>
										<option value="40">40</option>
										<option value="50">50</option>
									</select>

									<button
										className="relative md:hidden flex items-center gap-1 bg-gray-200 px-2 py-1 rounded border border-gray-300"
										type="button"
										onClick={handleOpenFilters}
									>
										<span className="absolute -top-2 -right-1 rounded-full flex items-center justify-center h-7 w-7 bg-akgreen text-white">
											{countNumberOfFiltersSet}
										</span>
										<span>Filter</span>
										<IoFilterOutline size={30} />
									</button>
								</div>
							</div>

							<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-x-4 gap-y-6 mt-6">
								{books?.data?.records
									?.sort((book1, book2) => book2.rank - book1.rank)
									?.map((book) => (
										<BookListItem
											key={book.id}
											coverUrl={book?.book_cover?.url}
											title={book?.title}
											description={book?.description}
											price={book?.price}
											zwl_price={book?.zwl_price}
											credits={book.credits}
											publisher={
												book?.publisher ||
												`${book?.user?.first_name} ${book?.user?.last_name}`
											}
											author={book?.author}
											reviews={book.ratings}
											bookId={book.id}
											sellable={book.sellable}
											rating={book.rating}
											isbn={book.rating}
											isPurchased={book.is_purchased}
											inLibrary={book.in_library}
											subscription={book.subscription}
											hasSubscription={isSubscribed?.data}
											category={book?.category?.name}
											isFree={book?.isFree}
											genres={book?.genre?.map((genre) => [
												genre.id,
												genre.name,
											])}
										/>
									))}
							</div>

							<div className="flex-1 flex items-end mt-6 border-t pt-6">
								<CustomPaginator
									firstPage={filters.firstPage}
									setFirstPage={filters.changeFirstPage}
									rowsPerPage={filters.rowsPerPage}
									setRowsPerPage={filters.changeRowsPerPage}
									totalRecords={books?.data?.total_records}
									setPage={filters.changePage}
									showRowsPerPageDropdown={false}
								/>
							</div>

							<BooksFilter
								visible={showFiltersForm}
								setVisible={setShowFiltersForm}
								publishers={publishers?.data}
								categories={categories?.data}
								genres={genres?.data?.data}
								filters={filters}
								countNumberOfFiltersSet={countNumberOfFiltersSet}
							/>
						</>
					);
				})()}
			</PaddingX>
		</Container>
	);
};

export default Books;
