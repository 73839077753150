import useCreateUpdateRate from "api/RatesResource/useCreateUpdateRate";
import useFetchRates from "api/RatesResource/useFetchRates";
import { DataTable, SelectField, TextField } from "components";
import { Form, Formik } from "formik";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsCurrencyExchange } from "react-icons/bs";
import { FcProcess } from "react-icons/fc";
import { FiEdit } from "react-icons/fi";
import Moment from "react-moment";
import { activeCurrencies, currencyCodes } from "utils";
import * as Yup from "yup";
import DeleteCurrency from "./DeleteCurrency";

const CurrencyRates = () => {
	//!

	const [display, setDisplay] = React.useState(false);

	const [showSubmitRateForm, setShowSubmitRateForm] = React.useState(false);

	const { data: rates, isLoading } = useFetchRates();

	const initialValues = {
		to: "",
		rate: 1,
	};

	const validate = Yup.object().shape({
		to: Yup.string()
			.required("Currency code required")
			.oneOf(
				[activeCurrencies.USD.code, activeCurrencies.ZWL.code],
				`Only ${activeCurrencies.USD.label} and ${activeCurrencies.ZWL.label} currencies are supported`
			),
	});

	const { mutateAsync, isLoading: isSubmittingRate } = useCreateUpdateRate();

	const handleSubmit = async (data) => {
		let rate = data.to === activeCurrencies.USD.code ? 1 : data.rate;
		let is_active = data.to === activeCurrencies.USD.code ? 1 : 0;
		await mutateAsync({ ...data, rate: rate, is_active: is_active });
		setShowSubmitRateForm(false);
	};

	const handleEdit = async (data) => {
		let rate = data.to === activeCurrencies.USD.code ? 1 : data.rate;
		let is_active = data.to === activeCurrencies.USD.code ? 1 : 0;
		await mutateAsync({ ...data, rate: rate, is_active: is_active });
		setShowEditRateField("");
	};

	const [showEditRateField, setShowEditRateField] = React.useState("");

	const cols = [
		{ field: "from", header: "From" },
		{ field: "to", header: "To" },
		{ field: "rate", header: "Prevailing Rate" },
		{ field: "updated_at", header: "Last Updated On" },
	];

	const currencyRates = rates?.data?.map((rate) => {
		return {
			id: rate.id,
			from: rate.from,
			to: rate.to,
			rate:
				showEditRateField === rate.to ? (
					<input
						name="editRate"
						type="number"
						step="0.01"
						min="1"
						placeholder="Enter..."
						className="border px-2 w-20 border-gray-400 rounded text-sm"
						onBlur={(e) =>
							handleEdit({
								to: rate.to,
								rate: e.target.value,
							})
						}
					/>
				) : (
					`1:${rate.rate}`
				),
			updated_at: (
				<Moment format="YYYY-MM-DD HH:mm:ss">{rate.updated_at}</Moment>
			),
		};
	});

	const actionBodyTemplate = (rowData) => {
		return (
			<div className="flex justify-start gap-1 items-center">
				<FiEdit
					size={25}
					className="text-gray-600 cursor-pointer"
					onClick={() => setShowEditRateField(rowData?.to)}
				/>
				<DeleteCurrency
					elementId={`button-${rowData.id}`}
					rateId={rowData.id}
				/>
			</div>
		);
	};

	return (
		<div>
			<button
				type="button"
				onClick={() => setDisplay(true)}
				className="px-2 py-1 text-sm rounded bg-gradient-to-b from-akgreenest to-akgreen text-gray-100 border border-akgreen"
			>
				Rates
			</button>

			<Dialog
				visible={display}
				onHide={() => setDisplay(false)}
				header={
					<div className="flex gap-4">
						<p>Currency rates</p>
					</div>
				}
				position="top"
				className="w-4/5"
				icons={
					<button
						type="button"
						onClick={() => setShowSubmitRateForm(true)}
						className="text-md bg-gradient-to-br from-akgreenest to-akgreen text-gray-100 px-3 py-1 rounded-lg"
					>
						Create new currency rate{" "}
						<BsCurrencyExchange className="inline" size={30} />
					</button>
				}
			>
				{showSubmitRateForm && (
					<Formik
						initialValues={initialValues}
						validationSchema={validate}
						onSubmit={(data) => handleSubmit(data)}
					>
						{(formik) => {
							return (
								<Form>
									<div className="flex gap-2 items-center mb-3 justify-center">
										<SelectField
											name="to"
											label="Currency"
											placeholder="-Select currency"
										>
											{currencyCodes.map((code, index) => (
												<option value={code.currency_code} key="index">
													{code.currencyCodeLabel} - {code.country}
												</option>
											))}
										</SelectField>

										<TextField
											name="rate"
											type="number"
											min="1"
											step="0.01"
											label="Rate"
										/>

										<button
											type="submit"
											className="rounded-xl px-3 mt-4 py-2 border border-akgreen flex items-center gap-2"
										>
											<span>Submit</span>
											{isSubmittingRate && (
												<FcProcess
													size={20}
													className="animate-spin bg-gray-200 rounded-full"
												/>
											)}
										</button>

										<button
											type="button"
											className="mt-4"
											onClick={() => setShowSubmitRateForm(false)}
										>
											<AiOutlineCloseCircle size={25} />
										</button>
									</div>
								</Form>
							);
						}}
					</Formik>
				)}

				{!isLoading && (
					<DataTable data={currencyRates} cols={cols}>
						<Column
							header="Actions"
							body={actionBodyTemplate}
							exportable={false}
						/>
					</DataTable>
				)}
			</Dialog>
		</div>
	);
};

export default CurrencyRates;
