import useUserCartItems from "api/CartResource/useUserCartItems";
import useFetchCurrencyByCode from "api/RatesResource/useFetchCurrencyByCode";
import {
	BookInCart,
	ButtonLoader,
	DesktopHeader,
	Empty,
	ModalNotification,
} from "components";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import { Divider } from "primereact/divider";
import React from "react";
import {
	AiOutlineCheckCircle,
	AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import useCurrencyStore from "store/currencyStore";
import EcocashPayment from "./EcocashPayment";
import PayPalPayment from "./PayPalPayment";
import RedeemStars from "./RedeemStars";
import Price from "./Price";
import usePrice from "hooks/usePrice";
import { BiCheck } from "react-icons/bi";
import EcocashPaymentUsd from "./EcocashPaymentUsd";
import { FiX } from "react-icons/fi";
import useClearCart from "api/CartResource/useClearCart";
import {
	activeCurrencies,
	activePaymentMethods,
	getActiveCurrencyCodeLabel,
} from "utils";

const Checkout = () => {
	const { currency, paymentMethod, setPaymentMethod } = useCurrencyStore();

	const { data: currencyRate, isLoading: isLoadingCurrency } =
		useFetchCurrencyByCode(currency);

	const { data: previewOrder, isLoading: previewIsLoading } =
		useUserCartItems();

	const [showPaymentCompletedModal, setShowPaymentCompletedModal] =
		React.useState(false);

	const [paymentIsSuccessful, setPaymentIsSuccessful] = React.useState(false);

	const [totalPaid, setTotalPaid] = React.useState(0);
	const [bookCount, setBookCount] = React.useState(0);
	const [bookItems, setBookItems] = React.useState(null);

	const grandTotal = usePrice({
		sellable: true,
		usdPrice: previewOrder?.data?.data?.grand_total,
		zwlPrice: previewOrder?.data?.data?.grand_total_zwl,
		isFree: false,
	});

	// console.log({ previewOrder });

	const credits = React.useMemo(() => {
		return previewOrder?.data?.data?.cart_items?.reduce(
			(prevValue, currentValue) =>
				prevValue +
				Number(currentValue.credits) * Number(currentValue.quantity),
			0
		);
	}, [previewOrder]);

	const { mutateAsync, isLoading: isClearingCart } = useClearCart();

	const clearCart = async () => {
		await mutateAsync();
	};

	const paymentOptionsButtons = [
		{
			paymentMethod: activePaymentMethods.ecocashUsd.name,
			label: activePaymentMethods.ecocashUsd.label,
		},
		{
			paymentMethod: activePaymentMethods.ecocashZwl.name,
			label: activePaymentMethods.ecocashZwl.label,
		},
		{
			paymentMethod: activePaymentMethods.paypal.name,
			label: activePaymentMethods.paypal.label,
		},
		{
			paymentMethod: activePaymentMethods.stars.name,
			label: activePaymentMethods.stars.label,
		},
	];

	if (paymentIsSuccessful) {
		return (
			<Container>
				<DesktopHeader shadow />

				<div
					className="mx-auto p-4 mt-12 text-center"
					style={{ maxWidth: 400 }}
				>
					<div className="flex flex-col justify-center items-center gap-2 text-center">
						<span className="rounded-full h-32 w-32 border border-akgreen flex items-center justify-center ">
							<BiCheck size={80} className="text-akgreen" />
						</span>

						<p className="text-base">
							{paymentMethod === activePaymentMethods.stars.name ? (
								<>
									Your stars were redeemed successful and you can now access
									your books for reading through the reader app
								</>
							) : (
								<>
									Your {paymentMethod} payment was processed and you can now
									access your books for reading through the reader app.
								</>
							)}
						</p>

						<p>Your can view your purchased book(s) in your library</p>

						<Link
							to="/my-library"
							className="text-base text-akgreenest border border-akgreener px-4 py-4 rounded-xl hover:bg-akgreener hover:text-white"
						>
							Visit your library
						</Link>
					</div>

					<p className="mt-6">
						{paymentMethod === activePaymentMethods.stars.name ? (
							<>
								If your stars were deducted from your Akello Smartlearning
								account but the book(s) are still not available in your library
								and are not accessible for reading on your Akello mobile
								application, please contact us for assistance{" "}
								<Link to="/support" className="font-bold text-blue-800">
									here
								</Link>
							</>
						) : (
							<>
								If money was deducted from your wallet (Ecocash, Paypal, Card)
								but the book(s) are still not available in your library and are
								not accessible for reading on your Akello mobile application,
								please contact us for assistance{" "}
								<Link to="/support" className="font-bold text-blue-800">
									here
								</Link>
							</>
						)}
					</p>
				</div>
			</Container>
		);
	}

	return (
		<Container>
			{/* <Header /> */}

			<DesktopHeader shadow />

			<div className="mt-6">
				<PaddingX>
					<div className="flex justify-end mb-4">
						{previewOrder?.data?.data?.cart_items.length > 0 && (
							<button
								type="button"
								className="rounded-full px-4 py-2 xs:py-3 bg-akgreener text-white font-bold flex gap-1 items-center text-base"
								onClick={clearCart}
							>
								<span>Clear cart </span>

								{isClearingCart ? (
									<AiOutlineLoading3Quarters
										size={25}
										className=" animate-spin "
									/>
								) : (
									<FiX size={25} />
								)}
							</button>
						)}
					</div>

					{previewIsLoading || isLoadingCurrency ? (
						<div className="flex justify-center items-center">
							<ButtonLoader size={100} />
						</div>
					) : !previewOrder?.data?.data?.cart_items.length ? (
						<Empty cartIconSize={200}>
							<div className="flex flex-col items-center gap-6">
								<p className="text-center text-lg">No books in the cart</p>
								<Link
									to="/books"
									className="bg-akgreener px-6 py-4 text-base text-white rounded-full"
								>
									Continue shopping
								</Link>
							</div>
						</Empty>
					) : (
						<div className="grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-5 gap-8 mt-4 pb-4">
							<div className="col-span-2 lg:col-span-3 flex flex-col lg:flex-row justify-between lg:justify-start gap-8">
								<div className="">
									<h3 className="mb-3 font-cera-black">Preview Order</h3>

									<div className="flex gap-4 flex-row lg:flex-col overflow-x-auto pb-4 ">
										{previewOrder?.data?.data?.cart_items?.map((cart) => (
											<BookInCart
												key={cart.id}
												title={cart?.book_title}
												coverUrl={cart?.book_cover?.url}
												author={cart?.author}
												bookId={cart?.book_id}
												quantity={cart?.quantity}
												// publisher={cart?.user}
												price={cart?.price}
												zwl_price={cart?.zwl_price}
												currencySymbol={cart?.currency_symbol}
												cartId={cart?.id}
											/>
										))}
									</div>
								</div>

								<div>
									<h3 className="font-cera-black">Order Summary</h3>
									<p>Currency - {getActiveCurrencyCodeLabel(currency)}</p>
									<Divider className="border-b border-akgreener" />
									{previewOrder?.data?.data?.cart_items?.map((cart) => (
										<div className="flex justify-between gap-2" key={cart.id}>
											<section>
												{cart.quantity} x {cart.book_title}
											</section>
											<section>
												{getActiveCurrencyCodeLabel(currency)}
												<Price
													sellable={true}
													usdPrice={cart?.price}
													zwlPrice={cart?.zwl_price}
													isFree={false}
												/>
											</section>
										</div>
									))}
									<Divider />
									<div className="flex justify-between">
										<section>SUBTOTAL</section>
										<section>
											<Price
												sellable={true}
												usdPrice={previewOrder?.data?.data?.grand_total}
												zwlPrice={previewOrder?.data?.data?.grand_total_zwl}
												isFree={false}
											/>
										</section>
									</div>
									<Divider className="border-b border-akgreener" />
									<div className="flex justify-between ">
										<section className="font-bold">TOTAL</section>
										<section>
											<Price
												sellable={true}
												usdPrice={previewOrder?.data?.data?.grand_total}
												zwlPrice={previewOrder?.data?.data?.grand_total_zwl}
												isFree={false}
											/>
										</section>
									</div>
								</div>
							</div>

							<div className="col-span-2 lg:col-span-2">
								<h3 className="font-cera-black">Payment Options</h3>
								<div className="mt-1 grid grid-cols-2 gap-2 justify-between items-center border-b-2 pb-3 border-dashed border-akgreen mb-3">
									{paymentOptionsButtons.map((button, index) => (
										<button
											type="button"
											className={`py-2 px-3 font-bold rounded border border-green-300 ${
												paymentMethod === button.paymentMethod
													? "bg-akgreen text-white border-2 border-transparent"
													: ""
											}`}
											onClick={() => setPaymentMethod(button.paymentMethod)}
										>
											{button.label}
										</button>
									))}
								</div>

								<div className="mt-6">
									{paymentMethod === activePaymentMethods.stars.name &&
										currency === activeCurrencies.ZWL.code && (
											<RedeemStars
												credits={credits}
												grandTotal={grandTotal}
												books={previewOrder?.data?.data?.cart_items?.map(
													(cartItem) => [cartItem.book_id, cartItem.quantity]
												)}
												setBookCount={setBookCount}
												setTotalPaid={setTotalPaid}
												setBookItems={setBookItems}
												setPaymentIsSuccessful={setPaymentIsSuccessful}
											/>
										)}

									{paymentMethod === activePaymentMethods.ecocashZwl.name &&
										currency === activeCurrencies.ZWL.code && (
											<EcocashPayment
												grandTotal={grandTotal}
												books={previewOrder?.data?.data?.cart_items?.map(
													(cartItem) => [cartItem.book_id, cartItem.quantity]
												)}
												setPaymentIsSuccessful={setPaymentIsSuccessful}
											/>
										)}

									{paymentMethod === activePaymentMethods.ecocashUsd.name && (
										<EcocashPaymentUsd
											grandTotal={grandTotal}
											books={previewOrder?.data?.data?.cart_items?.map(
												(cartItem) => [cartItem.book_id, cartItem.quantity]
											)}
											setPaymentIsSuccessful={setPaymentIsSuccessful}
										/>
									)}

									{paymentMethod === activePaymentMethods.paypal.name &&
										currency === activeCurrencies.USD.code && (
											<PayPalPayment
												grandTotal={grandTotal}
												setPaymentIsSuccessful={setPaymentIsSuccessful}
												books={previewOrder?.data?.data?.cart_items?.map(
													(cartItem) => [cartItem.book_id, cartItem.quantity]
												)}
											/>
										)}
								</div>
							</div>
						</div>
					)}

					<ModalNotification
						visible={showPaymentCompletedModal}
						setVisible={setShowPaymentCompletedModal}
						title="Payment successful!!"
					>
						<div className="flex items-center gap-2 ">
							<div>
								<AiOutlineCheckCircle size={130} className="" />
							</div>
							<div>
								<p>
									Your <strong>{paymentMethod}</strong> payment was successful.
									Details are as follows:
								</p>
								<ul className="list-disc list-inside text-akgreener">
									<li>
										Total payment:{" "}
										<strong>${Number(totalPaid).toFixed(2)}</strong>
									</li>
									<li>
										Number of books: <strong>{bookCount}</strong>
									</li>
								</ul>
								<p>
									Go to{" "}
									<Link
										to="/my-library"
										className="text-blue-700 font-bold underline"
										onClick={() => setShowPaymentCompletedModal(false)}
									>
										My library
									</Link>{" "}
									to view purchased books
								</p>
							</div>
						</div>

						<hr className="my-4 border-2 border-gray-400" />

						<div className="grid grid-cols-1 sm:grid-cols-2 gap-1 space-y-2">
							{bookItems?.map((bookItem) => (
								<BookInCart
									key={bookItem.id}
									title={bookItem?.book_title}
									coverUrl={bookItem?.book_cover?.url}
									author={bookItem?.author}
									quantity={bookItem?.quantity}
									publisher={bookItem?.user}
									price={(
										bookItem?.price * Number(currencyRate?.data?.rate || 1)
									).toFixed(2)}
									currencySymbol={bookItem?.currency_symbol}
									cartId={bookItem?.id}
									paymentResponse
								/>
							))}
						</div>

						<p className="p-2 bg-gray-300 mt-3 text-center">
							Go to{" "}
							<Link
								to="/my-library"
								className="text-blue-700 font-bold underline"
								onClick={() => setShowPaymentCompletedModal(false)}
							>
								My library
							</Link>{" "}
							to view purchased books
						</p>
					</ModalNotification>
				</PaddingX>
			</div>
		</Container>
	);

	//!
};

export default Checkout;
