import React from "react";
import { phoneRegex, MAX_IMAGE_SIZE } from "utils";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import useUpdateProfile from "api/UserResource/useUpdateProfile";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { AvatarInput } from "components";

const EditProfile = ({ profile }) => {
	const initialValues = {
		mobile_number: profile?.mobile_number || "",
		first_name: profile?.first_name || "",
		last_name: profile?.last_name || "",
		email: profile?.email || "",
		avatar: null,
	};

	const validate = Yup.object().shape({
		mobile_number: Yup.string()
			.nullable()
			.matches(phoneRegex, "Invalid phone number")
			.min(10, "Mobile number must have at least characters")
			.max(20, "Mobile number cannot exceed 20 characters"),
		first_name: Yup.string().required("First name is required"),
		last_name: Yup.string().required("Surname is required"),
		email: Yup.string()
			.required("Email is required")
			.email("Invalid email address"),
		avatar: Yup.mixed()
			.nullable()
			.test(
				"fileSize",
				`Image size should not exceed ${MAX_IMAGE_SIZE}MB`,
				(value) => {
					if (!value) return true;
					return value.size / 1024 / 1024 <= MAX_IMAGE_SIZE;
				}
			)
			.test({
				name: "mimeType",
				test: (value) =>
					!value ||
					["image/jpeg", "image/png", "image/svg+xml", "image/webp"].includes(
						value.type
					),
				message:
					"Only images with these file extension are required: jpeg, jpg, png, svg, webp",
			}),
	});

	const { mutateAsync, isLoading } = useUpdateProfile(profile?.id);

	const handleUpdateProfile = async (data) => {
		await mutateAsync(data);
	};

	return (
		<div>
			<Formik
				initialValues={initialValues}
				validationSchema={validate}
				onSubmit={handleUpdateProfile}
			>
				{({ setFieldValue, values, errors, touched }) => {
					return (
						<Form className="mt-4 space-y-6">
							<FormField type="text" name="first_name" label="First Name *" />

							<FormField type="text" name="last_name" label="Last Name *" />

							<FormField type="text" name="email" label="Email Address *" />

							<FormField
								name="mobile_number"
								type="phone"
								label="Phone Number"
								placeholder="phone"
								value={values.mobile_number}
								_onChange={(phone) => {
									if (phone !== "+") values.mobile_number = "+" + phone;
									else values.mobile_number = phone;
								}}
								error={Boolean(errors.mobile_number && touched.mobile_number)}
								errorText={errors.mobile_number}
							/>

							<AvatarInput
								value={null}
								onChange={(event) =>
									setFieldValue("avatar", event.target.files[0])
								}
								name="avatar"
							/>

							<div className=" flex justify-center border-t border-gray-300 pt-2">
								<button
									type="submit"
									className="px-4 py-2 rounded-full bg-akgreenest text-white flex gap-1 items-center justify-center"
								>
									{isLoading ? (
										<>
											Updating{" "}
											<AiOutlineLoading3Quarters
												size={20}
												className="animate-spin"
											/>
										</>
									) : (
										<>
											Update <IoIosArrowForward size={20} />
										</>
									)}
								</button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default EditProfile;
