import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects, fetchConfig } from "utils";

const useFetchPublisherAggregateBooks = (queryParams, userId = null) => {
	const { accessToken } = useTokenStore();

	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const params = queryParams ? deleteEmptyObjects(queryParams) : null;
		const url = userId
			? `aggregate-publisher-books/${userId}`
			: `aggregate-publisher-books`;
		const res = await instance.get(url, {
			params: { ...params },
		});
		return res.data;
	};

	return useQuery(
		["publisher_books", "aggregate", queryParams, userId],
		() => fetch(),
		{
			...fetchConfig,
		}
	);
};

export default useFetchPublisherAggregateBooks;
