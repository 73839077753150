// import useCreateOrder from "api/OrderResource/useCreateOrder";
import useCreatePurchaseOrder from "api/OrderResource/useCreatePurchaseOrder";
import useGetStars from "api/StarsResource/useGetStars";
import { Button, TextField } from "components";
import { Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import { FiLoader } from "react-icons/fi";
import useCurrencyStore from "store/currencyStore";
import { activeCurrencies } from "utils";
import * as Yup from "yup";

const RedeemStars = ({
	credits,
	grandTotal,
	books,
	setTotalPaid,
	setBookCount,
	setPaymentIsSuccessful,
}) => {
	const [stars, setStars] = React.useState(0);

	const { currency } = useCurrencyStore();

	const [studentNumber, setStudentNumber] = React.useState("");

	const initialValues = {
		student_number: "",
		security_answer: "",
	};

	const validate = Yup.object().shape({
		student_number: Yup.string().required("Student number is required"),
		security_answer: Yup.string().required("Security answer is required"),
	});

	const {
		mutateAsync,
		isLoading,
		data: starsResponse,
		isSuccess,
	} = useGetStars(setStars);

	const handleRequestStars = async (data) => {
		//     setStars(12);
		await mutateAsync(data);
		setStudentNumber(data.student_number);
	};

	const { mutateAsync: mutateFreeOrder, isLoading: isCreatingOrder } =
		useCreatePurchaseOrder();

	const placeOrder = async () => {
		await mutateFreeOrder({
			street_address: "",
			city: "",
			province: "",
			country: "",
			postcode: "",
			payment_method: "Stars Redemption",
			payment_type: "Purchase",
			subtotal: 0,
			grand_charges: 0,
			grand_total: 0,
			grand_total_zwl: grandTotal,
			reference:
				moment().format("YYYYMMDDHHmmss") +
				"-" +
				moment().subtract(1, "minutes").format("YYYYMMDDHHmmss"),
			status: "Completed",
			student_number: studentNumber,
			points_used: credits,
			books: books,
			currency: activeCurrencies.ZWL.code,
			total_amount: grandTotal,
		});
		setBookCount(books?.length);
		setTotalPaid(0);
		setPaymentIsSuccessful(true);
		// setShowPaymentCompletedModal(true);
	};

	if (!isLoading && Number(stars) < Number(credits) && starsResponse) {
		return (
			<div className="flex justify-center items-center">
				<p>
					Unfortunately you have only{" "}
					<span className="font-bold text-gray-600">{stars} stars</span> which
					is not redeemable to complete this transaction. You have to proceed
					with other payment methods.
				</p>
			</div>
		);
	}

	if (!isLoading && Number(stars) >= Number(credits)) {
		return (
			<div>
				<p>
					You have enough stars to proceed with the transaction. Note that your
					"stars" balance on Akello Smart Learning will be reduced by{" "}
					<span className="font-bold text-gray-600">{credits} stars</span>.
					Press the button below to proceed.
				</p>

				<Button
					type="button"
					className="mt-2 py-2 w-full flex justify-center items-center gap-3"
					onClick={() => placeOrder()}
				>
					{isCreatingOrder ? (
						<>
							Processing
							<FiLoader size={20} className="animate-s" />
						</>
					) : (
						"Proceed"
					)}
				</Button>
			</div>
		);
	}

	if (isSuccess && starsResponse?.data?.status !== 1) {
		return (
			<div>
				<p>Your attempt to redeem stars failed because of the following</p>
				<p className="text-red-500">
					{starsResponse?.data?.message
						? starsResponse?.data?.message
						: "Your student number or security answer is incorrect"}
				</p>

				{/* <button type="button" onClick={() => setStars(0)}>
					Try again
				</button> */}
			</div>
		);
	}

	return (
		<div>
			<Formik
				initialValues={initialValues}
				validationSchema={validate}
				onSubmit={(data) => handleRequestStars(data)}
			>
				{(formik) => {
					return (
						<Form>
							<p className="mb-4 text-sm p-4 rounded-md bg-gray-300">
								This payment of{" "}
								<span className="font-bold text-gray-600">
									{currency}${grandTotal}
								</span>{" "}
								is equivalent to{" "}
								<span className="font-bold text-gray-600">{credits} stars</span>
								. As a student of{" "}
								<a
									href="https://smartlearning.akello.co/"
									className="text-blue-700"
									target="_blank"
									rel="noreferrer"
								>
									Akello Smart Learning
								</a>
								, you can redeem your stars, if you have any, by entering these
								security checks as set in your Akello Smart Learning account.
							</p>

							<TextField
								name="student_number"
								placeholder="Enter student number"
								label="Student Number"
							/>
							<TextField
								name="security_answer"
								placeholder="Enter security answer"
								label="Security answer"
							/>

							{/* <Button type="submit" className="mt-2 py-2 w-full">
								{isLoading ? "...Requesting" : "Request stars"}
							</Button> */}

							<Button
								type="submit"
								className="mt-2 py-3 w-full flex items-center justify-center gap-2"
							>
								{isLoading ? "Requesting" : "Request stars"}
								{isLoading && <FiLoader size={20} className="animate-spin" />}
							</Button>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default RedeemStars;
