import useDeleteRate from "api/RatesResource/useDeleteRate";
import { ConfirmPopup } from "primereact/confirmpopup";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FcProcess } from "react-icons/fc";

const DeleteCurrency = ({ elementId, rateId }) => {
  const [visible, setVisible] = React.useState(false);

  const { mutateAsync, isLoading: isDeletingRate } = useDeleteRate(rateId);

  const accept = async () => {
    await mutateAsync();
  };

  return (
    <>
      <button
        className="border-0 transform -rotate-3"
        onClick={() => setVisible(true)}
        id={elementId}
      >
        {isDeletingRate ? (
          <FcProcess size={30} className="animate-spin  rounded-full" />
        ) : (
          <AiOutlineDelete size={30} className="text-red-500" />
        )}
      </button>

      <ConfirmPopup
        target={document.getElementById(elementId)}
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete?"
        icon="pi pi-exclamation-triangle"
        accept={accept}
      />
    </>
  );
};

export default DeleteCurrency;
