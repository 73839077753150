import { useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable as Dtable } from "primereact/datatable";
import { FaFileCsv, FaFilePdf } from "react-icons/fa";
import tw from "tailwind-styled-components/dist/tailwind";
const ExportBtn = tw.button`
      px-2 py-1 rounded
      text-white
`;

const DataTable = ({ data, cols, children, prepend, footer, ...rest }) => {
  //!

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const dt = useRef(null);

  const exportColumns = cols?.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, data);
        doc.save("document.pdf");
      });
    });
  };

  const TableHeader = () => {
    return (
      <div className="flex justify-between font">
        <div className="flex gap-2">
          <h3>Exports</h3>
          <ExportBtn
            type="button"
            onClick={() => exportCSV(false)}
            className="bg-purple-500"
          >
            <FaFileCsv />
          </ExportBtn>
          <ExportBtn type="button" onClick={exportPdf} className="bg-akgreen">
            <FaFilePdf />
          </ExportBtn>
        </div>
        {footer}
        <div>
          <input
            type="search"
            value={globalFilterValue}
            onChange={(e) => setGlobalFilterValue(e.target.value)}
            placeholder={rest.searchPlaceholder || "Search"}
            className="px-3 py-1 rounded border border-akgreen outline-none"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="text-xs">
      <Dtable
        ref={dt}
        sortField="first_name"
        resizableColumns
        columnResizeMode="fit"
        value={data}
        className="font-sans"
        dataKey="id"
        globalFilter={globalFilterValue}
        header={<TableHeader />}
        responsiveLayout="scroll"
        breakpoint="340px"
        footer={footer}
        scrollable
      >
        {prepend}
        {cols?.map(
          (col) =>
            col.field !== "id" && (
              <Column
                key={col.field}
                className=""
                field={col.field}
                sortable
                header={col.header}
              />
            )
        )}
        {children}
      </Dtable>
    </div>
  );

  //!
};

export default DataTable;
