import useImportBooks from "api/BookResource/useImportBooks";
import { Button, ButtonLoader, TextField } from "components";
import { Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import React from "react";
import { FiArrowDown } from "react-icons/fi";
import { activeCurrencies } from "utils";

const ImportBooks = () => {
	const [displayExp, setDisplayExp] = React.useState(false);

	const initialValues = {
		csv: null,
	};

	const { mutateAsync, isLoading } = useImportBooks();
	const handleImportBooks = async (data) => {
		await mutateAsync(data);
		setDisplayExp(false);
	};

	return (
		<>
			<button
				type="button"
				onClick={() => setDisplayExp(true)}
				className="px-2 py-1 text-sm rounded bg-gradient-to-b from-akgreenest to-akgreen text-gray-100 border border-akgreen flex gap-2 items-center"
			>
				Import Books <FiArrowDown />
			</button>
			<Dialog
				header="Import CSV or Excel Sheet"
				visible={displayExp}
				onHide={() => setDisplayExp(false)}
			>
				<p className="text-lg ">Import CSV/Excel with the following headers</p>
				<p>NB: Prices should be in {activeCurrencies.USD.label}</p>
				{/* <p className="font-thin">
          Title | Author | Price | Publisher Email | Publisher Firstname | Publisher Lastname |
          Category | Summary | Genre | Credits
        </p> */}

				<div className="flex justify-between items-center gap-1 divide-x divide-gray-300 mt-4 ">
					<div className="flex flex-col justify-center items-center">
						<p className="font-bold">Title</p>
						<p>(text)</p>
					</div>

					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Author</p>
						<p>(text)</p>
					</div>

					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Price</p>
						<p>(numeric)</p>
					</div>
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">ISBN Number</p>
						<p>(text)</p>
					</div>
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Publisher Email</p>
						<p>(text)</p>
					</div>
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Publisher Firstname</p>
						<p>(text)</p>
					</div>
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Publisher Lastname</p>
						<p>(text)</p>
					</div>
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Publishing Company</p>
						<p>(text)</p>
					</div>
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Category</p>
						<p>(text)</p>
					</div>
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Collection</p>
						<p>(text)</p>
					</div>
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Summary</p>
						<p>(text)</p>
					</div>
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Credits</p>
						<p>(numeric)</p>
					</div>
				</div>

				<p className="mt-3 underline text-lg text-red-500">
					NB: Please ignore the text in brackets when you prepare your csv/excel
					sheet
				</p>

				<Divider />
				<Formik
					initialValues={initialValues}
					// validationSchema={validationSchema}
					onSubmit={(data) => handleImportBooks(data)}
				>
					{(formik) => {
						return (
							<Form>
								<div className="flex justify-center mt-3">
									<TextField
										type="file"
										name="csv"
										value={null}
										onChange={(event) =>
											formik.setFieldValue("csv", event.target.files[0])
										}
									/>
								</div>

								<div className="flex justify-center mt-3">
									{isLoading ? (
										<ButtonLoader size={40} />
									) : (
										<Button type="submit" className="ml-3 flex items-center ">
											Submit
										</Button>
									)}
								</div>
							</Form>
						);
					}}
				</Formik>
			</Dialog>
		</>
	);
};

export default ImportBooks;
