import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects, fetchConfig } from "utils";

const useFetchAggregateBooks = (queryParams) => {
	const { accessToken } = useTokenStore();

	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const params = queryParams ? deleteEmptyObjects(queryParams) : null;
		const res = await instance.get("aggregate-books", {
			params: { ...params },
		});
		return res.data;
	};

	return useQuery(["books", "aggregate", queryParams], () => fetch(), {
		...fetchConfig,
	});
};

export default useFetchAggregateBooks;
