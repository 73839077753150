import { Link } from "react-router-dom";

const BigLink = ({ to, children, ...rest }) => {
  const commonClasses =
    "rounded-full text-center px-4 py-2 w-full block text-lg";
  if (rest.light) {
    return (
      <Link
        to={to}
        className={`border border-akgreen text-akgreener bg-white ${commonClasses}`}
      >
        {children}
      </Link>
    );
  }
  return (
    <Link
      to={to}
      className={`bg-gradient-to-r from-akgreen to-akgreenest text-white ${commonClasses}`}
    >
      {children}
    </Link>
  );
};

export default BigLink;
