import { DesktopHeader } from "components";
import useSearchParams from "hooks/useSearchParams";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import React from "react";
import { BiCheck } from "react-icons/bi";
import { Link } from "react-router-dom";

const PaymentSuccessResponse = () => {
	const { getParam } = useSearchParams();

	const paymentMethod = getParam("payment_method");

	return (
		<Container>
			<DesktopHeader shadow />

			<PaddingX>
				<div className="mt-10 flex flex-col justify-center items-center">
					<div className="flex justify-center rounded-full border-2 border-akgreener">
						<BiCheck size={150} className="text-akgreener opacity-95" />
					</div>

					<div
						className="flex flex-col gap-1 items-center text-center mt-4"
						style={{ maxWidth: 350 }}
					>
						<h1>Payment Successful</h1>

						<h3>
							Your <span className="font-bold">{paymentMethod}</span> payment
							was recorded successfully
						</h3>

						<p>
							Go to &nbsp;
							<Link
								className="font-bold text-blue-800 text-base underline"
								to={`/my-library`}
							>
								your library
							</Link>{" "}
							to see books that you have purchased
						</p>

						<p className="mt-4 pt-4 border-t border-gray-300 text-xs">
							You can read the books from your mobile device(s) using the
							library mobile app
						</p>
					</div>
				</div>
			</PaddingX>
		</Container>
	);
};

export default PaymentSuccessResponse;
