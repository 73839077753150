import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";

const useFetchCategories = (fromPortal = "no") => {
	const { accessToken } = useTokenStore();

	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		// const url = hasBooks ? `categories?hasBooks=${true}` : "categories";
		const res = await instance.get(`categories?from_portal=${fromPortal}`);
		return res.data;
	};
	return useQuery(["book_categories", fromPortal], () => fetch(), {
		...fetchConfig,
	});
};

export default useFetchCategories;
