import useChangeOrderStatus from "api/OrderResource/useChangeOrderStatus";
import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BiX } from "react-icons/bi";
import { BsPencil } from "react-icons/bs";
import * as Yup from "yup";

const ChangeStatus = ({ orderId, currentOrderStatus, orderNumber }) => {
	const [visible, setVisible] = React.useState(false);

	const initialValues = {
		status: currentOrderStatus,
	};

	const validate = Yup.object().shape({
		status: Yup.string().required("Status is required"),
	});

	const { mutateAsync, isLoading } = useChangeOrderStatus(orderId);
	const handleSubmit = async (data) => {
		await mutateAsync(data);
		setVisible(false);
	};

	return (
		<>
			<button
				type="button"
				className="bg-white border-transparent p-2"
				onClick={() => setVisible(true)}
			>
				<BsPencil size={15} />
			</button>

			<Dialog
				visible={visible}
				onHide={() => setVisible(false)}
				showHeader={false}
				contentClassName="rounded-lg relative"
			>
				<button
					type="button"
					className="p-2 border-transparent bg-transparent absolute top-2 right-2 "
					onClick={() => setVisible(false)}
				>
					<BiX size={30} />
				</button>

				<Formik
					initialValues={initialValues}
					validationSchema={validate}
					onSubmit={(data) => handleSubmit(data)}
				>
					<Form className="py-8 mt-4">
						<h3 className="font-bold">Change order status</h3>

						<p>
							Changing status for order number{" "}
							<span className="text-akgreenest font-bold">{orderNumber}</span>
						</p>

						<div className="mt-4 py-2 border-t border-gray-300 space-y-2">
							<p>As status as </p>
							<FormField
								name="status"
								type="select"
								filter={false}
								options={[
									{ label: "Set as completed", value: "Completed" },
									{ label: "Set as failed", value: "Failed" },
								]}
							/>

							<div className="pt-4 flex flex-end">
								<button
									type="submit"
									className="flex items-center gap-2 justify-center bg-akgreener rounded-md h-10 w-32 text-white"
								>
									<span>Change status</span>
									{isLoading ? (
										<AiOutlineLoading3Quarters size={25} />
									) : undefined}
								</button>
							</div>
						</div>
					</Form>
				</Formik>
			</Dialog>
		</>
	);
};

export default ChangeStatus;
