import create from "zustand";
import { persist } from "zustand/middleware";

const appDownloadsStore = (set, get) => ({
	openDownLoadLinks: true,
	closeDownLoadLinks: () => set({ openDownLoadLinks: false }),
});

const useAppDownloadsStore = create(
	persist(appDownloadsStore, {
		name: "zus-app-downloads",
		getStorage: () => localStorage,
	})
);

export default useAppDownloadsStore;
