import { NavLink } from "react-router-dom";

const Link = ({ to, label, className }) => {
	return (
		<NavLink
			to={to}
			exact
			className={`px-3 py-1 ${className ?? ""}`}
			activeClassName="rounded-full bg-gradient-to-r from-akgreen to-akgreenest text-white"
		>
			{label}
		</NavLink>
	);
};

export default Link;
