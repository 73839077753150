import React from "react";

const RevenueCard = ({ label, value, icon }) => {
	const isValueArray = Array.isArray(value);

	return (
		<div className="py-2 px-3  bg-white rounded shadow  w-full">
			<div className="h-8 w-8 rounded-full flex items-center justify-center border">
				{icon}
			</div>

			<div className="flex flex-col items-start">
				<h4>{label}</h4>

				<div className="mt-2 space-y-2 ">
					{isValueArray ? (
						value?.map((val, index) => (
							<p className="flex items-center text-sm " key={index}>
								{val}
							</p>
						))
					) : (
						<p className="flex items-center text-sm">{value}</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default RevenueCard;
