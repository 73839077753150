import logo from "assets/images/dark_logo.png";
import PaddingX from "layouts/PaddingX";
import { BiArrowToRight } from "react-icons/bi";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { NavLink, useLocation, Link as SimpleLink } from "react-router-dom";
import useAuthStore from "store/authStore";
import Cart from "./Cart/Cart";
import CurrencyConverter from "./CurrencyConverter";
import Link from "./Link";
import MobileMenu from "./MobileMenu";
import ProfileIcon from "./ProfileIcon";
import Search from "./Search";
import { FaAppStore } from "react-icons/fa";
import { SiHuawei } from "react-icons/si";
import { Link as RawLink } from "react-router-dom";
import usePortalDashboard from "hooks/usePortalDashboard";
import { FiX } from "react-icons/fi";
import useAppDownloadsStore from "store/appDownloadsStore";

const Header = ({ shadow }) => {
	const logoWidth = 80;
	const { pathname } = useLocation();
	const showDownLoadLinks = pathname === "/" || pathname === "/books";
	const isAuthenticated = useAuthStore((state) => state.loggedIn);
	const { dashboardUrl, canAccessPortal } = usePortalDashboard();
	const { openDownLoadLinks, closeDownLoadLinks } = useAppDownloadsStore();

	return (
		<div
			className={`sticky top-0 bg-white`}
			style={{
				zIndex: 999,
			}}
		>
			<div className="block lg:hidden py-2">
				<PaddingX>
					<MobileMenu
						dashboardUrl={dashboardUrl}
						canAccessPortal={canAccessPortal}
						isAuthenticated={isAuthenticated}
					>
						<CurrencyConverter />
						<Cart />
						{isAuthenticated && <ProfileIcon />}
					</MobileMenu>
				</PaddingX>
			</div>

			<div className="hidden lg:block">
				<PaddingX>
					<div className="grid grid-cols-12 justify-between py-2  w-full">
						<div className="flex items-center col-span-2 ">
							<NavLink to="/">
								<img src={logo} alt="Akello Library" width={logoWidth} />
							</NavLink>
						</div>

						<div className="flex items-center justify-center py-2 gap-2 col-span-8 ">
							<Link to="/" label="Home" />
							<Link to="/books" label="Books" />
							{isAuthenticated && (
								<RawLink
									to="/my-library"
									className={`px-3 py-1 ${
										["/my-library", "/my-library/picks"]?.includes(pathname)
											? "rounded-full bg-gradient-to-r from-akgreen to-akgreenest text-white"
											: ""
									}`}
								>
									My Library
								</RawLink>
							)}
							{/* {isAuthenticated && <Link to="/my-library" label="My Library" />} */}
							<Link to="/subscriptions" label="Subscribe" />
							{!isAuthenticated && <Link to="/register" label="Register" />}

							{!isAuthenticated && <Link to="/login" label="Login" />}

							{isAuthenticated && canAccessPortal && (
								<Link
									to={`/${dashboardUrl}`}
									label={
										<span className="flex justify-center items-center">
											Portal
											<BiArrowToRight size={20} />
										</span>
									}
									className="hidden lg:block"
								/>
							)}

							{/* {pathname !== "/books" && ( */}
							<div className={`px-2 `}>
								<Search />
							</div>
							{/* )} */}
						</div>

						<div className="flex items-center justify-end py-2 gap-2 col-span-2">
							<div className={`px-1 `}>
								<CurrencyConverter />
							</div>
							<div className="px-1">
								<Cart />
							</div>
							{/* <AkelloProducts light /> */}

							{isAuthenticated && <ProfileIcon />}
						</div>
					</div>
				</PaddingX>

				{showDownLoadLinks && openDownLoadLinks && (
					<div className="pt-8 pb-4 bg-gradient-to-b from-gray-50 to-white">
						<PaddingX>
							<div className="flex items-center justify-between relative">
								<button
									type="button"
									className="rounded-full flex items-center justify-center bg-gray-50 absolute -top-6 -right-1 p-1 border border-gray-300"
									onClick={closeDownLoadLinks}
								>
									<FiX size={28} />
								</button>
								<p className="">
									Download our mobile app for the best reading experience!
									<br />
									Get it now on Play Store, App Store, and <br />
									Huawei App Gallery
								</p>

								<div className="flex flex-col justify-center items-end gap-3">
									<div className="flex justify-end items-start gap-3">
										<a
											href="https://play.google.com/store/apps/details?id=com.akellolibrary"
											rel="noreferrer noopener"
											target="_blank"
											className="px-4 py-2 rounded-lg bg-gradient-to-t from-akgreenest to-akgreener text-white flex items-center"
										>
											<IoLogoGooglePlaystore size={45} />
											<span className="flex flex-col items-start justify-center">
												<span className="mb-0">Download on</span>
												<span className="font-bold text-base">Android</span>
											</span>
										</a>

										<a
											href="https://apps.apple.com/zw/app/akello-library/id6451323375"
											rel="noreferrer noopener"
											target="_blank"
											className="px-4 py-2 rounded-lg bg-gradient-to-t from-akgreenest to-akgreener text-white flex items-center gap-1"
										>
											<FaAppStore size={45} />
											<span className="flex flex-col items-start justify-center">
												<span className="mb-0">Download for</span>
												<span className="font-bold text-base">iPhone</span>
											</span>
										</a>

										<a
											href="https://appgallery.huawei.com/app/C108363007"
											rel="noreferrer noopener"
											target="_blank"
											className="px-4 py-2 rounded-lg bg-gradient-to-t from-akgreenest to-akgreener text-white flex items-center gap-1"
										>
											<SiHuawei size={45} />
											<span className="flex flex-col items-start justify-center">
												<span className="mb-0">Download for</span>
												<span className="font-bold text-base">Huawei</span>
											</span>
										</a>

										{/* <button
											type="button"
											className="rounded-full flex items-center justify-center  bg-gray-50  "
											onClick={closeDownLoadLinks}
										>
											<FiX size={40} />
										</button> */}
									</div>

									<SimpleLink
										to="/downloads"
										className="flex flex-col items-end text-lg text-blue-700 hover:text-blue-900 group"
										// style={{ marginRight: 55 }}
									>
										<span>View more downloads</span>
										<span className="border-b-2 border-blue-400 w-32 pb-1 group-hover:border-blue-900" />
									</SimpleLink>
								</div>
							</div>
						</PaddingX>
					</div>
				)}
			</div>
		</div>
	);
};

export default Header;
