import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";
// import useAuthUser from "api/UserResource/useAuthUser";

const useFetchRates = () => {
	// const { access_token } = useAuthUser();

	const { accessToken } = useTokenStore();

	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const res = await instance.get("currency-rates");
		return res.data;
	};
	return useQuery("rates", () => fetch(), {
		...fetchConfig,
	});
};

export default useFetchRates;
