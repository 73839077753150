const BookInfo = ({ type, value, className }) => {
  return (
    <p className={className} title={value}>
      <span className="font-semibold">
        {(function () {
          switch (type) {
            case "author":
              return "Author:";
            case "publisher":
              return "Published By:";
            case "isbn":
              return "ISBN:";
            default:
              break;
          }
        })()}
      </span>{" "}
      {value}
    </p>
  );
};

export default BookInfo;
