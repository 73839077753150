import useCreateUpdateRate from "api/RatesResource/useCreateUpdateRate";
import useFetchRates from "api/RatesResource/useFetchRates";
import ButtonLoader from "components/ButtonLoader";
import DataTable from "components/DataTable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Moment from "react-moment";
import useOverlayStore from "store/overlayStore";
import { FiEdit } from "react-icons/fi";
import DeleteCurrency from "./DeleteCurrency";
import FormField from "components/v2/FormField";
import {
	activeCurrencies,
	currencyCodes,
	getActiveCurrencyCodeLabel,
} from "utils";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import ApplyRateToPrices from "./ApplyRateToPrices";
import useAuthStore from "store/authStore";
import { Role } from "utils/roles";

const AddRate = () => {
	const { hasRoles } = useAuthStore();
	const { showCreateRate, setShowCreateRate } = useOverlayStore();
	const [showEditRateField, setShowEditRateField] = useState("");
	const [showSubmitRateForm, setShowSubmitRateForm] = useState(false);
	const { data: rates, isLoading } = useFetchRates();

	const initialValues = {
		to: "",
		rate: "",
	};

	const validate = Yup.object().shape({
		to: Yup.string().required("Currency code required"),
		rate: Yup.number().required("Rate is required"),
	});

	const { mutateAsync, isLoading: isCreatingRate } = useCreateUpdateRate();

	const handleSubmit = async (data) => {
		let rate = data.to === activeCurrencies.USD.code ? 1 : data.rate;
		let is_active = data.to === activeCurrencies.USD.code ? 1 : 0;
		await mutateAsync({ ...data, rate: rate, is_active: is_active });
		setShowSubmitRateForm(false);
	};

	const handleEdit = async (data) => {
		let rate = data.to === activeCurrencies.USD.code ? 1 : data.rate;
		let is_active = data.to === activeCurrencies.USD.code ? 1 : 0;
		await mutateAsync({ ...data, rate: rate, is_active: is_active });
		setShowEditRateField("");
	};

	const cols = [
		{ field: "from", header: "From" },
		{ field: "toLabel", header: "To" },
		{ field: "rate", header: "Prevailing Rate" },
		{ field: "updated_at", header: "Last Updated On" },
	];

	const currencyRates = rates?.data?.collection.map((rate) => {
		return {
			id: rate.id,
			from: rate.from,
			to: rate.to,
			toLabel: getActiveCurrencyCodeLabel(rate.to),
			rate:
				showEditRateField === rate.to ? (
					<input
						name="editRate"
						type="number"
						step="0.01"
						min="1"
						placeholder="Enter..."
						className="border px-2 w-20 border-gray-400 rounded text-sm"
						onBlur={(e) =>
							handleEdit({
								to: rate.to,
								rate: e.target.value,
							})
						}
					/>
				) : (
					`1:${rate.rate}`
				),
			updated_at: (
				<Moment format="YYYY-MM-DD HH:mm:ss">{rate.updated_at}</Moment>
			),
		};
	});

	const actionBodyTemplate = (rowData) => {
		return (
			<div className="flex justify-start gap-1 items-center">
				<FiEdit
					size={25}
					className="text-gray-600 cursor-pointer"
					onClick={() => setShowEditRateField(rowData?.to)}
				/>
				<DeleteCurrency
					elementId={`button-${rowData.id}`}
					rateId={rowData.id}
				/>
			</div>
		);
	};

	return (
		<Dialog
			visible={showCreateRate}
			style={{ width: "80vw" }}
			header={
				<div className="text-xl font-sans font-bold sticky top-4 z-20 bg-white mr-2 flex items-center justify-between">
					<p className="">Create currency rate</p>

					<button
						type="button"
						className="text-sm rounded-full px-3 py-1 border border-akgreener hover:bg-akgreener hover:text-white"
						onClick={() => setShowSubmitRateForm(true)}>
						Add currency FX
					</button>
				</div>
			}
			onHide={() => setShowCreateRate(false)}
			position="top"
			draggable={false}
			contentClassName="rounded-b-xl "
			className="rounded-xl text-sm relative">
			{showSubmitRateForm && (
				<Formik
					initialValues={initialValues}
					validationSchema={validate}
					onSubmit={(data) => handleSubmit(data)}>
					<Form className="mt-7 mb-4 flex gap-4">
						<FormField
							type="select"
							name="to"
							label="Currency *"
							width={"w-44"}
							// options={currencies}
							options={currencyCodes?.map((code) => ({
								label: `${code.currencyCodeLabel} (${code.country})`,
								value: code.currency_code,
							}))}
						/>

						<FormField
							type="number"
							min="0"
							step="0.01"
							name="rate"
							label={`Rate to ${activeCurrencies.USD.label} *`}
						/>

						<button
							type="submit"
							className="px-4 py-2 rounded-full bg-akgreenest text-white flex gap-1 items-center justify-center">
							{isCreatingRate ? (
								<>
									Submitting{" "}
									<AiOutlineLoading3Quarters
										size={20}
										className="animate-spin"
									/>
								</>
							) : (
								<>
									Submit <IoIosArrowForward size={20} />
								</>
							)}
						</button>
					</Form>
				</Formik>
			)}
			{isLoading ? (
				<div className="flex justify-center">
					<ButtonLoader size={60} />
				</div>
			) : (
				<DataTable data={currencyRates} cols={cols}>
					<Column
						header="Actions"
						body={actionBodyTemplate}
						exportable={false}
					/>
				</DataTable>
			)}

			{hasRoles([Role.SENIOR_PUBLISHER, Role.PUBLISHER]) && (
				<div className="mt-8">
					<ApplyRateToPrices useRatePricing={rates?.data?.use_rate_pricing} />
				</div>
			)}
		</Dialog>
	);
};

export default AddRate;
