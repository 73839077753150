import DeleteUser from "admin/Users/DeleteUser";
import EditUser from "admin/Users/EditUser";
import useFetchPublisherByLoggedUser from "api/PublisherResource/useFetchPublisherByLoggedUser";
import useFetchUsers from "api/UserResource/useFetchUsers";
import { CustomPaginator, DataLoader, DataTable } from "components";
import Admin from "layouts/Admin";
import { Column } from "primereact/column";
import React, { useState } from "react";
import AddPublisherUser from "./AddPublisherUser";

const PublisherUsers = () => {
  //!

  const [firstPage, setFirstPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(1);

  const [roleFilter, setRoleFilter] = React.useState({
    "filter[role_is]": "Senior Publisher",
  });

  const { data: publisher, isLoading: isPublisherLoading } =
    useFetchPublisherByLoggedUser();

  const { data: publisherUsers, isLoading: isLoadingPublisherUsers } =
    useFetchUsers({
      ...roleFilter,
      "filter[publisher_is]": publisher?.data?.id || "none",
      page: page,
      rows_per_page: rowsPerPage,
    });

  const cols = [
    { field: "id", header: "ID" },
    { field: "first_name", header: "First Name" },
    { field: "last_name", header: "Last Name" },
    { field: "email", header: "Email" },
    { field: "mobile_number", header: "Mobile Number" },
    { field: "telephone", header: "Telephone Number" },
  ];

  const users = publisherUsers?.data?.records?.map((user) => {
    return {
      id: user.id,
      avatar: user.avatar,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      mobile_number: user.mobile_number,
      telephone: user.telephone,
      role_name: user.role_name,
      formatted_roles: user.role_name?.join(" , "),
    };
  });

  const avatarTemplate = (rowData) => {
    return (
      <img
        src={rowData?.avatar}
        alt="Profile"
        className="w-14 h-14 rounded-full border p-1"
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <EditUser elementId={`edit-${rowData.id}`} userData={rowData} />
        <DeleteUser elementId={`button-${rowData.id}`} userId={rowData.id} />
      </>
    );
  };

  return (
    <Admin pageTitle="Publisher Users" headerContent={<AddPublisherUser />}>
      <div className="mb-4 flex gap-3 items-center">
        <span className="font-bold">Show</span>
        <select
          name="role"
          id="role"
          onChange={(e) =>
            setRoleFilter({
              ...roleFilter,
              "filter[role_is]": e.target.value,
            })
          }
          className="outline-none rounded py-1 px-2 border"
        >
          <option value="Senior Publisher">Senior Publishers</option>
          <option value="Publisher">Publishers</option>
        </select>
      </div>
      {isLoadingPublisherUsers || isPublisherLoading ? (
        <div className="max-h-screen">
          <DataLoader />
          <DataLoader />
          <DataLoader />
          <DataLoader />
        </div>
      ) : (
        <div className="space-y-3">
          <DataTable
            data={users}
            cols={cols}
            prepend={<Column body={avatarTemplate} exportable={false} />}
            footer={
              <CustomPaginator
                firstPage={firstPage}
                rowsPerPage={rowsPerPage}
                setFirstPage={setFirstPage}
                setRowsPerPage={setRowsPerPage}
                totalRecords={publisherUsers?.data?.total_records}
                setPage={setPage}
              />
            }
          >
            <Column
              header="Actions"
              body={actionBodyTemplate}
              exportable={false}
            />
          </DataTable>
        </div>
      )}
    </Admin>
  );

  //!
};

export default PublisherUsers;
