import create from "zustand";

const appStore = (set, get) => ({
	flashMessage: null,
	setFlashMessage: (msg) => set({ flashMessage: msg }),
	clearFlashMessage: () => set({ flashMessage: null }),

	openDownLoadLinks: true,
	closeDownLoadLinks: () => set({ openDownLoadLinks: false }),
});

const useAppStore = create(appStore);

export default useAppStore;
