import UsersFilters from "admin/Users2/UsersFilters";
import useUsersFilters from "admin/Users2/useUsersFilters";
import useFetchBrandAmbassadors from "api/UserResource/useFetchBrandAmbassadors";
import { CustomPaginator } from "components";
import AdminPageLoader from "components/AdminPageLoader";
import Admin2 from "layouts/Admin2";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { AiOutlineLine } from "react-icons/ai";
import ViewStats from "./ViewStats";

const BrandAmbassadors = () => {
	const filters = useUsersFilters();
	const { data, isLoading } = useFetchBrandAmbassadors({
		page: filters?.page,
		rows_per_page: filters?.rowsPerPage,
		first_name: filters?.firstName,
		last_name: filters?.lastName,
		email: filters?.email,
		date_from: filters?.dateFrom,
		date_to: filters?.dateTo,
	});

	const users = data?.data?.records;
	const totalRecords = data?.data?.total_records;

	const createdAtTemplate = (row) => <span>{row?.created_at}</span>;

	const actionTemplate = (row) => (
		<div className="flex gap-1 items-center">
			<ViewStats
				userId={row?.id}
				firstName={row?.first_name}
				lastName={row?.last_name}
				email={row?.email}
			/>
		</div>
	);

	if (isLoading) {
		return <AdminPageLoader />;
	}

	return (
		<Admin2>
			<div className="w-full mb-4">
				<h3>List of Brand Ambassadors</h3>
			</div>
			<div className="mt-4 w-full flex justify-center">
				<UsersFilters filters={filters} hide={["status", "role"]} />
			</div>

			<div className="flex flex-col items-center" style={{ maxWidth: "90%" }}>
				<DataTable
					value={users}
					tableClassName="font-sans text-sm"
					autoLayout
					dataKey="id"
				>
					<Column
						header="Name"
						body={(row) => (
							<div className="flex gap-1">
								<span>{row?.first_name}</span>
								<span>{row?.last_name}</span>
							</div>
						)}
					/>
					<Column field="email" header="Email" />
					<Column
						header="Phone Number"
						headerClassName="text-center"
						bodyClassName="text-center"
						body={(row) =>
							row.mobile_number ? (
								<span>{row.mobile_number} </span>
							) : (
								<AiOutlineLine size={20} />
							)
						}
					/>
					<Column header="Registered on" body={createdAtTemplate} />
					<Column
						header="Actions"
						headerClassName="text-center"
						body={actionTemplate}
					/>
				</DataTable>

				<div className="flex-1 flex justify-end mt-6">
					<CustomPaginator
						firstPage={filters?.firstPage}
						setFirstPage={filters?.changeFirstPage}
						rowsPerPage={filters?.rowsPerPage}
						setRowsPerPage={filters?.changeRowsPerPage}
						totalRecords={totalRecords}
						setPage={filters?.changePage}
						showRowsPerPageDropdown={false}
					/>
				</div>
			</div>
		</Admin2>
	);
};

export default BrandAmbassadors;
