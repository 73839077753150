import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import { Dialog } from "primereact/dialog";
import { BsPencil } from "react-icons/bs";
import useEditSubjectGroup from "api/SubjectGroupResource/useEditSubjectGroup";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";

const EditSubjectGroup = ({ id, name }) => {
	const [display, setdisplay] = useState(false);

	const initialValues = {
		name: name || "",
	};

	const validate = Yup.object().shape({
		name: Yup.string().required("Name of subject group required"),
	});

	const { mutateAsync, isUpdatingSubejctGroup } = useEditSubjectGroup(id);

	const handleSubmit = async (data) => {
		await mutateAsync(data);
		setdisplay(false);
	};

	return (
		<>
			<button type="button" className="appearance-none border-0" onClick={() => setdisplay(true)}>
				<BsPencil size={15} />
			</button>

			<Dialog
				visible={display}
				style={{ width: "50vw" }}
				header={
					<div className="text-xl font-sans font-bold sticky top-4 z-20 bg-white mr-2">
						<p>Edit subject group - ({name})</p>
					</div>
				}
				onHide={() => setdisplay(false)}
				position="top"
				draggable={false}
				contentClassName="rounded-b-xl "
				className="rounded-xl relative"
			>
				<Formik initialValues={initialValues} validationSchema={validate} onSubmit={handleSubmit}>
					<Form className="py-6 space-y-6">
						<FormField type="text" name="name" label="Name of subject group *" />

						<div className=" flex justify-center border-t border-gray-300 pt-2">
							<button
								type="submit"
								className="px-4 py-2 rounded-full bg-akgreenest text-white flex gap-1 items-center justify-center"
							>
								{isUpdatingSubejctGroup ? (
									<>
										Updating <AiOutlineLoading3Quarters size={20} className="animate-spin" />
									</>
								) : (
									<>
										Update <IoIosArrowForward size={20} />
									</>
								)}
							</button>
						</div>
					</Form>
				</Formik>
			</Dialog>
		</>
	);
};

export default EditSubjectGroup;
