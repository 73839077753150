import UsersFilters from "admin/Users2/UsersFilters";
import useUsersFilters from "admin/Users2/useUsersFilters";
import useFetchUsers from "api/UserResource/useFetchUsers";
import { CustomPaginator } from "components";
import AdminPageLoader from "components/AdminPageLoader";
import Admin2 from "layouts/Admin2";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import Statistics from "./Statistics";
import useGetBAStatistics from "api/BrandAmbassadorResource/useGetBAStatistics";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import ClearFilters from "./ClearFilters";

const BrandAmbassadorDashboard = () => {
	const WIDTH = 1235;

	const [fromDate, setFromDate] = React.useState("");
	const [toDate, setToDate] = React.useState("");
	const changeFromDate = (date = "") => setFromDate(date);
	const changeToDate = (date = "") => setToDate(date);

	const { data: stats, isLoading: isLoadingStats } = useGetBAStatistics({
		from_date: fromDate,
		to_date: toDate,
	});

	const filters = useUsersFilters();
	const { data: usersData, isLoading: isLoadingUsers } = useFetchUsers({
		page: filters?.page,
		rows_per_page: filters?.rowsPerPage,
		"filter[first_name]": filters?.firstName,
		"filter[last_name]": filters?.lastName,
		"filter[email]": filters?.email,
		"filter[trashed]": filters?.trashed,
		"filter[role_is]": filters?.roleIs,
	});

	const users = usersData?.data?.records;
	const totalRecords = usersData?.data?.total_records;
	const countDefinedFilters = [fromDate, toDate].filter((item) => item)?.length;

	const roleTemplate = (row) => {
		return (
			<span className="rounded-full border-4 border-akgreener px-2 py-1">
				{row?.role_name[0]}
			</span>
		);
	};

	if (isLoadingUsers || isLoadingStats) {
		return <AdminPageLoader />;
	}

	return (
		<>
			{/* <div className="block xl:hidden">
				<BrandAmbassadorDashboardMobile />
			</div> */}
			<div className="block">
				<Admin2>
					<div
						className="mt-4 flex justify-between items-center gap-2 w-full"
						style={{ maxWidth: WIDTH }}
					>
						<h3 className="hidden sm:block">Statistics</h3>
						<div className="flex justify-end items-end gap-2">
							<Calendar
								value={new Date(fromDate)}
								name="from_date"
								dateFormat="yy-mm-dd"
								placeholder="Date (from)"
								className="w-52 h-10 font-sans"
								inputClassName="border border-gray-300 bg-white rounded"
								showIcon
								readOnlyInput
								showButtonBar
								onChange={(e) =>
									changeFromDate(moment(e.target.value).format("YYYY-MM-DD"))
								}
								onClearButtonClick={() => changeFromDate()}
							/>
							<Calendar
								value={new Date(toDate)}
								name="to_date"
								dateFormat="yy-mm-dd"
								placeholder="Date (to)"
								className="w-52 h-10 font-sans"
								inputClassName="border border-gray-300 bg-white rounded"
								showIcon
								showButtonBar
								onChange={(e) =>
									changeToDate(moment(e.target.value).format("YYYY-MM-DD"))
								}
								onClearButtonClick={() => changeToDate()}
							/>
							<ClearFilters
								count={countDefinedFilters}
								changeFromDate={() => changeFromDate()}
								changeToDate={() => changeToDate()}
							/>
						</div>
					</div>

					<div className="mt-3 w-full" style={{ maxWidth: WIDTH }}>
						<Statistics
							registeredUsers={stats?.data?.totalRegisteredClients}
							usdCommission={stats?.data?.usdCommission}
							zigCommission={stats?.data?.zwlCommission}
							usdPayout={stats?.data?.usdPayout}
							zigPayout={stats?.data?.zwlPayout}
							netUsdCommission={stats?.data?.netUsdCommission}
							netZigCommission={stats?.data?.netZwlCommission}
							totalPaymentsMade={stats?.data?.totalPaymentsMade}
						/>
					</div>
					<div className="mt-8 space-y-3 w-full" style={{ maxWidth: WIDTH }}>
						<h3>List of registered clients</h3>
						<div className="hidden xl:block">
							<UsersFilters filters={filters} />
						</div>
						<DataTable
							value={users}
							dataKey="id"
							tableClassName="font-sans text-sm"
							columnResizeMode="fit"
						>
							<Column
								body={(row) => (
									<span>
										{row?.first_name} {row?.last_name}
									</span>
								)}
								header="Full name"
							/>
							<Column
								header="Contact details"
								body={(row) => (
									<div className="flex flex-col lg:flex-row gap-1">
										<span>{row?.email}</span>
										{row?.mobile_number && (
											<span className="text-akgreener">
												({row?.mobile_number})
											</span>
										)}
									</div>
								)}
							/>
							<Column header="Role" body={roleTemplate} />
						</DataTable>
						<div className="flex-1 flex justify-end mt-6">
							<CustomPaginator
								firstPage={filters?.firstPage}
								setFirstPage={filters?.changeFirstPage}
								rowsPerPage={filters?.rowsPerPage}
								setRowsPerPage={filters?.changeRowsPerPage}
								totalRecords={totalRecords}
								setPage={filters?.changePage}
								showRowsPerPageDropdown={false}
							/>
						</div>
					</div>
				</Admin2>
			</div>
		</>
	);
};

export default BrandAmbassadorDashboard;
