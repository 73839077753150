import useDeleteCartItem from "api/CartResource/useDeleteCartItem";
import useUpdateCartItem from "api/CartResource/useUpdateCartItem";
import { ButtonLoader } from "components";
import { Link as ReactLink } from "react-router-dom";
import usePrice from "hooks/usePrice";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsHandThumbsDown } from "react-icons/bs";
// import useCurrencyStore from "store/currencyStore";
import { delay, formatCurrency } from "utils";

const BookInCart = ({
	title,
	coverUrl,
	author,
	cartId,
	quantity,
	price: usdPrice,
	zwl_price,
	bookId,
	paymentResponse,
	// ...rest
}) => {
	const { mutateAsync: mutateDeleteCart, isLoading: isDeleteCartLoading } =
		useDeleteCartItem(cartId);

	const handleDeleteCart = async () => {
		await mutateDeleteCart();
	};

	const [cartItemQuantity, setCartItemQuantity] = React.useState(quantity);
	const [isDelaying, setIsDelaying] = React.useState(false);

	const { mutateAsync, isLoading } = useUpdateCartItem(cartId);

	const increaseQuantity = async () => {
		setIsDelaying(true);
		await delay(1);
		await mutateAsync({ quantity: cartItemQuantity + 1 });
		setIsDelaying(false);
		setCartItemQuantity((prev) => prev + 1);
	};

	const decreaseQuantity = async () => {
		if (cartItemQuantity <= 1) return;
		setIsDelaying(true);
		await delay(1);
		await mutateAsync({ quantity: cartItemQuantity - 1 });
		setIsDelaying(false);
		setCartItemQuantity((prev) => prev - 1);
	};

	const price = usePrice({
		isFree: false,
		usdPrice: usdPrice,
		zwlPrice: zwl_price,
		sellable: true,
	});

	return (
		<div className="flex gap-2">
			<div
				className="p-2 bg-gray-200 rounded-lg shadow border border-gray-300"
				style={{ maxHeight: 230, width: 150 }}
			>
				{coverUrl && (
					<ReactLink to={`/book/${bookId}`}>
						<img
							src={coverUrl}
							width="120"
							height="200"
							alt="Book Cover"
							className="rounded-lg h-48"
						/>
					</ReactLink>
				)}
			</div>

			<div className="" style={{ width: 120 }}>
				<p className="text-sm truncate font-semibold" title={title}>
					{title}
				</p>
				<p className="truncate" title={author}>
					{author}
				</p>

				<div className="mt-2 gap-2">
					{!paymentResponse && <p>Quantity - {quantity}</p>}
					<>
						<p className="text-gray-600">Price - {formatCurrency(price)}</p>
					</>
					{paymentResponse ? (
						<div className="flex items-center gap-2 mt-3 text-akgreenest border-t-2 border-gray-400 pt-2">
							<span className="text-xl">Paid</span>{" "}
							<BsHandThumbsDown size={40} className="transform -rotate-180" />
						</div>
					) : (
						<div className="mt-2">
							{isDeleteCartLoading ? (
								<ButtonLoader size={30} />
							) : (
								<button
									type="button"
									onClick={handleDeleteCart}
									className="p-1 px-2 border border-akgreen rounded-2xl hover:bg-akgreen hover:text-white"
								>
									Remove
								</button>
							)}
						</div>
					)}

					<div className="flex items-center gap-1 mt-2">
						{isLoading || isDelaying ? (
							<AiOutlineLoading3Quarters size={40} className=" animate-spin " />
						) : (
							<>
								<button
									onClick={decreaseQuantity}
									type="button"
									className="h-10 w-10 border border-akgreen rounded-full hover:bg-akgreen hover:text-white font-bold"
								>
									-
								</button>
								<span>{cartItemQuantity}</span>
								<button
									onClick={increaseQuantity}
									type="button"
									className="h-10 w-10 border border-akgreen rounded-full hover:bg-akgreen hover:text-white font-bold"
								>
									+
								</button>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BookInCart;
