import useMoveBookToTrash from "api/BookResource/useMoveBookToTrash";
import { ButtonLoader } from "components";
import { ConfirmPopup } from "primereact/confirmpopup";
import { Tooltip } from "primereact/tooltip";
import React from "react";
import { FiTrash2 } from "react-icons/fi";

const DeleteBook = ({ elementId, bookId }) => {
  const [visible, setVisible] = React.useState(false);

  const { mutateAsync, isLoading: bookIsDeleting } = useMoveBookToTrash(bookId);

  const accept = async () => {
    await mutateAsync();
  };

  return (
    <div>
      {bookIsDeleting ? (
        <ButtonLoader size={40} />
      ) : (
        <button
          type="button"
          className="w-8 h-8 flex items-center border border-akgreenest p-2 text-white justify-center rounded-full bg-red-400"
          onClick={() => setVisible(true)}
          id={elementId}
          tooltip="Click to proceed"
        >
          <FiTrash2 />
        </button>
      )}
      <Tooltip
        className="green-tooltip"
        target={`#${elementId}`}
        position="bottom"
        content="Deactivate"
      />
      <ConfirmPopup
        target={document.getElementById(elementId)}
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete?"
        icon="pi pi-exclamation-triangle"
        accept={accept}
      />
    </div>
  );
};

export default DeleteBook;
