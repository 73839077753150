import useAuthStore from "store/authStore";

const useViewableRoles = () => {
	const { roles: manageableRoles } = useAuthStore();

	const rolesMap = {
		"Super Administrator": [
			"Super Administrator",
			"Administrator",
			"Senior Publisher",
			"Publisher",
			"Institution",
			"Educator",
			"Student",
			"Individual",
			"Brand Ambassador",
		],
		Administrator: [
			"Administrator",
			"Senior Publisher",
			"Publisher",
			"Institution",
			"Educator",
			"Student",
			"Individual",
			"Brand Ambassador",
		],
		"Senior Publisher": ["Senior Publisher", "Publisher"],
		Publisher: ["Publisher"],
		Institution: ["Institution", "Educator", "Student"],
		Educator: ["Student"],
		"Brand Ambassador": ["Individual", "Institution"],
	};

	const roles = manageableRoles
		? rolesMap[manageableRoles[0]]?.map((role) => ({
				label: role,
				value: role,
		  }))
		: null;

	return { roles };
};

export default useViewableRoles;
