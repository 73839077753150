import useFetchWeeklyRegistrationsGraph from "api/AdminReports/useFetchWeeklyRegistrationsGraph";
import React, { useRef } from "react";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, Tooltip } from "recharts";
import { useMeasure } from "react-use";
import { useState } from "react";
import { Menu } from "primereact/menu";
import { HiMenuAlt3 } from "react-icons/hi";
import moment from "moment";
import { BsArrowRight, BsDash } from "react-icons/bs";
import { NavLink } from "react-router-dom";

const WeeklyRegistrationsGraph = () => {
  const [ref, { width }] = useMeasure();

  const [week, setWeek] = useState("this_week");

  const menu = useRef(null);

  let from = moment();
  let to = moment();

  switch (week) {
    case "this_week":
      from = from.startOf("week");
      to = to.endOf("week");
      break;
    case "last_week":
      from = from.subtract({ weeks: 1 }).startOf("week");
      to = to.subtract({ weeks: 1 }).endOf("week");
      break;
    case "two_weeks_ago":
      from = from.subtract({ weeks: 2 }).startOf("week");
      to = to.subtract({ weeks: 2 }).endOf("week");
      break;

    case "three_weeks_go":
      from = from.subtract({ weeks: 3 }).startOf("week");
      to = to.subtract({ weeks: 3 }).endOf("week");
      break;
    case "four_weeks_ago":
      from = from.subtract({ weeks: 4 }).startOf("week");
      to = to.subtract({ weeks: 4 }).endOf("week");
      break;

    default:
      from = from.startOf("week");
      to = to.endOf("week");
      break;
  }

  const items = [
    {
      label: "This week",
      command: () => setWeek("this_week"),
    },
    {
      label: "Last week",
      command: () => setWeek("last_week"),
    },
    {
      label: "Two weeks ago",
      command: () => setWeek("two_weeks_ago"),
    },
    {
      label: "Three weeks ago",
      command: () => setWeek("three_weeks_ago"),
    },
    {
      label: "Four weeks ago",
      command: () => setWeek("four_weeks_ago"),
    },
  ];

  const { data: stats } = useFetchWeeklyRegistrationsGraph({ week: week });

  return (
    <div>
      <div
        className="w-full flex flex-col py-4 px-4 rounded shadow-lg space-y-4 relative"
        style={{ height: 360 }}
        ref={ref}
      >
        <div className="flex items-center justify-between gap-2 w-full">
          <div className="flex items-center gap-2">
            <h5>Registrations</h5>

            <Menu
              model={items}
              popup
              ref={menu}
              id="popup_menu"
              className="max-w-max font-sans text-xs "
            />
            <button
              type="button"
              className="rounded px-2 py-1 border border-akgreener text-xs flex items-center gap-1 "
              onClick={(event) => menu.current.toggle(event)}
              aria-controls="popup_menu"
              aria-haspopup
            >
              <HiMenuAlt3 size={15} />
              {(week?.charAt(0)?.toUpperCase() + week?.slice(1)?.toLowerCase())?.replace("_", " ")}
            </button>
          </div>

          <div className="flex items-center gap-1 text-xs">
            <p className="px-2 py-1 border border-akgreener rounded">{from.format("YYYY-MM-DD")}</p>
            <BsDash size={20} />
            <p className="px-2 py-1 border border-akgreener rounded">{to.format("YYYY-MM-DD")}</p>
          </div>
        </div>

        <div className="mb-4">
          <LineChart width={width - 10} height={300} data={stats?.data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Current" stroke="rgba(95, 160, 0, 0.7)" />
            <Line type="monotone" dataKey="Previous" stroke="rgba(37, 99, 235, 0.7)" />
          </LineChart>
        </div>
      </div>

      <div className="mt-6">
        <NavLink to="/users/admin/all" className="text-blue-900 font-bold flex items-center gap-2">
          View users <BsArrowRight size={20} />
        </NavLink>
      </div>
    </div>
  );
};

export default WeeklyRegistrationsGraph;
