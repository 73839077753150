import useDeleteException from "api/Exceptions/useDeleteException";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdOutlineDeleteOutline } from "react-icons/md";

const DeleteException = ({ errorId }) => {
	const { mutateAsync, isLoading: isDeletingException } = useDeleteException();

	const deleteException = async (id) => {
		await mutateAsync(id);
	};
	return (
		<button
			id={`delete-exception-${errorId}`}
			onClick={() => deleteException(errorId)}
			className="text-blue-700 flex items-center gap-1"
		>
			{isDeletingException ? (
				<AiOutlineLoading3Quarters size={18} className="animate-spin" />
			) : (
				<MdOutlineDeleteOutline size={18} />
			)}

			<span>Delete</span>
		</button>
	);
};

export default DeleteException;
