import useFetchTopReadBooks from "api/InstitutionResource/useFetchTopReadBooks";
import React from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ButtonLoader } from "components";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

const MostReadBooks = () => {
	const { data: books, isLoading: isLoadingBooks } = useFetchTopReadBooks();

	const numberOfReads = (row) => (
		<span className="font-bold bg-gray-500 text-white px-2 py-1 rounded-full ">
			{row.reading_users_count} student(s)
		</span>
	);

	const categorization = (row) => (
		<div>
			<p>{row?.category?.name}</p>
			<p>
				In <span className="underline">"{row?.genres[0]?.name}"</span> genre
			</p>
		</div>
	);

	return (
		<div>
			<div className="flex justify-between items-center w-full mb-2">
				<h4 className="font-cera-black">Most read books</h4>
			</div>

			<div className="flex flex-col">
				{isLoadingBooks ? (
					<ButtonLoader size={60} />
				) : (
					<DataTable
						value={books?.data}
						tableClassName="font-sans text-sm"
						autoLayout
					>
						<Column
							header="Number of students who read"
							headerClassName="text-center"
							body={numberOfReads}
						/>
						<Column field="title" header="Title" />
						<Column field="author" header="Author" />
						<Column header="Categorization" body={categorization} />
					</DataTable>
				)}
			</div>

			<div className="mt-4">
				<Link
					to="/books/institution"
					className="flex gap-2 text-blue-400 items-center "
				>
					View all books <FiArrowRight />
				</Link>
			</div>
		</div>
	);
};

export default MostReadBooks;
