import useFetchPublisherAggregateRevenue from "api/AdminReports/useFetchPublisherAggregateRevenue";
import RevenueCard from "components/v2/RevenueCard";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import React, { useState } from "react";
import { BiCalendar } from "react-icons/bi";
import { GiTakeMyMoney } from "react-icons/gi";
import { activeCurrencies } from "utils";

const RevenueReport = ({ publisherId }) => {
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");

	const { data: revenue } = useFetchPublisherAggregateRevenue(
		{
			from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
			to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
			model_type: "publisher",
		},
		publisherId
	);

	return (
		<div className="space-y-3">
			<div className="flex justify-between items-center gap-4">
				<h4 className="font-cera-black">Earnings report</h4>
				<div className="flex gap-4 items-center">
					<span className="p-float-label">
						<Calendar
							id={fromDate}
							className="font-sans"
							value={fromDate}
							onChange={(e) => setFromDate(e.target.value)}
							dateFormat="yy-mm-dd"
							mask="9999-99-99"
							panelClassName="font-sans text-xs"
							showIcon
							icon={<BiCalendar size={20} />}
							inputClassName="pl-4 text-sm"
						/>
						<label htmlFor={fromDate}>From date</label>
					</span>
					<span className="p-float-label">
						<Calendar
							id={toDate}
							className="font-sans"
							value={toDate}
							onChange={(e) => setToDate(e.target.value)}
							dateFormat="yy-mm-dd"
							mask="9999-99-99"
							panelClassName="font-sans text-xs"
							showIcon
							icon={<BiCalendar size={20} />}
							inputClassName="pl-4 text-sm"
						/>
						<label htmlFor={toDate}>To date</label>
					</span>
				</div>
			</div>

			<div>
				<div className="py-3 space-y-2">
					<p className="text-lg">{activeCurrencies.USD.label} Income</p>
					<div className="flex gap-6">
						<RevenueCard
							label="Total revenue (S+P)"
							value={[
								`Gross ($ ${revenue?.data?.totalIncome || 0})`,
								`Received ($ ${revenue?.data?.amountReceived || 0})`,
								`Net ($ ${revenue?.data?.netTotalIncome || 0})`,
							]}
							icon={<GiTakeMyMoney size={20} className="text-gray-400" />}
						/>

						<RevenueCard
							label="From subscriptions (S)"
							value={`Gross ($ ${revenue?.data?.incomeFromSubscription || 0})`}
							icon={<GiTakeMyMoney size={20} className="text-gray-400" />}
						/>

						<RevenueCard
							label="From purchases (P)"
							value={`Gross ($ ${revenue?.data?.incomeFromPurchases || 0})`}
							icon={<GiTakeMyMoney size={20} className="text-gray-400" />}
						/>
					</div>
				</div>

				<div className="py-3 space-y-2">
					<p className="text-lg">{activeCurrencies.ZWL.label} Income</p>
					<div className="flex gap-6">
						<RevenueCard
							label="Total revenue (S + P)"
							value={[
								`Gross ($ ${revenue?.data?.totalIncomeZwl || 0})`,
								`Received ($ ${revenue?.data?.amountReceivedZwl || 0})`,
								`Net ($ ${revenue?.data?.netTotalIncomeZwl || 0})`,
							]}
							icon={<GiTakeMyMoney size={20} className="text-gray-400" />}
						/>

						<RevenueCard
							label="From subscriptions (S)"
							value={`Gross ($ ${
								revenue?.data?.incomeFromSubscriptionZwl || 0
							})`}
							icon={<GiTakeMyMoney size={20} className="text-gray-400" />}
						/>

						<RevenueCard
							label="From purchases (P)"
							value={`Gross ($ ${revenue?.data?.incomeFromPurchasesZwl || 0})`}
							icon={<GiTakeMyMoney size={20} className="text-gray-400" />}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RevenueReport;
