import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useAuthStore from "store/authStore";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";

const useCheckIfUserIsSubscribed = () => {
	const { loggedIn } = useAuthStore();
	const { accessToken } = useTokenStore();
	// const isAuthenticated = useAuthStore((state) => state.loggedIn);
	const fetch = async () => {
		if (!loggedIn) {
			return null;
		}

		const instance = await InitApi({ accessToken });
		const res = await instance.get("has-subscription");
		return res?.data;
	};
	return useQuery("is_subscribed", () => fetch(), {
		...fetchConfig,
	});
};

export default useCheckIfUserIsSubscribed;
