import React from "react";
import { Formik, Form } from "formik";
import useApplyRatesToPrices from "api/RatesResource/useApplyRatesToPrices";
import FormField from "components/v2/FormField";
import { activeCurrencies } from "utils";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const ApplyRateToPrices = ({ useRatePricing }) => {
	const initialValues = {
		apply_rates_to_prices: Boolean(useRatePricing),
	};

	const { mutateAsync, isLoading } = useApplyRatesToPrices();

	const submit = async (value) => {
		const payLoad = {
			apply_rates_to_prices: value ? 1 : 0,
		};
		await mutateAsync(payLoad);
	};

	return (
		<Formik initialValues={initialValues}>
			{({ values, setFieldValue }) => (
				<Form className="flex items-center gap-2">
					<FormField
						type="switcher"
						name="apply_rates_to_prices"
						label={`Do you want to apply currency rate to ${activeCurrencies.ZWL.label} prices?`}
						switchLabelPlacement="left"
						onChange={(e) => {
							setFieldValue("apply_rates_to_prices", e.value);
							submit(e.value);
						}}
					/>
					{isLoading && (
						<AiOutlineLoading3Quarters size={20} className="animate-spin" />
					)}
				</Form>
			)}
		</Formik>
	);
};

export default ApplyRateToPrices;
