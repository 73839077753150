import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";

const useFetchRoles = () => {
	const { accessToken } = useTokenStore();
	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		return await instance.get("roles");
	};
	return useQuery(["roles"], () => fetch(), {
		...fetchConfig,
	});
};

export default useFetchRoles;
