import InitApi from "api/InitApi";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import useTokenStore from "store/tokenStore";
// import useOverlayStore from "store/overlayStore";
import { deleteEmptyObjects } from "utils";

const useCreateSubscriptionOrder = () => {
	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	// const { setShowListOfPicksToRetain } = useOverlayStore();

	const history = useHistory();
	const location = useLocation();
	const retryUrl = location.pathname;

	const submit = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);

		const response = await instance.post(
			`subscriptions/subscribe/${body?.id}`,
			body
		);
		return response?.data;
	};

	return useMutation(submit, {
		onSuccess: async ({ data }) => {
			queryClient.invalidateQueries("is_subscribed");
			queryClient.invalidateQueries("picks");
			queryClient.invalidateQueries("subscribedBooks");
			const queryString = `payment_method=${data?.payment_method}&has_picks=${data?.has_picks}`;
			history.push(`/subscription-success-response?${queryString}`);
		},
		onError: async (error) => {
			queryClient.invalidateQueries("is_subscribed");
			queryClient.invalidateQueries("picks");
			queryClient.invalidateQueries("subscribedBooks");

			const errorMsg =
				error?.response?.data?.message ||
				"Something went wrong. Please try again.";
			const queryString = `retryUrl=${retryUrl}&error=${errorMsg}`;
			history.push(`/subscription-error-response?${queryString}`);
		},
	});
};

export default useCreateSubscriptionOrder;
