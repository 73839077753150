import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import useAuthStore from "store/authStore";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useUpdateProfile = (user_id) => {
	//!

	const queryClient = useQueryClient();

	const history = useHistory();
	const { accessToken } = useTokenStore();

	const update = async (obj) => {
		const instance = await InitApi({ accessToken }, "multipart/form-data");

		const body = deleteEmptyObjects(obj);

		if (body?.avatar_file) {
			const response = await instance.post(
				`/users/${user_id}?_method=PATCH`,
				body
			);
			return response;
		}

		const formData = new FormData();
		Object.keys(body).forEach((key) => {
			formData.append(key, body[key]);
		});

		const response = await instance.post(
			`/users/${user_id}?_method=PATCH`,
			formData
		);

		return response;
	};

	const updateUser = useAuthStore((state) => state.storeUser);

	return useMutation(update, {
		onSuccess: async ({ data: body }) => {
			updateUser(body.data);
			queryClient.invalidateQueries("profile");
			toast.success("Profile updated successfully");
		},
		onError: async (error) => {
			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);
		},
	});

	//!
};

export default useUpdateProfile;
