import moment from "moment";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { FiX } from "react-icons/fi";
import { HiOutlineExternalLink } from "react-icons/hi";
import RevenueReport from "./RevenueReport";
import AggregateBooks from "./AggregateBooks";

const MoreAboutPublisher = ({ publisher, facilitators }) => {
	const [visible, setVisible] = useState(false);

	return (
		<>
			<button type="button" onClick={() => setVisible(true)}>
				<HiOutlineExternalLink size={20} className="text-blue-700" />
			</button>

			<Dialog
				visible={visible}
				onHide={() => setVisible(false)}
				showHeader={false}
				className="font-sans text-sm"
				contentClassName="rounded-md relative"
				style={{ width: 900 }}
				// fullScreen
			>
				<div
					className="sticky top-0 w-full  py-8 bg-white"
					style={{ zIndex: 100 }}
				>
					<div className="flex justify-between items-center">
						<h4 className="text-akgreenest font-bold text-center text-xl  ">
							<span>{publisher.name}</span>
							<span className="font-normal text-lg">
								, registered on{" "}
								{moment(publisher?.created_at).format("MMM DD, YYYY")}
							</span>
						</h4>

						<button type="button" onClick={() => setVisible(false)}>
							<FiX size={25} />
						</button>
					</div>
					{publisher?.address && <p>{publisher?.address}</p>}
					<div
						style={{ width: 180 }}
						className="border-b-4 border-gray-500 mt-2"
					/>
				</div>

				<div className="py-4">
					<div className="pb-6 mb-8 border-b border-gray-300">
						<h4>Facilitators</h4>
						<div className="border-b-4 border-gray-500 w-16" />
						<ul className="mt-2" style={{ maxWidth: 400 }}>
							{facilitators?.map((user) => (
								<li
									key={user?.id}
									className="flex justify-between items-center gap-3"
								>
									<span>
										{user?.first_name} {user?.last_name}
									</span>
									<span>{user?.email}</span>
								</li>
							))}
						</ul>
					</div>

					<div className="flex flex-col gap-8 ">
						<RevenueReport publisherId={publisher?.id} />

						<AggregateBooks publisherId={publisher?.id} />
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default MoreAboutPublisher;
