import useSearchParams from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { deleteEmptyObjects } from "utils";

const useMyLibraryFilters = () => {
	const DEFAULT_ROWS = 40;
	const DEFAULT_FIRST_PAGE = 0;
	const DEFAULT_PAGE = 1;

	const [firstPage, setFirstPage] = useState(DEFAULT_FIRST_PAGE);
	const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS);
	const [page, setPage] = useState(DEFAULT_PAGE);

	const { push } = useHistory();
	const { pathname } = useLocation();

	const { getParam, setParam, deleteParam, hasParam } = useSearchParams();

	useEffect(() => {
		if (hasParam("page")) {
			const pageParam =
				getParam("page")?.length > 0 ? getParam("page") : DEFAULT_PAGE;
			setPage(pageParam);
		}
		if (hasParam("rows")) {
			const rowsPerPageParam =
				getParam("rows")?.length > 0 ? getParam("rows") : DEFAULT_ROWS;
			setRowsPerPage(rowsPerPageParam);
		}

		if (hasParam("first_page")) {
			const firstPageParam =
				getParam("first_page")?.length > 0
					? getParam("first_page")
					: DEFAULT_FIRST_PAGE;
			setFirstPage(firstPageParam);
		}

		return () => {
			deleteParam("page");
			deleteParam("rows");
			deleteParam("first_page");
		};
	}, [getParam, hasParam, deleteParam]);

	const changeAllFiltersAtOnce = (data) => {
		const values = deleteEmptyObjects(data);
		setFirstPage(values.first_page);
		setRowsPerPage(values.rows);
		setPage(values.page);

		push(`${pathname}?${new URLSearchParams(values).toString()}`);
	};

	const changePage = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("page");
			setPage(DEFAULT_PAGE);
			return push(`${pathname}?${q}`);
		}
		const q = setParam("page", value);
		push(`${pathname}?${q}`);
	};

	const changeRowsPerPage = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("rows");
			setRowsPerPage(DEFAULT_ROWS);
			return push(`${pathname}?${q}`);
		}
		const q = setParam("rows", value);
		push(`${pathname}?${q}`);
	};

	const changeFirstPage = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("first_page");
			setFirstPage(DEFAULT_FIRST_PAGE);
			return push(`${pathname}?${q}`);
		}
		const q = setParam("first_page", value);
		push(`${pathname}?${q}`);
	};

	return {
		firstPage,
		rowsPerPage,
		page,
		changeAllFiltersAtOnce,
		changePage,
		changeRowsPerPage,
		changeFirstPage,
	};
};

export default useMyLibraryFilters;
