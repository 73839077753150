// import toast from "react-hot-toast";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({
	component: Component,
	isAuthenticated,
	hasRight,
	...rest
}) => {
	//!

	return (
		<Route
			render={({ location, ...props }) => {
				if (isAuthenticated && hasRight) {
					return <Component {...props} />;
				}
				if (!hasRight) {
					return <Redirect to="/access-denied" />;
				}

				return (
					<Redirect to={{ pathname: "/login", state: { from: location } }} />
				);

				// return <Component {...props} />;
			}}
			{...rest}
		/>
	);

	//!
};

export default PrivateRoute;
