import useUpdateProfile from "api/UserResource/useUpdateProfile";
import { AvatarInput, ButtonLoader } from "components";
import { Form, Formik } from "formik";
import { MAX_IMAGE_SIZE, phoneRegex } from "utils";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import moment from "moment";

const UpdateProfileForm = ({ user }) => {
	const initialValues = {
		first_name: user?.first_name || "",
		middle_name: user?.middle_name || "",
		email: user?.email || "",
		last_name: user?.last_name || "",
		dob: user?.dob || "",
		sex: user?.sex || "",
		mobile_number: user?.mobile_number || "",
		telephone: user?.telephone || "",
		avatar: null,
	};

	const updateSchema = Yup.object().shape({
		first_name: Yup.string().required("First name is required"),
		last_name: Yup.string().required("Surname is required"),
		email: Yup.string()
			.required("Email is required")
			.email("Invalid email address"),
		mobile_number: Yup.string()
			.matches(phoneRegex, "Invalid phone number")
			.min(10, "Mobile number must have at least characters")
			.max(20, "Mobile number cannot exceed 20 characters"),
		dob: Yup.string().matches(
			/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
			"Invalid date format"
		),
		avatar: Yup.mixed()
			.nullable()
			.test(
				"fileSize",
				`Image size should not exceed ${MAX_IMAGE_SIZE}MB`,
				(value) => {
					if (!value) return true;
					return value.size / 1024 / 1024 <= MAX_IMAGE_SIZE;
				}
			),
	});

	const { mutateAsync, isLoading } = useUpdateProfile(user?.id);

	const handleUpdateProfile = async (data) => {
		await mutateAsync(data);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={updateSchema}
			onSubmit={(data) => handleUpdateProfile(data)}
		>
			{(formik) => {
				return (
					<Form className=" rounded-3xl w-full py-3 space-y-8 border-akgreener">
						<FormField type="text" name="first_name" label="First Name *" />

						<FormField type="text" name="last_name" label="Last Name *" />

						<FormField type="text" name="email" label="Email Address *" />

						<FormField
							name="mobile_number"
							type="phone"
							label="Phone Number"
							placeholder="phone"
							value={formik.values.mobile_number}
							_onChange={(phone) => {
								if (phone !== "+") formik.values.mobile_number = "+" + phone;
								else formik.values.mobile_number = phone;
							}}
							error={Boolean(
								formik.errors.mobile_number && formik.touched.mobile_number
							)}
							errorText={formik.errors.mobile_number}
						/>

						<FormField
							name="dob"
							type="date"
							label="Date of Birth"
							value={formik.values.dob}
							onChange={(e) =>
								formik.setFieldValue(
									"dob",
									moment(e.target?.value).format("YYYY-MM-DD")
								)
							}
						/>

						<FormField
							type="select"
							name="sex"
							label="Select gender"
							options={[
								{ label: "Male", value: "Male" },
								{ label: "Female", value: "Female" },
							]}
						/>

						<AvatarInput
							value={null}
							onChange={(event) =>
								formik.setFieldValue("avatar", event.target.files[0])
							}
							name="avatar"
						/>

						<div className="mt-4 flex justify-center items-center">
							{isLoading ? (
								<ButtonLoader size={40} />
							) : (
								<button
									type="submit"
									className="py-2 px-2 flex w-full justify-center bg-gradient-to-b from-akgreenest to-akgreen text-white rounded-lg"
								>
									Update
								</button>
							)}
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

export default UpdateProfileForm;
