import React from "react";
import ProfileLayout from "../ProfileLayout";
import useSearchParams from "hooks/useSearchParams";
import useProfile from "api/UserResource/useProfile";
import ChangeEmailAddress from "../ChangeEmailAddress";

const Emails = () => {
	const { getParam } = useSearchParams();
	const heading = getParam("heading") || "Emails";
	const { data: profile, isLoading: isLoadingProfile } = useProfile();

	return (
		<ProfileLayout
			heading={heading}
			isLoading={isLoadingProfile}
			profile={profile?.data}
		>
			<div className="pt-4">
				<ChangeEmailAddress />
			</div>
		</ProfileLayout>
	);
};

export default Emails;
