import { Dialog } from "primereact/dialog";

const ModalNotification = ({ title, visible, setVisible, children }) => {
  return (
    <Dialog
      visible={visible}
      header={<div className="text-center">{title}</div>}
      onHide={() => setVisible(false)}
    >
      {children}
    </Dialog>
  );
};

export default ModalNotification;
