import { DesktopHeader } from "components";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import useRequestSupport from "api/CommandCenterResource/useRequestSupport";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiCheck } from "react-icons/fi";
import useSearchParams from "hooks/useSearchParams";
import useSupportQueryStore from "store/supportQueryStore";

const Support = () => {
	const { getParam } = useSearchParams();

	const { query, setQuery } = useSupportQueryStore();

	const initialValues = {
		name: getParam("name") || "",
		email: getParam("email") || "",
		query: query,
		category: getParam("category") || "",
	};

	const validationSchema = Yup.object({
		name: Yup.string().optional(),
		email: Yup.string()
			.email("Invalid email format")
			.required("Your email is required"),
		query: Yup.string().required("Enter your enquiry or issue"),
		category: Yup.string().required("Select a category"),
	});

	const { mutateAsync, isLoading, isSuccess } = useRequestSupport();

	const onSubmit = async (data) => {
		await mutateAsync(data);
		setQuery("");
	};

	return (
		<Container>
			<DesktopHeader shadow />

			<div className="mt-10">
				<PaddingX>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ values }) => (
							<>
								{isSuccess ? (
									<div className="mx-auto w-80 xs:w-96 sm:w-600 flex flex-col items-center gap-6 ">
										<span className="w-36 h-36 rounded-full border border-gray-300 flex items-center justify-center">
											<FiCheck size={40} />
										</span>

										<h2 className="text-center">
											Thank you for contacting us!
										</h2>

										<p className="text-center">
											We understand the importance of timely assistance, and we
											will do our best to get back to you as soon as possible.
											In the meantime, if you have any additional information to
											share or questions to ask, please don't hesitate to reach
											out to us. We appreciate your patience and look forward to
											resolving your inquiry or issue.
										</p>

										<p className="font-bold text-center">
											Look out for our reply to your email ({values.email})
										</p>
									</div>
								) : (
									<Form className="mx-auto w-80 xs:w-96 sm:w-600">
										<div className="mb-8 space-y-3">
											<h2>How can we assist you?</h2>

											<p>
												We're here to provide you with the assistance you need.
												Please take a moment to describe your inquiry or issue
												in detail. The more information you provide, the better
												we can understand and address your request. Our
												dedicated support team will promptly review your
												submission and get back to you with a solution or
												further guidance. Thank you for choosing Akello Library,
												and we appreciate your patience!
											</p>
										</div>

										<div className="space-y-6 w-full">
											<FormField
												type="text"
												name="name"
												label="Your name (Optional)"
											/>

											<FormField
												type="email"
												name="email"
												label="Your email*"
											/>

											<FormField
												label="Select inquiry category*"
												type="select"
												name="category"
												options={[
													// { value: "", label: "Select a category" },
													{ value: "payments", label: "Payments" },
													{ value: "navigation", label: "Platform navigation" },
													{ value: "registration", label: "Registration" },
													{ value: "search", label: "Searching for resources" },
													{
														value: "feature_suggestion",
														label: "Feature suggestion",
													},
													{
														value: "content_suggestion",
														label: "Suggestion for content",
													},
													{ value: "other", label: "Other" },
												]}
											/>

											<FormField
												type="textarea"
												name="query"
												label="How can we help you*"
											/>

											<button
												type="submit"
												className="rounded-md py-2 px-4 flex items-center justify-center gap-2 bg-akgreener text-white w-32 text-base"
											>
												Submit{" "}
												{isLoading && (
													<AiOutlineLoading3Quarters
														size={20}
														className="animate-spin"
													/>
												)}
											</button>
										</div>
									</Form>
								)}
							</>
						)}
					</Formik>
				</PaddingX>
			</div>
		</Container>
	);
};

export default Support;
