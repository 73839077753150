import useSendPasswordResetLink from "api/UserResource/useSendPasswordResetLink";
import { ButtonLoader } from "components";
import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import AuthLayout from "layouts/AuthLayout";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Message } from "primereact/message";
import { FiX } from "react-icons/fi";
import { useState } from "react";

const SendPasswordResetLink = () => {
	//!

	// const logoWidth = 80;

	const [successMessage, setsuccessMessage] = useState(true);

	const initialValues = {
		email: "",
	};

	const validate = Yup.object().shape({
		email: Yup.string().required("Email is required"),
	});

	const { mutateAsync, isLoading, isSuccess } = useSendPasswordResetLink();
	const handleSubmit = async (data) => {
		await mutateAsync({
			...data,
		});
	};

	return (
		<AuthLayout hideCamera>
			<div style={{ width: 350 }}>
				<h1 className="text-akgreen font-cera-black mt-2">Password Reset Link</h1>
				<p className="mt-2">
					Enter your current email address and a password reset link will be send to you
				</p>
			</div>

			<div className="mt-6">
				<Formik
					initialValues={initialValues}
					validationSchema={validate}
					onSubmit={(data) => handleSubmit(data)}
				>
					{(formik) => (
						<Form className="flex flex-col gap-4 w-full">
							{isSuccess && successMessage && (
								<div className="mb-4">
									<Message
										severity="success"
										className="font-sans text-left text-sm rounded-md"
										content={
											<div className="flex items-center gap-2 w-full">
												<span>
													Password reset link has been send to your email
													address
												</span>
												<button
													type="button"
													onClick={() => setsuccessMessage(false)}
												>
													<FiX size={20} />
												</button>
											</div>
										}
									/>
								</div>
							)}
							<div style={{ width: 350 }}>
								<FormField name="email" type="text" label="Email Address" />
							</div>

							<div>
								<button
									type="submit"
									className="px-4 py-1 flex items-center justify-center gap-1 text-white w-32 rounded-full bg-gradient-to-r from-akgreen to-akgreenest"
								>
									{isLoading ? (
										<>
											Sending...
											<ButtonLoader
												size={25}
												color="rgba(255, 255, 255)"
											/>{" "}
										</>
									) : (
										"Send Link"
									)}
								</button>
							</div>

							<p>
								<Link to="/login">Go to Login</Link>
							</p>
						</Form>
					)}
				</Formik>
			</div>
		</AuthLayout>
	);

	//!
};

export default SendPasswordResetLink;
