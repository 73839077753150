import React from "react";
import { BsFilter } from "react-icons/bs";

const ClearFilters = ({ count, changeFromDate, changeToDate }) => {
	const clearFilters = () => {
		changeFromDate("");
		changeToDate("");
	};

	return (
		<>
			{count > 0 && (
				<button
					type="button"
					className="flex items-center relative bg-akgreener text-white px-2 rounded"
					onClick={clearFilters}
				>
					<span>Clear Filters</span>
					<span>
						<BsFilter size={40} />
						<span className="absolute top-1 right-1 h-6 w-6 border border-akgreen rounded-full bg-white text-gray-700 flex items-center justify-center">
							{count || 0}
						</span>
					</span>
				</button>
			)}
		</>
	);
};

export default ClearFilters;
