import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";

const useFetchPermissions = () => {
	const { accessToken } = useTokenStore();
	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const res = await instance.get("permissions");
		return res.data;
	};
	return useQuery(["permissions"], () => fetch(), {
		...fetchConfig,
	});
};

export default useFetchPermissions;
