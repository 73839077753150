import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useOverlayStore from "store/overlayStore";
import { Dialog } from "primereact/dialog";
import useAddSubscription from "api/SubscriptionsResource/useAddSubscription";
import { activeCurrencies } from "utils";

const AddSubscriptionPlan = () => {
	const initialValues = {
		name: "",
		duration: "",
		time_unit: "",
		cost: "",
		category: "",
		currency_symbol: "$",
		currency_code: activeCurrencies.USD.code,
	};

	const validate = Yup.object().shape({
		name: Yup.string().required("Name of plan required"),
		duration: Yup.number().min(1).required("Duration required"),
		time_unit: Yup.string().required("Time units required"),
		cost: Yup.number().min(0).required("Cost required"),
		category: Yup.string().required("Category required"),
	});

	const { showCreateSubscription, setShowCreateSubscription } =
		useOverlayStore();

	const { mutateAsync, isLoading } = useAddSubscription();

	const onSubmit = async (data) => {
		await mutateAsync(data);
	};

	return (
		<Dialog
			visible={showCreateSubscription}
			style={{ width: "50vw" }}
			header={
				<div className="text-xl font-sans font-bold sticky top-4 z-20 bg-white mr-2">
					<p className="">Create subscripton plan</p>
				</div>
			}
			onHide={() => setShowCreateSubscription(false)}
			position="top"
			draggable={false}
			contentClassName="rounded-b-xl "
			className="rounded-xl relative"
		>
			<Formik
				initialValues={initialValues}
				validationSchema={validate}
				onSubmit={onSubmit}
			>
				{({ setFieldValue, values, errors, touched }) => {
					return (
						<Form className="mt-6 space-y-6">
							<FormField type="text" name="name" label="Subscription name *" />
							<FormField
								type="number"
								name="duration"
								label="Duration*"
								helperText="Numeric number e.g 7"
							/>

							<FormField
								type="select"
								name="time_unit"
								label="Time units (e.g days) *"
								options={[
									{ label: "Hours", value: "hours" },
									{ label: "Days", value: "days" },
									{ label: "Weeks", value: "weeks" },
									{ label: "Months", value: "months" },
									{ label: "Years", value: "years" },
								]?.map((role) => ({
									label: role.label,
									value: role.value,
								}))}
							/>

							<FormField
								type="number"
								min="0"
								name="cost"
								step="0.01"
								label="Cost*"
							/>

							<FormField
								type="select"
								name="category"
								label="Category *"
								options={[
									{ label: "Individual", value: "individual" },
									{ label: "Institution", value: "institution" },
								]?.map((role) => ({
									label: role.label,
									value: role.value,
								}))}
							/>

							<div className=" flex justify-center border-t border-gray-300 pt-2">
								<button
									type="submit"
									className="px-4 py-2 rounded-full bg-akgreenest text-white flex gap-1 items-center justify-center"
								>
									{isLoading ? (
										<>
											Submitting{" "}
											<AiOutlineLoading3Quarters
												size={20}
												className="animate-spin"
											/>
										</>
									) : (
										<>
											Submit <IoIosArrowForward size={20} />
										</>
									)}
								</button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</Dialog>
	);
};

export default AddSubscriptionPlan;
