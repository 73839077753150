import useFetchSubscription from "api/SubscriptionsResource/useFetchSubscription";
import { ButtonLoader, DesktopHeader } from "components";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import React from "react";
import { useParams } from "react-router-dom";
import useAuthStore from "store/authStore";
import useCurrencyStore from "store/currencyStore";
import EcocashPaymentZwl from "./EcocashPaymentZwl";
import { Link, useLocation } from "react-router-dom";
import EcocashPaymentUsd from "./EcocashPaymentUsd";
import PayPal from "./PayPal";
import StarsPay from "./StarsPay";
import useCheckIfUserIsSubscribed from "api/SubscriptionsResource/useCheckIfUserIsSubscribed";
import { activeCurrencies, activePaymentMethods, getMoneyPrefix } from "utils";

const SubscriptionCheckout = () => {
	const isAuthenticated = useAuthStore((state) => state.loggedIn);
	const { currency, paymentMethod, setPaymentMethod } = useCurrencyStore();
	const { subscriptionId } = useParams();

	const { data: subscription, isLoading } =
		useFetchSubscription(subscriptionId);

	const { data: isSubscribed, isLoading: isLoadingSubscribed } =
		useCheckIfUserIsSubscribed();

	// console.log({ isSubscribed });

	const hasActiveSubscription = Boolean(isSubscribed?.data);

	const location = useLocation();

	const id = subscription?.data?.id;
	const usdPrice = subscription?.data?.cost;
	const zwlPrice = subscription?.data?.zwl_price;
	const credits = subscription?.data?.credits;

	/**
	 * This is the cost of the subscription in the currency that the user has selected.
	 */
	const cost = React.useMemo(() => {
		return currency === activeCurrencies.USD.code ? usdPrice : zwlPrice;
	}, [currency, usdPrice, zwlPrice]);

	/**
	 * Subscription label based on the name of the subscription.
	 */
	const duration = React.useMemo(() => {
		if (subscription?.data?.name.toLowerCase() === "weekly") {
			return "7 days";
		}
		if (subscription?.data?.name.toLowerCase() === "monthly") {
			return "30 days";
		}
		return undefined;
	}, [subscription]);

	const paymentOptionsButtons = [
		{
			paymentMethod: activePaymentMethods.ecocashUsd.name,
			label: activePaymentMethods.ecocashUsd.label,
		},
		{
			paymentMethod: activePaymentMethods.ecocashZwl.name,
			label: activePaymentMethods.ecocashZwl.label,
		},
		{
			paymentMethod: activePaymentMethods.paypal.name,
			label: activePaymentMethods.paypal.label,
		},
		{
			paymentMethod: activePaymentMethods.stars.name,
			label: activePaymentMethods.stars.label,
		},
	];

	if (isLoading || isLoadingSubscribed) {
		return (
			<div className="min-h-screen flex justify-center items-center">
				<ButtonLoader size={60} />
			</div>
		);
	}

	if (!isAuthenticated) {
		return (
			<Container>
				<DesktopHeader shadow />

				<PaddingX>
					<div className="mt-10 mx-auto flex flex-col items-center text-center">
						<h1 className="text-2xl font-semibold text-center">
							Subscription checkout
						</h1>

						<div className="mt-6 flex flex-col items-center gap-2">
							<p>You need to log in to continue with your subscription</p>

							<Link
								to={`/login?intended=${location.pathname}`}
								className="font-bold text-lg text-blue-900 py-1 px-2 rounded-xl"
							>
								Login
							</Link>
						</div>
					</div>
				</PaddingX>
			</Container>
		);
	}

	return (
		<Container>
			<DesktopHeader shadow />

			<PaddingX>
				<div className="mt-10 mx-auto w-auto sm:w-500">
					<div className="flex flex-col justify-center items-center ">
						<h1 className="text-2xl font-semibold">Subscription checkout</h1>
						<h4 className="mt-2 text-center">
							Please review your order before proceeding with payment.
						</h4>
					</div>

					<div className="mx-auto w-full mt-4">
						<div className="flex items-center justify-between ">
							<span>Package name</span>
							<span>{subscription?.data?.name}</span>
						</div>
						<div className="flex items-center justify-between ">
							<span>Duration</span>
							<span>{duration}</span>
						</div>
						<div className="flex items-center justify-between ">
							<span>Amount to pay</span>
							<p className="flex items-center gap-2">
								<span>
									{getMoneyPrefix(currency)} {cost}
								</span>
								{/* {currency === activeCurrencies.USD.code ? (
									<span>
										{getActiveCurrencyCodeLabel(currency)}
										{getActiveCurrencySymbol(currency)} {cost}
									</span>
								) : (
									<span>
										{getActiveCurrencySymbol(currency)} {cost}
									</span>
								)} */}
							</p>
						</div>
					</div>

					<div className="mt-8 pt-8 border-t mx-auto px-0 xs:px-8">
						{(function () {
							if (hasActiveSubscription) {
								return (
									<div className="flex flex-col gap-3 items-center">
										{/* <h4>You already have an active subscription</h4> */}
										<p className="text-center text-base">
											You already have an active subscription. Note that you can
											only have one active subscription at a time.
										</p>

										<p className="text-center">
											You can add books to your picks and read them whilst you
											have an active subscription. Browse books and add them to
											your picks by clicking the "Add to picks" button on a book
											that you like.
										</p>

										<Link
											to="/books"
											className="rounded-md py-3 px-3 border border-akgreener text-akgreenest font-bold transition duration-150 hover:bg-akgreener hover:text-white"
										>
											Browse books
										</Link>
									</div>
								);
							}

							return (
								<>
									<h4>Choose payment method that you want</h4>

									<div className="grid grid-cols-2 gap-2 justify-between items-center mt-2 ">
										{paymentOptionsButtons.map((button, index) => (
											<button
												type="button"
												className={`py-2 px-3 font-bold rounded border border-green-300 ${
													paymentMethod === button.paymentMethod
														? "bg-akgreen text-white border-2 border-transparent"
														: ""
												}`}
												onClick={() => setPaymentMethod(button.paymentMethod)}
											>
												{button.label}
											</button>
										))}
									</div>

									<div className="mt-2">
										{paymentMethod === activePaymentMethods.ecocashZwl.name &&
											currency === activeCurrencies.ZWL.code && (
												<EcocashPaymentZwl grandTotal={cost} id={id} />
											)}

										{paymentMethod === activePaymentMethods.ecocashUsd.name &&
											currency === activeCurrencies.USD.code && (
												<EcocashPaymentUsd grandTotal={cost} id={id} />
											)}

										{paymentMethod === activePaymentMethods.paypal.name &&
											currency === activeCurrencies.USD.code && (
												<PayPal id={id} grandTotal={cost} />
											)}

										{paymentMethod === activePaymentMethods.stars.name &&
											currency === activeCurrencies.ZWL.code && (
												<StarsPay id={id} grandTotal={cost} credits={credits} />
											)}
									</div>
								</>
							);
						})()}
					</div>
				</div>
			</PaddingX>
		</Container>
	);
};

export default SubscriptionCheckout;
