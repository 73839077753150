import dark_logo from "assets/images/dark_logo.png";
import light_logo from "assets/images/light_logo.png";
import { Link } from "react-router-dom";

const Logo = (props) => {
  const width = props.width || 130;
  return (
    <Link to="/">
      {props.dark ? (
        <img
          src={`${dark_logo}?${new Date().getTime()}`}
          width={width}
          alt="AKELLO"
        />
      ) : (
        <img
          src={`${light_logo}?${new Date().getTime()}`}
          width={width}
          alt="AKELLO"
        />
      )}
    </Link>
  );
};

export default Logo;
