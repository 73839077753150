import React from "react";
import { FiX } from "react-icons/fi";
import useAppStore from "store/appStore";

const FlashMessage = () => {
	const { flashMessage, clearFlashMessage } = useAppStore();
	return (
		<p className="flex px-4 py-2 font-bold justify-between items-center mt-2 bg-green-400 rounded text-white">
			{flashMessage}
			<button onClick={() => clearFlashMessage()}>
				<FiX size={20} />
			</button>
		</p>
	);
};

export default FlashMessage;
