import InputError from "components/InputError";
import { useField } from "formik";
import { Tooltip } from "primereact/tooltip";
import PropTypes from "prop-types";
import { BsInfoCircle } from "react-icons/bs";

const TextField = ({
  type,
  name,
  placeholder,
  className,
  inputClasses,
  ...rest
}) => {
  const [field, meta] = useField(name);

  if (type === "textarea") {
    return (
      <div className={`w-full mb-1 ${className ?? ""}`}>
        {rest.label && (
          <label htmlFor={name} className="text-sm">
            {rest.label}
          </label>
        )}
        <textarea
          className={`px-4 py-2 rounded-3xl border border-akgreener text-akgreenest placeholder-gray-400 outline-none w-full`}
          placeholder={placeholder}
          id={name}
          type={type}
          rows={rest.rows || 4}
          {...field}
          {...rest}
        ></textarea>
        {meta.error && meta.touched && <InputError error={meta.error} />}
      </div>
    );
  }

  return (
    <div className={`w-full mb-1 ${className ?? ""}`}>
      {rest.label && (
        <label htmlFor={name} className="text-sm">
          {rest.label}
        </label>
      )}
      <div
        className={`flex items-center justify-between w-full ${
          inputClasses || "px-4 rounded-3xl border border-akgreener bg-gray-50 "
        }`}
      >
        <input
          placeholder={placeholder}
          className="w-full outline-none placeholder-gray-400 text-akgreenest py-2 bg-gray-50"
          id={name}
          type={type}
          {...field}
          {...rest}
        />
        {rest.info && (
          <>
            <Tooltip
              target={`.${name}-tooltip`}
              className="light-tooltip "
              position="top"
            >
              {rest.info}
            </Tooltip>
            <button type="button" className={`outline-none ${name}-tooltip`}>
              <BsInfoCircle size={25} className="inline" />
            </button>
          </>
        )}
      </div>

      {meta.error && meta.touched && <InputError error={meta.error} />}
    </div>
  );
};

TextField.propTypes = {
  type: PropTypes.oneOf([
    "text",
    "number",
    "password",
    "textarea",
    "file",
    "date",
  ]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default TextField;
