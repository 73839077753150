import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import useTokenStore from "store/tokenStore";

const useMoveUserToTrash = (id, destruction_level = null) => {
	const { accessToken } = useTokenStore();
	const queryClient = useQueryClient();

	const history = useHistory();

	const toTrash = async () => {
		const instance = await InitApi({ accessToken });
		const response = await instance.delete(
			`/users/${id}?destruction_level=${destruction_level}`
		);
		return response;
	};

	return useMutation(toTrash, {
		onSuccess: async ({ data: body }) => {
			queryClient.invalidateQueries(["users"]);
			toast.success("User deactivated successfully");
		},
		onError: async (error) => {
			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);
		},
	});
};

export default useMoveUserToTrash;
