import ClearFilters from "admin/BrandAmbassadorDashboard/ClearFilters";
import Statistics from "admin/BrandAmbassadorDashboard/Statistics";
import useGetBAStatistics from "api/BrandAmbassadorResource/useGetBAStatistics";
import { ButtonLoader } from "components";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { FiX } from "react-icons/fi";
import { HiOutlineExternalLink } from "react-icons/hi";

const ViewStats = ({ userId, firstName, lastName, email }) => {
	const [visible, setVisible] = useState(false);

	const WIDTH = 900;

	const [fromDate, setFromDate] = React.useState("");
	const [toDate, setToDate] = React.useState("");
	const changeFromDate = (date = "") => setFromDate(date);
	const changeToDate = (date = "") => setToDate(date);

	const countDefinedFilters = [fromDate, toDate].filter((item) => item)?.length;

	const { data: stats, isLoading: isLoadingStats } = useGetBAStatistics({
		from_date: fromDate,
		to_date: toDate,
		userId,
	});

	console.log({ stats });

	return (
		<>
			<button type="button" onClick={() => setVisible(true)}>
				<HiOutlineExternalLink size={20} className="text-blue-700" />
			</button>

			<Dialog
				visible={visible}
				onHide={() => setVisible(false)}
				showHeader={false}
				className="font-sans text-sm "
				contentClassName="rounded-md relative "
				style={{ width: WIDTH }}
				position="top"
			>
				<button
					type="button"
					onClick={() => setVisible(false)}
					className="absolute right-4 top-2"
				>
					<FiX size={30} />
				</button>
				{(function () {
					if (isLoadingStats) {
						return (
							<div
								className="flex justify-center mt-12 pb-4 px-6"
								style={{ maxWidth: WIDTH }}
							>
								<ButtonLoader size={60} />
							</div>
						);
					}

					return (
						<div className="mt-12 pb-4 px-6">
							<div
								className="mt-4 flex justify-between items-center gap-2 w-full"
								style={{ maxWidth: WIDTH }}
							>
								<div className="flex flex-col">
									<h2 className="hidden sm:block">BA Statistics for</h2>
									<h4 className="flex gap-1 items-center">
										<span>
											{firstName} {lastName}
										</span>
										<span>-</span>
										<span className="font-bold text-base">{email}</span>
									</h4>
								</div>

								<div className="flex justify-end items-end gap-2">
									<Calendar
										value={new Date(fromDate)}
										name="from_date"
										dateFormat="yy-mm-dd"
										placeholder="Date (from)"
										className="w-52 h-10 font-sans"
										inputClassName="border border-gray-300 bg-white rounded"
										showIcon
										readOnlyInput
										showButtonBar
										onChange={(e) =>
											changeFromDate(
												moment(e.target.value).format("YYYY-MM-DD")
											)
										}
										onClearButtonClick={() => changeFromDate()}
									/>
									<Calendar
										value={new Date(toDate)}
										name="to_date"
										dateFormat="yy-mm-dd"
										placeholder="Date (to)"
										className="w-52 h-10 font-sans"
										inputClassName="border border-gray-300 bg-white rounded"
										showIcon
										showButtonBar
										onChange={(e) =>
											changeToDate(moment(e.target.value).format("YYYY-MM-DD"))
										}
										onClearButtonClick={() => changeToDate()}
									/>
									<ClearFilters
										count={countDefinedFilters}
										changeFromDate={() => changeFromDate()}
										changeToDate={() => changeToDate()}
									/>
								</div>
							</div>

							<div
								className="mt-3 flex justify-center w-full"
								style={{ maxWidth: WIDTH }}
							>
								<Statistics
									registeredUsers={stats?.data?.totalRegisteredClients}
									usdCommission={stats?.data?.usdCommission}
									zigCommission={stats?.data?.zwlCommission}
									usdPayout={stats?.data?.usdPayout}
									zigPayout={stats?.data?.zwlPayout}
									netUsdCommission={stats?.data?.netUsdCommission}
									netZigCommission={stats?.data?.netZwlCommission}
									totalPaymentsMade={stats?.data?.totalPaymentsMade}
									showPayments={false}
								/>
							</div>
						</div>
					);
				})()}
			</Dialog>
		</>
	);
};

export default ViewStats;
