import useSearchParams from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { deleteEmptyObjects } from "utils";

const usePurchasedBooksFilters = () => {
	const [firstPage, setFirstPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [page, setPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [categoryIs, setCategoryIs] = useState("");
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");
	const [revenueLine, setRevenueLine] = useState("purchase");

	const { getParam, setParam, deleteParam, hasParam } = useSearchParams();

	const { push } = useHistory();
	const { pathname } = useLocation();

	useEffect(() => {
		if (hasParam("page")) {
			const pageParam = getParam("page")?.length > 0 ? getParam("page") : 1;
			setPage(pageParam);
		}
		if (hasParam("rows")) {
			const rowsPerPageParam =
				getParam("rows")?.length > 0 ? getParam("rows") : 50;
			setRowsPerPage(rowsPerPageParam);
		}

		if (hasParam("first_page")) {
			const firstPageParam =
				getParam("first_page")?.length > 0 ? getParam("first_page") : 0;
			setFirstPage(firstPageParam);
		}

		if (hasParam("search_term")) {
			const searchTermParam =
				getParam("search_term")?.length > 0 ? getParam("search_term") : "";
			setSearchTerm(searchTermParam);
		}

		if (hasParam("category_is")) {
			const categoryIsParam =
				getParam("category_is")?.length > 0 ? getParam("category_is") : "";
			setCategoryIs(categoryIsParam);
		}
		if (hasParam("from_date")) {
			const fromDateParam =
				getParam("from_date")?.length > 0 ? getParam("from_date") : "";
			setFromDate(fromDateParam);
		}
		if (hasParam("to_date")) {
			const toDateParam =
				getParam("to_date")?.length > 0 ? getParam("to_date") : "";
			setToDate(toDateParam);
		}

		if (hasParam("revenue_line")) {
			const revenueLineParam =
				getParam("revenue_line")?.length > 0
					? getParam("revenue_line")
					: "purchase";
			setRevenueLine(revenueLineParam);
		}

		return () => {
			deleteParam("page");
			deleteParam("rows");
			deleteParam("first_page");
			deleteParam("search_term");
			deleteParam("category_is");
			deleteParam("from_date");
			deleteParam("to_date");
			deleteParam("revenue_line");
		};
	}, [hasParam, getParam, deleteParam]);

	const changeAllFiltersAtOnce = (data) => {
		const values = deleteEmptyObjects(data);

		setFirstPage(values?.first_page || "");
		setRowsPerPage(values?.rows || "");
		setPage(values?.page || "");
		setCategoryIs(values?.category_is || "");
		setSearchTerm(values?.search_term || "");
		setFromDate(values?.form_date || "");
		setToDate(values?.to_date || "");
		setRevenueLine(values?.revenue_line || "");

		push(`${pathname}?${new URLSearchParams(values).toString()}`);
	};

	const changePage = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("page");
			setPage(1);
			return push(`${pathname}?${q}`);
		}
		const q = setParam("page", value);
		push(`${pathname}?${q}`);
	};

	const changeRowsPerPage = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("rows");
			setRowsPerPage(50);
			return push(`${pathname}?${q}`);
		}
		const q = setParam("rows", value);
		push(`${pathname}?${q}`);
	};

	const changeFirstPage = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("first_page");
			setFirstPage(0);
			return push(`${pathname}?${q}`);
		}
		const q = setParam("first_page", value);
		push(`${pathname}?${q}`);
	};

	const changeCategoryIs = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("category_is");
			setCategoryIs("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("category_is", value);
		push(`${pathname}?${q}`);
	};

	const changeSearchTerm = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("search_term");
			setSearchTerm("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("search_term", value);
		push(`${pathname}?${q}`);
	};

	const changeFromDate = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("from_date");
			setFromDate("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("from_date", value);
		push(`${pathname}?${q}`);
	};

	const changeToDate = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("to_date");
			setToDate("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("to_date", value);
		push(`${pathname}?${q}`);
	};

	const changeRevenueLine = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("revenue_line");
			setRevenueLine("purchase");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("revenue_line", value);
		push(`${pathname}?${q}`);
	};

	return {
		firstPage,
		rowsPerPage,
		page,
		categoryIs,
		searchTerm,
		fromDate,
		toDate,
		revenueLine,
		changePage,
		changeRowsPerPage,
		changeFirstPage,
		changeCategoryIs,
		changeSearchTerm,
		changeFromDate,
		changeToDate,
		changeRevenueLine,
		changeAllFiltersAtOnce,
	};
};

export default usePurchasedBooksFilters;
