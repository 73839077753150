import PaddingX from "layouts/PaddingX";
// import Books from "../../../assets/images/book.png";
import Slide from "../../../assets/images/home_banner_2.png";

const SlideItem2 = () => {
	return (
		<div
			className="flex items-center w-full h-full  bg-slide-banner23 bg-no-repeat bg-cover"
			style={{
				backgroundColor: "#fd8627",
			}}
		>
			<PaddingX>
				{/* <div className="flex flex-col gap-4 sm:gap-0 sm:flex-row items-center px-8 ">
          <div className="slide2-img">
            <img alt="book" src={Books} className=" " />
          </div>

          #fd8627

          <div className="flex-5 text-center sm:text-left">
            <h1 className="text-white font-cera-black slider-title mb-4 sm:mb-12">
              Alice in Wonderland
            </h1>

            <p className=" text-white text-3xl mb-3 ">
              "Today a reader, tomorrow a leader"
            </p>

            <p className=" text-white text-3xl mb-3">- Margaret Fuller</p>
          </div>
        </div> */}

				<div>
					<img src={Slide} alt="book" />
				</div>
			</PaddingX>
		</div>
	);
};

export default SlideItem2;
