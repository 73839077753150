import { DesktopHeader } from "components";
import { Container, Xpadding } from "layouts";

import AnimatedPage from "layouts/AnimatedPage";

const NotFound = () => {
  return (
    <Container>
      <div className="min-h-screen flex flex-col justify-between ">
        {/* <Header /> */}
        <DesktopHeader />
        <Xpadding className="min-h-screen flex flex-col justify-center items-center">
          <AnimatedPage>
            <h2>Page not found!</h2>
          </AnimatedPage>
        </Xpadding>
      </div>
    </Container>
  );
};

export default NotFound;
