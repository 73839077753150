import React from "react";
import { RiSearch2Line } from "react-icons/ri";
import { useHistory, useLocation } from "react-router-dom";
import useBooksFilterFrontStore from "store/booksFilterFrontStore";
import { sleep } from "utils";

const Search = () => {
	const history = useHistory();
	const location = useLocation();

	const { bookFilters, setBookFilters, setFilterCleared } =
		useBooksFilterFrontStore();

	const [searchValue, setSearchValue] = React.useState(
		() => bookFilters.searchTerm
	);

	const handleSearch = async (e) => {
		setSearchValue(e.target.value);
		await sleep(1000);
		setBookFilters({ ...bookFilters, searchTerm: e.target.value });
		setFilterCleared(false);
	};

	const handleClick = () => {
		const searchParams = new URLSearchParams(location.search);
		searchParams.append("open_filters", "true");
		history.push(`/books?${searchParams.toString()}`);
	};

	return (
		<>
			<div
				className={`flex gap-2 items-center px-4 bg-white border border-akgreenest rounded-full`}
			>
				<input
					type="text"
					name="search"
					className="py-1 outline-none"
					value={searchValue}
					placeholder="Search book..."
					onClick={handleClick}
					onChange={handleSearch}
				/>
				<RiSearch2Line size={20} />
			</div>
		</>
	);
};

export default Search;
