import create from "zustand";

const cartStore = (set) => ({
	addToCartIsSuccessful: false,

	setAddToCartIsSuccessful: (addToCartIsSuccessful) => {
		set({ addToCartIsSuccessful });
	},

	cart: [],
});

const useCartStore = create(cartStore);

export default useCartStore;
