import React, { useState } from "react";
import EditPermission from "./EditPermission";

const Permissions = ({ permissions }) => {
	const [searchPermission, setsearchPermission] = useState("");

	return (
		<div className="space-y-4">
			<h3>User Permissions</h3>

			<input
				type="text"
				placeholder="Search permission..."
				value={searchPermission}
				onChange={(e) => setsearchPermission(e.target.value)}
				className="border border-gray-400 w-full p-2 text-center"
			/>

			<div className="flex flex-col gap-2">
				{permissions
					?.filter((permission) => {
						if (!searchPermission) return true;
						return permission.label
							.toLocaleLowerCase()
							.includes(searchPermission.toLocaleLowerCase());
					})
					?.map((permission) => (
						<>
							<EditPermission
								key={permission.id}
								permissionName={permission?.name}
								permissionLabel={permission?.label}
								permissionId={permission?.id}
							/>
						</>
					))}
			</div>
		</div>
	);
};

export default Permissions;
