import ContentLoader from "react-content-loader";

const index = (props) => {
  return (
    <div className="flex justify-start items-center ">
      <ContentLoader
        speed={1}
        width={props.width || 400}
        height={props.height || 150}
        viewBox="0 0 400 160"
        // backgroundColor= "#ffffff"
        backgroundColor={props.dark ? "#6DA719" : "#DEDBC5"}
        // foregroundColor="#deb187"
        foregroundColor="#66A140"
        {...props}
      >
        <rect x="0" y="0" rx="3" ry="3" width="200" height="6" />
        <rect x="210" y="0" rx="3" ry="3" width="200" height="6" />
        <rect x="0" y="10" rx="3" ry="3" width="200" height="6" />
        <rect x="210" y="10" rx="3" ry="3" width="200" height="6" />
        <rect x="0" y="20" rx="3" ry="3" width="200" height="6" />
        <rect x="210" y="20" rx="3" ry="3" width="200" height="6" />
        <rect x="0" y="30" rx="3" ry="3" width="200" height="6" />
        <rect x="210" y="30" rx="3" ry="3" width="200" height="6" />
        <rect x="0" y="40" rx="3" ry="3" width="200" height="6" />
        <rect x="210" y="40" rx="3" ry="3" width="200" height="6" />
        <rect x="0" y="50" rx="3" ry="3" width="200" height="6" />
        <rect x="210" y="50" rx="3" ry="3" width="200" height="6" />
        <rect x="0" y="60" rx="3" ry="3" width="200" height="6" />
        <rect x="210" y="60" rx="3" ry="3" width="200" height="6" />
        <rect x="0" y="70" rx="3" ry="3" width="200" height="6" />
        <rect x="210" y="70" rx="3" ry="3" width="200" height="6" />
        <rect x="0" y="80" rx="3" ry="3" width="200" height="6" />
        <rect x="210" y="80" rx="3" ry="3" width="200" height="6" />
        <rect x="0" y="90" rx="3" ry="3" width="200" height="6" />
        <rect x="210" y="90" rx="3" ry="3" width="200" height="6" />
        <rect x="0" y="100" rx="3" ry="3" width="200" height="6" />
        <rect x="210" y="100" rx="3" ry="3" width="200" height="6" />
        <rect x="0" y="110" rx="3" ry="3" width="200" height="6" />
        <rect x="210" y="110" rx="3" ry="3" width="200" height="6" />
        <rect x="0" y="120" rx="3" ry="3" width="200" height="6" />
        <rect x="210" y="120" rx="3" ry="3" width="200" height="6" />
        <rect x="0" y="130" rx="3" ry="3" width="200" height="6" />
        <rect x="210" y="130" rx="3" ry="3" width="200" height="6" />
        <rect x="0" y="140" rx="3" ry="3" width="200" height="6" />
        <rect x="210" y="140" rx="3" ry="3" width="200" height="6" />
        <rect x="0" y="150" rx="3" ry="3" width="200" height="6" />
        <rect x="210" y="150" rx="3" ry="3" width="200" height="6" />
      </ContentLoader>
    </div>
  );
};

export default index;
