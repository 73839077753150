import { BiCartAlt } from "react-icons/bi";

const index = ({ children, cartIconSize = 200 }) => {
	return (
		<div className="flex flex-col justify-center items-center w-full">
			<BiCartAlt size={cartIconSize} className=" text-gray-300 " />
			{children}
		</div>
	);
};

export default index;
