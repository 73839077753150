import { Paginator } from "primereact/paginator";

const CustomPaginator = ({
	setPage,
	firstPage,
	rowsPerPage,
	setFirstPage,
	setRowsPerPage,
	totalRecords,
	showRowsPerPageDropdown = true,
}) => {
	const templateLayout = `
		FirstPageLink
	 	PrevPageLink 
		PageLinks 
	 	NextPageLink 
	 	LastPageLink
	 	${showRowsPerPageDropdown ? "RowsPerPageDropdown" : ""}
	`;

	const onBasicPageChange = (event) => {
		setFirstPage(event.first);
		setRowsPerPage(event.rows);
		setPage(event.page + 1);
	};

	return (
		<div>
			<Paginator
				first={firstPage}
				rows={rowsPerPage}
				totalRecords={totalRecords}
				rowsPerPageOptions={[2, 4, 8, 10, 20, 50]}
				onPageChange={onBasicPageChange}
				pageLinkSize={5}
				className="font-sans !bg-white"
				template={{
					layout: templateLayout,
				}}
				alwaysShow={false}
			/>
		</div>
	);

	//!
};

export default CustomPaginator;
