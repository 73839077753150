import { DesktopHeader } from "components";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import React from "react";
import {
	accessToDigitalLibrary,
	benefitsOfDigitalLibrary,
	changesToTerms,
	commencement,
	communications,
	definitions,
	limitOfIndemnity,
	misrepresentation,
	noticePeriod,
	payments,
	registrations,
	serviceCategories,
	serviceDenial,
	terminationOfTerms,
	wholeAgreement,
} from "./Sections";

const TermsAndConditions = () => {
	return (
		<Container className="min-h-screen flex flex-col w-full ">
			<DesktopHeader shadow />

			<PaddingX>
				<div
					className="mt-8 mx-auto space-y-2"
					style={{
						maxWidth: 700,
					}}
				>
					<p className="text-lg font-bold">
						Please read and fully understand each clause set out in these terms
						and conditions
					</p>

					<p>
						These terms and conditions can be changed at Akello’s sole
						discretion but subject to sixty (60) days’ notice to Customers.
						Akello will notify Customers by SMS or Akello Social Media platforms
						of any amendments to these terms and conditions and ensure that they
						are available on the official website. Any Customer who does not
						accept the changes to these terms and conditions shall notify Akello
						in writing within thirty (30) days of posting of the amendments and
						such notification shall be deemed to constitute termination of the
						agreement between customer and Akello with effect from the date on
						which the new amendments take effect. When a Customer registers for
						the Akello Digital Library Platform Services, the Customer must know
						and fully understand the terms and conditions set out in this
						document.{" "}
					</p>

					<p>
						Confirmation of registration means that the Customer agrees to abide
						and be bound by these Terms and Conditions{" "}
					</p>

					<h1 className="text-lg font-bold">Definition</h1>
					<ul className="list-outside list-decimal ml-4">
						{definitions.map((item, index) => (
							<li key={index} className=" ist-item ">
								{item}
							</li>
						))}
					</ul>

					<h1 className="text-lg font-bold">Registration</h1>
					<ul className="list-outside list-decimal ml-4">
						{registrations.map((item, index) => (
							<li key={index} className=" ist-item ">
								{item}
							</li>
						))}
					</ul>

					<h1 className="text-lg font-bold">Commencement date and duration</h1>
					<ul className="list-outside list-decimal ml-4">
						{commencement.map((item, index) => (
							<li key={index} className=" ist-item ">
								{item}
							</li>
						))}
					</ul>

					<h1 className="text-lg font-bold">Payments</h1>
					<ul className="list-outside list-decimal ml-4">
						{payments.map((item, index) => (
							<li key={index} className=" ist-item ">
								{item}
							</li>
						))}
					</ul>

					<h1 className="text-lg font-bold">
						Benefits of the digital library platform
					</h1>
					<ul className="list-outside list-decimal ml-4">
						{benefitsOfDigitalLibrary.map((item, index) => (
							<li key={index} className=" ist-item ">
								{item}
							</li>
						))}
					</ul>

					<h1 className="text-lg font-bold">
						Termination of terms and conditions
					</h1>
					<ul className="list-outside list-decimal ml-4">
						{terminationOfTerms.map((item, index) => (
							<li key={index} className=" ist-item ">
								{item}
							</li>
						))}
					</ul>

					<h1 className="text-lg font-bold">
						Notice period for payment increase
					</h1>
					<ul className="list-outside list-decimal ml-4">
						{noticePeriod.map((item, index) => (
							<li key={index} className=" ist-item ">
								{item}
							</li>
						))}
					</ul>

					<h1 className="text-lg font-bold">Misrepresentation and fraud</h1>
					<ul className="list-outside list-decimal ml-4">
						{misrepresentation.map((item, index) => (
							<li key={index} className=" ist-item ">
								{item}
							</li>
						))}
					</ul>

					<h1 className="text-lg font-bold">
						Changes in the terms and conditions
					</h1>
					<ul className="list-outside list-decimal ml-4">
						{changesToTerms.map((item, index) => (
							<li key={index} className=" ist-item ">
								{item}
							</li>
						))}
					</ul>

					<h1 className="text-lg font-bold">
						Access to digital library platform
					</h1>
					<ul className="list-outside list-decimal ml-4">
						{accessToDigitalLibrary.map((item, index) => (
							<li key={index} className=" ist-item ">
								{item}
							</li>
						))}
					</ul>

					<h1 className="text-lg font-bold">
						Service categories and specifications
					</h1>
					<ul className="list-outside list-decimal ml-4">
						{serviceCategories.map((item, index) => (
							<li key={index} className=" ist-item ">
								{item}
							</li>
						))}
					</ul>

					<h1 className="text-lg font-bold">
						Service denial, conflicts and dispute
					</h1>
					<ul className="list-outside list-decimal ml-4">
						{serviceDenial.map((item, index) => (
							<li key={index} className=" ist-item ">
								{item}
							</li>
						))}
					</ul>

					<h1 className="text-lg font-bold">Communications</h1>
					<ul className="list-outside list-decimal ml-4">
						{communications.map((item, index) => (
							<li key={index} className=" ist-item ">
								{item}
							</li>
						))}
					</ul>

					<h1 className="text-lg font-bold">Limit of indemnity</h1>
					<ul className="list-outside list-decimal ml-4">
						{limitOfIndemnity.map((item, index) => (
							<li key={index} className=" ist-item ">
								{item}
							</li>
						))}
					</ul>

					<h1 className="text-lg font-bold">Whole agreement</h1>
					<ul className="list-outside list-decimal ml-4">
						{wholeAgreement.map((item, index) => (
							<li key={index} className=" ist-item ">
								{item}
							</li>
						))}
					</ul>
				</div>
			</PaddingX>
		</Container>
	);
};

export default TermsAndConditions;
