import React from "react";
import { Link, useLocation } from "react-router-dom";

const MyLibraryLayout = ({ children }) => {
	const { pathname } = useLocation();

	return (
		<div>
			<span className="hidden xs:flex items-center justify-end">
				<Link
					to="/my-library"
					className={`py-2 px-3 border border-akgreener rounded-l-full ${
						pathname === "/my-library" ? "bg-akgreener text-white" : ""
					} `}
				>
					Purchased books
				</Link>
				<Link
					to="/my-library/picks"
					className={`py-2 px-3 border border-akgreener rounded-r-full ${
						pathname === "/my-library/picks" ? "bg-akgreener text-white" : ""
					}`}
				>
					Books in picks
				</Link>
			</span>
			<div>{children}</div>
		</div>
	);
};

export default MyLibraryLayout;
