import { FaChevronRight } from "react-icons/fa";

const OnNext = ({ _onNext }) => {
  return (
    <button
      type="button"
      onClick={_onNext}
      className="absolute top-[50%] right-2   md:right-6  z-50  p-1 md:p-2 bg-white rounded-full border-akgreen hover:border-2"
      style={{ top: "40%" }}
    >
      <FaChevronRight style={{ fontSize: 24 }} />
    </button>
  );
};

export default OnNext;
