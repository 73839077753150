import useAuthStore from "store/authStore";
import useOverlayStore from "store/overlayStore";
import { Permission } from "utils/permissions";
import { Role } from "utils/roles";

const useCreateResourceModal = () => {
	const { hasRoles, hasPermissions } = useAuthStore();
	const {
		setShowCreateBook,
		setShowCreateUser,
		setShowCreateCategory,
		setShowCreateCollection,
		setShowCreateSubscription,
		setShowUploadBooks,
		setShowCreateSubjectGroups,
		setShowUploadUsers,
	} = useOverlayStore();

	const createBook = {
		label: "Book",
		command: () => setShowCreateBook(true),
		available:
			hasRoles([
				Role.SUPER_ADMIN,
				Role.ADMIN,
				Role.SENIOR_PUBLISHER,
				Role.PUBLISHER,
			]) && hasPermissions([Permission.CREATE_BOOK]),
	};
	const uploadBooks = {
		label: "Upload books",
		command: () => setShowUploadBooks(true),
		available:
			hasRoles([
				Role.SUPER_ADMIN,
				Role.ADMIN,
				Role.SENIOR_PUBLISHER,
				Role.PUBLISHER,
			]) && hasPermissions([Permission.CREATE_BOOK]),
	};
	const createUser = {
		label: "User",
		command: () => setShowCreateUser(true),
		available:
			hasRoles([
				Role.SUPER_ADMIN,
				Role.ADMIN,
				Role.SENIOR_PUBLISHER,
				Role.PUBLISHER,
				Role.INSTITUTION,
				Role.EDUCATOR,
				Role.BA,
			]) && hasPermissions([Permission.CREATE_USER]),
	};
	const uploadUsers = {
		label: "Upload students",
		command: () => setShowUploadUsers(true),
		available: hasRoles([Role.INSTITUTION, Role.EDUCATOR]),
	};
	const createSubjectGroup = {
		label: "Subject Group",
		command: () => setShowCreateSubjectGroups(true),
		available:
			hasRoles([
				Role.SUPER_ADMIN,
				Role.ADMIN,
				Role.INSTITUTION,
				Role.EDUCATOR,
			]) && hasPermissions([Permission.CREATE_SUBJECT_GROUP]),
	};
	const createBookCategory = {
		label: "Book category",
		command: () => setShowCreateCategory(true),
		available:
			hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) &&
			hasPermissions([Permission.CREATE_CATEGORY]),
	};
	const createBookCollection = {
		label: "Book collection",
		command: () => setShowCreateCollection(true),
		available:
			hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) &&
			hasPermissions([Permission.CREATE_GENRE]),
	};
	const createSubscriptionPlan = {
		label: "Subscription plan",
		command: () => setShowCreateSubscription(true),
		available:
			hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) &&
			hasPermissions([Permission.CREATE_SUBSCRIPTION]),
	};

	const items = [
		createBook,
		uploadBooks,
		createUser,
		uploadUsers,
		createSubjectGroup,
		createBookCategory,
		createBookCollection,
		createSubscriptionPlan,
	]?.filter((items) => items.available);

	return items;
};

export default useCreateResourceModal;
