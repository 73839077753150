import React, { useState } from "react";
import { BsCheckCircle, BsPencil } from "react-icons/bs";
import { Dialog } from "primereact/dialog";
import { Formik, Form } from "formik";
// import * as Yup from "yup";
import useFetchPermissions from "api/RoleResource/useFetchPermissions";
import { Checkbox } from "primereact/checkbox";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import useUpdateRole from "api/RoleResource/useUpdateRole";
import { ButtonLoader } from "components";

const EditRole = ({ roleId, roleName, permissionNames }) => {
	const [showModal, setshowModal] = useState(false);

	const [selectOptions, setselectOptions] = useState("default");

	const { data: allPermissions, isLoading: isLoadingPermissions } =
		useFetchPermissions();

	const initialValues = {
		permissions: permissionNames,
	};

	const { mutateAsync, isLoading: isUpdatingRole } = useUpdateRole(roleId);

	const handleEditRole = async (data) => {
		await mutateAsync(data);
		setshowModal(false);
	};

	return (
		<>
			<button type="button" onClick={() => setshowModal(true)}>
				<BsPencil size={20} />
			</button>

			<Dialog
				visible={showModal}
				style={{ width: "50vw" }}
				header={
					<div className="text-xl font-sans font-bold sticky top-4 z-20 bg-white mr-2">
						<p className="">Assign permissions associated with the role</p>
					</div>
				}
				onHide={() => setshowModal(false)}
				position="top"
				draggable={false}
				contentClassName="rounded-b-xl "
				className="rounded-xl relative"
			>
				{isLoadingPermissions ? (
					<div className="flex justify-center items-center ">
						<ButtonLoader size={40} />
					</div>
				) : (
					<Formik
						initialValues={initialValues}
						// validationSchema={validate}
						onSubmit={handleEditRole}
					>
						{({ values, handleChange, handleBlur, setFieldValue }) => {
							const handleSelectAll = () => {
								setselectOptions("all");
								let all = allPermissions?.data?.map(
									(permission) => permission?.name
								);
								setFieldValue("permissions", [...all]);
							};

							const handleSelectDefault = () => {
								setselectOptions("default");
								setFieldValue("permissions", [...permissionNames]);
							};

							const handleDeselectAll = () => {
								setselectOptions("none");
								setFieldValue("permissions", []);
							};

							return (
								<Form>
									<div className="pt-3 pb-6 space-y-6">
										<div className="space-y-4">
											<h4>
												Select permissions to assign to the role{" "}
												<span className="font-bold">{roleName}</span>{" "}
											</h4>

											<div className="flex justify-between gap-2">
												<div className="flex gap-2">
													<button
														className="text-akgreenest border p-2 border-akgreen rounded flex items-center gap-2 justify-between"
														type="button"
														onClick={handleSelectAll}
													>
														Select all
														{selectOptions === "all" && (
															<BsCheckCircle size={20} />
														)}
													</button>

													<button
														className="text-akgreenest border p-2 border-akgreen rounded flex items-center gap-2 justify-between"
														type="button"
														onClick={handleDeselectAll}
													>
														Deselect all
														{selectOptions === "none" && (
															<BsCheckCircle size={20} />
														)}
													</button>
												</div>

												<button
													className="text-akgreenest border p-2 border-akgreen rounded flex items-center gap-2 justify-between"
													type="button"
													onClick={handleSelectDefault}
												>
													Select assigned
													{selectOptions === "default" && (
														<BsCheckCircle size={20} />
													)}
												</button>
											</div>

											<div className="grid grid-cols-3 gap-2">
												{!isLoadingPermissions &&
													allPermissions?.data?.map((permission) => (
														<div
															className="flex gap-2 items-center"
															key={permission.id}
														>
															<Checkbox
																inputId={permission.name}
																name="permissions"
																value={permission.name}
																onChange={handleChange}
																checked={values.permissions?.includes(
																	permission.name
																)}
																onBlur={handleBlur}
															/>
															<label
																htmlFor={permission.name}
																className=" cursor-pointer flex flex-col "
															>
																<span className="font-bold">
																	{permission?.name}
																</span>
																<span
																	className="truncate"
																	style={{ width: 260 }}
																	title={permission?.label}
																>
																	{permission?.label}
																</span>
															</label>
														</div>
													))}
											</div>
										</div>
									</div>

									<div className=" flex justify-center border-t border-gray-300 pt-2">
										<button
											type="submit"
											className="px-4 py-2 rounded-full bg-akgreenest text-white flex gap-1 items-center justify-center"
										>
											{isUpdatingRole ? (
												<>
													Updating{" "}
													<AiOutlineLoading3Quarters
														size={20}
														className="animate-spin"
													/>
												</>
											) : (
												<>
													Update <IoIosArrowForward size={20} />
												</>
											)}
										</button>
									</div>
								</Form>
							);
						}}
					</Formik>
				)}
			</Dialog>
		</>
	);
};

export default EditRole;
