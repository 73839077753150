import React from "react";
import { Dialog } from "primereact/dialog";
import { Link } from "react-router-dom";

const ActionErrorMsg = ({
	heading,
	message,
	children,
	action,
	className = undefined,
}) => {
	const [visible, setVisible] = React.useState(false);

	return (
		<>
			<button
				type="button"
				className={className}
				onClick={() => setVisible(true)}
			>
				{children}
			</button>

			<Dialog
				visible={visible}
				header={<p className="text-lg">{heading}</p>}
				onHide={() => setVisible(false)}
				showHeader={true}
			>
				<div className="py-4">
					<div className=" mt-4 ">
						<p className="text-center mx-auto w-80">{message}</p>
					</div>

					{action && (
						<div className="mx-auto flex justify-center mt-3">
							<Link
								to={action.link}
								className="bg-akgreener text-white rounded-full px-4 py-2"
							>
								{action.label}
							</Link>
						</div>
					)}
				</div>
			</Dialog>
		</>
	);
};

export default ActionErrorMsg;
