import React from "react";
import { Dialog } from "primereact/dialog";
import useCartStore from "store/cartStore";
import { useHistory } from "react-router-dom";
import { useUrlQuery } from "hooks/useUrlQuery";

const AddToCartSuccessful = () => {
	const { addToCartIsSuccessful, setAddToCartIsSuccessful } = useCartStore();
	const queryParams = useUrlQuery();
	const returnUrl = queryParams.get("returnUrl");
	const history = useHistory();

	const handleGoToCheckout = () => {
		setAddToCartIsSuccessful(false);
		history.push("/checkout");
	};

	const handleGoToBooks = () => {
		setAddToCartIsSuccessful(false);
		const cleanUrl = returnUrl
			?.replace(/lgAnd/g, "&")
			?.replace(/lgQuestionMark/g, "?")
			?.replace(/lgHyphen/, "-");
		window.location.replace(cleanUrl || "/books");
		// history.push(cleanUrl || "/books");
	};

	return (
		<Dialog
			visible={addToCartIsSuccessful}
			onHide={() => setAddToCartIsSuccessful(false)}
			position="center"
			showHeader={false}
			className="h-56 flex items-center justify-center"
		>
			<div className="px-4 flex items-center justify-center h-full">
				<div className="space-y-4">
					<p className="text-center text-lg">
						This book was successfully added to your cart.
					</p>

					<div className="flex items-center justify-center gap-2">
						<button
							type="button"
							onClick={handleGoToBooks}
							className="text-lg py-2 px-4 rounded-lg font-bold border-2 border-gray-500"
						>
							Continue shopping
						</button>

						<button
							type="button"
							onClick={handleGoToCheckout}
							className="text-lg py-2 px-4 rounded-lg font-bold bg-akgreener text-white border-2 border-transparent"
						>
							Go to checkout
						</button>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default AddToCartSuccessful;
