import useFetchUserCommissions from "api/CommissionResource/useFetchUserCommissions";
import AdminPageLoader from "components/AdminPageLoader";
import Admin2 from "layouts/Admin2";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { getActiveCurrencyCodeLabel, getActiveCurrencySymbol } from "utils";
import ClearFilters from "./ClearFilters";
import { CustomPaginator } from "components";
import useCommissionsFilters from "./useCommissionsFilters";

const BaClientPayments = () => {
	const {
		page,
		rowsPerPage,
		firstPage,
		fromDate,
		toDate,
		changePage,
		changeRowsPerPage,
		changeFirstPage,
		changeFromDate,
		changeToDate,
	} = useCommissionsFilters();
	const { data, isLoading } = useFetchUserCommissions({
		page: page,
		rows_per_page: rowsPerPage,
		from_date: fromDate,
		to_date: toDate,
	});

	const commissionList = data?.data?.records;
	const countDefinedFilters = [fromDate, toDate].filter((item) => item)?.length;

	const amountTemplate = (row) => {
		return (
			<div
				className={`flex gap-1 font-bold ${
					row?.type === "debit" ? "text-red-500" : ""
				}`}
			>
				<span>{getActiveCurrencySymbol(row?.currency)}</span>
				<span>
					{row?.type === "credit" ? row?.amount : `(${row?.amount})`}{" "}
				</span>
			</div>
		);
	};

	const currencyTemplate = (row) => (
		<span>{getActiveCurrencyCodeLabel(row?.currency)}</span>
	);

	const transactionTemplate = (row) => {
		if (row?.type === "credit") {
			return <span>Commission earned</span>;
		}

		return <span>Amount received</span>;
	};

	if (isLoading) {
		return <AdminPageLoader />;
	}

	return (
		<Admin2>
			<div className="w-full flex justify-between items-center">
				<h4>Commission statement</h4>

				<div className=" mt-4 flex justify-end items-end gap-2">
					<select
						name="rowsPerPage"
						id="rowsPerPage"
						value={rowsPerPage}
						onChange={(e) => changeRowsPerPage(e.target.value)}
						className="border border-gray-300 bg-white rounded h-10 px-2"
					>
						<option value="10">10</option>
						<option value="20">20</option>
						<option value="50">50</option>
					</select>
					<Calendar
						value={new Date(fromDate)}
						name="from_date"
						dateFormat="yy-mm-dd"
						placeholder="Date (from)"
						className="w-52 h-10 font-sans"
						inputClassName="border border-gray-300 bg-white rounded"
						showIcon
						readOnlyInput
						showButtonBar
						onChange={(e) =>
							changeFromDate(moment(e.target.value).format("YYYY-MM-DD"))
						}
						onClearButtonClick={() => changeFromDate("")}
					/>
					<Calendar
						value={new Date(toDate)}
						name="to_date"
						dateFormat="yy-mm-dd"
						placeholder="Date (to)"
						className="w-52 h-10 font-sans"
						inputClassName="border border-gray-300 bg-white rounded"
						showIcon
						showButtonBar
						onChange={(e) =>
							changeToDate(moment(e.target.value).format("YYYY-MM-DD"))
						}
						onClearButtonClick={() => changeToDate("")}
					/>
					{/* <div className="place-content-start"> */}
					<ClearFilters
						count={countDefinedFilters}
						changeFromDate={changeFromDate}
						changeToDate={changeToDate}
					/>
					{/* </div> */}
				</div>
			</div>

			<div className="w-full mt-8">
				<DataTable
					value={commissionList}
					dataKey="id-type"
					autoLayout
					tableClassName="font-sans text-sm"
				>
					<Column
						header="ID"
						body={(row) => (
							<span>
								{row?.id}/{row?.type}
							</span>
						)}
					/>
					<Column header="Transaction details" body={transactionTemplate} />
					<Column body={amountTemplate} header="Amount" />
					<Column body={currencyTemplate} header="Currency" />
					<Column
						body={(row) => (
							<span>{moment(row?.created_at)?.format("Y-MM-DD")}</span>
						)}
						header="Transaction date"
					/>
				</DataTable>

				<div className="flex-1 flex items-end justify-end pb-4 mt-4">
					<CustomPaginator
						firstPage={firstPage}
						setFirstPage={changeFirstPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={changeRowsPerPage}
						totalRecords={data?.data?.total_records}
						setPage={changePage}
						showRowsPerPageDropdown={false}
					/>
				</div>
			</div>
		</Admin2>
	);
};

export default BaClientPayments;
