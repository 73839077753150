import useMigrateAndSeed from "api/CommandCenterResource/useMigrateAndSeed";
import React from "react";
import { AiOutlineLoading3Quarters, AiOutlineRight } from "react-icons/ai";

const RunMigrations = () => {
	const [seederClass, setSeederClass] = React.useState("");

	const { mutateAsync, isLoading } = useMigrateAndSeed();

	const runMigration = async () => {
		await mutateAsync({ seeder_class: seederClass });
	};

	return (
		<div className="w-full px-2 py-2 rounded-lg bg-akgreen border-2 border-akgreener flex justify-between items-center text-white">
			<div className="flex items-center gap-3">
				<span className=" text-base ">Run DB migrations</span>
				<input
					type="text"
					placeholder="Enter seeder class (optional)"
					value={seederClass}
					className="px-2 py-1 rounded w-52 text-gray-700 "
					onChange={(e) => setSeederClass(e.target.value)}
				/>
			</div>

			<button
				type="button"
				onClick={runMigration}
				className="flex justify-between items-center px-2 py-2 bg-akgreenest rounded-lg gap-2"
			>
				Run
				{isLoading ? (
					<AiOutlineLoading3Quarters size={20} className="animate-spin" />
				) : (
					<AiOutlineRight size={20} />
				)}
			</button>
		</div>
	);
};

export default RunMigrations;
