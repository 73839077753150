import React from "react";
import Paypal from "components/Paypal";
// import useCreateOrder from "api/OrderResource/useCreateOrder";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useCreateSubscriptionOrder from "api/OrderResource/useCreateSubscriptionOrder";
import { activeCurrencies } from "utils";

const PayPal = ({ id, grandTotal }) => {
	const [voucherNumber, setVoucherNumber] = React.useState("");

	const [paymentMethod, setPaymentMethod] = React.useState("PayPal");
	const [applyVoucher, setApplyVoucher] = React.useState(false);

	const handleApplyVoucher = () => {
		setPaymentMethod("Voucher");
		setApplyVoucher(true);
	};

	const handleBackToPaypal = () => {
		setPaymentMethod("PayPal");
		setApplyVoucher(false);
	};

	// const initialValues

	const { mutateAsync, isLoading, error } = useCreateSubscriptionOrder();

	const _onPaypalComplete = async (order = null) => {
		const reference = order
			? order?.id + "-" + order?.payer?.payer_id
			: Date.now();

		await mutateAsync({
			street_address:
				order?.purchase_units[0]?.shipping?.address?.address_line_1,
			city: order?.purchase_units[0]?.shipping?.address?.admin_area_2,
			province: order?.purchase_units[0]?.shipping?.address?.admin_area_1,
			country: order?.purchase_units[0]?.shipping?.address?.country_code,
			postcode: order?.purchase_units[0]?.shipping?.address?.postal_code,
			payment_method: paymentMethod,
			payment_type: "Subscription",
			subtotal: 0,
			grand_charges: 0,
			grand_total: grandTotal,
			grand_total_zwl: 0,
			reference: reference,
			status: order?.status === "COMPLETED" ? "Completed" : "Failed",
			id: id,
			voucher_number: voucherNumber,
			currency: activeCurrencies.USD.code,
			total_amount: grandTotal,
		});
	};
	return (
		<div className="relative mt-4 space-y-2">
			<h3 className="">PayPal payment</h3>

			<div>
				{!applyVoucher ? (
					<>
						<Paypal
							amount={grandTotal}
							_onComplete={(order) => _onPaypalComplete(order)}
						/>
						<div className="mx-auto flex justify-center mt-2">
							<button
								type="button"
								onClick={handleApplyVoucher}
								className="text-lg text-blue-800 font-bold"
							>
								Would you like to pay using a voucher?
							</button>
						</div>
					</>
				) : (
					<>
						{/* <p>Please enter your voucher number</p> */}

						<div>
							<p>Enter your {activeCurrencies.USD.label} voucher number</p>
							<p>For example TKRpiLHXXy</p>
						</div>

						<div className="pt-2">
							<input
								type="text"
								autoFocus
								name="voucher_number"
								className="rounded-lg bg-gray-200 border border-gray-300 h-9 px-4 w-full"
								onChange={(e) => setVoucherNumber(e.target.value)}
							/>

							{error && (
								<p className="text-red-500 text-xs">
									{error?.response?.data?.message}
								</p>
							)}
						</div>

						<div className="flex gap-4 mt-2">
							<button
								type="button"
								onClick={handleBackToPaypal}
								className="rounded-full px-3 py-2 text-white bg-akgreen opacity-70 w-1/2"
							>
								Back to Paypal
							</button>
							<button
								type="button"
								onClick={() => _onPaypalComplete(null)}
								className="rounded-full px-3 py-2 text-white bg-akgreenest w-1/2 flex items-center justify-center gap-2"
							>
								Proceed with payment
								{isLoading ? (
									<AiOutlineLoading3Quarters
										size={20}
										className="animate-spin"
									/>
								) : undefined}
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default PayPal;
