import useImportUsers from "api/BookResource/useImportUsers";
import { Button, ButtonLoader, TextField } from "components";
import { Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import React from "react";
import useOverlayStore from "store/overlayStore";

const UploadUsers = () => {
	const { showUploadUsers, setShowUploadUsers } = useOverlayStore();

	const initialValues = {
		csv: null,
	};

	const { mutateAsync, isLoading } = useImportUsers();
	const handleImportUsers = async (data) => {
		await mutateAsync(data);
	};

	return (
		<>
			<Dialog
				visible={showUploadUsers}
				style={{ width: "80vw" }}
				header={
					<div className="text-xl font-sans font-bold sticky top-4 z-20 bg-white mr-2">
						<p className="">Upload users metadata (Excel/CSV)</p>
					</div>
				}
				onHide={() => setShowUploadUsers(false)}
				position="top"
				draggable={false}
				contentClassName="rounded-b-xl "
				className="rounded-xl relative"
			>
				<p className="text-lg mt-4">
					Import CSV/Excel with the following headers. Use correct format for
					data as guided.
				</p>

				<ul className=" list-disc list-inside mt-2 mb-10">
					<li className=" list-item ">
						NB: Headers with a (*) means that the column should not have empty
						values, otherwise the import will fail
					</li>
					<li className=" list-item ">Role should be student</li>
					<li className=" list-item ">
						Gender should be <span className="font-bold">Male</span> or{" "}
						<span className="font-bold">Female</span>
					</li>
				</ul>

				<div className="flex justify-between items-center gap-1 divide-x divide-gray-300 mt-4 ">
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">First name *</p>
						<p>(text)</p>
					</div>

					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Last name *</p>
						<p>(text)</p>
					</div>

					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Username *</p>
						<p>(text)</p>
					</div>

					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Password * </p>
						<p>(text)</p>
					</div>

					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Gender *</p>
						<p>(text)</p>
					</div>

					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Role *</p>
						<p>(text)</p>
					</div>

					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Email address</p>
						<p>(text)</p>
					</div>

					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Mobile number</p>
						<p>(text)</p>
					</div>

					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Date of birth (yyyy-mm-dd)</p>
						<p>(text)</p>
					</div>

					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Subject group (e.g Form 1, or Arts)</p>
						<p>(text)</p>
					</div>
				</div>

				<p className="mt-3 underline text-lg text-red-500">
					NB: Please ignore the text in brackets and stars (*) when you prepare
					your csv/excel sheet
				</p>

				<Divider />

				<Formik
					initialValues={initialValues}
					onSubmit={(data) => handleImportUsers(data)}
				>
					{({ setFieldValue }) => {
						return (
							<Form>
								<div className="flex justify-center mt-3">
									<TextField
										type="file"
										name="csv"
										value={null}
										onChange={(event) =>
											setFieldValue("csv", event.target.files[0])
										}
									/>
								</div>

								<div className="flex justify-center mt-3">
									{isLoading ? (
										<ButtonLoader size={40} />
									) : (
										<Button type="submit" className="ml-3 flex items-center ">
											Submit
										</Button>
									)}
								</div>
							</Form>
						);
					}}
				</Formik>
			</Dialog>
		</>
	);
};

export default UploadUsers;
