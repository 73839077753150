import BookCard from "admin/AllBooks2/BookCard";
import BookFilter from "admin/AllBooks2/BookFilter";
import useInstitutionBooks from "api/BookResource/useInstitutionBooks";
import { ButtonLoader, CustomPaginator } from "components";
import { useFormik } from "formik";
import Admin2 from "layouts/Admin2";
import React, { useState } from "react";
import { BsCheckAll, BsX } from "react-icons/bs";
import { Link } from "react-router-dom";
import useAuthStore from "store/authStore";
import useBooksFilterStore from "store/booksFilterStore";
import AssignSubjectGroups from "./AssignSubjectGroups";
import AssignUsers from "./AssignUsers";
import { Role } from "utils/roles";

const InstitutionBooks = () => {
	const [firstPage, setFirstPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(8);
	const { bookFilters, page, setPage } = useBooksFilterStore();

	const hasRoles = useAuthStore((state) => state.hasRoles);

	const { data: books, isLoading: isLoadingBooks } = useInstitutionBooks({
		page: page,
		rows_per_page: rowsPerPage,
		from_backend: true,
		...bookFilters,
	});

	const [selectedBooks, setSelectedBooks] = useState([]);

	const { values, handleSubmit, setFieldValue } = useFormik({
		initialValues: {
			books: [...selectedBooks],
			action: "",
		},

		onSubmit: async (data) => {},
		enableReinitialize: true,
	});

	return (
		<Admin2>
			<div className="mb-4 space-y-4 ">
				<div className="flex flex-col items-center gap-4">
					<p className="text-lg font-bold">Purchased books</p>
					<BookFilter />
				</div>

				{isLoadingBooks ? (
					<div className="flex justify-center">
						<ButtonLoader size={60} />
					</div>
				) : (
					<form
						onSubmit={handleSubmit}
						className="flex flex-col items-center border-t pt-4">
						<div className="flex items-center gap-4 mb-4">
							<label
								htmlFor="selectActions"
								className="flex items-center gap-1">
								<input
									type="checkbox"
									name="select"
									className="h-8 w-8"
									checked={
										selectedBooks?.length === books?.data?.records?.length
									}
									onChange={() =>
										setSelectedBooks((prev) =>
											prev?.length <= 0 ? [...books?.data?.records] : []
										)
									}
									id="selectActions"
								/>
								<span className="flex item-center gap-1 px-1 py-1 border border-akgreener rounded cursor-pointer">
									{selectedBooks.length <= 0 ? (
										<>
											Select All <BsCheckAll size={20} />
										</>
									) : (
										<>
											Deselect All <BsX size={20} />
										</>
									)}
								</span>
							</label>

							{values.books?.length > 0 && (
								<>
									<AssignUsers
										books={values.books}
										className="border px-2 py-1 rounded border-gray-300 hover:border-2 hover:border-akgreener hover:text-akgreener">
										Assign users
									</AssignUsers>

									<AssignSubjectGroups
										books={values.books}
										className="border px-2 py-1 rounded border-gray-300 hover:border-2 hover:border-akgreener hover:text-akgreener">
										Assign subject groups
									</AssignSubjectGroups>
								</>
							)}
						</div>

						<div className="grid grid-cols-2 gap-4  pt-10">
							{books?.data?.records?.map((book) => (
								<label
									htmlFor={`bookitem${book.id}`}
									key={book.id}
									className="max-w-max">
									{values?.books?.map((book) => book.id)?.includes(book.id) ? (
										<input
											type="checkbox"
											checked
											name="books"
											value={book}
											className="w-6 h-6"
											onChange={() =>
												setFieldValue(
													"books",
													values.books.filter((_book) => _book.id !== book.id)
												)
											}
										/>
									) : (
										<input
											type="checkbox"
											name="books"
											value={book}
											onChange={() =>
												setFieldValue("books", [...values.books, book])
											}
											id={`bookitem${book.id}`}
											className="w-6 h-6 border-2 border-akgreen"
										/>
									)}
									<BookCard
										bookId={book?.id}
										title={book?.title}
										author={book?.author}
										price={book?.price}
										zwlPrice={book?.zwl_price}
										isbn={book?.isbn}
										description={book?.description}
										sellable={book?.sellable}
										onSubscription={book?.subscription}
										isActive={book?.is_active}
										isFree={book?.isFree}
										credits={book?.credits}
										bookCover={book?.book_cover?.url}
										publisher={book?.publisher}
										category={book?.category}
										genres={book?.genres[0]}
										hasFile={book?.has_book_file}
										numberOfCopiesPurchased={book?.number_of_copies_purchased}
										copiesAssignable={book?.number_assignable}
										isAdmin={hasRoles([Role.SUPER_ADMIN, Role.ADMIN])}
										className="items-stretch mb-4 -mt-6"
									/>
								</label>
							))}
						</div>
					</form>
				)}

				<div className="flex justify-center rounded-full mt-4">
					<CustomPaginator
						firstPage={firstPage}
						rowsPerPage={rowsPerPage}
						setFirstPage={setFirstPage}
						setRowsPerPage={setRowsPerPage}
						totalRecords={books?.data?.total_records}
						setPage={setPage}
					/>
				</div>

				<h4 className="my-4 flex justify-center items-center gap-1">
					<span className="text-3xl">{books?.data?.total_records}</span> book
					titles purchased
				</h4>

				<div className="flex justify-center">
					<Link to="/books" className="text-blue-700">
						Want to buy more?
					</Link>
				</div>
			</div>
		</Admin2>
	);
};

export default InstitutionBooks;
