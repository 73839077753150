import useAuthStore from "store/authStore";
import { Role } from "utils/roles";

const usePortalDashboard = () => {
	const { hasRoles, roles } = useAuthStore();
	const dashboardMap = {
		"Super Administrator": "admin-dashboard",
		Administrator: "admin-dashboard",
		"Senior Publisher": "publisher-dashboard",
		Publisher: "publisher-dashboard",
		Institution: "institution-dashboard",
		Educator: "institution-dashboard",
		"Brand Ambassador": "ba-dashboard",
	};

	const dashboardUrl = roles ? dashboardMap[roles[0]] : "#";

	const canAccessPortal = hasRoles([
		Role.SUPER_ADMIN,
		Role.ADMIN,
		Role.PUBLISHER,
		Role.SENIOR_PUBLISHER,
		Role.INSTITUTION,
		Role.EDUCATOR,
		Role.BA,
	]);

	return {
		dashboardUrl,
		canAccessPortal,
	};
};

export default usePortalDashboard;
