import useSendEnquiry from "api/Contact/useSendEnquiry";
import { DesktopHeader, TextField } from "components";
import PhoneTextField from "components/PhoneTextField";
import { Form, Formik } from "formik";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import * as Yup from "yup";

const ContactUs = () => {
  //!

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    comment: "",
  };

  const validate = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    name: Yup.string().required("Full name is required"),
    phone: Yup.string()
      .required("Phone number is required")
      // .matches(phoneRegex, "Invalid phone number")
      .min(10, "Mobile number must have at least characters")
      .max(20, "Mobile number cannot exceed 20 characters"),
    comment: Yup.string().required("Comment is required"),
  });

  const { mutateAsync, isLoading } = useSendEnquiry();

  const handleForm = async (data, { resetForm }) => {
    const mobile = data.phone.includes("+") ? data.phone : `+${data.phone}`;
    await mutateAsync({
      ...data,
      area: "N/A",
      phone: mobile,
    });
    resetForm();
  };

  return (
    <Container>
      <div className="min-h-screen flex flex-col ">
        {/* <Header /> */}

        <DesktopHeader shadow />

        <div className="flex-grow mt-8">
          <PaddingX className="flex-grow pt-4 sm:pt-10 mt-8">
            <div>
              <p className="text-3xl mb-4">Contact Us</p>

              <Formik
                initialValues={initialValues}
                validationSchema={validate}
                onSubmit={handleForm}
              >
                {(formik) => {
                  return (
                    <Form>
                      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 w-full">
                        <div className="space-y-2">
                          <TextField
                            type="text"
                            label="Full Name*"
                            name="name"
                            autoFocus
                          />

                          <PhoneTextField
                            value={initialValues.mobile_number}
                            _onChange={(phone) => {
                              if (phone !== "+")
                                formik.values.phone = "+" + phone;
                              else formik.values.phone = phone;
                            }}
                            error={Boolean(
                              formik.errors.phone && formik.touched.phone
                            )}
                            errorText={formik.errors.phone}
                          />

                          <TextField
                            type="text"
                            label="Email Address*"
                            name="email"
                          />
                        </div>

                        <div>
                          <TextField
                            type="textarea"
                            name="comment"
                            placeholder="Start typing..."
                            label="Comment*"
                            rows="7"
                          />
                        </div>

                        <div className="flex flex-col md:items-center w-full space-y-2">
                          <button
                            className="px-4 mb-4 py-2 rounded-full bg-gradient-to-r from-akgreen w-60 to-akgreenest text-white text-lg text-center"
                            type="submit"
                          >
                            {isLoading ? "Sending..." : "Submit"}
                          </button>

                          <p>
                            Email:{" "}
                            <a href="mailto:library@akello.co">
                              library@akello.co
                            </a>
                          </p>

                          <p>
                            Facebook:{" "}
                            <a href="https://twitter.com/learnwithakello">
                              LearnWithAkello
                            </a>
                          </p>

                          <p>
                            Instagram:{" "}
                            <a href="https://www.instagram.com/learnwithakello/">
                              learnwithakello
                            </a>
                          </p>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </PaddingX>
        </div>
      </div>
    </Container>
  );

  //!
};

export default ContactUs;
