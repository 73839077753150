import { Logo } from "components";
import { TieredMenu } from "primereact/tieredmenu";
import { BsFileEarmarkPdf, BsFilePdf } from "react-icons/bs";
import { FiUser, FiUsers } from "react-icons/fi";
import { NavLink, useHistory } from "react-router-dom";
import useAuthStore from "store/authStore";
import tw from "tailwind-styled-components/dist/tailwind";
import { Role } from "utils/roles";

const AkAdminSidebar = tw.div`
      min-h-screen
      
`;

const Nav = tw(NavLink)`
       
      border border-akgreen
      rounded-lg
      shadow-md
      block 
      w-full
      px-2 py-2
      text-gray-100  
      hover:text-gray-700 
      hover:bg-gradient-to-b hover:from-gray-300 hover:to-gray-100
      mb-1
`;

const LinkGroup = tw.h4`
  text-gray-100 mb-3 bg-akgreen px-3 text-center
`;

const AdminSidebar = () => {
	//!

	// const { hasRoles } = useAuthUser();
	const hasRoles = useAuthStore((state) => state.hasRoles);

	const history = useHistory();

	const adminMenu = [];
	if (hasRoles([Role.SUPER_ADMIN, Role.ADMIN])) {
		adminMenu.push({
			label: "Administrator",
			command: () => history.push("/admin-dashboard"),
		});
	}

	if (hasRoles([Role.SENIOR_PUBLISHER, Role.PUBLISHER])) {
		adminMenu.push({
			label: "Publisher",
			command: () => history.push("/publisher-dashboard"),
		});
	}

	if (hasRoles([Role.INSTITUTION, Role.EDUCATOR])) {
		adminMenu.push({
			label: "Institution",
			command: () => history.push("/users/institution"),
		});
	}

	const items = [
		{
			label: "Dashboard",
			icon: "pi pi-fw pi-chart-bar",
			items: [...adminMenu],
		},
	];

	// let dashboardUrl = "admin-dashboard";
	// if (hasRoles(["Super Administrator", "Administrator"])) {
	//   dashboardUrl = "admin-dashboard";
	// }

	return (
		<AkAdminSidebar>
			<div className="flex justify-center mb-5">
				<Logo />
			</div>

			<div className="mb-4">
				<TieredMenu model={items} className="font-bold text-akgreen w-full" />
			</div>

			<LinkGroup>Books Management</LinkGroup>
			<div className="px-3 text-sm mb-3">
				{hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) && (
					<Nav
						to="/books/admin/all"
						activeClassName="border border-purple-500 bg-gradient-to-l from-akgreener to-akgreen text-gray-700 transform -rotate-3">
						<span className="flex gap-1 items-center">
							<BsFilePdf className="inline" size={20} /> All Books
						</span>
					</Nav>
				)}

				{hasRoles([Role.PUBLISHER, Role.SENIOR_PUBLISHER]) && (
					<Nav
						to="/books/admin/my-books"
						activeClassName="border border-purple-500 bg-gradient-to-l from-akgreener to-akgreen text-gray-700 transform -rotate-3">
						<span className="flex gap-1 items-center">
							<BsFilePdf className="inline" size={20} /> My Books
						</span>
					</Nav>
				)}

				{hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) && (
					<Nav
						to="/books/admin/genres-and-categories"
						activeClassName="border border-purple-500 bg-gradient-to-l from-akgreener to-akgreen text-gray-700 transform -rotate-3">
						<span className="flex gap-1 items-center">
							<BsFileEarmarkPdf className="inline" size={20} /> Book categories
						</span>
					</Nav>
				)}
			</div>
			<LinkGroup>User Management</LinkGroup>
			<div className="px-3 text-sm">
				{hasRoles([Role.SUPER_ADMIN]) && (
					<Nav
						to="/users/admin/all"
						activeClassName="border border-purple-500 bg-gradient-to-l from-akgreener to-akgreen text-gray-700 transform -rotate-3">
						<span className="flex gap-1 items-center">
							<FiUsers className="inline" size={20} /> Users
						</span>
					</Nav>
				)}

				{hasRoles([Role.INSTITUTION, Role.EDUCATOR]) && (
					<Nav
						to="/users/institution"
						activeClassName="border border-purple-500 bg-gradient-to-l from-akgreener to-akgreen text-gray-700 transform -rotate-3">
						<span className="flex gap-1 items-center">
							<FiUsers className="inline" size={20} /> Institution users
						</span>
					</Nav>
				)}

				{hasRoles([Role.SENIOR_PUBLISHER]) && (
					<Nav
						to="/users/publisher"
						activeClassName="border border-purple-500 bg-gradient-to-l from-akgreener to-akgreen text-gray-700 transform -rotate-3">
						<span className="flex gap-1 items-center">
							<FiUsers className="inline" size={20} /> Publisher users
						</span>
					</Nav>
				)}
			</div>

			<div className="px-3 text-sm mt-4">
				<Nav
					to={`/portal-profile`}
					activeClassName="border border-purple-500 bg-gradient-to-l from-akgreener to-akgreen text-gray-700 transform -rotate-3 ">
					<span className="flex justify-center gap-1 items-center">
						<FiUser className="inline" size={20} /> Profile
					</span>
				</Nav>
			</div>
		</AkAdminSidebar>
	);

	//!
};

export default AdminSidebar;
