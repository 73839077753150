import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";

const useFetchPublisherByLoggedUser = () => {
	//!

	const { accessToken } = useTokenStore();

	const fetch = async () => {
		const instance = await InitApi({ accessToken });

		const res = await instance.get("/publisher/mine");
		return res.data;
	};
	return useQuery(["publisher"], () => fetch(), {
		...fetchConfig,
	});

	//!
};

export default useFetchPublisherByLoggedUser;
