import useCountUserCartItems from "api/CartResource/useCountUserCartItems";
import { BsBasket } from "react-icons/bs";
import { Link } from "react-router-dom";

const Cart = () => {
	const { data: body } = useCountUserCartItems();

	return (
		<Link to="/checkout" className="flex appearance-none">
			<BsBasket size={20} />
			<span className="h-6 w-6 rounded-full p-1 border border-akgreenest text-sm bg-white flex items-center justify-center -mx-1">
				{body?.data || 0}
			</span>
		</Link>
	);
};

export default Cart;
