import React, { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { BsArrowRight } from "react-icons/bs";
import useFetchPublisherBooks from "api/BookResource/useFetchPublisherBooks";
import useBooksFilterStore from "store/booksFilterStore";
import BookCard from "admin/AllBooks2/BookCard";
import useAuthStore from "store/authStore";
import BookFilter from "admin/AllBooks2/BookFilter";
import { ButtonLoader, CustomPaginator } from "components";
import { Role } from "utils/roles";

const PublisherBooks = ({ userId }) => {
	const [openBooks, setopenBooks] = useState(false);

	const [firstPage, setFirstPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(8);

	const { bookFilters, page, setPage } = useBooksFilterStore();

	const { data: books, isLoading: isLoadingBooks } = useFetchPublisherBooks(
		{
			page: page,
			rows_per_page: rowsPerPage,
			...bookFilters,
		},
		userId
	);

	const hasRoles = useAuthStore((state) => state.hasRoles);

	return (
		<>
			<button
				type="button"
				onClick={() => setopenBooks(true)}
				className="text-blue-900 font-bold flex items-center gap-2">
				View books <BsArrowRight size={20} />
			</button>

			<Sidebar
				visible={openBooks}
				icons={
					<h4 className="font-cera-black text-akgreenest text-2xl">
						Uploaded books
					</h4>
				}
				blockScroll
				onHide={() => setopenBooks(false)}
				className="font-sans "
				fullScreen>
				<hr className="mb-4" />

				<BookFilter hideFilters={["publisher"]} />

				{(function () {
					if (isLoadingBooks) {
						return (
							<div className="flex justify-center">
								<ButtonLoader size={60} />
							</div>
						);
					}

					return (
						<div className="flex flex-col items-center ">
							<div className="grid grid-cols-2 lg:grid-cols-3 gap-4 justify-center pt-10 p-4 border-b border-gray-200">
								{books?.data?.records?.map((book) => (
									<div key={book?.id}>
										<BookCard
											bookId={book?.id}
											title={book?.title}
											author={book?.author}
											price={book?.price}
											isbn={book?.isbn}
											description={book?.description}
											sellable={book?.sellable}
											onSubscription={book?.subscription}
											isActive={book?.is_active}
											isFree={book?.isFree}
											credits={book?.credits}
											bookCover={book?.book_cover?.url}
											bookUrl={book?.book_file?.url}
											category={book?.category}
											genres={book?.genres[0]}
											isAdmin={hasRoles([Role.SUPER_ADMIN, Role.ADMIN])}
											className="items-stretch mb-2 -mt-6"
										/>
									</div>
								))}
							</div>

							<div className="flex justify-center rounded-full mt-4">
								<CustomPaginator
									firstPage={firstPage}
									rowsPerPage={rowsPerPage}
									setFirstPage={setFirstPage}
									setRowsPerPage={setRowsPerPage}
									totalRecords={books?.data?.total_records}
									setPage={setPage}
								/>
							</div>
						</div>
					);
				})()}
			</Sidebar>
		</>
	);
};

export default PublisherBooks;
