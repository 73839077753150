import money from "assets/images/icons/money.png";
import { Menu } from "primereact/menu";
import { useRef } from "react";
import useCurrencyStore from "store/currencyStore";
import { activeCurrencies, getActiveCurrencyCodeLabel } from "utils";

const CurrencyConverter = () => {
	const { currency, setCurrency } = useCurrencyStore();

	const menu = useRef(null);

	const items = [
		{
			label: activeCurrencies.ZWL.label,
			command: () => {
				setCurrency(activeCurrencies.ZWL.code);
			},
		},
		{
			label: activeCurrencies.USD.label,
			command: () => {
				setCurrency(activeCurrencies.USD.code);
			},
		},
	];

	return (
		<>
			<button
				type="button"
				onClick={(event) => menu.current.toggle(event)}
				aria-controls="currency_menu"
				aria-haspopup
				className="flex"
			>
				<img src={money} alt="Currency Converter" width={20} />
				<span
					className="h-6 w-6 font-cera-black rounded-full transform translate-y-0 -ml-1 bg-white border border-akgreener flex items-center justify-center"
					style={{ fontSize: 8 }}
				>
					{currency === "STARS"
						? activeCurrencies.USD.label
						: getActiveCurrencyCodeLabel(currency)}
				</span>
			</button>

			<Menu
				className="font-sans max-w-max"
				model={items}
				popup
				ref={menu}
				id="currency_menu"
			/>
		</>
	);
};

export default CurrencyConverter;
