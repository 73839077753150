import create from "zustand";
import { persist } from "zustand/middleware";

const tokenStore = (set, get) => ({
	accessToken: null,
	storeAccessToken: (token) => set({ accessToken: token }),
});

const useTokenStore = create(
	persist(tokenStore, { name: "zus-token", getStorage: () => localStorage })
);

export default useTokenStore;
