import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import useAuthStore from "store/authStore";
import { Sidebar } from "primereact/sidebar";
import { BiUser } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import useLogout from "api/UserResource/useLogout";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const ShortProfile = ({ small = false }) => {
	const profile = useAuthStore((state) => state.userProfile);

	const [visible, setVisible] = useState(false);

	const { mutateAsync, isLoading } = useLogout();
	const handleLogout = async () => {
		await mutateAsync();
	};

	const header = (
		<div className="flex justify-between items-center gap-2">
			{profile?.avatar ? (
				<img
					src={profile?.avatar}
					className="rounded-full h-10 w-10"
					alt={profile?.email}
				/>
			) : (
				<BiUser
					size={20}
					className="h-10 w-10 p-2 rounded-full bg-gray-500 text-white"
				/>
			)}

			<p>
				{profile?.first_name} {profile?.last_name}
			</p>
		</div>
	);

	return (
		<div>
			<button
				type="button"
				className="py-2 px-2 rounded-full flex items-center w-full gap-2 "
				style={{ background: "rgba(127, 180, 45, 0.4)" }}
				onClick={() => setVisible(true)}
			>
				{profile?.avatar ? (
					<img
						src={profile?.avatar}
						className="rounded-full h-10 w-10"
						alt={profile?.email}
					/>
				) : (
					<BiUser
						size={20}
						className="h-10 w-10 p-2 rounded-full bg-gray-500 text-white"
					/>
				)}
				{/* <img
					src={profile?.avatar}
					className="rounded-full h-8 w-8"
					alt={profile?.email}
				/> */}

				<p className="truncate text-base font-bold">
					{profile?.first_name} {profile?.last_name}
				</p>

				<BsArrowRight size={20} />
			</button>

			<Sidebar
				visible={visible}
				onHide={() => setVisible(false)}
				className="ml-4 h-auto mt-5 rounded-3xl font-sans min-w-max text-sm"
				style={{ borderTopLeftRadius: "30px" }}
				icons={header}
			>
				<hr />

				<p className="mt-4 text-sm">
					You are signed in as{" "}
					<span className="text-akgreener">{profile?.email}</span>
				</p>

				<div className="mt-2 space-y-2">
					<NavLink
						to="/my-account"
						className="flex gap-2 items-center text-blue-900"
					>
						<BiUser size={20} /> Manage your profile
					</NavLink>
					<button
						type="button"
						onClick={() => handleLogout()}
						className="flex gap-2 items-center text-blue-900"
					>
						{isLoading ? (
							<>
								<AiOutlineLoading3Quarters size={20} className="animate-spin" />{" "}
								Signing out..
							</>
						) : (
							<>
								<FiLogOut size={20} /> Sign Out
							</>
						)}
					</button>
				</div>
			</Sidebar>
		</div>
	);
};

export default ShortProfile;
