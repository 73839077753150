import React from "react";
import useCurrencyStore from "store/currencyStore";
import { activeCurrencies } from "utils";

const usePrice = ({ isFree, usdPrice = 0, zwlPrice = 0, sellable }) => {
	const { currency } = useCurrencyStore();

	const price = React.useMemo(() => {
		if (isFree) return 0;
		if (!sellable) return 0;
		if (currency === activeCurrencies.USD.code) {
			return usdPrice;
		}
		return zwlPrice;
	}, [currency, isFree, usdPrice, zwlPrice, sellable]);

	return price;
};

export default usePrice;
