import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import useTokenStore from "store/tokenStore";

const useClearCart = () => {
	const { accessToken } = useTokenStore();

	const queryClient = useQueryClient();

	const history = useHistory();

	const clear = async () => {
		const instance = await InitApi({ accessToken });
		return await instance.delete("carts/clear");
	};

	return useMutation(clear, {
		onSuccess: async ({ data }) => {
			queryClient.invalidateQueries("user_cart_items");
			queryClient.invalidateQueries("user_cart_count");
			toast.success(data.message);
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);

			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}
		},
	});
};

export default useClearCart;
