import BooksFilter from "admin/AllBooks/BooksFilter";
import useBulkActionBooks from "api/BookResource/useBulkActionBooks";
import useFetchPublisherBooks from "api/BookResource/useFetchPublisherBooks";
import { ButtonLoader, CustomPaginator } from "components";
import { useFormik } from "formik";
import Admin from "layouts/Admin";
import { useState } from "react";
import { BsCheckAll, BsFillBookFill, BsX } from "react-icons/bs";
import useBooksFilterStore from "store/booksFilterStore";
import AddBook from "./AddBook";
import AdminBookItem from "./AdminBookItem";
import DeleteBook from "./DeleteBook";
import EditBook from "./EditBook";
import ImportBooks from "./ImportBooks";

const Books = () => {
  //!

  const [firstPage, setFirstPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const { bookFilters, page, setPage } = useBooksFilterStore();

  const { data: booksData, isLoading: booksIsLoading } = useFetchPublisherBooks(
    {
      page: page,
      rows_per_page: rowsPerPage,
      ...bookFilters,
    }
  );
  const [selectedBooks, setSelectedBooks] = useState([]);

  const { mutateAsync: mutateBulkAction, isLoading: isActioning } =
    useBulkActionBooks();

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      books: [...selectedBooks],
      action: "",
    },

    onSubmit: async (data) => {
      switch (data.action) {
        case "Activate":
          await mutateBulkAction({
            books: [...data.books],
            action: "activate",
          });
          break;

        case "Deactivate":
          await mutateBulkAction({
            books: [...data.books],
            action: "deactivate",
          });
          break;

        case "Set as free":
          await mutateBulkAction({
            books: [...data.books],
            action: "setAsFree",
          });
          break;

        case "Set as premium":
          await mutateBulkAction({
            books: [...data.books],
            action: "setAsPremium",
          });
          break;

        case "Delete":
          await mutateBulkAction({
            books: [...data.books],
            action: "deletePermanently",
          });
          break;

        default:
          break;
      }
    },
    enableReinitialize: true,
  });

  return (
    <Admin
      pageTitle="My Books"
      headerContent={
        <>
          <AddBook />
          <ImportBooks />
        </>
      }
      filters={<BooksFilter />}
    >
      {booksIsLoading ? (
        <div className="flex justify-center">
          <ButtonLoader size={100} />
        </div>
      ) : (
        <div className="space-y-4">
          <form onSubmit={handleSubmit}>
            <div className="flex items-center gap-4 mb-4">
              {(function () {
                // if (values.books.length <= 0) {
                return (
                  <label
                    htmlFor="selectActions"
                    className="flex items-center gap-1"
                  >
                    <input
                      type="checkbox"
                      name="select"
                      className="h-8 w-8"
                      checked={
                        selectedBooks?.length ===
                        booksData?.data?.records?.length
                      }
                      onChange={() =>
                        setSelectedBooks((prev) =>
                          prev?.length <= 0
                            ? [
                                ...booksData?.data?.records?.map(
                                  (book) => book.id
                                ),
                              ]
                            : []
                        )
                      }
                      id="selectActions"
                    />
                    <span className="flex item-center gap-1 px-1 py-1 border border-akgreener rounded cursor-pointer">
                      {selectedBooks.length <= 0 ? (
                        <>
                          Select All <BsCheckAll size={20} />
                        </>
                      ) : (
                        <>
                          Deselect All <BsX size={20} />
                        </>
                      )}
                    </span>
                  </label>
                );
                // }
              })()}

              {values.books.length > 0 && (
                <>
                  <p className="font-cera-black">With selected</p>

                  <select
                    name="action"
                    className="px-2 py-1 border border-gray-200 rounded-lg shadow"
                    value={values.action}
                    onChange={handleChange}
                  >
                    <option label="-- Select action --"></option>
                    <option value="Delete">Permanently delete</option>
                    <option value="Set as free">Set as free</option>
                    <option value="Set as premium">Set as premium</option>
                    <option value="Activate">Activate</option>
                    <option value="Deactivate">Deactivate</option>
                  </select>

                  {values.action && (
                    <button
                      type="submit"
                      className="rounded-lg px-2 py-1 bg-akgreen text-white flex gap-2 items-center justify-center"
                    >
                      Execute{" "}
                      {isActioning && (
                        <BsFillBookFill
                          size={20}
                          color="white"
                          className="animate-spin text-white"
                        />
                      )}
                    </button>
                  )}
                </>
              )}
            </div>

            <div className="grid grid-cols-3 w-full gap-4">
              {booksData?.data?.records?.map((book) => (
                <div className="mb-2" key={book.id}>
                  <label htmlFor={`bookitem${book.id}`}>
                    {values.books.includes(book.id) ? (
                      <input
                        type="checkbox"
                        checked
                        name="books"
                        value={Number(book.id)}
                        className="w-6 h-6"
                        onChange={() =>
                          setFieldValue(
                            "books",
                            values.books.filter((id) => id !== book.id)
                          )
                        }
                      />
                    ) : (
                      <input
                        type="checkbox"
                        name="books"
                        value={Number(book.id)}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        id={`bookitem${book.id}`}
                        className="w-6 h-6 border-2 border-akgreen"
                      />
                    )}

                    <div className="-mt-5 pl-2">
                      <AdminBookItem
                        book={book}
                        title={book.title}
                        coverUrl={book.book_cover.url}
                        author={book.author}
                        isbn={book?.isbn}
                        publisher={
                          book?.user?.publishers[0]?.name ||
                          `${book?.user?.first_name ?? ""} ${
                            book?.user?.last_name ?? ""
                          }`
                        }
                        currencyCode={book.currency_code}
                        currencySymbol={book.currency_symbol}
                        price={book.price}
                        category={book?.category?.name}
                        genre={book?.genres[0]?.name}
                        bookId={book.id}
                        isActive={book?.is_active}
                        // isAdmin={hasRoles(["Super Administrator"])}
                        // op={op}
                      />
                    </div>
                  </label>

                  <div className="px-4 flex gap-1 -mt-3">
                    <EditBook elementId={`editbook-${book.id}`} book={book} />
                    <DeleteBook
                      elementId={`book-${book.id}`}
                      bookId={book.id}
                    />
                  </div>
                </div>
              ))}
            </div>
          </form>

          <div className="flex justify-center bg-gray-200 border-r border-l border-gray-400 rounded-full mt-4">
            <CustomPaginator
              firstPage={firstPage}
              rowsPerPage={rowsPerPage}
              setFirstPage={setFirstPage}
              setRowsPerPage={setRowsPerPage}
              totalRecords={booksData?.data?.total_records}
              setPage={setPage}
            />
          </div>
        </div>
      )}
    </Admin>
  );

  //!
};

export default Books;
