import React from "react";
import ProfileLayout from "../ProfileLayout";
import useSearchParams from "hooks/useSearchParams";
import useProfile from "api/UserResource/useProfile";
import ChangePassword from "../ChangePassword";

const Passwords = () => {
	const { getParam } = useSearchParams();
	const heading = getParam("heading") || "Password and authentication";

	const { data: profile, isLoading: isLoadingProfile } = useProfile();

	return (
		<ProfileLayout
			heading={heading}
			isLoading={isLoadingProfile}
			profile={profile?.data}
		>
			<div className="pt-4">
				<ChangePassword />
			</div>
		</ProfileLayout>
	);
};

export default Passwords;
