import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects, fetchConfig } from "utils";

const useFetchOrders = (queryParams) => {
	//!

	const { accessToken } = useTokenStore();

	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const params = queryParams ? deleteEmptyObjects(queryParams) : null;
		const res = await instance.get("/total-orders", {
			params: { ...params },
		});
		return res.data;
	};

	return useQuery(["orders", queryParams], () => fetch(), {
		...fetchConfig,
	});

	//!
};

export default useFetchOrders;
