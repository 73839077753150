import React from "react";
import { Dialog } from "primereact/dialog";
import useDeleteAccount from "api/UserResource/useDeleteAccount";
import { dataThatCannotBeRecovered } from "utils";

const DeleteAccount = () => {
	const [visible, setVisible] = React.useState(false);

	const { mutateAsync, isLoading } = useDeleteAccount();

	const handleDeleteAccount = async () => {
		await mutateAsync();
	};

	return (
		<>
			<button
				type="submit"
				onClick={() => setVisible(true)}
				className="py-2 px-3 flex w-full justify-center border-2 border-red-700  rounded-lg hover:text-white hover:bg-red-700"
			>
				Delete Account
			</button>

			<Dialog
				visible={visible}
				header={<span className="font-cera-black">Delete Account</span>}
				onHide={() => setVisible(false)}
			>
				<div
					className="flex flex-col justify-center space-y-2 py-6"
					style={{
						maxWidth: 500,
					}}
				>
					<p>
						If you delete your account, all of your data and information will be
						permanently removed from our system and cannot be recovered.
					</p>

					<div>
						<p>This includes:</p>
						<ul className="list-outside list-disc ">
							{dataThatCannotBeRecovered.map((data, index) => (
								<li key={index} className="list-item ml-4">
									{data}
								</li>
							))}
						</ul>
					</div>

					<p>Are you sure you want to proceed with account deletion?</p>

					<button
						onClick={handleDeleteAccount}
						className="rounded-xl px-4 py-3 border-2 border-red-700 mt-2 hover:text-white hover:bg-red-700"
					>
						{isLoading ? "Deleting..." : "Yes, I understand the dangers"}
					</button>
				</div>
			</Dialog>
		</>
	);
};

export default DeleteAccount;
