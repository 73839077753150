import InputError from "components/InputError";
import { useField } from "formik";

const SelectField = ({ name, placeholder, className, children, ...rest }) => {
  const [field, meta] = useField(name);

  return (
    <div className="w-full mb-1">
      {rest.label && (
        <label htmlFor={name} className="text-sm">
          {rest.label}
        </label>
      )}
      <select
        id={name}
        {...field}
        {...rest}
        className={`px-4 py-2 rounded-3xl border border-akgreener text-akgreenest outline-none w-full ${
          className ?? ""
        } `}
      >
        <option label={placeholder}></option>
        {children}
      </select>
      {meta.error && meta.touched && <InputError error={meta.error} />}
    </div>
  );
};

export default SelectField;
