const Button = ({ children, className, type = "button", ...rest }) => {
  return (
    <div>
      <button
        type={type}
        className={`py-1 px-4 rounded-3xl text-center border bg-gradient-to-b from-akgreenest to-akgreen text-white ${
          className ?? ""
        } `}
        {...rest}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
