import useEditBook from "api/BookResource/useEditBook";
import useFetchCategories from "api/CategoryResource/useFetchCategories";
import useGenres from "api/GenreResource/useGenres";
import { Button, ButtonLoader, CheckBox, SelectField, TextField } from "components";
import { Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import React from "react";
import { FiEdit } from "react-icons/fi";
import { MAX_IMAGE_SIZE, MAX_PDF_SIZE } from "utils";
import * as Yup from "yup";

const EditBook = ({ book, elementId }) => {
	////

	const [displayBasic, setDisplayBasic] = React.useState(false);

	const { data: categories, isLoading: isCategoriesLoading } = useFetchCategories();
	const { data: genres, isLoading: isGenresLoading } = useGenres();

	const initialValues = {
		title: book?.title,
		price: book?.price,
		author: book?.author,
		isbn: book?.isbn,
		category_id: book?.category?.id,
		description: book?.description,
		genres: book?.genres[0]?.id,
		book_cover: null,
		book_file: null,
		subscription: !!book?.subscription,
		sellable: !!book?.sellable,
		isFree: !!book?.isFree,
	};

	const updateSchema = Yup.object().shape({
		title: Yup.string().required("Title is required"),
		// price: Yup.string().required("Price is required"),
		author: Yup.string().required("Author is required"),
		genres: Yup.string().required("Genre is required"),
		book_cover: Yup.mixed()
			.nullable()
			.test("fileSize", `Image size should not exceed ${MAX_IMAGE_SIZE}MB`, (value) => {
				if (!value) return true;
				return value.size / 1024 / 1024 <= MAX_IMAGE_SIZE;
			}),
		book_file: Yup.mixed()
			.nullable()
			.test("fileSize", `File size should not exceed ${MAX_PDF_SIZE}MB`, (value) => {
				if (!value) return true;
				return value.size / 1024 / 1024 <= MAX_PDF_SIZE;
			})
			.test("fileMimeType", "Only pdf or epub document is required", (value) => {
				if (!value) return true;
				return value.type === "application/pdf" || value.type === "application/epub+zip";
			}),
	});

	const { mutateAsync, isLoading } = useEditBook(book?.id);

	const handleSubmit = async (data) => {
		await mutateAsync({
			...data,
			subscription: data.subscription ? 1 : 0,
			sellable: data.sellable ? 1 : 0,
			isFree: data.isFree ? 1 : 0,
		});
		setDisplayBasic(false);
	};

	return (
		<div>
			<button
				className="w-8 h-8 flex items-center p-2 text-white justify-center rounded-full bg-gray-700"
				onClick={() => setDisplayBasic(true)}
				id={elementId}
			>
				<FiEdit />
			</button>
			<Tooltip
				className="green-tooltip"
				target={`#${elementId}`}
				position="bottom"
				content="Edit Book"
			/>

			<Dialog
				header="Edit book information"
				visible={displayBasic}
				onHide={() => setDisplayBasic(false)}
			>
				<Formik
					initialValues={initialValues}
					validationSchema={updateSchema}
					onSubmit={(data) => handleSubmit(data)}
				>
					{(formik) => {
						return (
							<Form>
								<TextField
									type="text"
									name="title"
									placeholder="e.g Rich Dad, Poor Dad"
									label="Title"
								/>

								<TextField
									type="text"
									name="author"
									placeholder="e.g Robert Kiyosaki"
									label="Author"
								/>

								<TextField
									type="text"
									name="isbn"
									placeholder="e.g ISN-123-453"
									label="ISBN Number"
								/>

								<TextField
									type="text"
									name="price"
									placeholder="e.g 100.99"
									label="Book Price"
								/>

								<SelectField
									name="category_id"
									label="Select Book Category"
									placeholder="e.g Academic"
								>
									{!isCategoriesLoading &&
										categories?.data?.map((category) => (
											<option value={category.id} key={category.id}>
												{category.name}
											</option>
										))}
								</SelectField>

								<SelectField
									name="genres"
									label="Select Book Collection"
									placeholder="e.g Fiction"
								>
									{!isGenresLoading &&
										genres?.data?.data?.map((genre) => (
											<option value={genre.id} key={genre.id}>
												{genre.name}
											</option>
										))}
								</SelectField>

								<TextField
									type="textarea"
									name="description"
									placeholder="Start typing..."
									label="Description"
								/>

								<TextField
									type="file"
									name="book_cover"
									value={null}
									label="Book Cover"
									onChange={(event) =>
										formik.setFieldValue("book_cover", event.target.files[0])
									}
								/>

								<TextField
									type="file"
									name="book_file"
									value={null}
									placeholder="e.g Rich Dad, Poor Dad"
									label="Upload Book"
									onChange={(event) =>
										formik.setFieldValue("book_file", event.target.files[0])
									}
								/>

								<CheckBox
									label="Do you want people to subscribe to this book?"
									name="subscription"
									checked={formik.values.subscription}
								/>

								<CheckBox
									label="Can people buy this book?"
									name="sellable"
									checked={formik.values.sellable}
								/>

								<CheckBox
									label="Make the book free?"
									name="isFree"
									checked={formik.values.isFree}
								/>

								<div className="mt-4 flex justify-start items-center gap-2">
									<Button type="reset">Cancel</Button>
									{isLoading ? (
										<ButtonLoader size={40} />
									) : (
										<Button type="submit" className="flex items-center ">
											Update Book
										</Button>
									)}
								</div>
							</Form>
						);
					}}
				</Formik>
			</Dialog>
		</div>
	);

	//!
};

export default EditBook;
