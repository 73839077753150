import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import React from "react";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import useTokenStore from "store/tokenStore";

const useDeleteException = () => {
	const { accessToken } = useTokenStore();
	const queryClient = useQueryClient();
	const destroy = async (id) => {
		const instance = await InitApi({ accessToken });
		const response = await instance.delete(`/reported-exceptions/${id}`);
		return response?.data;
	};

	return useMutation(destroy, {
		onSuccess: async (body) => {
			queryClient.invalidateQueries("exceptions");
			toast.success(body.message);
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);
		},
	});
};

export default useDeleteException;
