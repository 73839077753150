import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useEditGenre = (id) => {
	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	const history = useHistory();

	const save = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);

		return await instance.patch(`/book-genres/${id}`, body);
	};

	return useMutation(save, {
		onSuccess: async ({ data: body }) => {
			queryClient.invalidateQueries("genres");
			toast.success("Collection updated successfully");
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);

			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}
		},
	});
};

export default useEditGenre;
