import useMoveUserToTrash from "api/UserResource/useMoveUserToTrash";
import { Dialog } from "primereact/dialog";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiX } from "react-icons/fi";

const DeleteUser = ({ elementId, userId }) => {
	const [visible, setVisible] = React.useState(false);

	const { mutateAsync: deactivate, isLoading: isDeactivating } =
		useMoveUserToTrash(userId);
	const deactivateAccount = async () => {
		await deactivate();
		setVisible(false);
	};

	const { mutateAsync: remove, isLoading: isDeleting } = useMoveUserToTrash(
		userId,
		"permanent"
	);
	const removeAccount = async () => {
		await remove();
		setVisible(false);
	};

	return (
		<>
			<button
				type="button"
				className="appearance-none border-0"
				onClick={() => setVisible(true)}
				id={elementId}
			>
				<FiX size={20} />
			</button>

			<Dialog
				visible={visible}
				style={{ minWidth: "350px", maxWidth: "420px" }}
				onHide={() => setVisible(false)}
				position="center"
				draggable={false}
				showHeader={false}
			>
				<div className="flex justify-end mt-4">
					<button
						type="button"
						className="appearance-none border-0"
						onClick={() => setVisible(false)}
					>
						<FiX size={20} />
					</button>
				</div>
				<div className="flex gap-4 pt-3 justify-between text-xs ">
					<div className="w-1/2 space-y-2 flex-1 flex flex-col justify-between">
						<div className="space-y-2">
							<p className="text-sm font-bold">
								Do you want to deactivate the user?
							</p>
							<p>
								This action will deactivate the user's account. The user whose
								account is deactivated will not be able to log in to the library
								until their account is activated again.
							</p>
						</div>

						<button
							onClick={deactivateAccount}
							className="py-2 px-2 rounded-md border-2 border-red-500 flex gap-2 items-center justify-center"
						>
							Yes, deactivate user
							{isDeactivating && (
								<AiOutlineLoading3Quarters
									size={18}
									className="animate-spin text-red-500"
								/>
							)}
						</button>
					</div>

					<div className="w-1/2 space-y-2 flex-1 flex flex-col justify-between">
						<div className="space-y-2">
							<p className="text-sm font-bold">
								Do you want to permanently delete the user?
							</p>
							<p>
								Note that this action will result in the user losing their
								account and associated data. The account will not be recovered.
							</p>
						</div>

						<button
							onClick={removeAccount}
							className="py-2 px-2 rounded-md bg-red-500 text-white border-2 border-transparent flex gap-2 items-center justify-center"
						>
							Yes, delete user
							{isDeleting && (
								<AiOutlineLoading3Quarters
									size={18}
									className="animate-spin text-red-500"
								/>
							)}
						</button>
					</div>
				</div>
			</Dialog>

			{/* <ConfirmPopup
				target={document.getElementById(elementId)}
				visible={visible}
				onHide={() => setVisible(false)}
				className="font-sans text-sm"
				message="Are you sure you want to deactivate the user?"
				icon="pi pi-exclamation-triangle"
				accept={accept}
			/> */}
		</>
	);
};

export default DeleteUser;
