import { FaChevronLeft } from "react-icons/fa";

const OnPrev = ({ _onPrev }) => {
  return (
    <button
      type="button"
      onClick={_onPrev}
      className="absolute left-2 md:left-6  z-50 p-1 md:p-2 bg-white rounded-full  border-akgreen hover:border-2 "
      style={{ top: "40%" }}
    >
      <FaChevronLeft style={{ fontSize: 24 }} />
    </button>
  );
};

export default OnPrev;
