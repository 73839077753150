import useCreateOrder from "api/OrderResource/useCreateOrder";
import useGetStars from "api/StarsResource/useGetStars";
import { Button, TextField } from "components";
import { Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import { FcProcess } from "react-icons/fc";
import * as Yup from "yup";
import PaymentSuccessMessage from "./PaymentSuccessMessage";
import useCurrencyStore from "store/currencyStore";
import { BsArrowLeft } from "react-icons/bs";
import { activeCurrencies } from "utils";

const PayUsingStars = ({
	subtotal,
	books,
	credits,
	closePaymentOptions,
	bookQuantity,
	// closeBookDetails
}) => {
	const [stars, setStars] = React.useState(0);
	const [studentNumber, setStudentNumber] = React.useState("");

	const { paymentMethod } = useCurrencyStore();

	const [showSuccess, setShowSuccess] = React.useState(false);
	const [totalPaid, setTotalPaid] = React.useState(0);
	const [bookItems, setBookItems] = React.useState(null);

	const initialValues = {
		student_number: "",
		security_answer: "",
	};

	const validate = Yup.object().shape({
		student_number: Yup.string().required("Student number is required"),
		security_answer: Yup.string().required("Security answer is required"),
	});

	const { mutateAsync, isLoading, data: starsResponse } = useGetStars(setStars);

	const handleRequestStars = async (data) => {
		await mutateAsync(data);
		setStudentNumber(data.student_number);
	};

	const { mutateAsync: mutateFreeOrder, isLoading: isCreatingOrder } =
		useCreateOrder(setBookItems);

	const placeOrder = async () => {
		await mutateFreeOrder({
			street_address: "",
			city: "",
			province: "",
			country: "",
			postcode: "",
			payment_method: "Stars Redemption",
			payment_type: "Purchase",
			subtotal: 0,
			grand_charges: 0,
			grand_total: 0,
			grand_total_zwl: 0,
			reference:
				moment().format("YYYYMMDDHHmmss") +
				"-" +
				moment().subtract(1, "minutes").format("YYYYMMDDHHmmss"),
			status: "Completed",
			student_number: studentNumber,
			points_used: credits,
			books: books,
		});

		setTotalPaid(0);
		setShowSuccess(true);
		// setSubVisible(false);
		// setModalVisible(true);
	};

	return (
		<div>
			<div className="font-semibold text-akgreener py-2 border-b-2 border-akgreener mb-2">
				<button
					type="button"
					onClick={closePaymentOptions}
					className="flex items-center gap-1 py-1 px-3 rounded-full bg-gray-200 hover:bg-akgreenest hover:text-white "
				>
					<BsArrowLeft size={30} />
					<span>Back</span>
				</button>
			</div>

			<h2 className="font-cera-black mb-4">Get the book by redeeming stars</h2>

			{(function () {
				if (!isLoading && Number(stars) < Number(credits) && starsResponse) {
					return (
						<div className="flex justify-center items-center">
							<p>
								Unfortunately you have only{" "}
								<span className="font-bold text-gray-600">{stars} stars</span>{" "}
								which is not redeemable to complete this transaction. You have
								to proceed with other payment methods.
							</p>
						</div>
					);
				}

				if (!isLoading && Number(stars) >= Number(credits)) {
					return (
						<div>
							<p>
								You have enough stars to proceed with the transaction. Note that
								your "stars" balance on Akello Smart Learning will be reduced by{" "}
								<span className="font-bold text-gray-600">{credits} stars</span>
								. Press the button below to proceed.
							</p>

							<Button
								type="button"
								className="mt-2 py-2 w-full flex justify-center items-center gap-3"
								onClick={() => placeOrder()}
							>
								{isCreatingOrder ? (
									<>
										...Processing
										<FcProcess
											size={30}
											className="animate-spin bg-gray-100 rounded-full"
										/>
									</>
								) : (
									"Proceed"
								)}
								{/* Proceed */}
							</Button>
						</div>
					);
				}
				return (
					<Formik
						initialValues={initialValues}
						validationSchema={validate}
						onSubmit={(data) => handleRequestStars(data)}
					>
						{() => {
							return (
								<Form>
									<p className="mb-4 text-sm p-4 rounded-md bg-gray-300">
										This payment of{" "}
										<span className="font-bold text-gray-600">
											{activeCurrencies.USD.label}${subtotal}
										</span>{" "}
										is equivalent to{" "}
										<span className="font-bold text-gray-600">
											{credits} stars
										</span>
										. As a student of{" "}
										<a
											href="https://smartlearning.akello.co/"
											className="text-blue-700"
											target="_blank"
											rel="noreferrer"
										>
											Akello Smart Learning
										</a>
										, you can redeem your stars, if you have any, by entering
										these security checks as set in your Akello Smart Learning
										account.
									</p>

									<TextField
										name="student_number"
										placeholder="Enter student number"
										label="Student Number"
									/>

									<TextField
										name="security_answer"
										placeholder="Enter security answer"
										label="Security answer"
									/>

									<Button type="submit" className="mt-2 py-2 w-full">
										{isLoading ? "...Requesting" : "Request stars"}
									</Button>
								</Form>
							);
						}}
					</Formik>
				);
			})()}

			<PaymentSuccessMessage
				visible={showSuccess}
				setVisible={setShowSuccess}
				paymentMethod={paymentMethod}
				totalPaid={totalPaid}
				bookCount={bookQuantity}
				bookItems={bookItems}
				// currencyRate={rate}
			/>
		</div>
	);
};

export default PayUsingStars;
