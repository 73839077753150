import useMoveBookToTrash from "api/BookResource/useMoveBookToTrash";
import { ConfirmPopup } from "primereact/confirmpopup";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiX } from "react-icons/fi";

const DeleteBook = ({ elementId, bookId }) => {
	const [visible, setVisible] = React.useState(false);

	const { mutateAsync, isLoading: bookIsDeleting } = useMoveBookToTrash(bookId);

	const accept = async () => {
		await mutateAsync();
	};

	return (
		<div>
			<button
				type="button"
				className="flex items-center pt-2 px-2 bg-gradient-to-b from-gray-300 to-white justify-center rounded-t-md"
				onClick={() => setVisible(true)}
				id={elementId}
			>
				{bookIsDeleting ? (
					<AiOutlineLoading3Quarters size={20} className="animate-spin" />
				) : (
					<FiX size={20} />
				)}
			</button>

			<ConfirmPopup
				target={document.getElementById(elementId)}
				className="font-sans text-sm"
				visible={visible}
				onHide={() => setVisible(false)}
				message={
					<div>
						<p>Are you sure you want to permanently delete this book?</p>
						<p className="text-red-600 font-cera-black">
							Note this action is irreversible
						</p>
					</div>
				}
				icon="pi pi-exclamation-triangle"
				accept={accept}
			/>
		</div>
	);
};

export default DeleteBook;
