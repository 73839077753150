import InitApi from "api/InitApi";
// import axios from "axios";
import ServerErrors from "components/SeverErrors";
// import AccountRegistration from "emailTemplates/AccountRegistration";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { deleteEmptyObjects } from "utils";
import toast from "react-hot-toast";
import useOverlayStore from "store/overlayStore";
import useTokenStore from "store/tokenStore";

const useAddUser = () => {
	const queryClient = useQueryClient();

	const { accessToken } = useTokenStore();

	const history = useHistory();

	const { setShowCreateUser } = useOverlayStore();

	const register = async (obj) => {
		const instance = await InitApi({ accessToken }, "multipart/form-data");
		const body = deleteEmptyObjects(obj);
		const formData = new FormData();
		Object.keys(body).forEach((key) => {
			if (key === "role" || key === "permissions" || key === "subject_group") {
				formData.append(key, JSON.stringify(body[key]));
			} else formData.append(key, body[key]);
		});

		const response = await instance.post("/users", formData);
		return { ...response, plainPassword: obj.password };
	};

	return useMutation(register, {
		onSuccess: async () => {
			queryClient.invalidateQueries("users");
			queryClient.invalidateQueries("ba-statistics");
			setShowCreateUser(false);
			toast.success("User created successfully");
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data?.message}
					errors={error?.response?.data?.data}
				/>
			);

			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}
		},
	});
};

export default useAddUser;
