export const definitions = [
	"Akello Digital Library Platform: An online platform offered by Akello that provides digital library services to its customers.",
	"Customers: Individuals or organizations that register and use the Akello Digital Library Platform services.",
	"Content: Refers to the digital materials, such as books, articles, videos, and other media, that are made available on the Akello Digital Library Platform for customers to access and use.",
	"User: A person or a defined computer workstation that accesses and uses the Akello Digital Library Platform services upon acceptance of these terms and conditions.",
	"Registration: The process by which a customer signs up for and gains access to the Akello Digital Library Platform.",
];

export const registrations = [
	"Registration shall be done through the Customer’s mobile phone or any other means, which include but not limited to tablet/iPad, laptop/desktop computer as may be accepted by Akello.",
	"In order to qualify for Akello Digital Library Services, a Customer may be required to confirm the following:: Name, Surname, Username, Email, Phone Number, Date of Birth, Gender and Password.",
	"By completing the registration process a Customer confirms acceptance of these terms and conditions and authorizes Akello to provide them with access to the Akello Digital Library Platform services, subject to the terms and conditions set out in this agreement.",
	"The Customer acknowledges that access to read the content on the Akello Digital Library Platform is provided solely using the Akello eReader iOS or Android mobile application or the Akello Desktop eReader application provided by Akello on respective stores. The Customer shall be responsible for obtaining and maintaining the necessary hardware, software, and internet connection to use the platform services on the supported platforms.",
];

export const commencement = [
	"This Agreement shall commence on the date of registration of the Customer on the Akello Digital Library Platform and shall continue until terminated in accordance with the terms and conditions set out in this Agreement.",
	"The Customer acknowledges that the duration of this Agreement is dependent on the continued availability of the Akello Digital Library Platform service and Akello reserves the right to suspend or terminate the services at any time, subject to the terms and conditions set out in this Agreement.",
	"Akello reserves the right to modify or discontinue the Akello Digital Library Platform services at any time, without liability to the Customer, subject to providing 60 days' notice to the customer in advance via Akello the Akello website and Social Media platforms.",
];

export const payments = [
	"The Customer shall pay all fees and charges associated with the use of the Akello Digital Library Platform services in accordance with the pricing and payment packages set out on the official Akello Library website.",
	"All purchase and subscription fees shall be paid in advance, and failure to pay the required fee or charge shall result in the denial of the customer's access to the Akello Digital Library Platform billed services.",
	"The Customer acknowledges that the fees and charges for the Akello Digital Library Platform services are subject to change at Akello's sole discretion without any further notice given to the customer.",
];

export const benefitsOfDigitalLibrary = [
	"The Akello Digital Library Platform provides customers with access to a wide range of digital materials, including books, articles, videos, and other media, from leading publishers and content providers around the world.",
	"The Akello Digital Library Platform is available 24/7, providing Customers with the flexibility to access and use the platform at any time and from any location with an internet connection.",
	"The Akello Digital Library Platform is constantly updated with new digital materials, ensuring that Customers have access to the latest and most relevant content in their respective fields of study or interest.",
];

export const terminationOfTerms = [
	"This Agreement may be terminated by either party in the event of a material breach of the terms and conditions set out in this Agreement by the other party, provided that the non-breaching party provides written notice of the breach and the breaching party fails to cure the breach within thirty (30) days of receipt of such notice.",
	"Upon termination of this Agreement, the Customer shall immediately cease all use of the Akello Digital Library Platform services and shall return or destroy all materials obtained from the platform, including any copies made thereof, in accordance with the terms and conditions set out in this Agreement.",
];

export const noticePeriod = [
	"The Customer acknowledges that Akello may increase the fees or charges for the Akello Digital Library Platform services at any time without prior notice. The Customer shall be responsible for regularly reviewing the pricing and payment terms set out on the official website to ensure that they are aware of any changes to the fees or charges.",
];

export const misrepresentation = [
	"Any misrepresentation or non-disclosure of a Material Facts by the Customer may result in this agreement being cancelled without any refund of payments.",
	"Any fraudulent act used to obtain any benefit by a customer under these terms and conditions may render the agreement cancelled or void from inception and any payments made will be forfeited.",
	"The Customer acknowledges that any attempt to hack, modify, reverse engineer, or access without authorization the content files on the Akello Digital Library Platform, the source code of the platform, or the reader applications is strictly prohibited and may result in immediate termination of the Customer's access to the platform services, as well as civil and criminal liability under applicable laws.",
];

export const changesToTerms = [
	"Any changes to the Terms and conditions shall be done on the Akello Library website Terms and Conditions page and the Customer shall be deemed to have notice of such changes upon posting on the website.",
];

export const accessToDigitalLibrary = [
	"Any changes to the Terms and Conditions governing access to Akello Digital Library Platform must be done at any Akello Service Centre with proof of identification of the Customer",
	"Such changes are limited to: Change of mobile number; of any personal details of the Customer",
	"There can be no change in the identity of the customer other than official changes in name in accordance to the Zimbabwean registration laws.",
	"Once access is denied/cancelled customer will have to make a fresh application for reinstatement.",
	"The Customer acknowledges that the Akello Digital Library Platform contains proprietary and confidential information that is protected by intellectual property laws and agrees to use such information only in accordance with the terms and conditions set out in this Agreement.",
];

export const serviceCategories = [
	"The The following link will give you access to Akello Services: https://library.akello.co",
	"Please note that Akello is the sole provider of the Akello Digital Library Services under these terms and Conditions, however in the event that Akello cannot carry out the services, it will arrange another service provider at no additional cost to the customer.",
];

export const serviceDenial = [
	"In the event of any service request denial by Akello, the decision by Akello shall be reviewed by an Independent Party agreed between the parties. The Independent Party’s view shall not be binding on Akello, but may serve as a basis for a reappraisal of the decision service denial.",
	"In the event of the Customer not agreeing with the reappraisal, the Customer shall notify Akello in writing within thirty (30) days.",
	"Thereafter the matter shall be referred to arbitration by the Parties in terms of the relevant legislation in Zimbabwe, within a period of 60 (sixty) days.",
];

export const communications = [
	"Akello is entitled to address any written communication in the manner it deems most expedient by SMS and through other means such as the Akello Social Media platforms, Akello website (www.akello.co) and/or e-mail where applicable.",
];

export const limitOfIndemnity = [
	"Akello will provide Digital Library services only, but subject to the Exclusions and conditions contained herein.",
	"If Akello claims that the service is not due by virtue of the Exclusions or non-compliance with any of the terms and conditions stated herein, onus shall be on the Customer to prove the contrary.",
	"Akello shall not be held responsible for failure to provide its services, or for delays caused by strikes or conditions beyond its control, including but not limited to adverse factors such as natural disasters and where local laws or regulatory agencies prohibit the rendering of its services.",
	"The Digital Library services shall only be utilized by the Customer on the agreed basis that Akello nor any of its shareholders, employees, servants or associates shall be liable for any losses, damages, claims, or costs howsoever the same shall arise as a result of negligence of any nature on the part of Akello,  its shareholders, employees, servants or agents.",
];

export const wholeAgreement = [
	"These Terms and Conditions, shall constitute the entire agreement between Akello and the Customer.",
	"No contrary representations or agreement to amend the Terms and Conditions shall be of any force or effect unless reduced to writing and signed by someone specifically authorized thereto in writing by the Parties.",
];
