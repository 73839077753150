import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import React from "react";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import useTokenStore from "store/tokenStore";

const useActivatePublisher = (publisherId) => {
	const { accessToken } = useTokenStore();
	const queryClient = useQueryClient();

	const activate = async () => {
		const instance = await InitApi({ accessToken });
		const response = await instance.put(`publishers/activate/${publisherId}`);
		return response?.data;
	};

	return useMutation(activate, {
		onSuccess: async (data) => {
			queryClient.invalidateQueries("publishers");
			toast.success(data.message);
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);
		},
	});
};

export default useActivatePublisher;
