import React from "react";
import useAuthStore from "store/authStore";
import { useLocation } from "react-router-dom";

const useAllowedBookActions = ({
	isFree,
	in_library,
	is_purchased,
	sellable,
	subscribable,
	hasSubscription,
}) => {
	const { loggedIn: isLoggedIn } = useAuthStore();

	const location = useLocation();

	const intended = location.pathname;

	/**
	 * add a subscribable book to picks
	 */
	const addToPicks = React.useMemo(() => {
		let message = "";
		let action = undefined;

		if (!isLoggedIn) {
			message = "You need to login to continue with this action";
			action = { label: "Login", link: `/login?intended=${intended}` };
			return {
				possible: false,
				message,
				action,
			};
		}

		if (in_library) {
			message = "You have already added this book to picks";
			return { possible: false, message, action };
		}

		if (is_purchased) {
			message =
				"You do not need to add this book to picks because you have already purchased it";
			return { possible: false, message, action };
		}

		if (!hasSubscription) {
			if (!subscribable) {
				message = isFree
					? "Enjoy a free read! Download the Akello reader app to access the books."
					: "This book is not available through subscription and can not be added to your picks. To access it, please purchase it directly.";
				action = isFree
					? { label: "Download app", link: "/downloads" }
					: undefined;
			} else {
				message = isFree
					? "Enjoy a free read! Download the Akello reader app to access the books."
					: "You need an active subscription to add this book to picks.";
				action = isFree
					? { label: "Download app", link: "/downloads" }
					: { label: "Subscribe", link: "/subscriptions" };
			}

			return {
				possible: false,
				message,
				action,
			};
		}

		if (!subscribable && !isFree) {
			message =
				"You can't add this book to picks because it is not available for subscription";
			return { possible: false, message, action };
		}

		return { possible: true, message: undefined, action };
	}, [
		in_library,
		subscribable,
		is_purchased,
		hasSubscription,
		isLoggedIn,
		isFree,
		intended,
	]);

	/**
	 * Buy a single book item
	 */
	const purchase = React.useMemo(() => {
		let message = "";

		if (!isLoggedIn) {
			message = "You need to login to continue with this action";
			return {
				possible: false,
				message,
				action: { label: "Login", link: `/login?intended=${intended}` },
			};
		}

		if (isFree) {
			message = "You don't need to purchase this book because it is free";
			return { possible: false, message, action: undefined };
		}

		// if (is_purchased) {
		// 	message = "You have already purchased this book";
		// 	return { possible: false, message, action: undefined };
		// }

		if (!sellable) {
			message = isFree
				? "You don't need to purchase this book because it is free"
				: "This book is not available for purchase. Try adding it to picks if you have an active subscription";
			return {
				possible: false,
				message,
				action: undefined,
			};
		}

		return { possible: true, message: undefined, action: undefined };
	}, [isFree, sellable, isLoggedIn, intended]);

	/**
	 * add a book to cart
	 */
	const addToCart = React.useMemo(() => {
		let message = "";

		if (!isLoggedIn) {
			message = "You need to login to continue with this action";
			return {
				possible: false,
				message,
				action: { label: "Login", link: `/login?intended=${intended}` },
			};
		}

		if (isFree) {
			message = "You don't need to add this book to cart because it is free";
			return { possible: false, message, action: undefined };
		}

		if (!sellable) {
			message = isFree
				? "You don't need to purchase this book because it is free"
				: "This book is not available for purchase. Try adding it to picks if you have an active subscription";
			return { possible: false, message, action: undefined };
		}

		return { possible: true, message: undefined, action: undefined };
	}, [isLoggedIn, isFree, sellable, intended]);

	/**
	 * remove a book from picks
	 */
	const removeFromPicks = React.useMemo(() => {
		let message = "";
		if (!isLoggedIn) {
			message = "You need to login to continue with this action";
			return {
				possible: false,
				message,
				action: { label: "Login", link: `/login?intended=${intended}` },
			};
		}

		if (in_library) {
			if (!isFree) {
				message = "Only free books can be removed from picks";
				return { possible: false, message, action: undefined };
			} else {
				return { possible: true, message: undefined, action: undefined };
			}
		}

		return { possible: false, message: undefined, action: undefined };
	}, [in_library, isFree, isLoggedIn, intended]);

	return {
		addToPicks,
		purchase,
		addToCart,
		removeFromPicks,
	};
};

export default useAllowedBookActions;
