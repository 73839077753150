import Admin2 from "layouts/Admin2";
import React from "react";
import CreateVoucher from "./CreateVoucher";
import useGetActiveVouchers from "api/VoucherResource/useGetActiveVouchers";
import { ButtonLoader } from "components";
import { getActiveCurrencyCodeLabel } from "utils";

const Vouchers = () => {
	const { data, isLoading } = useGetActiveVouchers();

	return (
		<Admin2>
			<div className="flex flex-col items-center mt-10">
				<div style={{ width: 700 }}>
					<CreateVoucher />
				</div>

				<div className=" mt-10 " style={{ width: 700 }}>
					{isLoading ? (
						<ButtonLoader />
					) : (
						<div className="space-y-2">
							<h3>Active vouchers available</h3>

							<div className="grid grid-cols-2 gap-4">
								{data?.map((voucher) => (
									<div
										key={voucher.id}
										className="col-span-1 py-6 px-4 bg-gray-200 rounded-lg"
									>
										<div className="flex items-center gap-2">
											<span>Voucher number:</span>
											<p className="font-semibold">{voucher.voucher_number}</p>
										</div>

										<div className="flex items-center gap-2">
											<span>Tokens at creation:</span>
											<p className="font-semibold">
												{voucher.number_of_tokens}
											</p>
											<span>
												(= {getActiveCurrencyCodeLabel(voucher.currency)}${" "}
												{voucher.number_of_tokens})
											</span>
										</div>

										<div className="flex items-center gap-2">
											<span>Remaining tokens:</span>
											<p className="font-semibold">{voucher.remaining}</p>
											<span>
												(= {getActiveCurrencyCodeLabel(voucher.currency)}${" "}
												{voucher.remaining})
											</span>
										</div>

										<div className="flex items-center gap-2">
											<span>Expiry date:</span>
											<p className="font-semibold">{voucher.expiry_date}</p>
										</div>
									</div>
								))}
							</div>
						</div>
					)}
				</div>
			</div>
		</Admin2>
	);
};

export default Vouchers;
