import InputError from "components/InputError";
import PhoneInput from "react-phone-input-2";

const PhoneTextField = ({ value, _onChange, error, errorText, label }) => {
  return (
    <div>
      <p
        style={{
          margin: 0,
          fontSize: "0.875rem",
        }}
      >
        {label || "Mobile Number*"}
      </p>
      <PhoneInput
        country={"zw"}
        value={value}
        countryCodeEditable={false}
        onChange={_onChange}
        containerStyle={{
          border: "none",
          outline: "none",
        }}
        inputStyle={{
          background: "#fff",
          borderColor: error ? "red" : "#4CAF50",
          borderRadius: 20,
          width: "100%",
          paddingTop: 21,
          paddingBottom: 21,
          paddingLeft: 70,
          color: "#4a4a4a",
        }}
        buttonStyle={{
          backgroundColor: "#fff",
          paddingLeft: 10,
          paddingRight: 10,
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
          borderColor: error ? "red" : "#4CAF50",
          borderRightColor: "",
        }}
      />

      {error && <InputError error={errorText} />}
    </div>
  );
};

export default PhoneTextField;
