import useAssignSubscription from "api/SubscriptionsResource/useAssignSubscription";
import useCheckIfUserIsSubscribed from "api/SubscriptionsResource/useCheckIfUserIsSubscribed";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { FcProcess } from "react-icons/fc";
import Moment from "react-moment";

const AssignSubscription = ({ elementId, user }) => {
  //!

  const [display, setDisplay] = useState(false);

  const { mutateAsync, isLoading } = useAssignSubscription(user.id);

  const assign = async () => {
    await mutateAsync();
    setDisplay(false);
  };

  const { data: subscription, isLoading: isLoadingSubscribed } =
    useCheckIfUserIsSubscribed();

  return (
    <div>
      <button
        className="bg-akgreener w-full text-white text-sm py-1 px-2 justify-center rounded-full "
        onClick={() => setDisplay(true)}
        id={elementId}
      >
        Subscribe
      </button>

      <Dialog
        header={
          <p className="text-center border-b-2 pb-2">
            Give subscription access to &nbsp;
            <span className="text-akgreener text-sm">
              {user.first_name} {user.last_name} ({user.email})
            </span>
          </p>
        }
        visible={display}
        onHide={() => setDisplay(false)}
        position="top"
        className="w-3/4"
      >
        {!isLoadingSubscribed && subscription?.data ? (
          <div className="flex flex-col items-center text-center">
            <p>
              You are about to give{" "}
              <span className="font-bold">
                {user.first_name} {user.last_name}
              </span>{" "}
              access to the following subscription:
            </p>

            <p className="font-bold mt-2">{subscription?.data?.name}</p>
            <p>({subscription?.data?.category})</p>
            <p>
              expiring on{" "}
              <Moment
                format="dddd, DD MMMM YYYY @ HH:mm:ss"
                className="text-akgreener"
              >
                {subscription?.data?.pivot?.expiry_date}
              </Moment>
            </p>

            <h2>Do you want to proceed?</h2>

            <div className="space-x-2 mt-2">
              <button
                type="button"
                className="py-1 px-3 rounded border border-akgreen"
                onClick={() => setDisplay(false)}
              >
                No
              </button>
              <button
                type="button"
                className="py-1 px-3 rounded bg-akgreen text-white"
                onClick={() => assign()}
              >
                {isLoading ? (
                  <FcProcess
                    size={20}
                    className="animate-spin bg-gray-200 rounded-full inline"
                  />
                ) : (
                  "Yes"
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <p>
              Unfortunately you do not have active subscription at the moment
            </p>
          </div>
        )}
      </Dialog>
    </div>
  );

  //!
};

export default AssignSubscription;
