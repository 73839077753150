import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects, fetchConfig } from "utils";

const useFetchPublisherBookPerformance = (queryParams, userId = null) => {
	const { accessToken } = useTokenStore();

	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const params = queryParams ? deleteEmptyObjects(queryParams) : null;
		const url = userId
			? `publisher-book-performance/${userId}`
			: `publisher-book-performance`;
		const res = await instance.get(url, {
			params: { ...params },
		});
		return res.data;
	};

	return useQuery(
		["publisher_book", "performance", queryParams, userId],
		() => fetch(),
		{
			...fetchConfig,
		}
	);
};

export default useFetchPublisherBookPerformance;
