import InitApi from "api/InitApi";
import axios from "axios";
import ServerErrors from "components/SeverErrors";
import AccountRegistration from "emailTemplates/AccountRegistration";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useAddInstitutionUser = (type = "institution") => {
	const queryClient = useQueryClient();

	const { accessToken } = useTokenStore();

	const history = useHistory();

	const save = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);

		const url =
			type === "institution" ? "/users/institution" : "/users/publisher";

		const response = await instance.post(url, body);
		return { ...response, plainPassword: obj.password };
	};

	return useMutation(save, {
		onSuccess: async (body) => {
			queryClient.invalidateQueries("users");
			await axios.post(
				`${process.env.REACT_APP_QUIZ_BASE_URL}/library/send-email`,
				{
					email: body?.data?.data?.email,
					subject: "Akello Account Registration",
					html: AccountRegistration({
						name: body?.data?.data?.first_name,
						email: body?.data?.data?.email,
						password: body?.plainPassword,
					}),
				}
			);
			toast.success(body?.data?.message);
		},
		onError: async (error) => {
			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}
			toast.error(
				<ServerErrors
					errorMessage={
						error?.response?.data?.message?.response ||
						error?.response?.data?.message
					}
					errors={error?.response?.data?.data}
				/>
			);
		},
	});
};

export default useAddInstitutionUser;
