import useFetchConfigs from "api/ConfigResource/useFetchConfigs";
import { ButtonLoader } from "components";
import { Card } from "primereact/card";
import { Sidebar } from "primereact/sidebar";
import React, { useState } from "react";
import { BiEdit } from "react-icons/bi";
import { GrConfigure } from "react-icons/gr";
import EditConfig from "./EditConfig";

const Configs = () => {
	const { data: configs, isLoading } = useFetchConfigs();

	const [display, setDisplay] = useState();

	const [enableEditing, setEnableEditing] = useState(false);

	return (
		<>
			<button
				type="button"
				onClick={() => setDisplay(true)}
				className="flex items-center gap-1 px-2 py-1 text-sm rounded-lg bg-gradient-to-b from-akgreener to-gray-200 shadow "
			>
				<GrConfigure size={20} />
				Configurations
			</button>

			<Sidebar
				visible={display}
				onHide={() => setDisplay(false)}
				fullScreen
				blockScroll
				icons={
					<h4 className="font-cera-black text-akgreenest text-2xl">
						Configurations
					</h4>
				}
				className="font-sans"
			>
				<hr className="mb-4" />

				{isLoading ? (
					<ButtonLoader size={100} />
				) : (
					<div className="flex justify-center gap-4 ">
						{(function () {
							if (configs && configs?.length > 0) {
								return configs?.map((config) => (
									<Card
										key={config.id}
										className="flex flex-col justify-center items-center p-4 pb-6 relative gap-8 w-full md:max-w-max border border-gray-300"
										style={{
											borderRadius: "69% 64% 32% 31% / 9% 14% 17% 68% ",
											minWidth: 180,
										}}
									>
										<h3 className="text-center">{config.label}</h3>
										<p className="text-center border border-akgreen rounded-full text-sm py-1 px-2">
											{config.name}
										</p>

										<div className="mt-4 flex justify-center items-center">
											{enableEditing ? (
												<EditConfig
													value={config.value}
													name={config.name}
													setEnableEditing={setEnableEditing}
												/>
											) : (
												<span className="text-xl font-bold under">
													{config.value}
												</span>
											)}
										</div>

										<span
											onClick={() => setEnableEditing(true)}
											className="cursor-pointer h-12 w-12 rounded-full bg-akgreener text-white flex justify-center items-center absolute -bottom-2 right-2"
										>
											<BiEdit size={30} />
										</span>
									</Card>
								));
							}
							return <p>No configurations found</p>;
						})()}
					</div>
				)}
			</Sidebar>
		</>
	);
};

export default Configs;
