import React, { useState } from "react";
import useMenuItems from "./useMenuItems";
import { AiOutlineLoading3Quarters, AiOutlineMenu } from "react-icons/ai";
import { Sidebar } from "primereact/sidebar";
import { NavLink } from "react-router-dom";
import { BiArrowBack, BiPowerOff, BiUser } from "react-icons/bi";
import useLogout from "api/UserResource/useLogout";

const MobileMenu = () => {
	const [visibleLeft, setVisibleLeft] = useState(false);
	const menuItems = useMenuItems();
	const { mutateAsync, isLoading } = useLogout();
	const handleLogout = async () => {
		await mutateAsync();
	};
	return (
		<>
			<button type="button" onClick={() => setVisibleLeft(true)}>
				<AiOutlineMenu size={30} />
			</button>
			<Sidebar
				visible={visibleLeft}
				position="left"
				onHide={() => setVisibleLeft(false)}
			>
				<div className="flex flex-col">
					<div className="space-y-4">
						{menuItems.map((item, index) => (
							<NavLink
								key={index}
								to={item.to}
								className="text-akgreenest flex items-center gap-2 text-base"
								activeClassName="text-akgreener font-cera-black"
							>
								{item.icon}
								{item.label}
							</NavLink>
						))}
					</div>

					<div className="flex-1 flex flex-col gap-2 justify-end py-6 mt-6 border-t border-gray-300 ">
						<NavLink to={"/books"} className="flex gap-1 items-center">
							<BiArrowBack size={20} /> Home
						</NavLink>
						<NavLink to="/my-account" className="flex gap-1 items-center">
							<BiUser size={20} />
							My Profile
						</NavLink>

						<button
							type="button"
							className="px-2 py-2 rounded-full bg-akgreener shadow-md flex gap-1 items-center text-white max-w-max"
							onClick={() => handleLogout()}
						>
							{isLoading ? (
								<AiOutlineLoading3Quarters
									size={20}
									className="animate-spin text-white"
								/>
							) : (
								<BiPowerOff size={20} className={`text-white`} />
							)}
							Logout
						</button>
					</div>
				</div>
			</Sidebar>
		</>
	);
};

export default MobileMenu;
