import useBooks from "api/BookResource/useBooks";
import { CustomPaginator } from "components";
import Admin2 from "layouts/Admin2";
import React, { useState } from "react";
import useAuthStore from "store/authStore";
import useBooksFilterStore from "store/booksFilterStore";
import BookCard from "./BookCard";
import { useFormik } from "formik";
import BookFilter from "./BookFilter";
import useBulkActionBooks from "api/BookResource/useBulkActionBooks";
import { BsCheckAll, BsCloudDownload, BsX } from "react-icons/bs";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useExportBooks from "api/BookResource/useExportBooks";
import { FaCircleNotch } from "react-icons/fa";
import AdminPageLoader from "components/AdminPageLoader";
import useGenres from "api/GenreResource/useGenres";
import useFetchCategories from "api/CategoryResource/useFetchCategories";
import useFetchPublishers from "api/PublisherResource/useFetchPublishers";
import { Role } from "utils/roles";

const AllBooks2 = () => {
	const [firstPage, setFirstPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(8);
	const hasRoles = useAuthStore((state) => state.hasRoles);

	const { data: genres, isLoading: isLoadingGenres } = useGenres("yes");
	const { data: categories, isLoading: isLoadingCategories } =
		useFetchCategories("yes");

	const { data: publishers, isLoading: isLoadingPublishers } =
		useFetchPublishers();

	const { bookFilters, page, setPage } = useBooksFilterStore();
	const { data: books, isLoading: isBooksLoading } = useBooks({
		page: page,
		rows_per_page: rowsPerPage,
		from_backend: true,
		...bookFilters,
	});

	const [showGenerateBookCollection, setshowGenerateBookCollection] =
		useState(true);

	const [showDownloadBookCollection, setshowDownloadBookCollection] =
		useState(false);

	const { mutateAsync: mutateExport, isLoading: isExporting } =
		useExportBooks();

	const handleGenerateBookCollection = async () => {
		await mutateExport();
		setshowGenerateBookCollection(false);
		setshowDownloadBookCollection(true);
	};

	const [selectedBooks, setSelectedBooks] = useState([]);

	const { mutateAsync: mutateBulkAction, isLoading: isActioning } =
		useBulkActionBooks();

	const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
		initialValues: {
			books: [...selectedBooks],
			action: "",
		},

		onSubmit: async (data) => {
			switch (data.action) {
				case "Activate":
					await mutateBulkAction({
						books: [...data.books],
						action: "activate",
					});
					break;

				case "Deactivate":
					await mutateBulkAction({
						books: [...data.books],
						action: "deactivate",
					});
					break;

				case "Set as free":
					await mutateBulkAction({
						books: [...data.books],
						action: "setAsFree",
					});
					break;

				case "Set as premium":
					await mutateBulkAction({
						books: [...data.books],
						action: "setAsPremium",
					});
					break;

				case "Delete":
					await mutateBulkAction({
						books: [...data.books],
						action: "deletePermanently",
					});
					break;

				default:
					break;
			}
		},
		enableReinitialize: true,
	});

	if (
		isBooksLoading ||
		isLoadingCategories ||
		isLoadingGenres ||
		isLoadingPublishers
	) {
		return <AdminPageLoader />;
	}

	return (
		<Admin2>
			<div className="mb-4 space-y-4 ">
				<div className="flex justify-start mt-4">
					<BookFilter
						genres={genres}
						categories={categories}
						publishers={publishers}
					/>
				</div>

				<form
					onSubmit={handleSubmit}
					className="flex flex-col items-center pt-4 relative">
					{hasRoles([Role.SUPER_ADMIN, Role.ADMIN, Role.SENIOR_PUBLISHER]) && (
						<div className="flex items-center gap-4 mb-4">
							{/* <BookFilters /> */}

							{showGenerateBookCollection && (
								<button
									type="button"
									className="py-1 px-3 gap-2 border border-akgreen rounded-md flex items-center justify-center"
									onClick={handleGenerateBookCollection}>
									Generate books meta data sheet
									{isExporting ? (
										<FaCircleNotch size={15} className=" animate-spin " />
									) : (
										<BsCloudDownload size={15} />
									)}
								</button>
							)}

							{showDownloadBookCollection && (
								<a
									href={`${process.env.REACT_APP_FILES_BASE_URL}/downloads/books_collection.xlsx`}
									onClick={() => {
										setshowDownloadBookCollection(false);
										setshowGenerateBookCollection(true);
									}}
									target="_blank"
									rel="noreferrer"
									className="py-1 px-3 gap-2 border border-akgreen rounded-md flex items-center justify-center">
									Download books collection
								</a>
							)}

							<label
								htmlFor="selectActions"
								className="flex items-center gap-1">
								<input
									type="checkbox"
									name="select"
									className="h-8 w-8"
									hidden
									checked={
										selectedBooks?.length === books?.data?.records?.length &&
										selectedBooks?.length > 0
									}
									onChange={() =>
										setSelectedBooks((prev) =>
											prev?.length <= 0
												? [...books?.data?.records?.map((book) => book.id)]
												: []
										)
									}
									id="selectActions"
								/>
								<span
									className={`flex item-center gap-1 px-1 py-1 ${
										selectedBooks.length <= 0 ? "border" : "border-2"
									} border-akgreener rounded cursor-pointer`}>
									{selectedBooks.length <= 0 ? (
										<>
											Select All <BsCheckAll size={20} />
										</>
									) : (
										<>
											Deselect All <BsX size={20} />
										</>
									)}
								</span>
							</label>

							{values.books.length > 0 && (
								<>
									<p className="font-cera-black">With selected</p>

									<select
										name="action"
										className="px-2 py-1 border border-gray-200 rounded-lg shadow"
										value={values.action}
										onChange={handleChange}>
										<option label="-- Select action --"></option>
										{hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) && (
											<option value="Delete">Permanently delete</option>
										)}
										<option value="Set as free">Set as free</option>
										<option value="Set as premium">Set as premium</option>
										<option value="Activate">Activate</option>
										<option value="Deactivate">Deactivate</option>
									</select>

									{values.action && (
										<button
											type="submit"
											className="rounded-lg px-2 py-1 bg-akgreen text-white flex gap-2 items-center justify-center">
											Execute{" "}
											{isActioning && (
												<AiOutlineLoading3Quarters
													size={20}
													className="animate-spin"
												/>
											)}
										</button>
									)}
								</>
							)}
						</div>
					)}

					<div className="grid grid-cols-2 gap-4 mt-6">
						{books?.data?.records?.map((book) => (
							<label
								htmlFor={`bookitem${book.id}`}
								key={book.id}
								className="max-w-max">
								{(function () {
									if (
										hasRoles([
											Role.SUPER_ADMIN,
											Role.ADMIN,
											Role.SENIOR_PUBLISHER,
										])
									) {
										return values.books.includes(book.id) ? (
											<input
												type="checkbox"
												checked
												name="books"
												value={Number(book.id)}
												className="w-6 h-6"
												onChange={() =>
													setFieldValue(
														"books",
														values.books.filter((id) => id !== book.id)
													)
												}
											/>
										) : (
											<input
												type="checkbox"
												name="books"
												value={Number(book.id)}
												onChange={(e) => {
													handleChange(e);
												}}
												id={`bookitem${book.id}`}
												className="w-6 h-6 border-2 border-akgreen"
											/>
										);
									}
								})()}

								<BookCard
									bookId={book?.id}
									title={book?.title}
									author={book?.author}
									price={book?.price}
									zwlPrice={book?.zwl_price}
									isbn={book?.isbn}
									description={book?.description}
									sellable={book?.sellable}
									onSubscription={book?.subscription}
									isActive={book?.is_active}
									isFree={book?.isFree}
									credits={book?.credits}
									bookCover={book?.book_cover?.url}
									bookUrl={book?.book_file?.url}
									category={book?.category}
									genres={book?.genres[0]}
									hasFile={book?.has_book_file}
									publisher={book?.publisher}
									isAdmin={hasRoles([Role.SUPER_ADMIN, Role.ADMIN])}
									className="items-stretch mb-4 -mt-6"
								/>
							</label>
						))}
					</div>
				</form>

				<div className="flex justify-center rounded-full mt-4">
					<CustomPaginator
						firstPage={firstPage}
						rowsPerPage={rowsPerPage}
						setFirstPage={setFirstPage}
						setRowsPerPage={setRowsPerPage}
						totalRecords={books?.data?.total_records}
						setPage={setPage}
					/>
				</div>

				<h4 className="my-4 flex justify-center items-center gap-1">
					<span className="text-3xl">{books?.data?.total_records}</span> books
					returned
				</h4>
			</div>
		</Admin2>
	);
};

export default AllBooks2;
