import { DesktopHeader } from "components";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import React from "react";

const PrivacyPolicy = () => {
	return (
		<Container className="min-h-screen flex flex-col w-full ">
			<DesktopHeader shadow />

			<PaddingX>
				<div
					className="mt-8 mx-auto space-y-2"
					style={{
						maxWidth: 700,
					}}
				>
					<h2 className="text-xl font-bold">Akello Privacy Policy</h2>

					<p>
						This Privacy Notice describes how Akello, and its affiliates
						(collectively "Akello") collect and process your personal
						information through Akello websites, products, online and physical
						stores, and applications that reference this Privacy Notice.{" "}
						<span className="font-bold">
							By using Akello platforms, you are consenting to the practices
							described in this Privacy Notice.
						</span>
					</p>

					<h1 className="text-lg font-bold">
						What personal Information about Customers does Akello collect?
					</h1>
					<p>
						We collect your personal information in order to provide the best
						and continually improve our products and services; and provide our
						customers with exceptional user experiences.
					</p>
					<p>Here are the types of personal information we collect:</p>
					<ol className="list-outside list-decimal ml-4">
						<li className="list-item">
							<span className="font-bold">Information You Give Us:</span> We
							receive and store any information you provide in relation to
							Akello products. This is information you provide for instance when
							you, update your account, search or explore on any of our
							platforms, add or remove items from your cart, or communicate with
							us by email, live chat or otherwise.
						</li>
						<li className="list-item">
							You can choose not to provide certain information, but then you
							might not be able to take advantage of some Akello offerings.
						</li>
						<li className="list-item">
							<span className="font-bold">Automatic Information:</span> We
							automatically collect and store certain types of information about
							your use of Akello platforms, including information about your
							interaction with products, content, and features. Like many
							websites, we use "cookies" and other unique identifiers, and we
							obtain certain types of information when your web browser or
							device accesses Akello content. Physical stores where our products
							are distributed may use cameras, computer vision, sensors, and
							other technology to gather information about your activity in the
							store, such as the products and services you interact with.
						</li>
					</ol>

					<h1 className="text-lg font-bold">
						For What Purposes Does Akello Use Your Personal Information?
					</h1>
					<p>
						We use your personal information to operate, provide, develop, and
						improve the products and services that we offer our customers. These
						purposes include:
					</p>
					<ol className="list-outside list-decimal ml-4">
						<li className="list-item">
							<span className="font-bold">
								Purchase and delivery of products and services:
							</span>
							We use your personal information to take and manage orders, render
							digital products and services, process payments, and communicate
							with you about orders, products and services, and promotional
							offers.
						</li>
						<li className="list-item">
							<span className="font-bold">
								Provide, troubleshoot, and improve Akello platforms:
							</span>
							We use your personal information to provide functionality, analyse
							performance, fix errors, and improve the usability and
							effectiveness of Akello platforms.
						</li>
						<li className="list-item">
							<span className="font-bold">
								Recommendations and personalization:
							</span>
							We use your personal information to recommend features, products,
							and services that might be of interest to you, identify your
							preferences, and personalize your experience on Akello platforms.
						</li>
						<li className="list-item">
							<span className="font-bold">Comply with legal obligations:</span>
							In certain cases, we collect and use your personal information to
							comply with laws. For instance, we collect from Educational
							Institutions information regarding place and date of establishment
							and contact person information for identity verification and other
							purposes.
						</li>
						<li>
							<span className="font-bold">Communicate with you:</span>
							We use your personal information to communicate with you in
							relation to Akello products via different channels (e.g., by
							phone, email, chat).
						</li>
						<li>
							<span className="font-bold">Advertising:</span>
							We use your personal information to display interest-based ads for
							features, products, and services that might be of interest to you.
							We however do not use any information that personally identifies
							you to display interest-based ads.
						</li>
						<li>
							<span className="font-bold">
								Fraud Prevention and Credit Risks:
							</span>
							 We use personal information to prevent and detect fraud and abuse
							in order to protect the security of our customers, Akello, and
							others.
						</li>
					</ol>

					<h1 className="text-lg font-bold">
						What About Cookies and Other Identifiers?
					</h1>
					<p>
						To enable our systems to recognize your browser or device and to
						provide and improve Akello, we use cookies and other identifiers.
					</p>

					<h1 className="text-lg font-bold">
						Does Akello Share Your Personal Information?
					</h1>
					<p>
						Information about our customers is an important part of our
						business, and we are not in the business of selling our customers'
						personal information to others. We share customers' personal
						information only as described below and with subsidiaries within the
						Econet Group under which Akello sits; that either are subject to
						this Privacy Notice or follow practices at least as protective as
						those described in this Privacy Notice.{" "}
					</p>
					<ol className="list-outside list-decimal ml-4">
						<li>
							<span className="font-bold">
								Transactions involving Third Parties:
							</span>
							We make available to you services, products, applications, or
							skills provided by third parties for use on or through Akello
							platforms. For example, you can make payments through the third
							party Ecocash or PayPal payment gateway. We may also offer
							services or sell product lines jointly with third-party
							businesses, such as discounted data bundles. You can tell when a
							third party is involved in your transactions, and we share
							customers' personal information related to those transactions with
							that third party.
						</li>
						<li>
							<span className="font-bold">Business Transfers:</span>
							As we continue to develop our business, we might sell or buy other
							businesses or services. In such transactions, customer information
							generally is one of the transferred business assets but remains
							subject to the promises made in any pre-existing Privacy Notice
							(unless, of course, the customer consents otherwise). Also, in the
							unlikely event that Akello or substantially all of its assets are
							acquired, customer information will of course be one of the
							transferred assets.
						</li>
						<li>
							<span className="font-bold">
								Protection of Akello and Others:
							</span>
							We release account and other personal information when we believe
							release is appropriate to comply with the law; enforce or apply
							our Conditions of Use and other agreements; or protect the rights,
							property, or safety of Akello, our users, or others. This includes
							exchanging information with other companies and organizations for
							fraud protection.
						</li>
					</ol>
					<p>
						Other than as set out above, you will receive notice when personal
						information about you might be shared with third parties, and you
						will have an opportunity to choose not to share the information.
					</p>

					<h1 className="text-lg font-bold">
						How Secure Is Information About Me?
					</h1>
					<p>We design our systems with your security and privacy in mind.</p>
					<ol className="list-outside list-decimal ml-4">
						<li>
							We work to protect the security of your personal information
							during transmission by using encryption protocols and software.
						</li>
						<li>
							We follow the Payment Card Industry Data Security Standard (PCI
							DSS) when handling credit card data.
						</li>
						<li>
							We maintain physical, electronic, and procedural safeguards in
							connection with the collection, storage, and disclosure of
							personal customer information. Our security procedures mean that
							we may ask to verify your identity before we disclose personal
							information to you or access to your account.
						</li>
						<li>
							It is important for you to protect against unauthorized access to
							your password and to your computers, devices, and applications. We
							recommend using unique password for your Akello accounts. Be sure
							to sign off when finished using a shared computer.
						</li>
					</ol>

					<h1 className="text-lg font-bold">What About Advertising?</h1>
					<ul className="list-outside list-decimal ml-4">
						<li>
							<span className="font-bold">
								Third Party Advertisers and Links to Other Websites:
							</span>
							Akello may include third-party advertising and links to other
							websites and apps. Third-party advertising partners may collect
							information about you when you interact with their content,
							advertising, and services.
						</li>
					</ul>

					<h1 className="text-lg font-bold">What Information Can I Access?</h1>
					<p>
						You can access your information, including your name, address,
						payment options, profile information, subscriptions, settings, and
						purchase history on the Akello platforms.
					</p>

					<h1 className="text-lg font-bold">What Choices Do I Have?</h1>
					<p>
						If you have any questions as to how we collect and use your personal
						information, please contact support team info@akello.co As described
						above, you can choose not to provide certain information, but then
						you might not be able to take advantage of all the Akello services
						and features.
					</p>
					<ul className="list-outside list-decimal ml-4">
						<li>
							If you do not want to receive email or other communications from
							us, please adjust your preferences.
						</li>
						<li>
							If you do not want to receive in-app notifications from us, please
							adjust your notification settings in the app or device.
						</li>
						<li>
							The Help feature on most browsers and devices will tell you how to
							prevent your browser or device from accepting new cookies or other
							identifiers, how to have the browser notify you when you receive a
							new cookie, or how to block cookies altogether. Because cookies
							and identifiers allow you to take advantage of some essential
							features, we recommend that you leave them turned on.
						</li>
						<li>
							If you are a publisher or author, you can add or update the
							information you have provided in your Akello library publishers
							portal.
						</li>
					</ul>

					<h1 className="text-lg font-bold">
						Are Children Allowed to Use Akello?
					</h1>
					<p>
						Akello offers digital products and services for children, likely to
						be purchased by adults. If you are under 13, you may use Akello only
						with the involvement of a parent or guardian. We do not knowingly
						collect personal information from children under the age of 13
						without the consent of the child's parent, guardian or teacher
						through the school.
					</p>

					<h1 className="text-lg font-bold">
						Conditions of Use, Notices, and Revisions
					</h1>
					<p>
						If you choose to use Akello, your use and any dispute over privacy
						is subject to this Notice and our Conditions of Use, including
						limitations on damages, resolution of disputes. If you have any
						concern about privacy at Akello, please contact us with a thorough
						description, and we will try to resolve it. Our business changes
						constantly, and our Privacy Notice will change also. You can check
						our websites frequently to see recent changes. Unless stated
						otherwise, our current Privacy Notice applies to all information
						that we have about you and your account. We stand behind the
						promises we make, however, and will never materially change our
						policies and practices to make them less protective of customer
						information collected in the past without the consent of affected
						customers.
					</p>
				</div>
			</PaddingX>
		</Container>
	);
};

export default PrivacyPolicy;
