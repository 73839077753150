import useFetchSubscriptions from "api/SubscriptionsResource/useFetchSubscriptions";
import { Card } from "primereact/card";
import { Sidebar } from "primereact/sidebar";
import { useState } from "react";
import { MdAdsClick } from "react-icons/md";
import EditSubscription from "./EditSubscription";
import { activeCurrencies } from "utils";

const Subscriptions = () => {
	const { data: subs } = useFetchSubscriptions();

	const [display, setDisplay] = useState(false);

	const categories = [
		...new Set(subs?.data?.map((subscription) => subscription.category)),
	];

	return (
		<>
			<button
				type="button"
				onClick={() => setDisplay(true)}
				className="flex items-center gap-1 px-2 py-1 text-sm rounded-lg bg-gradient-to-b from-akgreener to-gray-200 shadow "
			>
				<MdAdsClick size={20} />
				Subscriptions
			</button>

			<Sidebar
				visible={display}
				onHide={() => setDisplay(false)}
				icons={
					<h4 className="font-cera-black text-akgreenest text-2xl">
						Subscriptions
					</h4>
				}
				fullScreen
				className="font-sans"
				blockScroll
			>
				<hr className="mb-4" />
				{categories.map((category, index) => (
					<div key={index}>
						<h3 className="font-cera-black uppercase text-center">
							{category} plans
						</h3>
						<div className="flex justify-center gap-4 mb-4 mt-2">
							{subs?.data
								?.filter((sub) => sub.category === category)
								?.map((subscription) => (
									<Card
										className="bg-gradient-to-br from-akgreener via-akgreen to-gray-200 flex flex-col justify-center items-center text-white p-4 gap-8 w-full md:max-w-max"
										key={subscription?.id}
										style={{
											borderRadius: "69% 64% 32% 31% / 9% 14% 17% 68% ",
											minWidth: 180,
											maxWidth: 300,
										}}
									>
										<h3 className="mb-4">{subscription?.name}</h3>
										<p>
											Duration: {subscription?.duration}{" "}
											{subscription?.time_unit}
										</p>
										<p>
											Price: {activeCurrencies.USD.label}${" "}
											<span className="font-bold"></span>
											{subscription?.cost}
										</p>
										<p>Equivalent stars: {subscription?.credits}</p>

										<p className="mt-6">
											Maximum of {subscription?.max_number_of_books} books
											available for adding to picks
										</p>

										<div className="flex justify-center gap-2 mt-4">
											<EditSubscription
												id={subscription?.id}
												name={subscription?.name}
												category={subscription?.category}
												cost={subscription?.cost}
												duration={subscription?.duration}
												credits={subscription?.credits}
												time_unit={subscription?.time_unit}
												max_number_of_books={subscription?.max_number_of_books}
											/>
										</div>
									</Card>
								))}
						</div>
					</div>
				))}
			</Sidebar>
		</>
	);
};

export default Subscriptions;
