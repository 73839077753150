import React from "react";
import { useUrlQuery } from "hooks/useUrlQuery";
import PublisherAggregateBooks from "admin/PublisherDashboard2/PublisherAggregateBooks";
import Admin2 from "layouts/Admin2";
import PublisherRevenueReport from "admin/PublisherDashboard2/PublisherRevenueReport";
import useProfile from "api/UserResource/useProfile";
import PublisherBookPerformance from "./PublisherBookPerformance";

const Profile = ({ firstName, lastName, email, mobileNumber }) => {
	return (
		<div className="grid grid-cols-4 rounded shadow-md p-3 ">
			<div className="flex flex-col gap-2 items-center">
				<p className="uppercase text-base">First name</p>
				<p>{firstName || "-"}</p>
			</div>

			<div className="flex flex-col gap-2 items-center">
				<p className="uppercase text-base">Last name</p>
				<p>{lastName || "-"}</p>
			</div>

			<div className="flex flex-col gap-2 items-center">
				<p className="uppercase text-base">Email address</p>
				<p>{email || "-"}</p>
			</div>

			<div className="flex flex-col gap-2 items-center">
				<p className="uppercase text-base">Mobile number</p>
				<p>{mobileNumber || "-"}</p>
			</div>
		</div>
	);
};

const PublisherDashboard2 = () => {
	const userId = useUrlQuery()?.get("userId") || null;

	const { data: profile } = useProfile(userId);

	return (
		<Admin2>
			<div className="space-y-14 mt-4 " style={{ minWidth: "80%" }}>
				<Profile
					firstName={profile?.data?.first_name}
					lastName={profile?.data?.last_name}
					email={profile?.data?.email}
					mobileNumber={profile?.data?.mobile_number}
				/>
				<PublisherRevenueReport userId={userId} />

				<PublisherAggregateBooks userId={userId} />

				<PublisherBookPerformance userId={userId} />
			</div>
		</Admin2>
	);
};

export default PublisherDashboard2;
