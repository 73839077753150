import React, { useState } from "react";
import EditRole from "./EditRole";

const Roles = ({ roles }) => {
	const [searchRole, setsearchRole] = useState("");

	return (
		<div className="space-y-4">
			<h3>User Roles</h3>

			<input
				type="text"
				placeholder="Search role..."
				value={searchRole}
				onChange={(e) => setsearchRole(e.target.value)}
				className="border border-gray-400 w-full p-2 text-center"
			/>

			<div className="flex flex-col gap-2">
				{roles
					?.filter((role) => {
						if (!searchRole) return true;
						return role.name
							.toLocaleLowerCase()
							.includes(searchRole.toLocaleLowerCase());
					})
					?.map((role) => (
						<div
							key={role.id}
							className="shadow-sm bg-gray-100 border border-gray-200 rounded px-3 py-3 flex justify-between items-center gap-2"
						>
							<span>{role?.name}</span>

							<div className="flex gap-2">
								<EditRole
									roleId={role.id}
									roleName={role.name}
									permissionNames={role?.permissions?.map(
										(permission) => permission.name
									)}
								/>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default Roles;
