export const Role = Object.freeze({
	SUPER_ADMIN: "Super Administrator",
	ADMIN: "Administrator",
	SENIOR_PUBLISHER: "Senior Publisher",
	PUBLISHER: "Publisher",
	INSTITUTION: "Institution",
	EDUCATOR: "Educator",
	STUDENT: "Student",
	INDIVIDUAL: "Individual",
	BA: "Brand Ambassador",
});
