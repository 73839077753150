import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import useAuthStore from "store/authStore";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useRegister = () => {
	const history = useHistory();

	const {
		storeUser,
		storeUserRoles,
		storeUserPermissions,
		storeUserObjectPermissions,
		storeRefreshToken,
		setSubscriptionStatus,
		setLogInStatusToTrue,
	} = useAuthStore();

	const { accessToken, storeAccessToken } = useTokenStore();

	const register = async (obj) => {
		const instance = await InitApi({ accessToken }, "multipart/form-data");

		const body = deleteEmptyObjects(obj);

		const formData = new FormData();
		Object.keys(body).forEach((key) => {
			formData.append(key, body[key]);
		});
		const response = await instance.post("/auth/register", formData);
		return { ...response, plainPassword: body?.password };
	};

	return useMutation(register, {
		onSuccess: async (body) => {
			storeUser(body?.data?.data?.user);
			storeAccessToken(body?.data?.data?.access_token);
			storeRefreshToken(body?.data?.data?.refresh_token);
			storeUserRoles(body?.data?.data?.user?.role_name);
			storeUserPermissions(body?.data?.data?.user?.permission_names);
			storeUserObjectPermissions(body?.data?.user?.object_permissions);
			setLogInStatusToTrue();
			setSubscriptionStatus(
				body?.data?.data?.user?.subscriptions?.length > 0 ? 1 : 0
			);
			history.push("/books");
		},
		onError: async ({ response }) => {
			if (response === undefined) {
				history.push("/subscriptions?new");
			} else {
				toast.error(
					<ServerErrors
						errorMessage={"Something went wrong"}
						errors={response?.data?.data}
					/>
				);
			}
		},
	});
};

export default useRegister;
