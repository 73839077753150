import useImportBooks from "api/BookResource/useImportBooks";
import { Button, ButtonLoader, TextField } from "components";
import { Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import React from "react";
import useOverlayStore from "store/overlayStore";
import { activeCurrencies } from "utils";

const UploadBooks = () => {
	const { showUploadBooks, setShowUploadBooks } = useOverlayStore();

	const initialValues = {
		csv: null,
	};

	const { mutateAsync, isLoading } = useImportBooks();
	const handleImportBooks = async (data) => {
		await mutateAsync(data);
	};

	return (
		<>
			<Dialog
				visible={showUploadBooks}
				style={{ width: "80vw" }}
				header={
					<div className="text-xl font-sans font-bold sticky top-4 z-20 bg-white mr-2">
						<p className="">Upload books metadata (Excel/CSV)</p>
					</div>
				}
				onHide={() => setShowUploadBooks(false)}
				position="top"
				draggable={false}
				contentClassName="rounded-b-xl "
				className="rounded-xl relative"
			>
				<p className="text-lg mt-4">
					Import CSV/Excel with the following headers
				</p>

				<ul className=" list-disc list-inside mt-2 mb-10">
					<li className=" list-item ">
						NB: Headers with a (*) means that the column should not have empty
						values, otherwise the import will fail
					</li>
					<li className=" list-item ">
						The <strong>Publisher Email</strong> should correspond to the Senior
						Publisher's account email. Otherwise the books you
						<br /> upload will not be associated with your Publishing House
					</li>
				</ul>

				<div className="flex justify-between items-center gap-1 divide-x divide-gray-300 mt-4 ">
					<div className="flex flex-col justify-center items-center">
						<p className="font-bold">Title *</p>
						<p>(text)</p>
					</div>

					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Author *</p>
						<p>(text)</p>
					</div>

					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">{activeCurrencies.USD.label} Price *</p>
						<p>(numeric)</p>
					</div>

					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">{activeCurrencies.ZWL.label} Price</p>
						<p>(numeric)</p>
					</div>

					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">ISBN Number</p>
						<p>(text)</p>
					</div>
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Publisher Email *</p>
						<p>(text)</p>
					</div>
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Publisher Firstname *</p>
						<p>(text)</p>
					</div>
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Publisher Lastname *</p>
						<p>(text)</p>
					</div>
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Publishing Company *</p>
						<p>(text)</p>
					</div>
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Category *</p>
						<p>(text)</p>
					</div>
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Collection *</p>
						<p>(text)</p>
					</div>
					<div className="flex flex-col justify-center items-center px-2">
						<p className="font-bold">Summary</p>
						<p>(text)</p>
					</div>
				</div>

				<p className="mt-3 underline text-lg text-red-500">
					NB: Please ignore the text in brackets when you prepare your csv/excel
					sheet
				</p>

				<Divider />

				<Formik
					initialValues={initialValues}
					// validationSchema={validationSchema}
					onSubmit={(data) => handleImportBooks(data)}
				>
					{({ setFieldValue }) => {
						return (
							<Form>
								<div className="flex justify-center mt-3">
									<TextField
										type="file"
										name="csv"
										value={null}
										onChange={(event) =>
											setFieldValue("csv", event.target.files[0])
										}
									/>
								</div>

								<div className="flex justify-center mt-3">
									{isLoading ? (
										<ButtonLoader size={40} />
									) : (
										<Button type="submit" className="ml-3 flex items-center ">
											Submit
										</Button>
									)}
								</div>
							</Form>
						);
					}}
				</Formik>
			</Dialog>
		</>
	);
};

export default UploadBooks;
