import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects, fetchConfig } from "utils";

const usePublisherPurchasedBooks = (queryParams, publisherId = "") => {
	const { accessToken } = useTokenStore();
	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const params = queryParams ? deleteEmptyObjects(queryParams) : null;

		const url =
			publisherId.length > 0
				? `books/publisher-purchased/${publisherId}`
				: `books/publisher-purchased`;

		const res = await instance.get(url, {
			params,
		});
		return res.data;
	};
	return useQuery(
		["books", "publisher_purchased", queryParams],
		() => fetch(),
		{
			...fetchConfig,
		}
	);
};

export default usePublisherPurchasedBooks;
