import AddToCart from "components/AddToCart";
import StarRating from "components/StarRating";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import useAddToReadingList from "api/BookResource/useAddToReadingList";
import useAddToSubscription from "api/BookResource/useAddToSubscription";
import useRemoveFromLibrary from "api/BookResource/useRemoveFromLibrary";
import useFetchCurrencyByCode from "api/RatesResource/useFetchCurrencyByCode";
import { motion } from "framer-motion/dist/framer-motion";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useState } from "react";
import useAuthStore from "store/authStore";
import useCurrencyStore from "store/currencyStore";
import tw from "tailwind-styled-components/dist/tailwind";
import { getActiveCurrencyCodeLabel } from "utils";

const animations = {
	initial: { opacity: 0, rotate: 2 },
	animate: { opacity: 1, rotate: 0 },
	exit: { opacity: 0 },
};

const CoverContainer = tw.div`
  bg-gradient-to-r from-gray-600 via-gray-400 to-gray-600
  flex justify-center 
`;

const BookItemColumn = ({
	title,
	coverUrl,
	author,
	publisher,
	rating,
	currencyCode,
	currencySymbol,
	price,
	credits,
	bookId,
	subscription,
	isbn,
	sellable,
	canRead,
	inLibrary,
	isPurchased,
	isFree,
	hasSubscription,
	className = "h-full",
	...rest
}) => {
	//!

	const { currency } = useCurrencyStore();

	const { data: currencyRate, isLoading: isLoadingCurrency } =
		useFetchCurrencyByCode(currency);

	const history = useHistory();

	const location = useLocation();

	const { book_id } = useParams();

	const { mutateAsync, isLoading } = useAddToSubscription();

	const handleAddToSubscription = async (e) => {
		// isAuthenticated && canRead;
		if (!isAuthenticated || !canRead) {
			setNotification(
				"Failed to add to library. Please make sure you are logged in and you have subscribed or purchased this book"
			);
			setShowErrorNotification(true);
			return;
		}
		let value = e.target.value;
		await mutateAsync({ booksToSubscribe: value });
	};

	const { mutateAsync: mutateReadingList, isLoading: isSavingReadingList } =
		useAddToReadingList(bookId);

	const handleRead = async () => {
		if (!canRead) {
			setNotification(
				"Failed to open the book. Please make sure you are logged in or you have subscribed/purchased this book"
			);
			setShowErrorNotification(true);
			return;
		}
		await mutateReadingList();
		if (!isSavingReadingList) {
			history.push(`/books/reader/${bookId}`);
		}
	};

	const {
		mutateAsync: mutateRemoveFromLibrary,
		isLoading: isRemovingFromLibrary,
	} = useRemoveFromLibrary(bookId);

	const removeFromLibrary = async () => {
		await mutateRemoveFromLibrary();
	};

	const coverWidth = "150px";

	// const { isAuthenticated } = useAuthUser();
	const isAuthenticated = useAuthStore((state) => state.loggedIn);

	const [notification, setNotification] = useState("");
	const [showErrorNotification, setShowErrorNotification] = useState(false);

	return (
		<motion.div
			variants={animations}
			initial="initial"
			animate="animate"
			exit="exit"
			transition={{ delay: 0.2 * rest.delay, duration: 0.8 }}
		>
			<div
				className={`flex flex-col justify-start ${
					rest.start ? "items-start" : "items-center"
				} w-full py-2  rounded-t-full cursor-pointer gap-2 sm:gap-3 aria-haspopup ${className} book-item text-sm `}
			>
				<div style={{ width: "150px " }}>
					<CoverContainer style={{ width: coverWidth }}>
						{coverUrl && (
							<img
								src={coverUrl}
								alt="Book Cover"
								width={coverWidth}
								height="230"
								className="bg-gray-600 rounded-md shadow-md w-full h-full"
							/>
						)}
					</CoverContainer>
				</div>

				<div
					className="flex w-full flex-col justify-between"
					style={{ width: coverWidth }}
				>
					<div>
						<p
							className="truncate px-2 py-1  rounded-full border border-akgreener shadow"
							title={author?.replace(";", " &")}
						>
							{author?.replace(";", " &")}
						</p>
						<p className="truncate mt-1 text-gray-600" title={isbn}>
							ISBN:
							<br />
							{isbn || "N/A"}
						</p>
						<p
							className={`text-sm truncate too${bookId}-tooltip mt-2`}
							title={title}
						>
							{title}
						</p>
						<p className="mt-1 font-bold">Published By:</p>
						<p className="text-xs pb-2 truncate">{publisher}</p>
					</div>

					<div className="w-full border-t-2 mt-2 pt-3 border-dotted border-akgreen">
						<p className="text-xs">
							<StarRating number={rating} />
						</p>

						{price > 0 && (
							<p className="text-lg">
								{getActiveCurrencyCodeLabel(currency)}
								{currencySymbol}&nbsp;
								{!isLoadingCurrency &&
									(price * Number(currencyRate?.data?.rate || 1)).toFixed(2)}
							</p>
						)}

						{credits > 0 && <p className="text-sm">{credits} stars</p>}
					</div>
					{!book_id && (
						<div className="p-2 border bg-white opacity-95 shadow mt-1 book-item-popup">
							<div className="flex flex-col justify-center items-center ">
								<p
									className="text-sm"
									style={{
										fontWeight: "bold",
										fontSize: 16,
										marginBottom: 14,
									}}
								>
									{title}
								</p>
								<Link to={`/books/${bookId}`} className="book-item-button">
									Overview
								</Link>
								<button
									type="button"
									onClick={handleRead}
									className="book-item-button"
								>
									Read
								</button>
								{!!sellable && !isPurchased && (
									<div className="mt-1">
										<AddToCart
											dark
											title={title}
											coverUrl={coverUrl}
											author={author}
											isbn={isbn}
											publisher={publisher}
											currencyCode={currencyCode}
											currencySymbol={currencySymbol}
											price={(
												price * Number(currencyRate?.data?.rate || 1)
											).toFixed(2)}
											bookId={bookId}
											rating={rating}
										/>
									</div>
								)}
								{/* hasSubscription */}
								{!inLibrary && !!subscription && (
									<>
										<label
											htmlFor={`subscribe${bookId}`}
											className="book-item-button cursor-pointer rounded-full"
										>
											<span className="text-sm">Add to my library</span>
											<input
												id={`subscribe${bookId}`}
												type="checkbox"
												hidden
												name="booksToSubscribe"
												value={bookId}
												onChange={(e) => handleAddToSubscription(e)}
											/>
										</label>

										{isLoading && (
											<span className="text-sm text-gray-400">adding...</span>
										)}
									</>
								)}
								{inLibrary &&
									location.pathname === "/my-library/picks" &&
									!isPurchased && (
										<button
											type="button"
											onClick={removeFromLibrary}
											className="rounded-3xl border border-akgreener mt-1 hover:scale-110 hover:text-akgreenest px-2 py-1"
										>
											{isRemovingFromLibrary
												? "Removing..."
												: "Remove from library"}
										</button>
									)}
							</div>
						</div>
					)}
				</div>

				<ConfirmDialog
					visible={showErrorNotification}
					onHide={() => setShowErrorNotification(false)}
					style={{ maxWidth: 300 }}
					className="font-sans"
					rejectClassName="hidden"
					acceptClassName="bg-white border border-akgreenest rounded-full text-akgreenest mx-auto"
					message={notification}
					header="Error!"
					acceptLabel="Close"
					rejectLabel=""
					icon="pi pi-exclamation-triangle"
					accept={() => setShowErrorNotification(false)}
				/>
			</div>
		</motion.div>
	);

	//!
};

export default BookItemColumn;
