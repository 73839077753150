import useFetchCurrencyByCode from "api/RatesResource/useFetchCurrencyByCode";
import geoCover from "assets/images/accounts_cover.jpg";
import { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import useCurrencyStore from "store/currencyStore";
import BookDetails from "../BookListItem/BookDetails";
import BookInfo from "./BookInfo";
import StarRating from "./StarRating";
import { NavLink } from "react-router-dom";

const BookItem = ({
	bookId,
	title,
	author,
	coverUrl,
	publisher,
	rating,
	price,
	credits,
	isbn,
	sellable,
	inLibrary,
	isPurchased,
	subscription,
	isFree,
	hasSubscription,
	description,
	category,
	reviews,
}) => {
	const coverWidth = 150;

	const { currency } = useCurrencyStore();

	const { data: currencyRate, isLoading: isLoadingCurrency } =
		useFetchCurrencyByCode(currency);

	const [visible, setVisible] = useState(false);

	function canRead() {
		return isPurchased || hasSubscription || isFree;
	}

	function calculatedPrice() {
		if (price > 0 && !isFree) {
			let cost = (price * Number(currencyRate?.data?.rate || 1)).toFixed(2);
			return `$${cost}`;
		}
		return false;
	}

	function ratedCost() {
		return (price * Number(currencyRate?.data?.rate || 1)).toFixed(2);
	}

	return (
		<div className="flex gap-6" style={{ width: "300px", height: "230px" }}>
			<div style={{ width: coverWidth, height: "230px" }}>
				<img
					src={coverUrl || geoCover}
					width={coverWidth}
					height="230"
					alt="Geo"
				/>
			</div>

			<div className="space-y-2">
				<div>
					<StarRating number={rating} />
					<p className="font-semibold w-36 truncate" title={title}>
						{title}
					</p>
				</div>

				<div>
					<BookInfo type="author" value={author} className="w-36 truncate" />
					<BookInfo type="isbn" value={isbn} className="w-36 truncate" />
					{/* <BookInfo
						type="publisher"
						value={publisher}
						className="w-36 truncate"
					/> */}
				</div>

				<div>
					<div className="mt-5">
						{/* <BuyNow maxWidth={100} /> */}
						{/* <Overview maxWidth={100} bookId={bookId} /> */}

						<NavLink
							to={`/book/${bookId}`}
							className="py-2 px-4 text-center rounded-full flex gap-2 border border-akgreenest hover:bg-akgreenest hover:text-white transform delay-75 duration-300 hover:-translate-y-1 max-w-max"
						>
							Overview <IoIosArrowForward size={20} />
						</NavLink>

						{/* <button
							type="button"
							className="py-2 px-4 text-center rounded-full flex gap-2 border border-akgreenest hover:bg-akgreenest hover:text-white transform delay-75 duration-300 hover:-translate-y-1 max-w-max"
							onClick={() => setVisible(true)}
						>
							Overview <IoIosArrowForward size={20} />
						</button> */}

						<BookDetails
							bookId={bookId}
							visible={visible}
							setVisible={setVisible}
							coverUrl={coverUrl}
							title={title}
							rating={rating}
							reviews={reviews}
							description={description}
							subscription={subscription}
							formattedPrice={
								!isLoadingCurrency && calculatedPrice()
									? calculatedPrice()
									: false
							}
							publisher={publisher}
							author={author}
							isFree={isFree}
							cost={ratedCost()}
							inLibrary={inLibrary}
							hasSubscription={hasSubscription}
							canRead={canRead}
							isPurchased={isPurchased}
							sellable={sellable}
							rate={currencyRate?.data?.rate}
						/>
						{/* <Read maxWidth={100} />
            <AddToLibrary /> */}
					</div>
					{/* <div></div> */}
				</div>
			</div>
		</div>
	);
};

export default BookItem;
