import useLogout from "api/UserResource/useLogout";
import avatarPlaceholder from "assets/images/avatar.jpg";
import { Menu } from "primereact/menu";
import { useRef } from "react";
import { AiOutlineLogout } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import useAuthStore from "store/authStore";

const ProfileIcon = () => {
	const menu = useRef(null);

	const history = useHistory();

	// const { data: user, isLoading: isProfileLoading } = useProfile();
	const userProfile = useAuthStore((state) => state.userProfile);

	const { mutateAsync } = useLogout();
	const handleLogout = async () => {
		await mutateAsync();
	};

	const items = [
		{
			label: (
				<div className="flex flex-col">
					<span className="font-bold">
						{userProfile?.first_name} {userProfile?.last_name}
					</span>
					<span className="text-sm">{userProfile?.email}</span>
					<span className="text-xs text-akgreen">My account</span>
				</div>
			),
			command: () => {
				history.push("/my-account");
			},
		},
		{
			label: (
				<span className="flex items-center text-akgreener gap-1">
					Logout
					<AiOutlineLogout size={20} />
				</span>
			),
			command: () => {
				handleLogout();
			},
		},
	];

	return (
		<>
			<button
				type="button"
				onClick={(event) => menu.current.toggle(event)}
				aria-controls="profile_menu"
				aria-haspopup
			>
				<img
					src={userProfile?.avatar || avatarPlaceholder}
					width={20}
					alt="Avatar"
					className="rounded-full border-2 border-akgreener h-7 w-7"
				/>
			</button>

			<Menu
				className="font-sans min-w-max"
				model={items}
				popup
				ref={menu}
				id="profile_menu"
			/>
		</>
	);
};

export default ProfileIcon;
