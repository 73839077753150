import useSearchParams from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useOrdersFilters = () => {
	const [firstPage, setFirstPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [page, setPage] = useState(1);
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");
	const [orderStatus, setOrderStatus] = useState("");

	const { getParam, setParam, deleteParam, hasParam } = useSearchParams();

	const { push } = useHistory();
	const { pathname } = useLocation();

	useEffect(() => {
		if (hasParam("page")) {
			const pageParam = getParam("page")?.length > 0 ? getParam("page") : 1;
			setPage(pageParam);
		}
		if (hasParam("rows")) {
			const rowsPerPageParam =
				getParam("rows")?.length > 0 ? getParam("rows") : 10;
			setRowsPerPage(rowsPerPageParam);
		}
		if (hasParam("first_page")) {
			const firstPageParam =
				getParam("first_page")?.length > 0 ? getParam("first_page") : 0;
			setFirstPage(firstPageParam);
		}
		if (hasParam("from_date")) {
			const fromDateParam =
				getParam("from_date")?.length > 0 ? getParam("from_date") : "";
			setFromDate(fromDateParam);
		}
		if (hasParam("to_date")) {
			const toDateParam =
				getParam("to_date")?.length > 0 ? getParam("to_date") : "";
			setToDate(toDateParam);
		}
		if (hasParam("order_status")) {
			const orderStatusParam =
				getParam("order_status")?.length > 0 ? getParam("order_status") : "";
			setOrderStatus(orderStatusParam);
		}

		return () => {
			deleteParam("page");
			deleteParam("rows");
			deleteParam("first_page");
			deleteParam("from_date");
			deleteParam("to_date");
			deleteParam("order_status");
		};
	}, [hasParam, getParam, deleteParam]);

	const changePage = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("page");
			setPage(1);
			return push(`${pathname}?${q}`);
		}
		const q = setParam("page", value);
		push(`${pathname}?${q}`);
	};

	const changeRowsPerPage = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("rows");
			setRowsPerPage(10);
			return push(`${pathname}?${q}`);
		}
		const q = setParam("rows", value);
		push(`${pathname}?${q}`);
	};

	const changeFirstPage = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("first_page");
			setFirstPage(0);
			return push(`${pathname}?${q}`);
		}
		const q = setParam("first_page", value);
		push(`${pathname}?${q}`);
	};

	const changeFromDate = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("from_date");
			setFromDate("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("from_date", value);
		push(`${pathname}?${q}`);
	};

	const changeToDate = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("to_date");
			setToDate("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("to_date", value);
		push(`${pathname}?${q}`);
	};

	const changeOrderStatus = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("order_status");
			setOrderStatus("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("order_status", value);
		push(`${pathname}?${q}`);
	};

	return {
		page,
		rowsPerPage,
		firstPage,
		fromDate,
		toDate,
		orderStatus,
		changePage,
		changeRowsPerPage,
		changeFirstPage,
		changeFromDate,
		changeToDate,
		changeOrderStatus,
	};
};

export default useOrdersFilters;
