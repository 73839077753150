import avatarPlaceholder from "assets/images/avatar.jpg";
import InputError from "components/InputError";
import { useField } from "formik";
import React from "react";
import { FiEdit } from "react-icons/fi";
import useAuthStore from "store/authStore";

const AvatarInput = ({ name, className, ...rest }) => {
	//!

	// const { data, isLoading } = useProfile();

	const user = useAuthStore((state) => state.userProfile);

	const placeholder = user?.avatar || avatarPlaceholder;

	const [field, meta] = useField(name);

	const inputRef = React.useRef();

	let src = null;
	if (inputRef?.current?.files.length > 0) {
		src = URL.createObjectURL(inputRef?.current?.files[0]);
	}

	return (
		<div>
			<label htmlFor={name} className="cursor-pointer flex items-center">
				<img
					src={`${src ?? placeholder}`}
					alt="Upload avatar"
					className="w-14 h-14 rounded-full"
				/>

				<p className="py-1 px-2 text-sm text-blue-900 flex flex-col items-start justify-center ">
					{rest.label || "Change profile picture"} <FiEdit className="inline" size="30" />
				</p>
				<input
					type="file"
					className="opacity-0"
					hidden
					{...field}
					{...rest}
					id={name}
					ref={inputRef}
				/>
			</label>
			{meta.error && meta.touched && <InputError error={meta.error} />}
		</div>
	);

	//!
};

export default AvatarInput;
