import ButtonLoader from "components/ButtonLoader";
import Admin2 from "layouts/Admin2";
import React from "react";

const AdminPageLoader = () => {
	return (
		<Admin2>
			<div className="flex-1 flex justify-center items-center">
				<ButtonLoader size={50} />
			</div>
		</Admin2>
	);
};

export default AdminPageLoader;
