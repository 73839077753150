import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";

const useFetchPublishers = (query = undefined) => {
	//!

	const { accessToken } = useTokenStore();

	const fetch = async () => {
		const instance = await InitApi({ accessToken });

		const res = await instance.get("/publishers", {
			params: query,
		});
		return res.data;
	};
	return useQuery("publishers", () => fetch(), {
		...fetchConfig,
	});

	//!
};

export default useFetchPublishers;
