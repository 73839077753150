import useViewableRoles from "hooks/useViewableRoles";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import React, { useState } from "react";
import { BsFilter } from "react-icons/bs";

const UsersFilters = ({ filters, hide = null }) => {
	const { roles } = useViewableRoles();

	// const hideRoles = hide?.includes("role");

	const countSetFilters = [
		filters?.firstName,
		filters?.lastName,
		filters?.email,
		filters?.trashed,
		filters?.roleIs,
		filters?.dateFrom,
		filters?.dateTo,
	].filter((item) => item?.length > 0 && item !== "with")?.length;

	const [filterValues, setFilterValues] = useState({
		rows: filters?.rowsPerPage,
		first_name: filters?.firstName,
		last_name: filters?.lastName,
		email: filters?.email,
		trashed: filters?.trashed,
		role_is: filters?.roleIs,
		date_from: filters?.dateFrom,
		date_to: filters?.dateTo,
	});

	const changeInputValue = (e) => {
		const value = e.target.value;
		setFilterValues({
			...filterValues,
			[e.target.name]: value,
		});
	};

	const changeDateFrom = (value) => {
		setFilterValues({
			...filterValues,
			date_from: value,
		});
	};

	const changeDateTo = (value) => {
		setFilterValues({
			...filterValues,
			date_to: value,
		});
	};

	const handleApplyFilters = (e) => {
		e.preventDefault();
		filters?.changeAllFiltersAtOnce(filterValues);
	};

	const handleClearFilters = () => {
		const params = {
			rows: "",
			first_name: "",
			last_name: "",
			email: "",
			trashed: "",
			role_is: "",
			date_from: "",
			date_to: "",
		};
		setFilterValues(params);
		filters?.changeAllFiltersAtOnce({
			...params,
			first_page: "",
			page: "",
		});
	};

	return (
		<form
			onSubmit={handleApplyFilters}
			className="mb-4 flex items-center justify-center gap-2 max-w-max"
		>
			<select
				name="rows"
				id="rows"
				value={filterValues?.rows}
				onChange={changeInputValue}
				className="border-4 border-gray-400 bg-white rounded h-9 px-2"
			>
				<option value="20">20</option>
				<option value="40">40</option>
				<option value="60">60</option>
				<option value="80">80</option>
				<option value="100">100</option>
			</select>

			<Calendar
				value={new Date(filterValues?.date_from)}
				name="date_from"
				dateFormat="yy-mm-dd"
				placeholder="Date (from)"
				className="w-40 h-9 font-sans"
				inputClassName="rounded border border-gray-400 bg-white rounded px-2"
				showIcon
				readOnlyInput
				showButtonBar
				onChange={(e) =>
					changeDateFrom(moment(e.target.value).format("YYYY-MM-DD"))
				}
				onClearButtonClick={() => changeDateFrom("")}
			/>

			<Calendar
				value={new Date(filterValues?.date_to)}
				name="date_to"
				dateFormat="yy-mm-dd"
				placeholder="Date (to)"
				className="w-40 h-9 font-sans"
				inputClassName="rounded border border-gray-400 bg-white rounded px-2"
				showIcon
				readOnlyInput
				showButtonBar
				onChange={(e) =>
					changeDateTo(moment(e.target.value).format("YYYY-MM-DD"))
				}
				onClearButtonClick={() => changeDateTo("")}
			/>

			{!hide?.includes("status") && (
				<select
					name="trashed"
					id="trashed"
					value={filterValues?.trashed}
					onChange={changeInputValue}
					className="border border-gray-300 bg-white rounded h-9 w-40 px-2"
				>
					<option label="- Status -"></option>
					<option value="not">Active</option>
					<option value="only">Inactive</option>
				</select>
			)}

			{!hide?.includes("role") && (
				<select
					name="role_is"
					id="roleIs"
					value={filterValues?.role_is}
					onChange={changeInputValue}
					className="border border-gray-300 bg-white rounded h-9 w-40 px-2"
				>
					<option label="- Select role -"></option>
					{roles?.map((role, index) => (
						<option key={index} value={role.value}>
							{role.label}
						</option>
					))}
				</select>
			)}

			<input
				name="first_name"
				placeholder="First name"
				value={filterValues?.first_name}
				onChange={changeInputValue}
				className="border border-gray-300 bg-white rounded h-9 w-40 px-2"
			/>

			<input
				name="last_name"
				placeholder="Last name"
				value={filterValues?.last_name}
				onChange={changeInputValue}
				className="border border-gray-300 bg-white rounded h-9 w-44 px-2"
			/>

			<input
				name="email"
				placeholder="Email address"
				value={filterValues?.email}
				onChange={changeInputValue}
				className="border border-gray-300 bg-white rounded h-9 w-40 px-2"
			/>

			<button
				type="submit"
				className="flex items-center gap-1 rounded px-3 h-9 bg-akgreenest text-white"
			>
				<span>Apply filters</span>
				<BsFilter size={20} />
			</button>
			{countSetFilters > 0 && (
				<button
					type="button"
					className="flex items-center gap-1 rounded px-2 h-9 bg-akgreener text-white"
					onClick={handleClearFilters}
				>
					<span>Clear filters</span>
					<span className="h-6 w-6 flex justify-center items-center text-akgreenest rounded-full bg-white font-bold">
						{countSetFilters}
					</span>
				</button>
			)}
		</form>
	);
};

export default UsersFilters;
