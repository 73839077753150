import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { reactLocalStorage } from "reactjs-localstorage";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";
import useAuthStore from "store/authStore";

const useUpdateInstitution = (id) => {
	//!

	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();
	const { storeUser, userProfile } = useAuthStore();

	const history = useHistory();

	const update = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);
		const response = await instance.patch(`/institutions/${id}`, body);
		return response;
	};

	return useMutation(update, {
		onSuccess: async ({ data: body }) => {
			storeUser({ ...userProfile, institutions: [body?.data] });
			queryClient.invalidateQueries("profile");
			toast.success(body.message);
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);
			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}
		},
	});

	//!
};

export default useUpdateInstitution;
