import React from "react";
import { Dialog } from "primereact/dialog";
import useOverlayStore from "store/overlayStore";
import useSubmitBook from "api/BookResource/useSubmitBook";
import useFetchCategories from "api/CategoryResource/useFetchCategories";
import useGenres from "api/GenreResource/useGenres";
import useAuthStore from "store/authStore";
import { MAX_IMAGE_SIZE, MAX_PDF_SIZE, activeCurrencies } from "utils";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import FormField from "components/v2/FormField";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import RichTextEditor from "components/RichTextEditor";
import { Role } from "utils/roles";

const AddBook = () => {
	const { showCreateBook, setShowCreateBook } = useOverlayStore();

	const hasRoles = useAuthStore((state) => state.hasRoles);

	const { data: categories, isLoading: isCategoriesLoading } =
		useFetchCategories("yes");

	const { data: genres, isLoading: isGenresLoading } = useGenres("yes");

	const initialValues = {
		title: "",
		isbn: "",
		author: "",
		publisher_email: "",
		book_cover: null,
		book_file: null,
		price: "",
		zwl_price: "",
		credits: "",
		description: "",
		genres: "",
		category_id: "",
		subscription: false,
		sellable: false,
		is_active: false,
		isFree: true,
	};

	const validate = Yup.object().shape({
		title: Yup.string().required("Book title is required"),
		author: Yup.string().required("Author is required"),
		publisher_email: !hasRoles([Role.SENIOR_PUBLISHER, Role.PUBLISHER])
			? Yup.string()
					.required("Publisher's email required")
					.email("Invalid email address")
			: Yup.string().notRequired().email("Invalid email address"),
		price: Yup.number().when("isFree", {
			is: true,
			then: Yup.number().notRequired(),
			otherwise: Yup.number()
				.required("Price is required")
				.moreThan(0, "Price should be greater than 0"),
		}),
		// price: Yup.number().moreThan(0, "Price should be greater than 0"),
		description: Yup.mixed(),
		category_id: Yup.number().required("Book category is required"),
		genres: Yup.string().required("Book genre is required"),
		book_cover: Yup.mixed()
			// .required("Cover image is required")
			.test({
				name: "fileSize",
				test: (value) => !value || value.size / 1024 / 1024 <= MAX_IMAGE_SIZE,
				message: `Image size should not exceed ${MAX_IMAGE_SIZE}MB`,
			})
			.test({
				name: "mimeType",
				test: (value) =>
					!value ||
					["image/jpeg", "image/png", "image/svg+xml", "image/webp"].includes(
						value.type
					),
				message:
					"Only images with these file extension are required: jpeg, jpg, png, svg, webp",
			}),

		book_file: Yup.mixed()
			// .required("Book file is required")
			.test({
				name: "fileSize",
				test: (value) => !value || value.size / 1024 / 1024 <= MAX_PDF_SIZE,
				message: `File size should not exceed ${MAX_PDF_SIZE}MB`,
			})
			.test({
				name: "mineType",
				test: (value) =>
					!value ||
					["application/pdf", "application/epub+zip"].includes(value.type),
				message: "Only Pdf or Epub document is required",
			}),
	});

	const { mutateAsync, isLoading } = useSubmitBook();

	const onSubmit = async (data) => {
		const payload = {
			...data,
			// book_cover: null,
			// book_file: null,
			subscription: data?.subscription ? 1 : 0,
			sellable: data?.sellable ? 1 : 0,
			is_active: data?.is_active ? 1 : 0,
			isFree: data?.isFree ? 1 : 0,
			price: data?.price ?? 0,
		};

		await mutateAsync(payload);
	};

	return (
		<Dialog
			visible={showCreateBook}
			style={{ width: "50vw" }}
			header={
				<div className="text-xl font-sans font-bold sticky top-4 z-20 bg-white mr-2">
					<p className="">Create book</p>
				</div>
			}
			onHide={() => setShowCreateBook(false)}
			position="top"
			draggable={false}
			contentClassName="rounded-b-xl "
			className="rounded-xl relative">
			<Formik
				initialValues={initialValues}
				validationSchema={validate}
				onSubmit={onSubmit}>
				{({ setFieldValue, values, errors, touched }) => {
					return (
						<Form className="mt-6 space-y-6  ">
							<div className="flex gap-2">
								<div className="w-1/2">
									<FormField type="text" name="title" label="Title *" />
								</div>
								<div className="w-1/2">
									<FormField type="text" name="isbn" label="ISBN Number" />
								</div>
							</div>

							<div className="flex gap-2">
								<div className="w-1/2">
									<FormField type="text" name="author" label="Author Name *" />
								</div>
								<div className="w-1/2">
									{!hasRoles([Role.SENIOR_PUBLISHER, Role.PUBLISHER]) && (
										<FormField
											type="text"
											name="publisher_email"
											label="Publisher's Email *"
										/>
									)}
								</div>
							</div>

							<div className="flex gap-2">
								<div className="w-1/2">
									<FormField
										type="file"
										name="book_cover"
										value={null}
										label="Book Cover"
										style={{
											paddingLeft: `${!values?.book_cover ? "90px" : ""} `,
										}}
										onChange={(event) =>
											setFieldValue("book_cover", event.target.files[0])
										}
									/>
								</div>
								<div className="w-1/2">
									<FormField
										type="file"
										name="book_file"
										value={null}
										label="Book File"
										style={{
											paddingLeft: `${!values?.book_file ? "90px" : ""} `,
										}}
										onChange={(event) =>
											setFieldValue("book_file", event.target.files[0])
										}
									/>
								</div>
							</div>

							<div className="flex gap-2">
								<div className="w-1/2">
									<FormField
										type="select"
										name="category_id"
										label="Book category"
										options={
											!isCategoriesLoading &&
											categories?.data?.map((category) => ({
												label: category.name,
												value: category.id,
											}))
										}
									/>
								</div>
								<div className="w-1/2">
									<FormField
										type="select"
										name="genres"
										label="Book Genre"
										options={
											!isGenresLoading &&
											genres?.data?.data?.map((genre) => ({
												label: genre.name,
												value: genre.id,
											}))
										}
									/>
								</div>
							</div>

							<div className="flex gap-2">
								<div className="w-1/2">
									<FormField
										type="number"
										min="0"
										step="0.01"
										name="price"
										label={`Book Price (${activeCurrencies.USD.label})`}
									/>
								</div>
								<div className="w-1/2">
									<FormField
										type="number"
										min="0"
										step="0.01"
										name="zwl_price"
										label={`Book Price (${activeCurrencies.ZWL.label})`}
									/>
								</div>
							</div>

							<div className="space-y-2">
								<p className="text-gray-500">Book summary</p>
								<RichTextEditor
									value={values.description}
									onTextChange={(e) =>
										setFieldValue("description", e.htmlValue)
									}
									error={
										!!(touched.description && errors.description) &&
										errors.description
									}
								/>
							</div>

							<div className=" space-y-2">
								<FormField
									type="switcher"
									name="subscription"
									label="Do you want people to subscribe to this book?"
								/>

								<FormField
									type="switcher"
									label="Can people buy this book?"
									name="sellable"
								/>

								<FormField
									type="switcher"
									label="Activate the book?"
									name="is_active"
								/>

								<FormField
									type="switcher"
									label="Make the book free?"
									name="isFree"
								/>
							</div>

							<div className=" flex justify-center border-t border-gray-300 pt-2">
								<button
									type="submit"
									className="px-4 py-2 rounded-full bg-akgreenest text-white flex gap-1 items-center justify-center">
									{isLoading ? (
										<>
											Submitting{" "}
											<AiOutlineLoading3Quarters
												size={20}
												className="animate-spin"
											/>
										</>
									) : (
										<>
											Submit <IoIosArrowForward size={20} />
										</>
									)}
								</button>
							</div>
						</Form>
					);
				}}
			</Formik>
			{/* </div> */}
		</Dialog>
	);
};

export default AddBook;
