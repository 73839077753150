import React from "react";

const useCountDown = (from, stopWhenZero = false) => {
	const [countdown, setCountdown] = React.useState(() => from || 5);

	React.useEffect(() => {
		const interval = setInterval(() => {
			setCountdown((prev) => prev - 1);
		}, 1000);
		if (stopWhenZero && countdown === 0) {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [countdown, stopWhenZero]);

	return {
		countdown,
		setCountdown,
	};
};

export default useCountDown;
