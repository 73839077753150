import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";

const useRecentBooks = () => {
	const { accessToken } = useTokenStore();
	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const res = await instance.get("books/recently-added");
		return res?.data?.data[0];
	};
	return useQuery("recently_added", () => fetch(), {
		...fetchConfig,
	});
};

export default useRecentBooks;
