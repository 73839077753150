import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useBulkActionBooks = () => {
	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	const history = useHistory();

	const save = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = obj ? deleteEmptyObjects(obj) : obj;
		const response = await instance.patch(`/books/bulk-action`, body);
		return response;
	};

	return useMutation(save, {
		onSuccess: async ({ data: body }) => {
			queryClient.invalidateQueries("books");
			queryClient.invalidateQueries("myUploadedBooks");
			queryClient.invalidateQueries("books_by_category");
			queryClient.invalidateQueries("genres");
			toast.success(body.message);
		},
		onError: async (error) => {
			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}

			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);
		},
	});
};

export default useBulkActionBooks;
