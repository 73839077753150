import React from "react";
import FormField from "components/v2/FormField";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import useAppStore from "store/appStore";
import FlashMessage from "components/v2/FlashMessage";
import useSendEmailChangeOtp from "api/UserResource/useSendEmailChangeOtp";
import useChangeEmail from "api/UserResource/useChangeEmail";

const ChangeEmailAddress = () => {
	const [actionIsSendingOtp, setActionIsSendingOtp] = React.useState(true);
	const [openOtpField, setOpenOtpField] = React.useState(false);

	const msg = useAppStore((state) => state.flashMessage);

	const initialValues = {
		email: "",
		otp: "",
	};

	const validate = Yup.object().shape({
		email: Yup.string()
			.required("Email is required")
			.email("Invalid email address"),
		otp: openOtpField
			? Yup.string().required("One-Time-Passcode is required")
			: Yup.string().notRequired(),
	});

	const { mutateAsync: mutateEmailChangeOtp, isLoading: isSendingOtp } =
		useSendEmailChangeOtp();

	const { mutateAsync: mutateChangeEmail, isLoading: isChangingEmail } =
		useChangeEmail();

	const handleChangeEmail = async (data) => {
		if (actionIsSendingOtp) {
			await mutateEmailChangeOtp({
				email: data.email,
			});
			setOpenOtpField(true);
			setActionIsSendingOtp(false);
			return;
		}

		await mutateChangeEmail(data);
		setActionIsSendingOtp(true);
		setOpenOtpField(false);
		return;
	};

	const handleResentOtp = () => {
		setOpenOtpField(false);
		setActionIsSendingOtp(true);
	};

	return (
		<>
			<div>
				<Formik
					initialValues={initialValues}
					validationSchema={validate}
					onSubmit={(data) => handleChangeEmail(data)}
				>
					<Form className="space-y-8">
						<p className="p-4 rounded-lg border border-green-300 bg-green-50">
							You email address will be used for sending notifications and
							updates. It also acts as a login credential when logging into this
							platform.
						</p>

						<p>
							You will received an OTP (One-Time-Passcode) to your current email
							address to confirm the change. Use that OTP to complete the email
							change process.
						</p>

						<FormField name="email" label="New email address *" />

						{openOtpField && (
							<>
								{msg && <FlashMessage />}

								<FormField name="otp" label="One Time Password (OTP)" />

								<button
									type="button"
									className="text-blue-700"
									onClick={() => handleResentOtp()}
								>
									OTP not received? Resend
								</button>
							</>
						)}

						<div className="mt-4 flex items-center">
							<button
								type="submit"
								className="py-3 px-3 bg-akgreen w-full rounded-full text-white flex items-center justify-center gap-2 max-w-max  "
							>
								{openOtpField ? "Update email" : "Send OTP"}

								{(isSendingOtp || isChangingEmail) && (
									<AiOutlineLoading3Quarters
										size={20}
										className="animate-spin"
									/>
								)}
							</button>
						</div>
					</Form>
				</Formik>
			</div>
		</>
	);
};

export default ChangeEmailAddress;
