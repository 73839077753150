import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import useOverlayStore from "store/overlayStore";
import { deleteEmptyObjects } from "utils";
import toast from "react-hot-toast";
import useTokenStore from "store/tokenStore";

const useSubmitBook = () => {
	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	const { setShowCreateBook } = useOverlayStore();

	const history = useHistory();

	const addBook = async (obj) => {
		const instance = await InitApi({ accessToken }, "multipart/form-data");

		const body = deleteEmptyObjects(obj);

		const formData = new FormData();
		Object.keys(body).forEach((key) => {
			formData.append(key, body[key]);
		});

		const response = await instance.post("/books", formData);
		return response;
	};

	return useMutation(addBook, {
		onSuccess: async ({ data: body }) => {
			queryClient.invalidateQueries("books");
			queryClient.invalidateQueries("myUploadedBooks");
			setShowCreateBook(false);
			toast.success(body.message);
			// toast.success(body.message);
		},
		onError: async (error) => {
			// console.log("error", error);
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);

			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}
		},
	});
};

export default useSubmitBook;
