import React, { useRef } from "react";
import { BiArrowBack, BiPowerOff, BiUser } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import ShortProfile from "./ShortProfile";
import { Menu } from "primereact/menu";
import { RiArrowDownSFill, RiExchangeDollarFill } from "react-icons/ri";
import useAuthStore from "store/authStore";
import useOverlayStore from "store/overlayStore";
import useLogout from "api/UserResource/useLogout";
import Subscriptions from "admin/Subscriptions";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useCurrencyStore from "store/currencyStore";
import Configs from "admin/Configs";
import { useLocation } from "react-router-dom";
import { activeCurrencies } from "utils";
import useCreateResourceModal from "./useCreateResourceModal";
import useMenuItems from "./useMenuItems";
import MobileMenu from "./MobileMenu";
import { Role } from "utils/roles";

const Admin2 = ({ children }) => {
	const menu = useRef(null);

	const location = useLocation();

	const showCurrencySelector = ["/books/admin/all"].includes(location.pathname);

	const { hasRoles } = useAuthStore();

	const { currency, setCurrency } = useCurrencyStore();

	const { setShowCreateRate } = useOverlayStore();

	const items = useCreateResourceModal();

	const menuItems = useMenuItems();

	const { mutateAsync, isLoading } = useLogout();
	const handleLogout = async () => {
		await mutateAsync();
	};

	return (
		<div className="flex w-full min-h-screen relative text-sm font-thin font-sans text-akgreenest bg-white">
			<div
				className="w-64 sticky top-0 h-screen px-3 py-4 hidden xl:flex flex-col"
				style={{ background: "rgba(127, 180, 45, 0.2)" }}>
				<ShortProfile />

				<nav className="mt-4 px-4 space-y-3">
					{menuItems.map((item, index) => (
						<NavLink
							key={index}
							to={item.to}
							className="text-akgreenest flex items-center gap-2 text-base"
							activeClassName="text-akgreener font-cera-black">
							{item.icon}
							{item.label}
						</NavLink>
					))}
				</nav>

				<footer className="flex-1 flex flex-col justify-end px-4 gap-4">
					<NavLink to="/my-account" className="flex gap-1 items-center">
						<BiUser size={20} />
						My Profile
					</NavLink>

					<button
						type="button"
						className="px-2 py-2 rounded-full bg-akgreener shadow-md flex gap-1 items-center text-white max-w-max"
						onClick={() => handleLogout()}>
						{isLoading ? (
							<AiOutlineLoading3Quarters
								size={20}
								className="animate-spin text-white"
							/>
						) : (
							<BiPowerOff size={20} className={`text-white`} />
						)}
						Logout
					</button>
				</footer>
			</div>

			<div className="pb-4 flex-grow flex flex-col min-h-screen relative">
				<div
					className="flex justify-between gap-2 sticky top-0 py-4 bg-white px-2 sm:px-12 bg-gradient-to-b from-gray-300 to-white"
					style={{ zIndex: 999 }}>
					<div className="flex items-center gap-1 xl:hidden">
						<ShortProfile />
						<NavLink
							to={"/books"}
							className="hover:bg-akgreener hover:text-white sm:flex gap-1 items-center px-3 py-1 rounded-full border border-akgreener text-akgreener h-12 hidden ">
							<BiArrowBack size={15} /> Home
						</NavLink>
					</div>

					<div className="hidden xl:block">
						<NavLink
							to={"/books"}
							className="hover:bg-akgreener hover:text-white flex gap-1 items-center px-2 py-1 rounded-full border border-akgreener text-akgreener ">
							<BiArrowBack size={15} /> Home
						</NavLink>
					</div>

					{showCurrencySelector && (
						<div className="flex items-center gap-2">
							<p>Show prices in</p>
							<select
								name="price_currency"
								onChange={(e) => setCurrency(e.target.value)}
								className="px-2 py-1 border border-green-200 rounded-lg">
								<option
									value={activeCurrencies.USD.code}
									selected={currency === activeCurrencies.USD.code}>
									{activeCurrencies.USD.label}
								</option>
								<option
									value={activeCurrencies.ZWL.code}
									selected={currency === activeCurrencies.ZWL.code}>
									{activeCurrencies.ZWL.label}
								</option>
							</select>
						</div>
					)}

					<div className="flex items-center gap-4">
						{hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) && (
							<>
								<Configs />
								<Subscriptions />
							</>
						)}

						{hasRoles([
							Role.SUPER_ADMIN,
							Role.ADMIN,
							Role.SENIOR_PUBLISHER,
							Role.PUBLISHER,
						]) && (
							<button
								type="button"
								onClick={() => setShowCreateRate(true)}
								className="flex items-center gap-1 px-2 py-1 bg-gradient-to-b from-akgreen to-gray-300 rounded-lg">
								<RiExchangeDollarFill size={20} />
								Rates
							</button>
						)}

						<Menu
							model={items}
							className="max-w-max font-sans text-sm"
							popup
							ref={menu}
							id="popup_menu"
							style={{ minWidth: 120 }}
						/>
						<button
							type="button"
							onClick={(event) => menu.current.toggle(event)}
							aria-controls="popup_menu"
							aria-haspopup
							style={{ width: 130 }}
							className="px-3 py-2 rounded-full bg-akgreenest text-white flex items-center gap-2 justify-between">
							Create new <RiArrowDownSFill size={20} />
						</button>

						<div className="block xl:hidden">
							<MobileMenu />
						</div>
					</div>
				</div>

				<div className="flex flex-col flex-grow items-center mt-4 px-2 sm:px-12">
					{children}
				</div>
			</div>
		</div>
	);
};

export default Admin2;
