import React from "react";
import moment from "moment";
import useFetchAggregateBooks from "api/AdminReports/useFetchAggregateBooks";
import { BiBookAdd, BiCalendar } from "react-icons/bi";
import { Calendar } from "primereact/calendar";
import { NavLink } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

const Card = ({ label, value }) => (
	<div
		className="p-3 flex justify-center gap-1 "
		style={{ borderRadius: "10% 69% 35% 20% / 21% 16% 40% 96% " }}>
		<div>
			<BiBookAdd size={25} className="text-gray-300" />
		</div>
		<div className="text-center">
			<p>{label}</p>
			<h5 className="rounded-full p-2 border border-akgreen h-14 w-14 flex justify-center items-center">
				{value}
			</h5>
		</div>
	</div>
);

const AggregateBooks = () => {
	// const { fromDate, setFromDate, toDate, setToDate } = useDateRangeStore();

	const [fromDate, setFromDate] = React.useState("");
	const [toDate, setToDate] = React.useState("");

	const { data: books } = useFetchAggregateBooks({
		from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
		to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
	});

	return (
		<div className="w-full space-y-4 ">
			<div className="flex justify-between gap-4 items-end w-full ">
				<h4 className="font-cera-black">Aggregate book statistics</h4>

				<div className="flex gap-4 items-center">
					<span className="p-float-label">
						<Calendar
							id={fromDate}
							className="font-sans"
							value={fromDate}
							onChange={(e) => setFromDate(e.target.value)}
							dateFormat="yy-mm-dd"
							mask="9999-99-99"
							panelClassName="font-sans text-xs"
							showIcon
							icon={<BiCalendar size={20} />}
							inputClassName="rounded-3xl pl-4 text-sm"
						/>
						<label htmlFor={fromDate}>From date -</label>
					</span>

					<span className="p-float-label">
						<Calendar
							id={toDate}
							className="font-sans"
							value={toDate}
							onChange={(e) => setToDate(e.target.value)}
							dateFormat="yy-mm-dd"
							mask="9999-99-99"
							panelClassName="font-sans text-xs"
							showIcon
							icon={<BiCalendar size={20} />}
							inputClassName="rounded-3xl pl-4 text-sm"
						/>
						<label htmlFor={toDate}>To date -</label>
					</span>
				</div>
			</div>

			<div className="grid grid-cols-5 gap-2 p-2 bg-white shadow rounded">
				<Card label="All books" value={books?.data?.total || 0} />

				<Card label="Active" value={books?.data?.active || 0} />

				<Card label="Inactive" value={books?.data?.inactive || 0} />

				<Card label="Free" value={books?.data?.free || 0} />

				<Card label="Premium" value={books?.data?.premium || 0} />
			</div>

			<div className="mt-6">
				<NavLink
					to="/books/admin/all"
					className="text-blue-900 font-bold flex items-center gap-2">
					View books <BsArrowRight size={20} />
				</NavLink>
			</div>
		</div>
	);
};

export default AggregateBooks;
