import InputError from "components/InputError";
import { useField } from "formik";
import tw from "tailwind-styled-components/dist/tailwind";

const PaymentRadio = tw.div`
      flex justify-start items-center gap-2
`;

const RadioButton = ({ name, label, className, id, ...rest }) => {
  const [field, meta] = useField(name);

  const link = rest.value.replace(/\s+/g, "").toLowerCase();

  return (
    <>
      <PaymentRadio>
        <input id={link} type="radio" {...field} {...rest} />
        <label
          htmlFor={link}
          className={className || "rounded-full border border-akgreener px-4"}
        >
          {label || rest.children}
        </label>
      </PaymentRadio>
      {meta.error && meta.touched && <InputError error={meta.error} />}
    </>
  );
};

export default RadioButton;
