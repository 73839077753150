// import FlashMessage from "components/v2/FlashMessage";
import AuthLayout from "layouts/AuthLayout";
import React from "react";
// import useAppStore from "store/appStore";
// import ResetWithEmail from "./ResetWithEmail";
// import ResetWithPhone from "./ResetWithPhone";
// import FormField from "components/v2/FormField";
// import { Form, Formik } from "formik";
import { useState } from "react";
import ResetPasswordForm from "./ResetPasswordForm";

const PasswordResetOptions = () => {
	// const msg = useAppStore((state) => state.flashMessage);

	const [accountEmail, setAccountEmail] = useState("");

	return (
		<div>
			<AuthLayout hideCamera>
				<h1 className="text-akgreen font-cera-black ">Reset Password</h1>

				<div style={{ width: 350 }} className="mt-2">
					<div className="mt-4 flex flex-col gap-4">
						{/* <ResetWithEmail />

						<ResetWithPhone /> */}

						<ResetPasswordForm
							accountEmail={accountEmail}
							setAccountEmail={setAccountEmail}
						/>
					</div>
				</div>
			</AuthLayout>
		</div>
	);
};

export default PasswordResetOptions;
