import usePrice from "hooks/usePrice";
import React from "react";
import { formatCurrency } from "utils";

const Price = ({ isFree, usdPrice, zwlPrice, sellable }) => {
	const price = usePrice({
		isFree,
		usdPrice,
		zwlPrice,
		sellable,
	});

	return <>{formatCurrency(price)}</>;
};

export default Price;
