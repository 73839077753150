import useEcocash from "api/PaymentsResource/useEcocash";
import ButtonLoader from "components/ButtonLoader";
import TextField from "components/TextField";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import useCurrencyStore from "store/currencyStore";
import PaymentSuccessMessage from "./PaymentSuccessMessage";

const PayUsingEcocash = ({
	subtotal,
	zwl_subtotal,
	books,
	rate,
	closePaymentOptions,
	bookQuantity,
	closeBookDetails,
}) => {
	// const [progressVisible, setProgressVisible] = React.useState(false);

	const { paymentMethod } = useCurrencyStore();

	const [showSuccess, setShowSuccess] = useState(false);
	const [totalPaid, setTotalPaid] = useState(0);
	const [bookItems, setBookItems] = useState(null);

	const initialValues = {
		street_address: "",
		city: "",
		province: "",
		country: "",
		postcode: "",
		payment_method: "Ecocash",
		payment_type: "Purchase",
		subtotal: subtotal,
		grand_charges: 0,
		grand_total: 0,
		grand_total_zwl: 0,
		order_note: "",
		books: books,
		mobile_number: "",
	};

	const { mutateAsync: mutateEcocash, isLoading: isLoadingPayment } =
		useEcocash(setBookItems);

	const handleSubmit = async (data) => {
		let _quantity = parseInt(bookQuantity);
		let _grandTotal = Number(data.subtotal) * _quantity;
		let _zwl_grandTotal = Number(zwl_subtotal) * _quantity + Number(data.grand_charges);

		await mutateEcocash({
			...data,
			grand_total: _grandTotal,
			grand_total_zwl: _zwl_grandTotal,
		});
		setTotalPaid(_zwl_grandTotal);
		// closeBookDetails();
		setShowSuccess(true);
	};

	return (
		<div className="px-2">
			<div className="font-semibold text-akgreener py-2 border-b-2 border-akgreener mb-2">
				<button
					type="button"
					onClick={closePaymentOptions}
					className="flex items-center gap-1 py-1 px-3 rounded-full bg-gray-200 hover:bg-akgreenest hover:text-white "
				>
					<BsArrowLeft size={30} />
					<span>Back</span>
				</button>
			</div>

			<h2 className="font-cera-black mb-4">Ecocash Payment</h2>

			<Formik initialValues={initialValues} onSubmit={(data) => handleSubmit(data)}>
				{() => {
					return (
						<Form className="flex flex-col items-center justify-center">
							<TextField
								type="number"
								name="mobile_number"
								label="Enter your ecocash number"
								placeholder="e.g 0783575000"
							/>

							<div className="mt-2">
								{isLoadingPayment ? (
									<div className="flex flex-col justify-center items-center">
										<ButtonLoader size={40} />
										<p style={{ maxWidth: 300 }}>
											Please patiently wait to receiving ecocash pin prompt from your
											mobile phone. Enter your pin number and wait for transaction to
											complete
										</p>
									</div>
								) : (
									<button
										type="submit"
										className="rounded-full px-3 py-1 text-white bg-akgreenest"
									>
										Proceed
									</button>
								)}
							</div>
						</Form>
					);
				}}
			</Formik>

			<PaymentSuccessMessage
				visible={showSuccess}
				setVisible={setShowSuccess}
				paymentMethod={paymentMethod}
				totalPaid={totalPaid}
				bookCount={bookQuantity}
				bookItems={bookItems}
				currencyRate={rate}
			/>
		</div>
	);
};

export default PayUsingEcocash;
