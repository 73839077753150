import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import useCreateVoucher from "api/VoucherResource/useCreateVoucher";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { activeCurrencies, getActiveCurrencyCodeLabel } from "utils";
import moment from "moment";

const CreateVoucher = () => {
	const initialValues = {
		number_of_tokens: "",
		expiry_date: "",
		currency: activeCurrencies.USD.code,
	};

	const validate = Yup.object({
		number_of_tokens: Yup.number()
			.required("Enter number of tokens")
			.min(1, "Number of tokens must be greater than 0"),
		expiry_date: Yup.string().required("Expiry date is required"),
	});

	const { mutateAsync, isLoading } = useCreateVoucher();

	const handleSubmit = (data) => {
		mutateAsync(data);
	};

	return (
		<div className="space-y-8">
			<div>
				<h2>Please fill the form below to create a voucher</h2>
				<p>Required fields are marked with a star (*)</p>
			</div>

			<div>
				<Formik
					initialValues={initialValues}
					validationSchema={validate}
					onSubmit={handleSubmit}
				>
					{(formik) => (
						<Form
							className="space-y-7"
							// style={{
							// 	width: 500,
							// }}
						>
							<FormField
								type="select"
								name="currency"
								label="Select currency for the voucher *"
								options={[
									{
										label: activeCurrencies.USD.label,
										value: activeCurrencies.USD.code,
									},
									{
										label: activeCurrencies.ZWL.label,
										value: activeCurrencies.ZWL.code,
									},
								]}
							/>

							<FormField
								label={`Number of tokens * (1 token = 1 ${getActiveCurrencyCodeLabel(
									formik.values.currency
								)})`}
								name="number_of_tokens"
								type="number"
							/>

							<FormField
								label="Expiry date *"
								name="expiry_date"
								type="date"
								value={formik.values.expiry_date}
								onChange={(e) =>
									formik.setFieldValue(
										"expiry_date",
										moment(e.target.value).format("YYYY-MM-DD")
									)
								}
							/>

							<button
								type="submit"
								className="px-4 py-2 bg-akgreenest text-white rounded-lg flex items-center gap-1"
							>
								{isLoading ? (
									<>
										Submitting{" "}
										<AiOutlineLoading3Quarters
											size={20}
											className="animate-spin"
										/>
									</>
								) : (
									<>
										Submit <IoIosArrowForward size={20} />
									</>
								)}
							</button>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default CreateVoucher;
