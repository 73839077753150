import useCreateOrder from "api/OrderResource/useCreateOrder";
import useFetchCurrencyByCode from "api/RatesResource/useFetchCurrencyByCode";
import ButtonLoader from "components/ButtonLoader";
import Paypal from "components/Paypal";
import { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import useCurrencyStore from "store/currencyStore";
import PaymentSuccessMessage from "./PaymentSuccessMessage";
import { activeCurrencies } from "utils";

const PayUsingPayPal = ({
	subtotal,
	zwl_subtotal,
	books,
	closePaymentOptions,
	bookQuantity,
}) => {
	const { isLoading: isLoadingCurrency } = useFetchCurrencyByCode(
		activeCurrencies.ZWL.code
	);

	const _grandCharges = 0; // in usd

	const { paymentMethod } = useCurrencyStore();

	const [showSuccess, setShowSuccess] = useState(false);
	const [totalPaid, setTotalPaid] = useState(0);
	const [bookItems, setBookItems] = useState(null);

	const { mutateAsync, isLoading } = useCreateOrder(setBookItems);

	const _onPaypalComplete = async (order) => {
		let quantity = parseInt(bookQuantity);

		let _subtotal = Number(subtotal) * quantity;
		let _grandTotal = _subtotal + _grandCharges;
		let _zwl_grandTotal = Number(zwl_subtotal) * quantity;

		await mutateAsync({
			street_address:
				order?.purchase_units[0]?.shipping?.address?.address_line_1,
			city: order?.purchase_units[0]?.shipping?.address?.admin_area_2,
			province: order?.purchase_units[0]?.shipping?.address?.admin_area_1,
			country: order?.purchase_units[0]?.shipping?.address?.country_code,
			postcode: order?.purchase_units[0]?.shipping?.address?.postal_code,
			payment_method: "PayPal",
			payment_type: "Purchase",
			subtotal: _subtotal,
			grand_charges: _grandCharges,
			books: books,
			grand_total: _grandTotal,
			grand_total_zwl: _zwl_grandTotal,
			reference: order?.id + "-" + order?.payer?.payer_id,
			status: order?.status === "COMPLETED" ? "Completed" : "Failed",
		});
		setTotalPaid(_grandTotal);
		// closeBookDetails();
		setShowSuccess(true);
	};

	return (
		<div className="px-2">
			<div className="font-semibold text-akgreener py-2 border-b-2 border-akgreener mb-2">
				<button
					type="button"
					onClick={closePaymentOptions}
					className="flex items-center gap-1 py-1 px-3 rounded-full bg-gray-200 hover:bg-akgreenest hover:text-white "
				>
					<BsArrowLeft size={30} />
					<span>Back</span>
				</button>
			</div>

			<h2 className="font-cera-black text-center mb-4">PayPal Payment</h2>

			{isLoading ? (
				<div className="flex flex-col gap-3 items-center justify-center mb-3">
					<p className="text-center">
						Wait while your payment is being recorded....
					</p>
					<ButtonLoader size={100} />
				</div>
			) : (
				<>
					{!isLoadingCurrency && (
						<Paypal
							_onComplete={(order) => _onPaypalComplete(order)}
							amount={
								parseFloat(subtotal) * parseInt(bookQuantity) + _grandCharges
							}
						/>
					)}
				</>
			)}

			<PaymentSuccessMessage
				visible={showSuccess}
				setVisible={setShowSuccess}
				paymentMethod={paymentMethod}
				totalPaid={totalPaid}
				bookCount={bookQuantity}
				bookItems={bookItems}
			/>
		</div>
	);
};

export default PayUsingPayPal;
