import useRefreshCache from "api/CommandCenterResource/useRefreshCache";
import React from "react";
import { AiOutlineLoading3Quarters, AiOutlineRight } from "react-icons/ai";

const ClearBackendCache = () => {
	const { mutateAsync, isLoading } = useRefreshCache();

	const refreshCache = async () => {
		await mutateAsync();
	};

	return (
		<div className="w-full px-2 py-2 rounded-lg bg-akgreen border-2 border-akgreener flex justify-between items-center text-white">
			<span className=" text-base ">Refresh cache</span>
			<button
				type="button"
				onClick={refreshCache}
				className="flex justify-between items-center px-2 py-2 bg-akgreenest rounded-lg gap-2"
			>
				Run
				{isLoading ? (
					<AiOutlineLoading3Quarters size={20} className="animate-spin" />
				) : (
					<AiOutlineRight size={20} />
				)}
			</button>
		</div>
	);
};

export default ClearBackendCache;
