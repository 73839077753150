import create from "zustand";
import { persist } from "zustand/middleware";

const supportQueryStore = (set, get) => ({
	query: "",
	setQuery: (query) => set({ query }),
	clearQuery: () => set({ query: "" }),
});

const useSupportQueryStore = create(
	persist(supportQueryStore, {
		name: "zus-support-query",
	})
);

export default useSupportQueryStore;
