import InputError from "components/InputError";
import { useField } from "formik";
// import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import PhoneInput from "react-phone-input-2";
import { MultiSelect } from "primereact/multiselect";
import { Password } from "primereact/password";
import { useEffect, useRef } from "react";
import { Tooltip } from "primereact/tooltip";
// import { Calendar } from "primereact/calendar";

const FormField = ({
	type,
	name,
	placeholder,
	className,
	inputClasses,
	width,
	filter = true,
	switchLabelPlacement = "right",
	...rest
}) => {
	const [field, meta] = useField(name);

	const phoneRef = useRef(null);

	useEffect(() => {
		if (type === "phone" && rest.value.length > 4) {
			phoneRef.current.style.marginTop = "-35px";
			phoneRef.current.className = "text-xs";
		}

		//eslint-disable-next-line
	}, []);

	if (type === "phone") {
		return (
			<div>
				<span className="p-float-label text-akgreen flex">
					<PhoneInput
						country={"zw"}
						value={rest.value}
						countryCodeEditable={false}
						onChange={rest._onChange}
						onFocus={() => {
							phoneRef.current.style.marginTop = "-35px";
							phoneRef.current.className = "text-xs";
						}}
						onBlur={(e) => {
							if (e.target.value.length <= 6) {
								phoneRef.current.style.marginTop = "-8px";
								phoneRef.current.className = "text-sm ml-20";
							}
						}}
						placeholder="Mobile Number"
						id={name}
						containerClass={`border-0 outline-none ${inputClasses} `}
						containerStyle={{ color: "red" }}
						inputStyle={{
							background: "rgba(243, 244, 246)",
							border: 0,
							borderRadius: 5,
							width: "100%",
							paddingTop: 4,
							paddingBottom: 4,
							paddingLeft: 50,
							color: "#4a4a4a",
						}}
						buttonStyle={{
							background: "rgba(243, 244, 246)",
							paddingLeft: 5,
							paddingRight: 5,
							borderTopLeftRadius: 5,
							borderBottomLeftRadius: 5,
							border: 0,
						}}
					/>
					<label
						ref={phoneRef}
						htmlFor={name}
						className="text-akgreen ml-20 italic"
					>
						{rest.label}
					</label>
				</span>
				{rest.error && <InputError error={rest.errorText} />}
			</div>
		);
	}

	if (type === "password") {
		return (
			<div>
				<span className="p-float-label text-akgreen">
					<Password
						id={name}
						{...field}
						{...rest}
						// inputClassName="bg-gray-100 border-0 text-sm"
						inputClassName={`rounded-lg bg-gray-100 border-0 text-sm ${
							width || "w-full"
						} ${inputClasses}`}
						toggleMask
						feedback={false}
					/>
					{rest.helperText && (
						<Tooltip
							target={`#${name}`}
							position="top"
							className="font-sans text-xs w-24"
						>
							{rest.helperText}
						</Tooltip>
					)}
					<label htmlFor={name} className="text-akgreen">
						{rest.label}
					</label>
				</span>
				{meta.error && meta.touched && <InputError error={meta.error} />}
			</div>
		);
	}

	if (type === "date") {
		return (
			<div>
				<span className="p-float-labels text-akgreen relative">
					<label
						htmlFor={name}
						className="absolute -top-8 text-gray-500 text-xs px-2"
					>
						{rest.label}
					</label>
					<input
						className={` bg-gray-100 rounded-lg h-9 right-0 px-2 text-gray-600 text-sm outline-none ${
							width || "w-full"
						} ${inputClasses}`}
						type="date"
						{...field}
						{...rest}
					/>
					{/* <Calendar
						id={name}
						dateFormat="yy-mm-dd"
						showButtonBar
						onClearButtonClick={rest.onClearDate}
						className={` ${width || "w-full"} ${inputClasses}`}
						inputClassName={`rounded-lg bg-gray-100 border-0 text-sm `}
						mask="9999-99-99"
						{...field}
						{...rest}
					/> */}
					{rest.helperText && (
						<Tooltip
							target={`#${name}`}
							position="top"
							className="font-sans text-xs w-24"
						>
							{rest.helperText}
						</Tooltip>
					)}
				</span>

				{meta.error && meta.touched && <InputError error={meta.error} />}
			</div>
		);
	}

	if (type === "select") {
		return (
			<div>
				<span className={`p-float-label text-akgreen`}>
					<Dropdown
						options={rest.options}
						optionLabel="label"
						optionValue="value"
						showClear
						filter={filter}
						{...field}
						{...rest}
						inputId={name}
						className={`rounded-lg font-sans bg-gray-100 border-0 text-sm ${
							width || "w-full"
						} ${inputClasses || ""} `}
					/>
					{rest.helperText && (
						<Tooltip
							target={`#${name}`}
							position="top"
							className="font-sans text-xs w-24"
						>
							{rest.helperText}
						</Tooltip>
					)}
					<label htmlFor={name} className="text-akgreen">
						{rest.label}
					</label>
				</span>

				{meta.error && meta.touched && <InputError error={meta.error} />}
			</div>
		);
	}

	if (type === "multiselect") {
		return (
			<div>
				<span className={`p-float-label text-akgreen`}>
					<MultiSelect
						options={rest.options}
						optionLabel="label"
						optionValue="value"
						filter
						showClear
						{...field}
						{...rest}
						inputId={name}
						className={`rounded-lg font-sans bg-gray-100 border-0 text-sm ${
							width || "w-full"
						} ${inputClasses} `}
						display="chip"
					/>
					{rest.helperText && (
						<Tooltip
							target={`#${name}`}
							position="top"
							className="font-sans text-xs w-24"
						>
							{rest.helperText}
						</Tooltip>
					)}
					<label htmlFor={name} className="text-akgreen">
						{rest.label}
					</label>
				</span>

				{meta.error && meta.touched && <InputError error={meta.error} />}
			</div>
		);
	}

	if (type === "switcher") {
		return (
			<div>
				<div className="flex gap-2 items-center ">
					{switchLabelPlacement === "left" && (
						<label htmlFor={name} className="text-akgreenest">
							{rest.label}
						</label>
					)}

					<InputSwitch
						checked={field.value === true}
						{...field}
						{...rest}
						id={name}
					/>
					{switchLabelPlacement === "right" && (
						<label htmlFor={name} className="text-akgreenest">
							{rest.label}
						</label>
					)}
				</div>
				{meta.error && meta.touched && <InputError error={meta.error} />}
			</div>
		);
	}

	if (type === "textarea") {
		return (
			<div>
				<span className={`p-float-label text-akgreen`}>
					<InputTextarea
						id={name}
						{...field}
						{...rest}
						className={`rounded-lg bg-gray-100 border-0 text-sm ${
							width || "w-full"
						}`}
						rows={5}
					/>
					{rest.helperText && (
						<Tooltip
							target={`#${name}`}
							position="top"
							className="font-sans text-xs w-24"
						>
							{rest.helperText}
						</Tooltip>
					)}
					<label htmlFor={name} className="text-akgreen">
						{rest.label}
					</label>

					{meta.error && meta.touched && <InputError error={meta.error} />}
				</span>
			</div>
		);
	}

	return (
		<div>
			<span className={`p-float-label text-akgreen`}>
				<InputText
					id={name}
					type={type || "text"}
					{...field}
					{...rest}
					className={`rounded-lg bg-gray-100 border-0 text-sm ${
						width || "w-full"
					} ${inputClasses} `}
				/>
				{rest.helperText && (
					<Tooltip
						target={`#${name}`}
						position="top"
						className="font-sans text-xs w-24"
					>
						{rest.helperText}
					</Tooltip>
				)}
				<label htmlFor={name} className="text-akgreen">
					{rest.label}
				</label>
			</span>

			{meta.error && meta.touched && <InputError error={meta.error} />}
			{/* {rest.info && <p className="text-xs pl-2 text-akgreenest">{rest.info}</p>} */}
		</div>
	);
};

export default FormField;
