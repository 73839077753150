import logo from "assets/images/dark_logo.png";
import { Sidebar } from "primereact/sidebar";
import { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { BiHomeAlt, BiLibrary } from "react-icons/bi";
import { BsFillJournalBookmarkFill, BsTelephoneForward } from "react-icons/bs";
import { FiPackage } from "react-icons/fi";
import { GiPortal } from "react-icons/gi";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { RiAccountPinCircleLine } from "react-icons/ri";
import { NavLink, useHistory } from "react-router-dom";

const MENU_LINK_CSS = `px-2 py-1 text-akgreenest flex gap-3 items-center bg-transparent`;

const MobileMenu = ({
	children,
	dashboardUrl,
	canAccessPortal,
	isAuthenticated,
}) => {
	//!

	const { push } = useHistory();

	const [visibleLeft, setVisibleLeft] = useState(false);

	const handleChangePath = (path) => {
		setVisibleLeft(false);
		push(path);
	};

	const logoWidth = 80;

	return (
		<div className="flex justify-between w-full">
			<NavLink to="/">
				<img src={logo} alt="Akello Library" width={logoWidth} />
			</NavLink>

			<div className="flex items-center justify-end gap-4">
				{children}
				{/* <CurrencyConverter /> */}
				<button type="button" onClick={() => setVisibleLeft(true)}>
					<AiOutlineMenu className="text-akgreenest" size={20} />
				</button>
			</div>

			<Sidebar
				visible={visibleLeft}
				position="left"
				className="rounded-md mt-4 ml-4 font-sans"
				onHide={() => setVisibleLeft(false)}
			>
				<ul className="flex flex-col gap-2 mobile-menu">
					<button
						type="button"
						onClick={() => handleChangePath("/")}
						className={MENU_LINK_CSS}
					>
						<BiHomeAlt /> Home
					</button>

					<button
						type="button"
						onClick={() => handleChangePath("/books")}
						className={MENU_LINK_CSS}
					>
						<BsFillJournalBookmarkFill /> Browse Books
					</button>

					<button
						type="button"
						onClick={() => handleChangePath("/support")}
						className={MENU_LINK_CSS}
					>
						<BsTelephoneForward /> Support
					</button>

					{isAuthenticated && (
						<button
							type="button"
							onClick={() => handleChangePath("/my-library")}
							className={MENU_LINK_CSS}
						>
							<BiLibrary /> My Library
						</button>
					)}

					{isAuthenticated && canAccessPortal && (
						<button
							type="button"
							// onClick={() => setVisibleLeft(false)}
							onClick={() => handleChangePath(`/${dashboardUrl}`)}
							className={`px-2 py-1 text-akgreenest bg-transparent flex flex-col items-start`}
						>
							<span className="flex items-center gap-3">
								<GiPortal /> Portal
							</span>
							<span className="text-xs pl-7">
								Portal is viewable on a larger screen
							</span>
						</button>
					)}

					<button
						type="button"
						onClick={() => handleChangePath("/subscriptions")}
						className={MENU_LINK_CSS}
					>
						<FiPackage /> Subscribe
					</button>

					{isAuthenticated && (
						<button
							type="button"
							onClick={() => handleChangePath("/my-account")}
							className={MENU_LINK_CSS}
						>
							<RiAccountPinCircleLine /> My Account
						</button>
					)}

					{!isAuthenticated && (
						<>
							<button
								type="button"
								onClick={() => handleChangePath("/register")}
								className={MENU_LINK_CSS}
							>
								<RiAccountPinCircleLine /> Register
							</button>

							<button
								type="button"
								onClick={() => handleChangePath("/login")}
								className={MENU_LINK_CSS}
							>
								<RiAccountPinCircleLine /> Login
							</button>
						</>
					)}

					<button
						type="button"
						onClick={() => handleChangePath("/downloads")}
						className={MENU_LINK_CSS}
					>
						<IoCloudDownloadOutline /> Downloads
					</button>
				</ul>
			</Sidebar>
		</div>
	);

	//!
};

export default MobileMenu;
