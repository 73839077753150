import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useCreateUpdateConfig from "api/ConfigResource/useCreateUpdateConfig";
import { BsSave } from "react-icons/bs";

const EditConfig = ({ name, value, setEnableEditing }) => {
	const initialValues = {
		value: value || "",
	};

	const validate = Yup.object().shape({
		value: Yup.string().required("Config value is required"),
	});

	const { mutateAsync, isLoading } = useCreateUpdateConfig();

	const save = async (data) => {
		await mutateAsync({
			name,
			value: data.value,
		});
		setEnableEditing(false);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validate}
			onSubmit={(data) => save(data)}
		>
			{({ values, setFieldValue }) => (
				<Form className="flex items-center justify-center">
					<input
						type="text"
						name="value"
						autoFocus
						value={values.value}
						onChange={(e) => setFieldValue("value", e.target.value)}
						onBlur={(e) =>
							save({
								name,
								value: e.target.value,
							})
						}
						className="ring-0 w-20 px-2 py-1 border border-gray-400 rounded-l h-8"
					/>

					<button
						type="submit"
						className="flex items-center bg-akgreener h-8 p-2 rounded-r text-white"
					>
						{isLoading ? <AiOutlineLoading3Quarters size={20} /> : <BsSave size={20} />}
					</button>
				</Form>
			)}
		</Formik>
	);
};

export default EditConfig;
