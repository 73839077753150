import { BookInCart } from "components";
import { Dialog } from "primereact/dialog";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import useAuthStore from "store/authStore";
import { Role } from "utils/roles";

const PaymentSuccessMessage = ({
	visible,
	setVisible,
	title,
	paymentMethod,
	totalPaid,
	bookCount,
	bookItems,
}) => {
	const { hasRoles } = useAuthStore();

	return (
		<Dialog
			visible={visible}
			header={
				<div className="text-center">{title || "Payment successful"}</div>
			}
			onHide={() => setVisible(false)}>
			<div className="flex items-center gap-2 ">
				<div>
					<AiOutlineCheckCircle size={130} className="" />
				</div>
				<div>
					<p>
						Your <strong>{paymentMethod}</strong> payment was successful.
						Details are as follows:
					</p>
					<ul className="list-disc list-inside text-akgreener">
						<li>
							Total payment: <strong>${Number(totalPaid).toFixed(2)}</strong>
						</li>
						<li>
							Number of books: <strong>{bookCount}</strong>
						</li>
					</ul>
					<p>
						Go to{" "}
						<Link
							to="/my-library"
							className="text-blue-700 font-bold underline"
							onClick={() => setVisible(false)}>
							My library
						</Link>{" "}
						to view purchased books
					</p>
				</div>
			</div>

			<hr className="my-4 border-2 border-gray-400" />

			<div className="grid grid-cols-1 sm:grid-cols-2 gap-1 space-y-2">
				{bookItems?.map((bookItem) => (
					<BookInCart
						key={bookItem.id}
						title={bookItem?.book_title}
						coverUrl={bookItem?.book_cover?.url}
						author={bookItem?.author}
						quantity={bookItem?.quantity}
						price={bookItem?.price}
						zwl_price={bookItem.zwl_price}
						cartId={bookItem?.id}
						paymentResponse
					/>
				))}
			</div>

			<p className="p-2 bg-gray-300 mt-3 text-center">
				Go to{" "}
				<Link
					// to="/my-library"
					to={
						hasRoles([Role.INSTITUTION]) ? "/books/institution" : "/my-library"
					}
					className="text-blue-700 font-bold underline"
					onClick={() => setVisible(false)}>
					My library
				</Link>{" "}
				to view purchased books
			</p>
		</Dialog>
	);
};

export default PaymentSuccessMessage;
