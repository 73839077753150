import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";

const useFetchRecommended = () => {
	const { accessToken } = useTokenStore();
	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const res = await instance.get("books/random/recommended");
		return res.data;
	};
	return useQuery("recommended", () => fetch(), {
		...fetchConfig,
	});
};

export default useFetchRecommended;
