import React from "react";
import { Form, Formik } from "formik";
import { MdCancel } from "react-icons/md";
import useUsersFilterStore from "store/userFilterStore";
// import useAuthStore from "store/authStore";
import useViewableRoles from "hooks/useViewableRoles";
import { delay } from "utils";

const UsersFilter = (props) => {
	const { userFilters, setUserFilters, setPage, setFilterCleared } =
		useUsersFilterStore();

	const initialValues = {
		first_name: "",
		last_name: "",
		email: "",
		role_is: "",
		trashed: "",
	};

	const { roles } = useViewableRoles();

	const handleReset = (resetForm) => {
		resetForm();
		setUserFilters({
			...userFilters,
			"filter[first_name]": "",
			"filter[last_name]": "",
			"filter[email]": "",
			"filter[role_is]": "",
			"filter[trashed]": "",
		});
		setFilterCleared(true);
	};

	return (
		<div className="w-full">
			<Formik initialValues={initialValues}>
				{({ setFieldValue, values, resetForm }) => {
					const handleChangeFirstName = async (e) => {
						const value = e.target.value;
						setFieldValue("first_name", value);
						await delay(5);
						setPage(1);
						setUserFilters({
							...userFilters,
							"filter[first_name]": value,
						});
						setFilterCleared(false);
					};

					const handleChangeLastName = async (e) => {
						const value = e.target.value;
						setFieldValue("last_name", value);
						await delay(5);
						setPage(1);
						setUserFilters({
							...userFilters,
							"filter[last_name]": values?.last_name,
						});
						setFilterCleared(false);
					};
					const handleChangeEmail = async (e) => {
						const value = e.target.value;
						setFieldValue("email", value);
						await delay(5);
						setPage(1);
						setUserFilters({
							...userFilters,
							"filter[email]": value,
						});
						setFilterCleared(false);
					};

					const handleChangeIsActive = (e) => {
						//with, only, not
						setPage(1);
						let value = "with";
						if (e.target.value === "1") {
							value = "not";
						} else if (e.target.value === "0") {
							value = "only";
						} else {
							value = "with";
						}
						setFieldValue("trashed", e.target.value);
						setUserFilters({
							...userFilters,
							"filter[trashed]": value,
						});
						setFilterCleared(false);
					};

					const handleChangeRoleIs = (e) => {
						setPage(1);
						const value = e.target.value;
						setFieldValue("role_is", value);
						setUserFilters({
							...userFilters,
							"filter[role_is]": value,
						});
						setFilterCleared(false);
					};

					return (
						<Form className="w-full">
							{!props.minimize && (
								<div className="flex items-center justify-center gap-3">
									<p>Apply filters</p>
									<select
										name="trashed"
										className="rounded border border-gray-200 px-2 py-1 w-40"
										onChange={handleChangeIsActive}
										value={values.trashed}
									>
										<option label="-Status-"></option>
										<option value="1">Active</option>
										<option value="0">Deactivated</option>
									</select>

									<select
										name="role_is"
										className="rounded border border-gray-200 px-2 py-1 w-40"
										onChange={handleChangeRoleIs}
										value={values.role_is}
									>
										<option label="-User role-"></option>

										{roles?.map((role, index) => (
											<option key={index} value={role.value}>
												{role.label}
											</option>
										))}
									</select>

									<button
										type="button"
										className="flex items-center gap-2 shadow rounded-full px-3 py-1 bg-gradient-to-b from-akgreen to-gray-50"
										onClick={() => handleReset(resetForm)}
									>
										Clear Filters
										<MdCancel size={20} />
									</button>
								</div>
							)}

							<div className="flex justify-center items-center gap-2 mt-2 ">
								<input
									type="text"
									name="first_name"
									placeholder="First Name"
									onChange={handleChangeFirstName}
									value={values.first_name}
									className="rounded border border-gray-200 px-2 py-1"
								/>
								<input
									type="text"
									name="last_name"
									placeholder="Last Name"
									onChange={handleChangeLastName}
									value={values.last_name}
									className="rounded border border-gray-200 px-2 py-1"
								/>
								<input
									type="text"
									name="email"
									placeholder="Email"
									onChange={handleChangeEmail}
									value={values.email}
									className="rounded border border-gray-200 px-2 py-1"
								/>

								<button
									type="button"
									className="flex items-center gap-2 shadow rounded-full px-3 py-1 bg-gradient-to-b from-akgreen to-gray-50"
									onClick={() => handleReset(resetForm)}
								>
									Clear Filters
									<MdCancel size={20} />
								</button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default UsersFilter;
