import React from "react";
import { Dialog } from "primereact/dialog";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import useOverlayStore from "store/overlayStore";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import useAddGenre from "api/GenreResource/useAddGenre";

const AddCollection = () => {
  const { showCreateCollection, setShowCreateCollection } = useOverlayStore();

  const initialValues = {
    name: "",
  };

  const validate = Yup.object().shape({
    name: Yup.string().required("Collection name is required"),
  });

  const { mutateAsync, isLoading: isAddingCollection } = useAddGenre();
  const handleSubmit = async (data) => {
    await mutateAsync(data);
  };

  return (
    <Dialog
      visible={showCreateCollection}
      style={{ width: "50vw" }}
      header={
        <div className="text-xl font-sans font-bold sticky top-4 z-20 bg-white mr-2">
          <p className="">Create Book Collection</p>
        </div>
      }
      onHide={() => setShowCreateCollection(false)}
      position="top"
      draggable={false}
      contentClassName="rounded-b-xl "
      className="rounded-xl relative"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={handleSubmit}
      >
        {() => {
          return (
            <Form className="py-6 space-y-6">
              <FormField type="text" name="name" label="Collection Name *" />

              <div className=" flex justify-center border-t border-gray-300 pt-2">
                <button
                  type="submit"
                  className="px-4 py-2 rounded-full bg-akgreenest text-white flex gap-1 items-center justify-center"
                >
                  {isAddingCollection ? (
                    <>
                      Submitting{" "}
                      <AiOutlineLoading3Quarters
                        size={20}
                        className="animate-spin"
                      />
                    </>
                  ) : (
                    <>
                      Submit <IoIosArrowForward size={20} />
                    </>
                  )}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AddCollection;
