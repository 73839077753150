import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects, fetchConfig } from "utils";

const useGetBAStatistics = (queryParams) => {
	const { accessToken } = useTokenStore();
	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const params = queryParams ? deleteEmptyObjects(queryParams) : null;
		const url = params?.userId
			? `brand-ambassador-statistics/${params?.userId}`
			: "brand-ambassador-statistics";
		const res = await instance.get(url, {
			params: { ...params },
		});
		return res.data;
	};

	return useQuery(["ba-statistics", queryParams], () => fetch(), {
		...fetchConfig,
	});
};

export default useGetBAStatistics;
