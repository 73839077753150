import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useAuthStore from "store/authStore";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";

const useProfile = (userId = null) => {
	const { accessToken } = useTokenStore();

	const profileId = useAuthStore((state) => state.userProfile)?.id;

	const id = userId ?? profileId;

	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const res = await instance.get(`users/${id}`);
		return res.data;
	};

	return useQuery(["profile", userId], () => fetch(), {
		...fetchConfig,
	});
};

export default useProfile;
