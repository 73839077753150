import React from "react";
import { BiMessageSquareError } from "react-icons/bi";
import {
	BsBookmarks,
	BsCreditCard2Front,
	BsDashCircleDotted,
	BsTerminal,
} from "react-icons/bs";
import { FaRegBuilding } from "react-icons/fa";
import { FiUserCheck, FiUsers } from "react-icons/fi";
import { HiOutlineTicket } from "react-icons/hi";
import { IoKeypadOutline } from "react-icons/io5";
import { MdOutlineSubject } from "react-icons/md";
import { RiProductHuntLine } from "react-icons/ri";
import useAuthStore from "store/authStore";
import { Permission } from "utils/permissions";
import { Role } from "utils/roles";

const useMenuItems = () => {
	const { hasRoles, hasPermissions } = useAuthStore();
	const ICON_SIZE = 25;
	let adminDashboard = "/admin-dashboard";

	if (hasRoles([Role.SENIOR_PUBLISHER, Role.PUBLISHER])) {
		adminDashboard = "/publisher-dashboard";
	} else if (hasRoles([Role.INSTITUTION, Role.EDUCATOR])) {
		adminDashboard = "/institution-dashboard";
	} else if (hasRoles([Role.BA])) {
		adminDashboard = "/ba-dashboard";
	}

	const menu = [
		{
			to: adminDashboard,
			label: "Dashboard",
			icon: <BsDashCircleDotted size={ICON_SIZE} />,
			show: hasRoles([
				Role.SUPER_ADMIN,
				Role.ADMIN,
				Role.SENIOR_PUBLISHER,
				Role.PUBLISHER,
				Role.INSTITUTION,
				Role.EDUCATOR,
				Role.BA,
			]),
		},
		{
			to: "/ba/client-payments",
			label: "Statement",
			icon: <RiProductHuntLine size={ICON_SIZE} />,
			show: hasRoles([Role.BA]),
		},

		{
			to: "/books/admin/all",
			label: "Books",
			icon: <BsBookmarks size={ICON_SIZE} />,
			show:
				hasRoles([
					Role.SUPER_ADMIN,
					Role.ADMIN,
					Role.SENIOR_PUBLISHER,
					Role.PUBLISHER,
				]) && hasPermissions([Permission.VIEW_BOOKS]),
		},
		{
			to: "/books/institution",
			label: "Books",
			icon: <BsBookmarks size={ICON_SIZE} />,
			show:
				hasRoles([Role.INSTITUTION, Role.EDUCATOR]) &&
				hasPermissions([Permission.VIEW_BOOKS]),
		},
		{
			to: "/books/admin/genres-and-categories",
			label: "Book categories",
			icon: <BsBookmarks size={ICON_SIZE} />,
			show:
				hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) &&
				hasPermissions([Permission.VIEW_CATEGORY, Permission.VIEW_GENRE]),
		},
		{
			to: "/users/admin/all",
			label: "Users",
			icon: <FiUsers size={ICON_SIZE} />,
			show:
				hasRoles([
					Role.SUPER_ADMIN,
					Role.ADMIN,
					Role.SENIOR_PUBLISHER,
					Role.PUBLISHER,
					Role.INSTITUTION,
					Role.EDUCATOR,
				]) && hasPermissions([Permission.VIEW_USER]),
		},
		{
			to: "/brand-ambassadors",
			label: "Brand Ambassadors",
			icon: <FiUserCheck size={ICON_SIZE} />,
			show:
				hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) &&
				hasPermissions([Permission.VIEW_USER]),
		},
		{
			to: "/books/publisher/purchased",
			label: "Sold books",
			icon: <BsBookmarks size={ICON_SIZE} />,
			show:
				hasRoles([Role.SENIOR_PUBLISHER, Role.PUBLISHER]) &&
				hasPermissions([Permission.VIEW_SOLD_BOOKS]),
		},
		{
			to: "/subject-groups",
			label: "Subject groups",
			icon: <MdOutlineSubject size={ICON_SIZE} />,
			show:
				hasRoles([
					Role.SUPER_ADMIN,
					Role.ADMIN,
					Role.INSTITUTION,
					Role.EDUCATOR,
				]) && hasPermissions([Permission.VIEW_SUBJECT_GROUP]),
		},
		{
			to: "/roles-and-permissions",
			label: "Roles/permissions",
			icon: <IoKeypadOutline size={ICON_SIZE} />,
			show:
				hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) &&
				hasPermissions([Permission.VIEW_ROLES_AND_PERMISSIONS]),
		},
		{
			to: "/vouchers",
			label: "Vouchers",
			icon: <HiOutlineTicket size={ICON_SIZE} />,
			show:
				hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) &&
				hasPermissions([Permission.VIEW_VOUCHER]),
		},
		{
			to: "/pay-publisher",
			label: "Record publisher payouts",
			icon: <HiOutlineTicket size={ICON_SIZE} />,
			show:
				hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) &&
				hasPermissions([Permission.RECORD_PUB_PAYOUTS]),
		},
		{
			to: "/pay-brand-ambassador",
			label: "Record BA payouts",
			icon: <BsCreditCard2Front size={ICON_SIZE} />,
			show:
				hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) &&
				hasPermissions([Permission.RECORD_BA_PAYOUTS]),
		},
		{
			to: "/publishers",
			label: "Publishers",
			icon: <FaRegBuilding size={ICON_SIZE} />,
			show:
				hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) &&
				hasPermissions([Permission.VIEW_PUBLISHER]),
		},
		{
			to: "/institutions",
			label: "Institutions",
			icon: <FaRegBuilding size={ICON_SIZE} />,
			show:
				hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) &&
				hasPermissions([Permission.VIEW_INSTITUTION]),
		},
		{
			to: "/orders",
			label: "Orders",
			icon: <RiProductHuntLine size={ICON_SIZE} />,
			show:
				hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) &&
				hasPermissions([Permission.VIEW_ORDER]),
		},
		{
			to: "/error-report",
			label: "Error report",
			icon: <BiMessageSquareError size={ICON_SIZE} />,
			show:
				hasRoles([Role.SUPER_ADMIN]) &&
				hasPermissions([Permission.VIEW_ERRORS]),
		},
		{
			to: "/command-center",
			label: "Command center",
			icon: <BsTerminal size={ICON_SIZE} />,
			show:
				hasRoles([Role.SUPER_ADMIN]) && hasPermissions([Permission.RUN_CLI]),
		},
	];
	return menu.filter((item) => item.show);
};

export default useMenuItems;
