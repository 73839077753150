import { ButtonLoader } from "components";
import Admin2 from "layouts/Admin2";
import React from "react";
import ClearBackendCache from "./ClearBackendCache";
import RunMigrations from "./RunMigrations";

import useGetServerDetails from "api/CommandCenterResource/useGetServerDetails";

const CommandCenter = () => {
	const { isLoading: isLoadingServerEnv } = useGetServerDetails();

	if (isLoadingServerEnv) {
		return (
			<Admin2>
				<div className="space-y-4 flex justify-center items-center">
					<ButtonLoader size={60} />
				</div>
			</Admin2>
		);
	}

	// const envItems = data?.data;

	// const envs = data?.data
	// 	? Object.keys(data?.data)?.map((key) => ({
	// 			key: key,
	// 			value: envItems[key],
	// 	  }))
	// 	: [];

	return (
		<Admin2>
			<div>
				<div className="space-y-4">
					<h3>Artisan commands</h3>

					<ClearBackendCache />

					<RunMigrations />
				</div>
			</div>
		</Admin2>
	);
};

export default CommandCenter;
