import StarRating from "components/StarRating";

const CartItem = ({
  title,
  coverUrl,
  author,
  publisher,
  rating,
  currencyCode,
  currencySymbol,
  price,
  isbn,
  bookId,
}) => {
  return (
    <div className="flex mt-2 w-full border p-1 rounded shadow-lg">
      <div className="w-1/3 bg-gray-100 border border-gray-200">
        {coverUrl && <img src={coverUrl} alt="Book Cover" className="h-52" />}
      </div>
      <div className="w-2/3 pl-2">
        <p className="text-sm">{title}</p>
        <p className="text-sm mb-2">ISBN: {isbn || "N/A"}</p>
        <p className="">{author}</p>
        <p className="mt-1 font-bold">Published By:</p>
        <p className="text-xs pb-2 border-b-2 mb-2 border-dotted border-white">
          {publisher}
        </p>
        <p className="text-xs">
          <StarRating number={rating} />
        </p>
        <div className="grid grid-cols-2 mt-3">
          <div className="text-center">
            <p className="text-md">Only</p>
            <p className="text-lg">
              {currencyCode} {currencySymbol}
              {price}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
