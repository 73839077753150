import InitApi from "api/InitApi";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useCreatePurchaseOrder = () => {
	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	const history = useHistory();
	const location = useLocation();
	const retryUrl = location.pathname;

	const submit = async (obj) => {
		const instance = await InitApi({ accessToken });

		const body = deleteEmptyObjects(obj);

		const response = await instance.post("/orders/create", body);
		return response?.data;
	};

	return useMutation(submit, {
		onSuccess: async ({ data }) => {
			queryClient.invalidateQueries("user_cart_items");
			queryClient.invalidateQueries("user_cart_count");
			queryClient.invalidateQueries("picks");
			queryClient.invalidateQueries("purchased");

			history.push(
				`payment-success-response?payment_method=${data?.payment_method}`
			);
		},
		onError: async (error) => {
			queryClient.invalidateQueries("user_cart_items");
			queryClient.invalidateQueries("user_cart_count");
			queryClient.invalidateQueries("picks");
			queryClient.invalidateQueries("purchased");
			const errorMsg = error?.response?.data?.message;
			const queryString = `retryUrl=${retryUrl}error=${errorMsg}`;
			history.push(`/payment-error-response?${queryString}`);
		},
	});
};

export default useCreatePurchaseOrder;
