import StarRating from "components/StarRating";
import { Link } from "react-router-dom";
import tw from "tailwind-styled-components/dist/tailwind";

const CoverContainer = tw.div`
  bg-gradient-to-r from-gray-600 via-gray-400 to-gray-600
  border-2 border-gray-200 
  flex justify-center items-center
  max-w-max
  
`;

const BookItemRow = ({
  title,
  coverUrl,
  author,
  publisher,
  rating,
  currencyCode,
  currencySymbol,
  price,
  bookId,
  sellable,
}) => {
  return (
    <div className="flex flex-col justify-between border-gray-300 rounded-md">
      <CoverContainer>
        <img
          src={coverUrl}
          alt="Book Cover"
          className="bg-gray-600"
          width="100"
        />
      </CoverContainer>
      <div className="mt-1">
        <p className="text-sm truncate w-28" title={title}>
          {title}
        </p>
        <p className="truncate w-28" title={title}>
          {author}
        </p>
        {/* <p className="mt-1 font-bold">Published by:</p>
        <p>{publisher}</p> */}
        {/* <p>
          {currencyCode} {currencySymbol}
          {price} or {credits}
        </p> */}
        <p>
          <StarRating number={rating} />
        </p>

        <Link
          to={`/books/${bookId}`}
          className="book-item-button"
          style={{ marginLeft: 0 }}
        >
          Preview
        </Link>

        <div className="mt-1">
          {/* {!!sellable && (
            <div className="mt-1">
              <AddToCart
                dark
                title={title}
                coverUrl={coverUrl}
                author={author}
                publisher={publisher}
                currencyCode={currencyCode}
                currencySymbol={currencySymbol}
                price={price}
                bookId={bookId}
                rating={rating}
              />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default BookItemRow;
