import React from "react";
// import { ConfirmPopup } from "primereact/confirmpopup";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiX } from "react-icons/fi";
import { confirmDialog } from "primereact/confirmdialog";
import useDeleteSubjectGroup from "api/SubjectGroupResource/useDeleteSubjectGroup";

const DeleteSubjectGroup = ({ id }) => {
	// const [visible, setVisible] = useState(false);

	const { mutateAsync, isLoading: userIsDeleting } = useDeleteSubjectGroup(id);

	const accept = async () => {
		await mutateAsync();
	};

	const messageNode = (
		<div className="pt-2 flex flex-col gap-2">
			<p className="text-base font-bold ">Do you want to delete this record?</p>
			<p>
				Note that students will lose access to books <br /> under this subject group
			</p>
		</div>
	);

	// message:"Do you want to delete this record? Note that students will lose access to books under this subject group",

	const confirm = () => {
		confirmDialog({
			message: messageNode,
			header: "Delete Confirmation",
			icon: "pi pi-info-circle",
			acceptClassName: "bg-akgreener",
			acceptLabel: "Delete",
			accept,
			// reject,
		});
	};

	return (
		<>
			<button type="button" className="appearance-none border-0" onClick={confirm}>
				{userIsDeleting ? <AiOutlineLoading3Quarters size={20} className="animate-spin" /> : <FiX size={20} />}
			</button>
		</>
	);
};

export default DeleteSubjectGroup;
