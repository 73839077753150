import useFetchBrandAmbassadors from "api/UserResource/useFetchBrandAmbassadors";
import Admin2 from "layouts/Admin2";
import React, { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiSearch, FiX } from "react-icons/fi";
import { activeCurrencies, delay, getActiveCurrencyCodeLabel } from "utils";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import FormField from "components/v2/FormField";
import { IoIosArrowForward } from "react-icons/io";
import useRegisterBApayouts from "api/BrandAmbassadorResource/useRegisterBApayouts";

const BrandAmbassadorPayouts = () => {
	const [search, setSearch] = useState("");
	const [querySearch, setQuerySearch] = useState("");
	const [selectedBA, setSelectedBA] = useState(null);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [showLoader, setShowLoader] = useState(false);

	const handleInputChange = (e) => {
		setShowLoader(true);
		setSearch(e.target.value);
		if (e.target.value.length <= 0) {
			setShowLoader(false);
			setDropdownOpen(false);
			setQuerySearch(e.target.value);
			return;
		}
		delay(2).then(() => {
			setShowLoader(false);
			setQuerySearch(e.target.value);
			setDropdownOpen(true);
		});
	};

	const { data, isLoading } = useFetchBrandAmbassadors({
		enabled: querySearch?.length > 0,
		search,
		rows_per_page: 10,
	});

	const brandAmbassadors = data?.data?.records || [];

	const SEARCH_INPUT_WIDTH = 380;
	const SEARCH_BUTTON_WIDTH = 90;
	const INPUT_WIDTH = SEARCH_INPUT_WIDTH + SEARCH_BUTTON_WIDTH;

	const initialValues = {
		amount: "",
		currency: activeCurrencies.USD.code,
		notes: "",
		user_id: "",
		reference: "",
	};
	const validate = Yup.object().shape({
		amount: Yup.number()
			.required("Amount is required")
			.moreThan(0, "Amount should be greater than 0"),
		currency: Yup.string().required("Currency is required"),
		user_id: Yup.string().required("Brand Ambassador is required"),
	});

	const {
		mutateAsync,
		isLoading: isProcessingPayment,
		isSuccess,
	} = useRegisterBApayouts();

	const onSubmit = async (values) => {
		await mutateAsync(values);
	};

	return (
		<Admin2>
			<Formik
				initialValues={initialValues}
				validationSchema={validate}
				onSubmit={onSubmit}
			>
				{(formik) => {
					// console.log(formik.values);
					const currencyLabel = formik.values.currency
						? `(in ${getActiveCurrencyCodeLabel(formik.values.currency)})`
						: "";

					const handleSelect = (ba) => {
						setSelectedBA(ba);
						setDropdownOpen(false);
						formik.setFieldValue("user_id", ba?.id);
					};

					return (
						<Form>
							<div
								className="mx-auto flex flex-col items-center"
								style={{ maxWidth: 500 }}
							>
								{isSuccess && (
									<p
										className="text-center p-3 rounded-lg bg-green-500 text-white"
										style={{ width: INPUT_WIDTH }}
									>
										Payment for {selectedBA?.first_name} {selectedBA?.last_name}{" "}
										has been successfully recorded
									</p>
								)}
								<h3 className="my-4 text-center">
									Please search the Brand Ambassador you want to record payment
									for
								</h3>
								<div className="flex items-center gap-0 relative">
									<input
										type="text"
										name="search"
										value={search}
										onChange={handleInputChange}
										placeholder="Search brand ambassador by name, email ..."
										className="border border-gray-300 h-12 pl-12 pr-2 rounded-lg focus:border-akgreener ring-0  "
										style={{ width: INPUT_WIDTH }}
										onFocus={() => setDropdownOpen(true)}
									/>
									<div className="border-0 absolute left-3">
										{isLoading || showLoader ? (
											<AiOutlineLoading3Quarters
												size={24}
												className="text-gray-400 animate-spin"
											/>
										) : (
											<FiSearch size={30} className="text-gray-400" />
										)}
									</div>
								</div>
								{dropdownOpen && brandAmbassadors?.length > 0 && (
									<ul
										style={{ width: INPUT_WIDTH }}
										className=" z-10 border border-gray-200 shadow-md overflow-y-auto max-h-60 bg-gray-50 relative"
									>
										<button
											type="button"
											className="absolute right-1 top-1 h-8 w-8 rounded-full bg-white flex items-center justify-center border border-akgreen"
											onClick={() => setDropdownOpen(false)}
										>
											<FiX size={25} />
										</button>
										{brandAmbassadors?.map((ba) => (
											<li
												key={ba?.id}
												className={`cursor-pointer  py-2 px-4 hover:bg-gray-200 ${
													ba?.id === selectedBA?.id
														? "bg-gray-300"
														: "bg-transparent"
												} `}
												onClick={() => handleSelect(ba)}
											>
												<span className="font-bold opacity-70">
													{ba?.first_name} {ba?.last_name}
												</span>{" "}
												- <span>{ba?.email}</span>
											</li>
										))}
									</ul>
								)}

								<div className="space-y-2 mt-5" style={{ width: INPUT_WIDTH }}>
									{selectedBA && (
										<>
											<div className="px-3 py-3 rounded-lg bg-green-100 space-y-2">
												<p>
													You are about to register payment for the following
													Brand Ambassador
												</p>
												<p className="flex items-center gap-2">
													<span className="font-bold ">
														{selectedBA?.first_name} {selectedBA?.last_name}
													</span>
													<span>-</span>
													<span>({selectedBA?.email})</span>
												</p>
											</div>

											<div className="pt-8 space-y-6">
												{formik.errors?.user_id && (
													<p>
														Please search and select the Brand Ambassador you
														want to record payment for
													</p>
												)}
												<FormField
													type="select"
													name="currency"
													label="Currency *"
													options={[
														{
															label: activeCurrencies.USD.label,
															value: activeCurrencies.USD.code,
														},
														{
															label: activeCurrencies.ZWL.label,
															value: activeCurrencies.ZWL.code,
														},
													]}
												/>

												<FormField
													name="amount"
													type="number"
													min="0"
													step="0.01"
													label={`Amount to be paid ${currencyLabel} *`}
												/>

												<FormField
													type="text"
													name="reference"
													label="Reference (e.g bank transaction reference)"
												/>

												<FormField type="textarea" name="notes" label="Notes" />

												<div>
													<button
														type="submit"
														className="px-4 py-2 rounded-full bg-akgreenest text-white flex gap-1 items-center justify-center "
													>
														{isProcessingPayment ? (
															<>
																Processing payment{" "}
																<AiOutlineLoading3Quarters
																	size={20}
																	className="animate-spin"
																/>
															</>
														) : (
															<>
																Proceed with payment{" "}
																<IoIosArrowForward size={20} />
															</>
														)}
													</button>
												</div>
											</div>
										</>
									)}
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</Admin2>
	);
};

export default BrandAmbassadorPayouts;
