import UsersFilter from "admin/Users2/UsersFilter";
import useFetchUsers from "api/UserResource/useFetchUsers";
import { ButtonLoader } from "components";
import { DataTable } from "primereact/datatable";
import { useFormik } from "formik";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import React, { useState } from "react";
import { AiOutlineLine, AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import useAuthStore from "store/authStore";
import useUsersFilterStore from "store/userFilterStore";
import useAssignBooksToUsers from "api/BookResource/useAssignBooksToUsers";
import { Dialog } from "primereact/dialog";
import { FiArrowRight, FiX } from "react-icons/fi";

const AssignUsers = ({ books, className, children }) => {
	const { userFilters, page, setPage } = useUsersFilterStore();

	const { userProfile } = useAuthStore();

	const [firstPage, setFirstPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [display, setdisplay] = useState(false);

	const { data: usersData, isLoading: isLoadingUsers } = useFetchUsers({
		page: page,
		rows_per_page: rowsPerPage,
		...userFilters,
		"filter[role_is]": "Student",
		"filter[institution_is]": userProfile?.institutions[0]?.id,
	});

	const [selectedUsers, setselectedUsers] = useState([]);

	// const users = usersData?.data?.records?.filter((user) => {
	// 	const assignedBooksIds = user?.assignedBooks?.map(
	// 		(assignedBook) => assignedBook.id
	// 	);
	// 	const booksIds = books?.map((book) => book.id);
	// 	if()
	// 	return 0;
	// });

	const users = usersData?.data?.records;

	const totalRecords = usersData?.data?.total_records;

	const onBasicPageChange = (event) => {
		setFirstPage(event.first);
		setRowsPerPage(event.rows);
		setPage(event.page + 1);
	};

	const roleTemplate = (row) => (
		<p className="flex flex-col">
			<span className="px-2 py-1 rounded-full text-white bg-akgreener text-xs text-center">
				{row?.role_name[0]}
			</span>
		</p>
	);

	const { mutateAsync, isLoading: isAssigningBooks } = useAssignBooksToUsers();

	const { values, handleSubmit, setFieldValue } = useFormik({
		initialValues: {
			users: [...selectedUsers?.map((user) => user.id)],
			books: [...books],
		},
		onSubmit: async (data) => {
			// let books = [];
			const books = data.books
				.filter((book) => Number(book.number_assignable) >= data.users.length)
				.map((book) => book.id);

			const payload = {
				users: data.users,
				books,
			};
			await mutateAsync(payload);

			setdisplay(false);
		},

		enableReinitialize: true,
	});

	const notAssignable = (book) => {
		const assignable = Number(book?.number_assignable);
		return values?.users?.length > assignable || assignable <= 0;
	};

	return (
		<>
			<button
				type="button"
				className={`appearance-none border-0 ${className} `}
				onClick={() => setdisplay(true)}
			>
				{children || <BsPencil size={15} />}
			</button>

			<Dialog
				visible={display}
				onHide={() => setdisplay(false)}
				breakpoints={{
					"960px": "90vw",
					"0px": "100vw",
				}}
				className="font-sans"
				showHeader={false}
				contentClassName="rounded-md relative"
				blockScroll={false}
			>
				<button
					type="button"
					onClick={() => setdisplay(false)}
					className="absolute top-4 right-4"
				>
					<FiX size={30} />
				</button>

				<div className="pt-8 pb-1 ">
					<div className="px-4">
						<h4 className="font-cera-black text-akgreenest text-xl ">
							Give students access to the {books?.length} selected book(s)
						</h4>
						<p>
							A book marked is "Not assignable" cannot be assigned because the
							number of copies remaining
							<br /> for assigning is less than the number of users you want to
							assign
						</p>
					</div>

					<div className="grid grid-cols-3 gap-x-6 mt-4 pt-4 border-t px-4 divide-x border-gray-300">
						<div className="col-span-1 space-y-2 py-4">
							<h4 className="font-bold">Books selected for assigning</h4>

							<div className="flex flex-col gap-2">
								{books?.map((book) => (
									<p
										key={book.id}
										className={`pl-3 pr-1 text-sm rounded-full border border-gray-300 flex items-center justify-between gap-2 py-1`}
									>
										<span
											style={{ width: 350 }}
											className={`truncate text-base ${
												notAssignable(book) ? "line-through" : ""
											} `}
										>
											{book?.title} by{" "}
											<span className="font-bold">({book?.author})</span>
										</span>
										{notAssignable(book) ? (
											<span className="bg-red-400 text-white px-2 py-2 rounded-full">
												Not assignable
											</span>
										) : (
											<span className="bg-white text-white px-2 py-2 rounded-full ">
												Assignable
											</span>
										)}
									</p>
								))}
							</div>
						</div>

						<div
							className={`col-span-2 py-4 flex ${
								isLoadingUsers
									? "items-center justify-center"
									: "items-start justify-start"
							}  `}
						>
							{(function () {
								if (isLoadingUsers) {
									return <ButtonLoader size={40} />;
								}

								return (
									<div className="space-y-4">
										<form
											onSubmit={handleSubmit}
											className="flex flex-col items-center space-y-4 "
											style={{ maxWidth: "100%" }}
										>
											<div className="flex justify-end w-full pr-2">
												<button
													type="submit"
													className="flex items-center gap-2 px-4 py-3 rounded-full bg-akgreener text-white"
												>
													Assign books{" "}
													{isAssigningBooks ? (
														<AiOutlineLoading3Quarters
															size={20}
															className="animate-spin"
														/>
													) : (
														<FiArrowRight size={20} />
													)}
												</button>
											</div>

											<UsersFilter minimize />

											<DataTable
												value={users}
												selection={selectedUsers}
												onSelectionChange={(e) => {
													setselectedUsers(e.value);
													setFieldValue("users", [
														...e.value?.map((user) => user?.id),
													]);
												}}
												tableClassName="font-sans text-sm"
												autoLayout
											>
												<Column
													selectionMode="multiple"
													headerStyle={{ width: "3em" }}
												></Column>
												<Column
													field="first_name"
													header="First Name"
													sortable
												/>
												<Column field="last_name" header="Last Name" sortable />
												<Column field="email" header="Email" sortable />
												{/* <Column field="address" header="Address" sortable /> */}
												<Column
													header="Phone Number"
													headerClassName="text-center"
													bodyClassName="text-center"
													body={(row) =>
														row.mobile_number ? (
															<span>{row.mobile_number} </span>
														) : (
															<AiOutlineLine size={20} />
														)
													}
													sortable
												/>
												<Column
													header="Role"
													headerClassName="text-center"
													body={roleTemplate}
												/>
											</DataTable>

											<Paginator
												first={firstPage}
												rows={rowsPerPage}
												totalRecords={totalRecords}
												rowsPerPageOptions={[10, 20, 30, 50]}
												onPageChange={onBasicPageChange}
												pageLinkSize={4}
												className="font-sans bg-white mt-4"
												template={{
													layout:
														"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
												}}
											/>

											<div className="flex justify-end w-full pr-2">
												<button
													type="submit"
													className="flex items-center gap-2 px-4 py-3 rounded-full bg-akgreener text-white"
												>
													Assign books{" "}
													{isAssigningBooks ? (
														<AiOutlineLoading3Quarters
															size={20}
															className="animate-spin"
														/>
													) : (
														<FiArrowRight size={20} />
													)}
												</button>
											</div>
										</form>
									</div>
								);
							})()}
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default AssignUsers;
