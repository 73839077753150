import useDeletePublisher from "api/PublisherResource/useDeletePublisher";
import { Dialog } from "primereact/dialog";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiX } from "react-icons/fi";
import { MdOutlineDelete } from "react-icons/md";

const DeletePublisher = ({ publisherId, publisherName }) => {
	const [visible, setVisible] = React.useState(false);

	const { mutateAsync: destroy, isLoading: isDeleting } = useDeletePublisher(
		publisherId,
		"permanent"
	);
	const { mutateAsync: deactivate, isLoading: isDeactivating } =
		useDeletePublisher(publisherId, "temporary");

	const destroyPublisher = async () => {
		await destroy();
		setVisible(false);
	};

	const deactivatePublisher = async () => {
		await deactivate();
		setVisible(false);
	};

	return (
		<>
			<button
				type="button"
				className="appearance-none border-0"
				onClick={() => setVisible(true)}
			>
				<MdOutlineDelete size={20} className="text-red-500" />
			</button>

			<Dialog
				visible={visible}
				onHide={() => setVisible(false)}
				style={{ width: "500px" }}
				showHeader={false}
				contentClassName="rounded-md"
			>
				<div className="flex justify-between gap-3 mt-4 py-4">
					<p className="font-bold text-lg">
						Delete or deactivate{" "}
						<span className="text-akgreener">{publisherName}</span>
					</p>
					<button
						type="button"
						className="appearance-none border-0"
						onClick={() => setVisible(false)}
					>
						<FiX size={20} />
					</button>
				</div>

				<div className="flex justify-between gap-4 mt-4">
					<div className="space-y-2 w-1/2 flex flex-col">
						<p className="font-bold">
							Do you want to permanently delete this publisher
						</p>
						<p>
							Note that this action will result in the lose of data associated
							with this publisher and the lost data can not be recovered.
						</p>

						<div className="flex-1 flex items-end">
							<button
								type="button"
								className="flex items-center gap-2 py-2 px-3 border border-red-600 bg-red-600 text-white rounded-md place-self-end"
								onClick={destroyPublisher}
							>
								Yes, delete publisher
								{isDeleting && (
									<AiOutlineLoading3Quarters
										size={18}
										className="animate-spin text-white"
									/>
								)}
							</button>
						</div>
					</div>

					<div className="space-y-2 w-1/2 flex flex-col">
						<p className="font-bold">
							Do you want to deactivate this publisher
						</p>
						<p>
							Note that books associated with this publisher will not be
							available for purchase or subscription until the publisher is
							activated again.
						</p>

						<div className="flex-1 flex items-end">
							<button
								type="button"
								className="flex items-center gap-2 py-2 px-3 border-2 border-red-600 rounded-md"
								onClick={deactivatePublisher}
							>
								Yes, deactivate publisher
								{isDeactivating && (
									<AiOutlineLoading3Quarters
										size={18}
										className="animate-spin text-red-600"
									/>
								)}
							</button>
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default DeletePublisher;
