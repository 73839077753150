import useSearchParams from "hooks/useSearchParams";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { deleteEmptyObjects } from "utils";

const useBooksFilters = () => {
	const [firstPage, setFirstPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(40);
	const [page, setPage] = useState(1);
	const [categoryIs, setCategoryIs] = useState("");
	const [genreIs, setGenreIs] = useState("");
	const [publisherIs, setPublisherIs] = useState("");
	const [pricingModelIs, setPricingModelIs] = useState("");
	const [searchTerm, setSearchTerm] = useState("");
	const [openFilters, setOpenFilters] = useState("");

	const { getParam, setParam, deleteParam, hasParam } = useSearchParams();

	const { push } = useHistory();
	const { pathname } = useLocation();

	useEffect(() => {
		if (hasParam("open_filters")) {
			const openFiltersParam =
				getParam("open_filters")?.length > 0 ? getParam("open_filters") : "";
			setOpenFilters(openFiltersParam);
		}

		if (hasParam("page")) {
			const pageParam = getParam("page")?.length > 0 ? getParam("page") : 1;
			setPage(pageParam);
		}
		if (hasParam("rows")) {
			const rowsPerPageParam =
				getParam("rows")?.length > 0 ? getParam("rows") : 40;
			setRowsPerPage(rowsPerPageParam);
		}

		if (hasParam("first_page")) {
			const firstPageParam =
				getParam("first_page")?.length > 0 ? getParam("first_page") : 0;
			setFirstPage(firstPageParam);
		}

		if (hasParam("category_is")) {
			const categoryIsParam =
				getParam("category_is")?.length > 0 ? getParam("category_is") : "";
			setCategoryIs(categoryIsParam);
		}

		if (hasParam("genre_is")) {
			const genreIsParam =
				getParam("genre_is")?.length > 0 ? getParam("genre_is") : "";
			setGenreIs(genreIsParam);
		}

		if (hasParam("publisher_is")) {
			const publisherIsParam =
				getParam("publisher_is")?.length > 0 ? getParam("publisher_is") : "";
			setPublisherIs(publisherIsParam);
		}

		if (hasParam("pricing_model_is")) {
			const pricingModelIsParam =
				getParam("pricing_model_is")?.length > 0
					? getParam("pricing_model_is")
					: "";
			setPricingModelIs(pricingModelIsParam);
		}

		if (hasParam("search_term")) {
			const searchTermParam =
				getParam("search_term")?.length > 0 ? getParam("search_term") : "";
			setSearchTerm(searchTermParam);
		}

		return () => {
			deleteParam("open_filters");
			deleteParam("page");
			deleteParam("rows");
			deleteParam("first_page");
			deleteParam("category_is");
			deleteParam("genre_is");
			deleteParam("publisher_is");
			deleteParam("pricing_model_is");
			deleteParam("search_term");
		};
	}, [hasParam, getParam, deleteParam]);

	const changeAllFiltersAtOnce = (data) => {
		const values = deleteEmptyObjects(data);

		setFirstPage(values?.first_page || "");
		setRowsPerPage(values?.rows || "");
		setPage(values?.page || "");
		setCategoryIs(values?.category_is || "");
		setGenreIs(values?.genre_is || "");
		setPublisherIs(values?.publisher_is || "");
		setPricingModelIs(values?.pricing_model_is || "");
		setSearchTerm(values?.search_term || "");
		setOpenFilters(values?.open_filters || "");

		push(`/books?${new URLSearchParams(values).toString()}`);
	};

	const changeOpenFilters = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("open_filters");
			setOpenFilters("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("open_filters", value);
		push(`${pathname}?${q}`);
	};

	const changePage = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("page");
			setPage(1);
			return push(`${pathname}?${q}`);
		}
		const q = setParam("page", value);
		push(`${pathname}?${q}`);
	};

	const changeRowsPerPage = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("rows");
			setRowsPerPage(10);
			return push(`${pathname}?${q}`);
		}
		const q = setParam("rows", value);
		push(`${pathname}?${q}`);
	};

	const changeFirstPage = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("first_page");
			setFirstPage(0);
			return push(`${pathname}?${q}`);
		}
		const q = setParam("first_page", value);
		push(`${pathname}?${q}`);
	};

	const changeCategoryIs = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("category_is");
			setCategoryIs("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("category_is", value);
		push(`${pathname}?${q}`);
	};

	const changeGenreIs = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("genre_is");
			setGenreIs("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("genre_is", value);
		push(`${pathname}?${q}`);
	};

	const changePublisherIs = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("publisher_is");
			setPublisherIs("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("publisher_is", value);
		push(`${pathname}?${q}`);
	};

	const changePricingModelIs = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("pricing_model_is");
			setPricingModelIs("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("pricing_model_is", value);
		push(`${pathname}?${q}`);
	};

	const changeSearchTerm = (value) => {
		if (value?.length <= 0) {
			const q = deleteParam("search_term");
			setSearchTerm("");
			return push(`${pathname}?${q}`);
		}
		const q = setParam("search_term", value);
		push(`${pathname}?${q}`);
	};

	return {
		openFilters,
		firstPage,
		rowsPerPage,
		page,
		categoryIs,
		genreIs,
		publisherIs,
		pricingModelIs,
		searchTerm,
		changeOpenFilters,
		changePage,
		changeRowsPerPage,
		changeFirstPage,
		changeCategoryIs,
		changeGenreIs,
		changePublisherIs,
		changePricingModelIs,
		changeSearchTerm,
		changeAllFiltersAtOnce,
	};
};

export default useBooksFilters;
