import useGetExceptions from "api/Exceptions/useGetExceptions";
import Admin2 from "layouts/Admin2";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import StackTrace from "./StackTrace";
import useExceptionsFilters from "./useExceptionsFilters";
import { CustomPaginator } from "components";
import { Calendar } from "primereact/calendar";
import ClearFilters from "./ClearFilters";
import AdminPageLoader from "components/AdminPageLoader";
import DeleteException from "./DeleteException";

const Exceptions = () => {
	const {
		page,
		rowsPerPage,
		firstPage,
		fromDate,
		toDate,
		changePage,
		changeRowsPerPage,
		changeFirstPage,
		changeFromDate,
		changeToDate,
	} = useExceptionsFilters();

	const { data, isLoading } = useGetExceptions({
		page,
		rows_per_page: rowsPerPage,
		from_date: fromDate,
		to_date: toDate,
	});

	const errorsList = data?.data?.records;

	const countSetFilters = [fromDate, toDate].filter((item) => item)?.length;

	const stackTraceTemplate = (row) => {
		return <StackTrace row={row} />;
	};

	const deleteExceptionTemplate = (row) => {
		return <DeleteException errorId={row?.id} />;
	};

	if (isLoading) {
		return <AdminPageLoader />;
	}

	return (
		<Admin2>
			<div className="w-full flex-1 flex flex-col justify-between gap-4">
				<div className="space-y-4 ">
					<h4>Reported errors & exceptions</h4>
					<div className="flex justify-end gap-2 items-center">
						<select
							name="rowsPerPage"
							id="rowsPerPage"
							value={rowsPerPage}
							onChange={(e) => changeRowsPerPage(e.target.value)}
							className="border border-gray-300 bg-white rounded h-10 px-2"
						>
							<option value="20">20</option>
							<option value="40">40</option>
							<option value="60">60</option>
							<option value="80">80</option>
							<option value="100">100</option>
						</select>

						<Calendar
							value={new Date(fromDate)}
							name="from_date"
							dateFormat="yy-mm-dd"
							placeholder="Date (from)"
							className="w-52 h-10 font-sans"
							inputClassName="border border-gray-300 bg-white rounded"
							showIcon
							readOnlyInput
							showButtonBar
							onChange={(e) =>
								changeFromDate(moment(e.target.value).format("YYYY-MM-DD"))
							}
							onClearButtonClick={() => changeFromDate("")}
						/>

						<Calendar
							value={new Date(toDate)}
							name="to_date"
							dateFormat="yy-mm-dd"
							placeholder="Date (to)"
							className="w-52 h-10 font-sans"
							inputClassName="border border-gray-300 bg-white rounded"
							showIcon
							showButtonBar
							onChange={(e) =>
								changeToDate(moment(e.target.value).format("YYYY-MM-DD"))
							}
							onClearButtonClick={() => changeToDate("")}
						/>

						<ClearFilters
							countOfSetFilters={countSetFilters}
							changeFromDate={changeFromDate}
							changeToDate={changeToDate}
						/>
					</div>

					<DataTable
						value={errorsList}
						dataKey="id"
						autoLayout
						tableClassName="font-sans text-sm"
					>
						<Column header="Message" body={stackTraceTemplate} />
						<Column field="user" header="Thrown By" />
						<Column field="ip_address" header="IP Address" />
						<Column field="exception_class" header="Exception" />
						<Column
							header="Raised on"
							body={(row) => (
								<span>{moment(row?.created_at).format("LLL")}</span>
							)}
						/>
						<Column header="Action" body={deleteExceptionTemplate} />
					</DataTable>
				</div>

				<div className="flex-1 flex justify-end">
					<CustomPaginator
						firstPage={firstPage}
						setFirstPage={changeFirstPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={changeRowsPerPage}
						totalRecords={data?.data?.total_records}
						setPage={changePage}
						showRowsPerPageDropdown={false}
					/>
				</div>
			</div>
		</Admin2>
	);
};

export default Exceptions;
