import React from "react";
import useFetchAggregateRevenue from "api/AdminReports/useFetchAggregateRevenue";
import { Calendar } from "primereact/calendar";
import { BiCalendar } from "react-icons/bi";
import moment from "moment";
import { GiTakeMyMoney } from "react-icons/gi";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { activeCurrencies, getMoneyPrefix } from "utils";

const Card = ({ label, value, value2, icon }) => (
	<div className="py-2 px-3  bg-white rounded shadow  w-full">
		<div className="h-12 w-12 rounded-full flex items-center justify-center border">
			{icon}
		</div>

		<div className="flex flex-col items-start">
			<h4 className="text-lg border-b py-1">{label}</h4>

			<div className="mt-2 space-y-2">
				<p className="flex items-center text-base">{value}</p>
				{value2 && (
					<p className="flex  items-center text-base font-bold">{value2}</p>
				)}
			</div>
		</div>
	</div>
);

const RevenueReport = () => {
	const [fromDate, setFromDate] = React.useState("");
	const [toDate, setToDate] = React.useState("");

	const { data: revenue } = useFetchAggregateRevenue({
		from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
		to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
	});

	return (
		<div className="w-full space-y-4">
			<div className="flex justify-between items-center w-full ">
				<h4 className="font-cera-black">Revenue reporting</h4>

				<div className="flex gap-4 items-center">
					<span className="p-float-label">
						<Calendar
							id={fromDate}
							className="font-sans"
							value={fromDate}
							onChange={(e) => setFromDate(e.target.value)}
							dateFormat="yy-mm-dd"
							mask="9999-99-99"
							panelClassName="font-sans text-xs"
							showIcon
							icon={<BiCalendar size={20} />}
							inputClassName="rounded-3xl pl-4 text-sm"
						/>
						<label htmlFor={fromDate}>From date -</label>
					</span>

					<span className="p-float-label">
						<Calendar
							id={toDate}
							className="font-sans"
							value={toDate}
							onChange={(e) => setToDate(e.target.value)}
							dateFormat="yy-mm-dd"
							mask="9999-99-99"
							panelClassName="font-sans text-xs"
							showIcon
							icon={<BiCalendar size={20} />}
							inputClassName="rounded-3xl pl-4 text-sm"
						/>
						<label htmlFor={toDate}>To date -</label>
					</span>
				</div>
			</div>

			<div>
				<div className="py-3 space-y-2">
					<p className="text-lg">{activeCurrencies.USD.label} Sales</p>

					<div className="flex gap-6">
						<Card
							label="Total Sales (S + P)"
							value={`${getMoneyPrefix(activeCurrencies.USD.code)} ${
								revenue?.data?.totalSales?.beforeShare || 0
							} (before share)`}
							value2={`${getMoneyPrefix(activeCurrencies.USD.code)} ${
								revenue?.data?.totalSales?.afterShare || 0
							} (after share)`}
							icon={<GiTakeMyMoney size={30} className="text-gray-400" />}
						/>
						<Card
							label="Subscription sales (S)"
							value={`${getMoneyPrefix(activeCurrencies.USD.code)} ${
								revenue?.data?.subscriptionSales?.beforeShare || 0
							} (before share)`}
							value2={`${getMoneyPrefix(activeCurrencies.USD.code)} ${
								revenue?.data?.subscriptionSales?.afterShare || 0
							} (after share)`}
							icon={<GiTakeMyMoney size={30} className="text-gray-400" />}
						/>
						<Card
							label="Purchase sales (P)"
							value={`${getMoneyPrefix(activeCurrencies.USD.code)} ${
								revenue?.data?.purchaseSales?.beforeShare || 0
							} (before share)`}
							value2={`${getMoneyPrefix(activeCurrencies.USD.code)} ${
								revenue?.data?.purchaseSales?.afterShare || 0
							} (after share)`}
							icon={<GiTakeMyMoney size={30} className="text-gray-400" />}
						/>
					</div>
				</div>

				<div className="py-3 space-y-2">
					<p className="text-lg">{activeCurrencies.ZWL.label} Sales</p>

					<div className="flex gap-6">
						<Card
							label="Total Sales (S + P)"
							value={`${getMoneyPrefix(activeCurrencies.ZWL.code)} ${
								revenue?.data?.totalSalesZwl?.beforeShare || 0
							} (before share)`}
							value2={`${getMoneyPrefix(activeCurrencies.ZWL.code)} ${
								revenue?.data?.totalSalesZwl?.afterShare || 0
							} (after share)`}
							icon={<GiTakeMyMoney size={30} className="text-gray-400" />}
						/>
						<Card
							label="Subscription sales (S)"
							value={`${getMoneyPrefix(activeCurrencies.ZWL.code)} ${
								revenue?.data?.subscriptionSalesZwl?.beforeShare || 0
							} (before share)`}
							value2={`${getMoneyPrefix(activeCurrencies.ZWL.code)} ${
								revenue?.data?.subscriptionSalesZwl?.afterShare || 0
							} (after share)`}
							icon={<GiTakeMyMoney size={30} className="text-gray-400" />}
						/>
						<Card
							label="Purchase sales (P)"
							value={`${getMoneyPrefix(activeCurrencies.ZWL.code)} ${
								revenue?.data?.purchaseSalesZwl?.beforeShare || 0
							} (before share)`}
							value2={`${getMoneyPrefix(activeCurrencies.ZWL.code)} ${
								revenue?.data?.purchaseSalesZwl?.afterShare || 0
							} (after share)`}
							icon={<GiTakeMyMoney size={30} className="text-gray-400" />}
						/>
					</div>
				</div>
			</div>

			<div>
				<Link
					to="/orders"
					className="text-blue-900 font-bold flex items-center gap-2"
				>
					View orders <BsArrowRight size={20} />
				</Link>
			</div>
		</div>
	);
};

export default RevenueReport;
