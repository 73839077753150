import StarRating from "components/StarRating";
import { useUrlQuery } from "hooks/useUrlQuery";
import { useHistory, useLocation } from "react-router-dom";
import useCurrencyStore from "store/currencyStore";
import {
	activeCurrencies,
	getActiveCurrencyCodeLabel,
	getActiveCurrencySymbol,
} from "utils";

const BookListItem = ({
	bookId,
	title,
	author,
	coverUrl,
	rating,
	price,
	zwl_price,
	sellable,
	isFree,
}) => {
	const history = useHistory();
	const location = useLocation();
	const query = useUrlQuery();

	const { currency } = useCurrencyStore();

	const shouldShowPrice = () => {
		return price > 0 && !Boolean(isFree) && Boolean(sellable);
	};

	const handleOpenBook = () => {
		let returnUrl = location.pathname;
		const queryString = query
			.toString()
			.replace(/&/g, "lgAnd")
			.replace(/-/g, "lgHyphen");
		if (query.toString().length > 0) {
			returnUrl = `${returnUrl}lgQuestionMark${queryString}`;
		}
		return history.push(`/book/${bookId}?returnUrl=${returnUrl}`);
	};

	return (
		<>
			{/* to={`/book/${bookId}`} */}
			<div onClick={handleOpenBook} className="cursor-pointer">
				<div
					className="bg-gray-200 border flex flex-col items-center border-gray-300 rounded py-4 px-2 shadow  hover:scale-105 transition relative"
					style={{ minWidth: 160, minHeight: 260 }}
				>
					<div
						className="bg-no-repeat bg-center rounded-lg flex flex-col items-end gap-1s"
						style={{
							backgroundImage: `url(${coverUrl})`,
							backgroundSize: "150px 230px",
							width: 150,
							height: 230,
							position: "relative",
						}}
					>
						{Boolean(!isFree) && (
							<span className="h-10 w-10 text-xs bg-white rounded-full flex items-center justify-center border-2 border-akgreener -mt-3 -mr-2">
								{getActiveCurrencyCodeLabel(currency)}
							</span>
						)}
					</div>

					{isFree && (
						<span className="bg-white px-3 py-1 rounded-bl text-akgreenest font-bold absolute left-0 bottom-0">
							Free
						</span>
					)}
				</div>

				<div
					className="flex flex-col items-center mb-4"
					style={{ minWidth: 160 }}
				>
					<div className="mt-2" style={{ width: 150 }}>
						<StarRating number={rating} />
						<p className="text-xs truncate" title={author}>
							Written By: <span className=" font-cera-black">{author}</span>
						</p>
						<p className="truncate" title={title}>
							{title}
						</p>

						{shouldShowPrice() && (
							<p className="font-bold text-base font-serif">
								{getActiveCurrencySymbol(currency)}&nbsp;
								{currency === activeCurrencies.USD.code
									? price?.toFixed(2)
									: zwl_price?.toFixed(2)}
							</p>
						)}
					</div>
				</div>
			</div>
			{/* </div> */}

			{/* <BookDetails
				bookId={bookId}
				visible={visible}
				setVisible={setVisible}
				coverUrl={coverUrl}
				title={title}
				rating={rating}
				description={description}
				subscription={subscription}
				price={price}
				zwl_price={zwl_price}
				shouldShowPrice={shouldShowPrice()}
				publisher={publisher}
				credits={credits}
				author={author}
				inLibrary={inLibrary}
				hasSubscription={hasSubscription}
				canRead={canRead}
				isPurchased={isPurchased}
				sellable={sellable}
				rate={currencyRate?.data?.rate}
			/> */}
		</>
	);
};

export default BookListItem;
