import useFetchPublisherBookPerformance from "api/AdminReports/useFetchPublisherBookPerformance";
import React, { useState } from "react";
import moment from "moment";
import BookCard from "admin/AllBooks2/BookCard";
import { ButtonLoader } from "components";
import useAuthStore from "store/authStore";
import { BsJournalBookmark } from "react-icons/bs";
import { Calendar } from "primereact/calendar";
import { BiCalendar } from "react-icons/bi";
import { Role } from "utils/roles";

const PublisherBookPerformance = ({ userId }) => {
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");

	const { data: response, isLoading } = useFetchPublisherBookPerformance(
		{
			from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
			to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
		},
		userId
	);

	const hasRoles = useAuthStore((state) => state.hasRoles);

	if (isLoading) {
		return (
			<div className="flex justify-center">
				<ButtonLoader size={60} />
			</div>
		);
	}

	return (
		<div className="w-full space-y-2">
			{/* <h4>Books Performance</h4> */}

			<div className="flex justify-between gap-4 items-end w-full ">
				<h4 className="font-cera-black">Books Performance</h4>

				<div className="flex gap-4 items-center">
					<span className="p-float-label">
						<Calendar
							id={fromDate}
							className="font-sans"
							value={fromDate}
							onChange={(e) => setFromDate(e.target.value)}
							dateFormat="yy-mm-dd"
							mask="9999-99-99"
							panelClassName="font-sans text-xs"
							showIcon
							icon={<BiCalendar size={20} />}
							inputClassName="rounded-3xl pl-4 text-sm"
						/>
						<label htmlFor={fromDate}>From date -</label>
					</span>

					<span className="p-float-label">
						<Calendar
							id={toDate}
							className="font-sans"
							value={toDate}
							onChange={(e) => setToDate(e.target.value)}
							dateFormat="yy-mm-dd"
							mask="9999-99-99"
							panelClassName="font-sans text-xs"
							showIcon
							icon={<BiCalendar size={20} />}
							inputClassName="rounded-3xl pl-4 text-sm"
						/>
						<label htmlFor={toDate}>To date -</label>
					</span>
				</div>
			</div>

			<div className="flex gap-8 pt-2 bg-white rounded">
				<div className="space-y-0" style={{ width: "352px" }}>
					<p className="font-bold text-sm p-2 rounded-t-lg w-full bg-gradient-to-b from-gray-200 to-gray-50 ">
						Most read book
					</p>

					{response?.data?.mostReadBook ? (
						<BookCard
							bookId={response?.data?.mostReadBook?.id}
							title={response?.data?.mostReadBook?.title}
							author={response?.data?.mostReadBook?.author}
							price={response?.data?.mostReadBook?.price}
							isbn={response?.data?.mostReadBook?.isbn}
							description={response?.data?.mostReadBook?.description}
							sellable={response?.data?.mostReadBook?.sellable}
							onSubscription={response?.data?.mostReadBook?.subscription}
							isActive={response?.data?.mostReadBook?.is_active}
							isFree={response?.data?.mostReadBook?.isFree}
							credits={response?.data?.mostReadBook?.credits}
							bookCover={response?.data?.mostReadBook?.book_cover?.url}
							bookUrl={response?.data?.mostReadBook?.book_file?.url}
							category={response?.data?.mostReadBook?.category}
							genres={response?.data?.mostReadBook?.genres[0]}
							isAdmin={hasRoles([Role.SUPER_ADMIN, Role.ADMIN])}
							className="items-stretch mb-2 "
						/>
					) : (
						<p className="flex items-center justify-center pt-4">
							No books read
						</p>
					)}
				</div>

				<div className="flex flex-col items-center border-t-2 gap-4 pt-4 mt-4 ">
					<p className="text-base flex items-center"># of Books Purchased</p>

					<div className="flex items-center gap-2">
						<BsJournalBookmark size={50} className=" text-gray-400 " />
						<p className="text-xl h-20 w-20 rounded-full border flex items-center justify-center">
							{response?.data?.numberOfBooksPurchased}
						</p>
					</div>
				</div>

				<div className="flex flex-col items-center border-t-2 gap-4 pt-4 mt-4 ">
					<p className="text-base flex items-center"># of Books Accessed</p>
					<div className="flex items-center gap-2">
						<BsJournalBookmark size={50} className=" text-gray-400 " />
						<p className="text-xl h-20 w-20 rounded-full border flex items-center justify-center">
							{response?.data?.numberOfBooksAccessed}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PublisherBookPerformance;
