import InitApi from "api/InitApi";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import useAuthStore from "store/authStore";
import useTokenStore from "store/tokenStore";

const useLogout = () => {
	const history = useHistory();

	const queryClient = useQueryClient();

	const { accessToken, storeAccessToken } = useTokenStore();
	const endSession = useAuthStore((state) => state.endSession);

	const logout = async () => {
		const instance = await InitApi({ accessToken });
		const response = await instance.post("/auth/logout");
		return response.data;
	};

	return useMutation(logout, {
		onSuccess: async (body) => {
			queryClient.clear();
			endSession();
			storeAccessToken(null);
			reactLocalStorage.clear();
			sessionStorage.clear();
			history.push("/books");
		},
		onError: async (error) => {
			queryClient.clear();
			endSession();
			storeAccessToken(null);
			reactLocalStorage.clear();
			sessionStorage.clear();
			history.push("/books");
		},
	});
};

export default useLogout;
