import useSubmitBook from "api/BookResource/useSubmitBook";
import useFetchCategories from "api/CategoryResource/useFetchCategories";
import useGenres from "api/GenreResource/useGenres";
import {
	Button,
	ButtonLoader,
	CheckBox,
	SelectField,
	TextField,
} from "components";
import { Form, Formik } from "formik";
import { Sidebar } from "primereact/sidebar";
import React from "react";
import { FiPlus } from "react-icons/fi";
import useAuthStore from "store/authStore";
import { MAX_IMAGE_SIZE, MAX_PDF_SIZE } from "utils";
import { Role } from "utils/roles";
import * as Yup from "yup";

const AddBook = () => {
	const [display, setDisplay] = React.useState(false);

	const { data: categories, isLoading: isCategoriesLoading } =
		useFetchCategories();

	const { data: genres, isLoading: isGenresLoading } = useGenres();

	// const { hasRoles } = useAuthUser();
	const hasRoles = useAuthStore((state) => state.hasRoles);

	const initialValues = {
		title: "",
		isbn: "",
		author: "",
		publisher_email: "",
		book_cover: null,
		book_file: null,
		price: "",
		credits: "",
		description: "",
		genres: "",
		category_id: "",
		subscription: false,
		sellable: false,
		is_active: false,
		isFree: false,
	};

	const validationSchema = Yup.object().shape({
		title: Yup.string().required("Book title is required"),
		author: Yup.string().required("Author is required"),
		price: Yup.string().required("Price is required"),
		description: Yup.string().required("Book summary is required"),
		category_id: Yup.string().required("Book category is required"),
		genres: Yup.string().required("Book genre is required"),
		book_cover: Yup.mixed()
			.required("Cover image is required")
			.test(
				"fileSize",
				`Image size should not exceed ${MAX_IMAGE_SIZE}MB`,
				(value) => {
					if (!value) return true;
					return value.size / 1024 / 1024 <= MAX_IMAGE_SIZE;
				}
			),
		book_file: Yup.mixed()
			.required("PDF document is required")
			.test(
				"fileSize",
				`File size should not exceed ${MAX_PDF_SIZE}MB`,
				(value) => {
					if (!value) return true;
					return value.size / 1024 / 1024 <= MAX_PDF_SIZE;
				}
			)
			.test(
				"fileMimeType",
				"Only pdf or epub document is required",
				(value) => {
					if (!value) return true;
					return (
						value.type === "application/pdf" ||
						value.type === "application/epub+zip"
					);
				}
			),
	});

	const { mutateAsync, isLoading } = useSubmitBook();
	const handleSubmitBook = async (data) => {
		await mutateAsync({
			...data,
			subscription: data.subscription ? 1 : 0,
			sellable: data.sellable ? 1 : 0,
			is_active: data.is_active ? 1 : 0,
			isFree: data.isFree ? 1 : 0,
		});
		setDisplay(false);
	};

	return (
		<>
			<button
				type="button"
				onClick={() => setDisplay(true)}
				className="px-2 py-1 text-sm rounded bg-gradient-to-b from-akgreenest to-akgreen text-gray-100 border border-akgreen flex gap-2 items-center">
				Add Book <FiPlus />
			</button>

			<Sidebar
				position="right"
				visible={display}
				onHide={() => setDisplay(false)}
				icons={
					<h2 className="border-b-2 border-gray-400 border-dashed">
						Add new book
					</h2>
				}
				blockScroll
				className="min-w-max">
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(data) => handleSubmitBook(data)}>
					{(formik) => {
						return (
							<Form>
								<TextField
									type="text"
									name="title"
									placeholder="e.g Rich Dad, Poor Dad"
									label="Title"
								/>

								<TextField
									type="text"
									name="isbn"
									placeholder="e.g 978-7929-543"
									label="ISBN Number"
								/>

								<TextField
									type="text"
									name="author"
									placeholder="e.g Robert Kiyosaki"
									label="Author"
								/>

								{!hasRoles([Role.SENIOR_PUBLISHER, Role.PUBLISHER]) && (
									<TextField
										type="text"
										name="publisher_email"
										placeholder="e.g publisher@example.com"
										label="Publisher's Email"
									/>
								)}

								<TextField
									type="file"
									name="book_cover"
									value={null}
									label="Book Cover"
									onChange={(event) =>
										formik.setFieldValue("book_cover", event.target.files[0])
									}
								/>

								<TextField
									type="file"
									name="book_file"
									value={null}
									placeholder="e.g Rich Dad, Poor Dad"
									label="Upload Book"
									onChange={(event) =>
										formik.setFieldValue("book_file", event.target.files[0])
									}
								/>

								<TextField
									type="number"
									min="0"
									step="0.01"
									name="price"
									placeholder="e.g 100.99"
									label="Book Price"
								/>

								<TextField
									type="number"
									min="0"
									step="0.01"
									name="credits"
									placeholder="e.g 2"
									label="Equivalent stars to the book price"
								/>

								<SelectField
									name="category_id"
									label="Select Book Category"
									placeholder="e.g Academic">
									{!isCategoriesLoading &&
										categories?.data?.map((category) => (
											<option value={category.id} key={category.id}>
												{category.name}
											</option>
										))}
								</SelectField>

								<SelectField
									name="genres"
									label="Select Book Collection"
									placeholder="e.g Fiction">
									{!isGenresLoading &&
										genres?.data?.data?.map((genre) => (
											<option value={genre.id} key={genre.id}>
												{genre.name}
											</option>
										))}
								</SelectField>

								<TextField
									type="textarea"
									name="description"
									placeholder="Start typing..."
									label="Description"
								/>

								<CheckBox
									label="Do you want people to subscribe to this book?"
									name="subscription"
								/>

								<CheckBox label="Can people buy this book?" name="sellable" />

								<CheckBox label="Activate the book?" name="is_active" />

								<CheckBox label="Make the book free?" name="isFree" />

								<div className="flex mt-3">
									<Button type="reset">Clear</Button>
									{isLoading ? (
										<ButtonLoader size={40} />
									) : (
										<Button type="submit" className="ml-3 flex items-center ">
											Submit
										</Button>
									)}
								</div>
							</Form>
						);
					}}
				</Formik>
			</Sidebar>
		</>
	);
};

export default AddBook;
