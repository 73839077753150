import RevenueReport from "admin/AdminDashboard2/RevenueReport";
import Admin2 from "layouts/Admin2";
import React from "react";
import AggregateBooks from "./AggregateBooks";
import AggregateRegistrations from "./AggregateRegistrations";
import WeeklyRegistrationsGraph from "./WeeklyRegistrationsGraph";
import useAuthStore from "store/authStore";
import { Permission } from "utils/permissions";

const AdminDashboard2 = () => {
	const { hasPermissions } = useAuthStore();

	return (
		<Admin2>
			<div className="space-y-8 my-4" style={{ minWidth: "80%" }}>
				{hasPermissions([Permission.VIEW_LIB_REVENUE]) && <RevenueReport />}

				<AggregateRegistrations />
				<WeeklyRegistrationsGraph />

				<AggregateBooks />
			</div>
		</Admin2>
	);
};

export default AdminDashboard2;
