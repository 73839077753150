import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsArrowBarRight, BsPencil } from "react-icons/bs";
import useUpdatePermission from "api/RoleResource/useUpdatePermission";

const EditPermission = ({ permissionId, permissionLabel, permissionName }) => {
	const [enableEditing, setEnableEditing] = React.useState(false);

	const initialValues = {
		label: permissionLabel || "",
	};

	const validate = Yup.object().shape({
		label: Yup.string().required("Permission label is required"),
	});

	const { mutateAsync, isLoading } = useUpdatePermission(permissionId);

	const save = async (data) => {
		await mutateAsync({
			label: data.label,
		});
		setEnableEditing(false);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validate}
			onSubmit={(data) => save(data)}
		>
			{({ values, setFieldValue }) => (
				<Form className="shadow-sm bg-gray-100 border border-gray-200 rounded px-3 py-3 flex justify-between items-center gap-4">
					<div className="flex flex-col justify-center gap-1 ">
						<span className="font-bold">{permissionName}</span>
						{enableEditing ? (
							<div className="flex">
								<input
									type="text"
									name="label"
									value={values.label}
									onChange={(e) => setFieldValue("label", e.target.value)}
									onBlur={(e) => save({ label: e.target.value })}
									className="ring-0 border border-gray-200 focus:ring-0 max-w-max py-1 px-2 rounded-l-lg h-8"
								/>
								<button
									type="submit"
									className="flex items-center bg-akgreener h-8 p-2 rounded-r text-white"
								>
									{isLoading ? (
										<AiOutlineLoading3Quarters size={20} />
									) : (
										<BsArrowBarRight size={20} />
									)}
								</button>
							</div>
						) : (
							<span className="text-xs">{permissionLabel}</span>
						)}
					</div>

					{!enableEditing && (
						<button
							type="button"
							onClick={() => setEnableEditing(true)}
							className=" bg-transparent "
						>
							<BsPencil size={20} />
						</button>
					)}
				</Form>
			)}
		</Formik>
	);
};

export default EditPermission;
