import useFetchAggregateRegistrations from "api/AdminReports/useFetchAggregateRegistrations";
import React from "react";
import { Calendar } from "primereact/calendar";
import { BiCalendar, BiUserPlus } from "react-icons/bi";
import moment from "moment";

const Card = ({ label, value }) => (
	<div
		className="p-3 flex justify-center gap-1 rounded-md"
		// style={{ borderRadius: "69% 69% 35% 12% / 21% 16% 71% 96% " }}
	>
		<div>
			<BiUserPlus size={25} className="text-gray-400 animate-pulse" />
		</div>

		<div className="text-center">
			<p>{label}</p>
			<h5 className="rounded-full p-2 border border-akgreen h-14 w-14 flex justify-center items-center">
				{value}
			</h5>
		</div>
	</div>
);

const AggregateRegistrations = () => {
	// const { fromDate, setFromDate, toDate, setToDate } = useDateRangeStore();

	const [fromDate, setFromDate] = React.useState("");
	const [toDate, setToDate] = React.useState("");

	const { data: users } = useFetchAggregateRegistrations({
		from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
		to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
	});

	return (
		<div className="w-full space-y-4">
			<div className="flex justify-between items-end w-full ">
				<h4 className="font-cera-black">Aggregate registrations</h4>

				<div className="flex gap-4 items-center">
					<span className="p-float-label">
						<Calendar
							id={fromDate}
							className="font-sans"
							value={fromDate}
							onChange={(e) => setFromDate(e.target.value)}
							dateFormat="yy-mm-dd"
							mask="9999-99-99"
							panelClassName="font-sans text-xs"
							showIcon
							icon={<BiCalendar size={20} />}
							inputClassName="rounded-3xl pl-4 text-sm"
						/>
						<label htmlFor={fromDate}>From date -</label>
					</span>

					<span className="p-float-label">
						<Calendar
							id={toDate}
							className="font-sans"
							value={toDate}
							onChange={(e) => setToDate(e.target.value)}
							dateFormat="yy-mm-dd"
							mask="9999-99-99"
							panelClassName="font-sans text-xs"
							showIcon
							icon={<BiCalendar size={20} />}
							inputClassName="rounded-3xl pl-4 text-sm"
						/>
						<label htmlFor={toDate}>To date -</label>
					</span>
				</div>
			</div>

			<div className="grid grid-cols-7 gap-1 p-2 bg-white shadow rounded">
				<Card label="All users" value={users?.data?.total || 0} />

				<Card label="Active" value={users?.data?.active || 0} />

				<Card label="Inactive" value={users?.data?.inactive || 0} />

				<Card label="Admins" value={users?.data?.admins || 0} />

				<Card label="Publishers" value={users?.data?.publishers || 0} />

				<Card label="Individuals" value={users?.data?.individual || 0} />

				<Card label="Institutions" value={users?.data?.institution || 0} />
			</div>
		</div>
	);
};

export default AggregateRegistrations;
