import useFetchPublisherAggregateBooks from "api/AdminReports/useFetchPublisherAggregateBooks";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import React, { useState } from "react";
import { BiBookAdd, BiCalendar } from "react-icons/bi";

const Card = ({ label, value }) => (
	<div
		className="p-3 flex justify-center gap-1 "
		style={{ borderRadius: "10% 69% 35% 20% / 21% 16% 40% 96% " }}
	>
		<div>
			<BiBookAdd size={25} className="text-gray-300" />
		</div>
		<div className="text-center">
			<p>{label}</p>
			<h5 className="rounded-full p-2 border border-akgreen h-10 w-10 flex justify-center items-center">
				{value}
			</h5>
		</div>
	</div>
);

const AggregateBooks = ({ publisherId }) => {
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");
	const { data: books } = useFetchPublisherAggregateBooks(
		{
			from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
			to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
			model_type: "publisher",
		},
		publisherId
	);
	return (
		<div className="space-y-3">
			<div className="flex justify-between gap-4 items-end w-full ">
				<h4 className="font-cera-black">Aggregate book statistics</h4>

				<div className="flex gap-4 items-center">
					<span className="p-float-label">
						<Calendar
							id={fromDate}
							className="font-sans"
							value={fromDate}
							onChange={(e) => setFromDate(e.target.value)}
							dateFormat="yy-mm-dd"
							mask="9999-99-99"
							panelClassName="font-sans text-xs"
							showIcon
							icon={<BiCalendar size={20} />}
							inputClassName=" pl-4 text-sm"
						/>
						<label htmlFor={fromDate}>From date -</label>
					</span>
					<span className="p-float-label">
						<Calendar
							id={toDate}
							className="font-sans"
							value={toDate}
							onChange={(e) => setToDate(e.target.value)}
							dateFormat="yy-mm-dd"
							mask="9999-99-99"
							panelClassName="font-sans text-xs"
							showIcon
							icon={<BiCalendar size={20} />}
							inputClassName="pl-4 text-sm"
						/>
						<label htmlFor={toDate}>To date -</label>
					</span>
				</div>
			</div>

			<div className="grid grid-cols-5 gap-2 p-2 bg-white shadow rounded">
				<Card label="All books" value={books?.data?.total || 0} />

				<Card label="Active" value={books?.data?.active || 0} />

				<Card label="Inactive" value={books?.data?.inactive || 0} />

				<Card label="Free" value={books?.data?.free || 0} />

				<Card label="Premium" value={books?.data?.premium || 0} />
			</div>
		</div>
	);
};

export default AggregateBooks;
