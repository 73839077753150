import { Form, Formik } from "formik";
import { FiX } from "react-icons/fi";
import { MdCancel } from "react-icons/md";
import useAuthStore from "store/authStore";
import useBooksFilterStore from "store/booksFilterStore";
import { sleep } from "utils";
import { Role } from "utils/roles";

const BookFilter = ({ hideFilters, genres, categories, publishers }) => {
	const { bookFilters, setBookFilters, setPage } = useBooksFilterStore();

	const hasRoles = useAuthStore((state) => state.hasRoles);

	const initialValues = {
		author: bookFilters["filter[author]"],
		title: bookFilters["filter[title]"],
		isbn: bookFilters["filter[isbn]"],
		is_active: bookFilters["filter[is_active]"],
		category_is: bookFilters["filter[category_is]"],
		genre_is: bookFilters["filter[genre_is]"]
			? bookFilters["filter[genre_is]"][0]
			: "",
		isFree: bookFilters["filter[isFree]"],
		publisher_is: bookFilters["filter[publisher_is]"],
		pricing_model_is: bookFilters["filter[pricing_model_is]"],
		without_file_collection: bookFilters["without_file_collection"],
		with_file_collection: bookFilters["with_file_collection"],
	};

	return (
		<div className="w-full">
			<Formik initialValues={initialValues}>
				{({ setFieldValue, values, setValues }) => {
					const handleResetFilters = () => {
						setBookFilters({
							...bookFilters,
							"filter[author]": "",
							"filter[title]": "",
							"filter[isbn]": "",
							"filter[is_active]": "",
							"filter[category_is]": "",
							"filter[publisher_is]": "",
							"filter[pricing_model_is]": "",
							"filter[genre_is]": [],
							"filter[isFree]": "",
							without_file_collection: "",
							with_file_collection: "",
						});
						setValues({
							author: "",
							title: "",
							isbn: "",
							is_active: "",
							category_is: "",
							genre_is: "",
							isFree: "",
							publisher_is: "",
							pricing_model_is: "",
							without_file_collection: "",
							with_file_collection: "",
						});
					};

					const handleChangeCategory = (e) => {
						setPage(1);
						const value = e.target.value;
						setFieldValue("category_is", value);
						setBookFilters({
							...bookFilters,
							"filter[category_is]": value,
						});
					};

					const handleChangeCollection = (e) => {
						setPage(1);
						const value = e.target.value;
						setFieldValue("genre_is", value);
						setBookFilters({
							...bookFilters,
							"filter[genre_is]": [value],
						});
					};

					const handleChangePublisher = (e) => {
						setPage(1);
						const value = e.target.value;
						setFieldValue("publisher_is", value);
						setBookFilters({
							...bookFilters,
							"filter[publisher_is]": value,
						});
					};

					const handleChangePricingModel = (e) => {
						setPage(1);
						const value = e.target.value;
						setFieldValue("pricing_model_is", value);
						setBookFilters({
							...bookFilters,
							"filter[pricing_model_is]": value,
						});
					};

					const handleChangeIsActive = (e) => {
						setPage(1);
						const value = e.target.value;
						setFieldValue("is_active", value);
						setBookFilters({
							...bookFilters,
							"filter[is_active]": value,
						});
					};

					const handleChangeAuthor = async (e) => {
						setPage(1);
						setFieldValue("author", e.target.value);
						await sleep();
						setBookFilters({
							...bookFilters,
							"filter[author]": e.target.value,
						});
					};

					const handleChangeTitle = async (e) => {
						setPage(1);
						setFieldValue("title", e.target.value);
						await sleep();
						setBookFilters({
							...bookFilters,
							"filter[title]": e.target.value,
						});
					};

					const handleChangeIsbn = async (e) => {
						setPage(1);
						setFieldValue("isbn", e.target.value);
						await sleep();
						setBookFilters({
							...bookFilters,
							"filter[isbn]": e.target.value,
						});
					};

					const handleChangeWithoutFileCollection = (e) => {
						setPage(1);
						const value = e.target.value;
						setFieldValue("without_file_collection", value);
						setBookFilters({
							...bookFilters,
							without_file_collection: value,
						});
					};

					const handleChangeWithFileCollection = (e) => {
						setPage(1);
						const value = e.target.value;
						setFieldValue("with_file_collection", value);
						setBookFilters({
							...bookFilters,
							with_file_collection: value,
						});
					};

					return (
						<Form className="w-full text-sm space-y-2">
							<div className="flex items-center justify-center gap-2 w-full">
								{hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) &&
									!hideFilters?.includes("publisher") && (
										<div
											className={`flex items-center gap-1 rounded-full border-2 bg-green-100 px-2 py-2 relative ${
												values.publisher_is
													? "border-akgreener"
													: "border-transparent"
											}`}>
											<select
												name="publisher_is"
												id=""
												className="rounded border-0 bg-transparent focus:outline-none"
												style={{ maxWidth: "180px" }}
												onChange={handleChangePublisher}
												value={values.publisher_is}>
												<option label="Publisher"></option>
												{publishers?.data?.map((publisher) => (
													<option key={publisher.id} value={publisher.id}>
														{publisher.name}
													</option>
												))}
												<option value="">[x] Clear filter</option>
											</select>

											{values.publisher_is && (
												<button
													type="button"
													className="rounded-full p-1 flex items-center justify-center bg-akgreenest absolute -right-1 -top-2 text-white"
													onClick={() => {
														setFieldValue("publisher_is", "");
														setBookFilters({
															...bookFilters,
															"filter[publisher_is]": [],
														});
													}}>
													<FiX size={20} />
												</button>
											)}
										</div>
									)}

								<div
									className={`flex items-center gap-1 rounded-full border-2 bg-green-100 px-2 py-2 relative ${
										values.category_is
											? "border-akgreener"
											: "border-transparent"
									}`}>
									<select
										name="category_is"
										id=""
										className="rounded border-0 bg-transparent focus:outline-none"
										style={{ maxWidth: "180px" }}
										onChange={handleChangeCategory}
										value={values.category_is}>
										<option label="Category"></option>
										{categories?.data?.map((category) => (
											<option key={category.id} value={category.name}>
												{category.name}
											</option>
										))}
										<option value="">[x] Clear filter</option>
									</select>

									{values.category_is && (
										<button
											type="button"
											className="rounded-full p-1 flex items-center justify-center bg-akgreenest absolute -right-1 -top-2 text-white"
											onClick={() => {
												setFieldValue("category_is", "");
												setBookFilters({
													...bookFilters,
													"filter[category_is]": [],
												});
											}}>
											<FiX size={20} />
										</button>
									)}
								</div>

								<div
									className={`flex items-center gap-1 rounded-full border-2 bg-green-100 px-2 py-2 relative ${
										values.genre_is ? "border-akgreener" : "border-transparent"
									}`}>
									<select
										name="genre_is"
										id=""
										className="rounded border-0 bg-transparent focus:outline-none"
										style={{ maxWidth: "180px" }}
										onChange={handleChangeCollection}
										value={values.genre_is}>
										<option label="Collection"></option>
										{genres?.data?.data?.map((genre) => (
											<option key={genre.id} value={genre.name}>
												{genre.name}
											</option>
										))}
										<option value="">[x] Clear filter</option>
									</select>

									{values.genre_is && (
										<button
											type="button"
											className="rounded-full p-1 flex items-center justify-center bg-akgreenest absolute -right-1 -top-2 text-white"
											onClick={() => {
												setFieldValue("genre_is", "");
												setBookFilters({
													...bookFilters,
													"filter[genre_is]": [],
												});
											}}>
											<FiX size={20} />
										</button>
									)}
								</div>

								<div
									className={`flex items-center gap-1 rounded-full border-2 bg-green-100 px-2 py-2 relative ${
										values.pricing_model_is
											? "border-akgreener"
											: "border-transparent"
									}`}>
									<select
										name="pricing_model_is"
										id=""
										className="rounded border-0 bg-transparent focus:outline-none"
										style={{ maxWidth: "180px" }}
										onChange={handleChangePricingModel}
										value={values.pricing_model_is}>
										<option label="Pricing model"></option>
										<option value="sellable">Purchase only</option>
										<option value="subscription">Subscription only</option>
										<option value="all">Purchase & subscription</option>
										<option value="free">Free</option>
										<option value="">[x] Clear filter</option>
									</select>

									{values.pricing_model_is && (
										<button
											type="button"
											className="rounded-full p-1 flex items-center justify-center bg-akgreenest absolute -right-1 -top-2 text-white"
											onClick={() => {
												setFieldValue("pricing_model_is", "");
												setBookFilters({
													...bookFilters,
													"filter[pricing_model_is]": "",
												});
											}}>
											<FiX size={20} />
										</button>
									)}
								</div>

								<div
									className={`flex items-center gap-1 rounded-full border-2 bg-green-100 px-2 py-2 relative ${
										values.is_active ? "border-akgreener" : "border-transparent"
									}`}>
									<select
										name="is_active"
										id=""
										className="rounded border-0 bg-transparent focus:outline-none"
										style={{ maxWidth: "180px" }}
										onChange={handleChangeIsActive}
										value={values.is_active}>
										<option label="Activation status"></option>
										<option value="1">Active</option>
										<option value="0">Inactive</option>
										<option value="">[x] Clear filter</option>
									</select>

									{values.is_active && (
										<button
											type="button"
											className="rounded-full p-1 flex items-center justify-center bg-akgreenest absolute -right-1 -top-2 text-white"
											onClick={() => {
												setFieldValue("is_active", "");
												setBookFilters({
													...bookFilters,
													"filter[is_active]": "",
												});
											}}>
											<FiX size={20} />
										</button>
									)}
								</div>

								<div
									className={`flex items-center gap-1 rounded-full border-2 bg-green-100 px-2 py-2 relative ${
										values.without_file_collection
											? "border-akgreener"
											: "border-transparent"
									}`}>
									<select
										name="without_file_collection"
										id=""
										className="rounded border-0 bg-transparent focus:outline-none"
										style={{ maxWidth: "180px" }}
										onChange={handleChangeWithoutFileCollection}
										value={values.without_file_collection}>
										<option label="Filter by missing files"></option>
										<option value="book_cover">Without book cover</option>
										<option value="book_file">Without book file</option>
										<option value="all">Without both files</option>
										<option value="">[x] Clear filter</option>
									</select>

									{values.without_file_collection && (
										<button
											type="button"
											className="rounded-full p-1 flex items-center justify-center bg-akgreenest absolute -right-1 -top-2 text-white"
											onClick={() => {
												setFieldValue("without_file_collection", "");
												setBookFilters({
													...bookFilters,
													without_file_collection: "",
												});
											}}>
											<FiX size={20} />
										</button>
									)}
								</div>

								<div
									className={`flex items-center gap-1 rounded-full border-2 bg-green-100 px-2 py-2 relative ${
										values.with_file_collection
											? "border-akgreener"
											: "border-transparent"
									}`}>
									<select
										name="with_file_collection"
										id=""
										className="rounded border-0 bg-transparent focus:outline-none"
										style={{ maxWidth: "180px" }}
										onChange={handleChangeWithFileCollection}
										value={values.with_file_collection}>
										<option label="Filter by available files"></option>
										<option value="book_cover">With book cover</option>
										<option value="book_file">With book file</option>
										<option value="all">With both files</option>
										<option value="">[x] Clear filter</option>
									</select>

									{values.with_file_collection && (
										<button
											type="button"
											className="rounded-full p-1 flex items-center justify-center bg-akgreenest absolute -right-1 -top-2 text-white"
											onClick={() => {
												setFieldValue("with_file_collection", "");
												setBookFilters({
													...bookFilters,
													with_file_collection: "",
												});
											}}>
											<FiX size={20} />
										</button>
									)}
								</div>
							</div>

							<div className="flex items-center justify-center gap-2 w-full ">
								<input
									type="text"
									name="author"
									placeholder="Author"
									onChange={handleChangeAuthor}
									value={values.author}
									className="rounded border border-gray-200 px-2 py-1 flex-1"
								/>
								<input
									type="text"
									name="title"
									placeholder="Book title"
									onChange={handleChangeTitle}
									value={values.title}
									className="rounded border border-gray-200 px-2 py-1 flex-1"
								/>
								<input
									type="text"
									name="isbn"
									placeholder="ISBN Number"
									onChange={handleChangeIsbn}
									value={values.isbn}
									className="rounded border border-gray-200 px-2 py-1 flex-1"
								/>
							</div>

							<div className="flex justify-center">
								{Object.values(values).some((value) => value !== "") && (
									<button
										type="button"
										className="flex items-center gap-2 shadow rounded-full px-3 py-1 bg-gradient-to-b from-akgreen to-gray-50"
										onClick={() => handleResetFilters()}>
										Clear Filters
										<MdCancel size={20} />
									</button>
								)}
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default BookFilter;
