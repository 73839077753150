import React from "react";

const PurchasedBooksLoader = () => {
	return (
		<div
			style={{ width: 360 }}
			className="flex gap-4 py-4 px-3 rounded border-r border-t border-gray-200 "
		>
			<div
				style={{
					width: 150,
					height: 250,
				}}
				className="rounded bg-green-300 animate-pulse "
			/>
			<div className="flex flex-col gap-1 py-3">
				<div
					className="bg-green-300 animate-pulse rounded-lg"
					style={{ width: 100, height: 10 }}
				/>
				<div
					className="bg-green-300 animate-pulse rounded-lg"
					style={{ width: 190, height: 10 }}
				/>

				<div className="mt-2 space-y-1">
					<div
						className="bg-green-400 animate-pulse rounded-full"
						style={{ width: 100, height: 30 }}
					/>
					<div
						className="bg-green-500 animate-pulse rounded-full"
						style={{ width: 150, height: 30 }}
					/>
				</div>

				<div className="mt-2 space-y-1">
					<div
						className="bg-green-300 animate-pulse rounded-lg"
						style={{ width: 170, height: 10 }}
					/>
					<div className="flex items-center gap-1">
						<div
							className="bg-green-300 animate-pulse rounded-lg"
							style={{ width: 100, height: 10 }}
						/>
						<div
							className="bg-green-300 animate-pulse rounded-full"
							style={{ width: 30, height: 10 }}
						/>
						<div
							className="bg-green-300 animate-pulse rounded-full"
							style={{ width: 20, height: 10 }}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PurchasedBooksLoader;
