import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects, fetchConfig } from "utils";

const useMyLibrary = (queryParams) => {
	const { accessToken } = useTokenStore();
	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const params = queryParams ? deleteEmptyObjects(queryParams) : null;
		const response = await instance.get("books/purchased", {
			params: { ...params },
		});
		return response.data;
	};
	return useQuery(["purchased", queryParams], () => fetch(), {
		...fetchConfig,
	});
};

export default useMyLibrary;
