import useEcocashPaySubscription from "api/PaymentsResource/useEcocashPaySubscription";
import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { activeCurrencies, activePaymentMethods } from "utils";
import * as Yup from "yup";

const EcocashPaymentUsd = ({ grandTotal, id }) => {
	const [applyVoucher, setApplyVoucher] = React.useState(false);
	const [paymentMethod, setPaymentMethod] = React.useState(
		activePaymentMethods.ecocashUsd.name
	);

	const handleApplyVoucher = () => {
		setPaymentMethod("Voucher");
		setApplyVoucher(true);
	};

	const handleBackToEcocash = () => {
		setPaymentMethod(activePaymentMethods.ecocashUsd.name);
		setApplyVoucher(false);
	};

	const initialValues = {
		grand_charges: 0,
		grand_total: 0,
		mobile_number: "",
		voucher_number: "",
	};

	const validateCheckout = Yup.object().shape({
		mobile_number:
			paymentMethod === activePaymentMethods.ecocashUsd.name
				? Yup.string()
						.typeError("Mobile number must be numeric")
						.min(9, "Mobile number must be 9 digits and above")
						.required("Mobile number is required")
				: Yup.string().notRequired(),
		voucher_number:
			paymentMethod === "Voucher"
				? Yup.string().required("Voucher number is required")
				: Yup.string().notRequired(),
	});

	const {
		mutateAsync: mutateEcocash,
		isLoading,
		error,
	} = useEcocashPaySubscription();

	const subscribe = async (data) => {
		await mutateEcocash({
			...data,
			payment_method: paymentMethod,
			payment_type: "Subscription",
			subtotal: 0,
			grand_charges: 0,
			grand_total: grandTotal,
			grand_total_zwl: 0,
			phone: data.mobile_number,
			id: id,
			reference: Date.now(),
			currency: activeCurrencies.USD.code,
			total_amount: grandTotal,
		});
	};

	return (
		<div className="relative">
			<Formik
				initialValues={initialValues}
				validationSchema={validateCheckout}
				onSubmit={(data) => subscribe(data)}
			>
				<Form className="mt-4 space-y-3">
					{!applyVoucher ? (
						<>
							<div>
								<h3>{activePaymentMethods.ecocashUsd.label} payment</h3>
								<p>Enter your EcoCash number to proceed</p>
								<p>For example 0783500000</p>
							</div>

							<FormField autoFocus type="number" name="mobile_number" />

							<div className="mt-2 flex flex-col gap-2">
								<button
									type="submit"
									className="rounded-full px-3 py-2 text-white bg-akgreenest flex items-center justify-center gap-2"
								>
									Proceed with payment
									{isLoading ? (
										<AiOutlineLoading3Quarters
											size={20}
											className="animate-spin"
										/>
									) : undefined}
								</button>
								<button
									type="button"
									onClick={handleApplyVoucher}
									className="text-lg text-blue-800 font-bold"
								>
									Would you like to pay using a voucher?
								</button>
							</div>
						</>
					) : (
						<>
							<div>
								<h3>{activePaymentMethods.ecocashUsd.label} payment</h3>
								<p>Enter your {activeCurrencies.USD.label} voucher number</p>
								<p>For example TKRpiLHXXy</p>
							</div>

							<div>
								<FormField name="voucher_number" autoFocus />

								{error && (
									<p className="text-red-500 text-xs">
										{error?.response?.data?.message}
									</p>
								)}
							</div>

							<div className="flex gap-4 mt-2">
								<button
									type="button"
									onClick={handleBackToEcocash}
									className="rounded-full px-3 py-2 text-white bg-akgreen opacity-70 w-1/2"
								>
									Back to {activePaymentMethods.ecocashUsd.label}
								</button>
								<button
									type="submit"
									className="rounded-full px-3 py-2 text-white bg-akgreenest w-1/2 flex items-center justify-center gap-2"
								>
									Proceed with payment
									{isLoading ? (
										<AiOutlineLoading3Quarters
											size={20}
											className="animate-spin"
										/>
									) : undefined}
								</button>
							</div>
						</>
					)}
				</Form>
			</Formik>
		</div>
	);
};

export default EcocashPaymentUsd;
