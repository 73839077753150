import create from "zustand";

const usersFilterStore = (set) => ({
	userFilters: {
		"filter[first_name]": "",
		"filter[last_name]": "",
		"filter[email]": "",
		"filter[role_is]": "",
		"filter[trashed]": "with", //with, only, not
		"filter[publisher_is]": "",
		"filter[institution_is]": "",
	},

	page: 1,

	filterCleared: true,

	setUserFilters: (data) => set({ userFilters: { ...data } }),

	setPage: (newPage) => set({ page: newPage }),

	setFilterCleared: (flag) => set({ filterCleared: flag }),
});

const useUsersFilterStore = create(usersFilterStore);

export default useUsersFilterStore;
