import React, { useState } from "react";
import { BsCheckCircle, BsPencil } from "react-icons/bs";
import { defaultPwd, phoneRegex } from "utils";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import { Dialog } from "primereact/dialog";
import useEditUserProfile from "api/UserResource/useEditUserProfile";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { Checkbox } from "primereact/checkbox";
import useFetchSubjectGroups from "api/SubjectGroupResource/useFetchSubjectGroups";
import { ButtonLoader } from "components";
import useViewableRoles from "hooks/useViewableRoles";

const EditUser = ({
	id,
	email,
	firstName,
	lastName,
	mobileNumber,
	role,
	userPermissions,
	allPermissions,
	subjectGroups,
}) => {
	const { roles } = useViewableRoles();

	const [display, setdisplay] = useState(false);

	const [selectOptions, setselectOptions] = useState("default");

	const { data, isLoading: isLoadingSubjectGroups } = useFetchSubjectGroups({
		no_pagination: true,
	});

	const subjectGroupsList = data?.data?.map((subject) => ({
		label: subject.name,
		value: subject.id,
	}));

	const initialValues = {
		mobile_number: mobileNumber || "",
		first_name: firstName || "",
		last_name: lastName || "",
		email: email || "",
		role: role || "",
		password: "",
		permission_names: userPermissions,
		subject_group: subjectGroups || [],
	};

	const validate = Yup.object().shape({
		mobile_number: Yup.string()
			.nullable()
			.matches(phoneRegex, "Invalid phone number")
			.min(10, "Mobile number must have at least characters")
			.max(20, "Mobile number cannot exceed 20 characters"),
		first_name: Yup.string().required("First name is required"),
		last_name: Yup.string().required("Surname is required"),
		email: Yup.string()
			.required("Email is required")
			.email("Invalid email address"),
		role: Yup.string().required("Select role"),
		password: Yup.string()
			.nullable()
			.min(6, "At least 6 characters required")
			.matches(/[a-zA-Z]/, "Must have at least one alphabet character")
			.matches(/[0-9]/, "Must have at least one digit"),
		// subject_group: Yup.array().when(["role"], {
		// 	is: (role) => role === "Student",
		// 	then: (schema) => schema.required("Subject group is required"),
		// 	otherwise: (schema) => schema.notRequired(),
		// }),
	});

	const { mutateAsync, isLoading } = useEditUserProfile(id);

	const handleUpdateProfile = async (data) => {
		await mutateAsync(data);
		setdisplay(false);
	};

	return (
		<>
			<button
				type="button"
				className="appearance-none border-0"
				onClick={() => setdisplay(true)}
			>
				<BsPencil size={15} />
			</button>

			<Dialog
				visible={display}
				style={{ width: "50vw" }}
				header={
					<div className="text-xl font-sans font-bold sticky top-4 z-20 bg-white mr-2">
						<p>
							Edit User - ({firstName} {lastName})
						</p>
					</div>
				}
				onHide={() => setdisplay(false)}
				position="top"
				draggable={false}
				contentClassName="rounded-b-xl "
				className="rounded-xl relative"
			>
				{(function () {
					if (isLoadingSubjectGroups) {
						return (
							<div className="flex  items-center justify-center ">
								<ButtonLoader size={60} />
							</div>
						);
					}

					return (
						<Formik
							initialValues={initialValues}
							validationSchema={validate}
							onSubmit={handleUpdateProfile}
						>
							{({
								setFieldValue,
								values,
								errors,
								touched,
								handleBlur,
								handleChange,
							}) => {
								const fillPassword = () =>
									setFieldValue("password", defaultPwd);

								const clearPassword = () => setFieldValue("password", "");

								const handleSelectAll = () => {
									setselectOptions("all");
									let all = allPermissions?.map(
										(permission) => permission?.name
									);
									setFieldValue("permission_names", [...all]);
								};

								const handleSelectDefault = () => {
									setselectOptions("default");
									setFieldValue("permission_names", [...userPermissions]);
								};

								const handleDeselectAll = () => {
									setselectOptions("none");
									setFieldValue("permission_names", []);
								};

								return (
									<Form className="py-6 space-y-6">
										<FormField
											type="text"
											name="first_name"
											label="First Name *"
										/>
										<FormField
											type="text"
											name="last_name"
											label="Last Name *"
										/>

										<FormField
											type="text"
											name="email"
											label="Email address *"
										/>

										<FormField
											name="mobile_number"
											type="phone"
											placeholder="phone"
											value={values.mobile_number}
											_onChange={(phone) => {
												if (phone !== "+") values.mobile_number = "+" + phone;
												else values.mobile_number = phone;
											}}
											error={Boolean(
												errors.mobile_number && touched.mobile_number
											)}
											errorText={errors.mobile_number}
										/>

										<FormField
											type="select"
											name="role"
											label="Role"
											options={roles?.map((role) => ({
												label: role.label,
												value: role.value,
											}))}
										/>

										{values.role === "Student" && (
											<FormField
												type="multiselect"
												name="subject_group"
												label="Subject group "
												options={subjectGroupsList?.map((subjectGroup) => ({
													label: subjectGroup.label,
													value: subjectGroup.value,
												}))}
											/>
										)}

										<div className="space-y-4">
											<h4>Permissions</h4>

											<div className="flex justify-between gap-2">
												<div className="flex gap-2">
													<button
														className="text-akgreenest border p-2 border-akgreen rounded flex items-center gap-2 justify-between"
														type="button"
														onClick={handleSelectAll}
													>
														Select all
														{selectOptions === "all" && (
															<BsCheckCircle size={20} />
														)}
													</button>

													<button
														className="text-akgreenest border p-2 border-akgreen rounded flex items-center gap-2 justify-between"
														type="button"
														onClick={handleDeselectAll}
													>
														Deselect all
														{selectOptions === "none" && (
															<BsCheckCircle size={20} />
														)}
													</button>
												</div>

												<button
													className="text-akgreenest border p-2 border-akgreen rounded flex items-center gap-2 justify-between"
													type="button"
													onClick={handleSelectDefault}
												>
													Select assigned
													{selectOptions === "default" && (
														<BsCheckCircle size={20} />
													)}
												</button>
											</div>

											<div className="grid grid-cols-3 gap-2">
												{allPermissions?.map((permission) => (
													<div
														className="flex gap-2 items-center"
														key={permission.id}
													>
														<Checkbox
															inputId={permission.name}
															name="permission_names"
															value={permission.name}
															onChange={handleChange}
															checked={values.permission_names?.includes(
																permission.name
															)}
															onBlur={handleBlur}
														/>
														<label
															htmlFor={permission.name}
															className=" cursor-pointer text-xs "
														>
															{permission.label}
														</label>
													</div>
												))}
											</div>
										</div>

										<div className="px-4 py-2 space-y-6 border rounded">
											<h4>Change user's password</h4>
											<div className="flex  gap-4 items-cneter">
												<FormField
													type="password"
													name="password"
													label="New user's password *"
													helperText={
														<ul className="divide-y space-y-1">
															<li>Must have at least 6 characters</li>
															<li>Must have at least one uppercase letter</li>
															<li>Must have at least one lowercase letter</li>
															<li>Must have at least one digit</li>
														</ul>
													}
												/>
												<div className="flex flex-col items-start">
													<button
														className="text-akgreenest"
														type="button"
														onClick={fillPassword}
													>
														Fill in default password
													</button>
													<button
														className="text-akgreenest"
														type="button"
														onClick={clearPassword}
													>
														Clear password
													</button>
												</div>
											</div>
										</div>

										<div className=" flex justify-center border-t border-gray-300 pt-2">
											<button
												type="submit"
												className="px-4 py-2 rounded-full bg-akgreenest text-white flex gap-1 items-center justify-center"
											>
												{isLoading ? (
													<>
														Updating{" "}
														<AiOutlineLoading3Quarters
															size={20}
															className="animate-spin"
														/>
													</>
												) : (
													<>
														Update <IoIosArrowForward size={20} />
													</>
												)}
											</button>
										</div>
									</Form>
								);
							}}
						</Formik>
					);
				})()}
			</Dialog>
		</>
	);
};

export default EditUser;
