import React from "react";

const BooksLoader = () => {
	return (
		<div className=" rounded-lg flex flex-col gap-2 ">
			<div
				className="bg-green-300 animate-pulse rounded-lg  "
				style={{ height: 250 }}
			/>
			<div className="space-y-1">
				<div
					className="bg-green-300 animate-pulse rounded-lg"
					style={{ width: 140, height: 10 }}
				/>
				<div
					className="bg-green-300 animate-pulse rounded-lg"
					style={{ width: 130, height: 10 }}
				/>
				<div
					className="bg-green-300 animate-pulse rounded-lg"
					style={{ width: 110, height: 10 }}
				/>
				<div
					className="bg-green-300 animate-pulse rounded-lg"
					style={{ width: 140, height: 10 }}
				/>
			</div>
		</div>
	);
};

export default BooksLoader;
