import AuthLayout from "layouts/AuthLayout";
import { useState } from "react";
import Individual from "./Individual";
import Institution from "./Institution";

const Register = () => {
	// const logoWidth = 80;

	const [type, setType] = useState("individual");

	let activeCss = "shadow border-2 border-akgreenest";

	return (
		<AuthLayout>
			<p>Start for Free</p>

			<h1 className="text-akgreen font-cera-black mt-2">
				Create a new account
			</h1>

			<div className="flex items-center gap-1 text-base font-sans mt-2">
				<p>Register as:</p>
				<button
					type="button"
					className={`rounded px-2 py-1" ${
						type === "individual" ? activeCss : "border border-akgreener "
					} `}
					onClick={() => setType("individual")}
				>
					Individual
				</button>

				<button
					type="button"
					className={`rounded px-2 py-1" ${
						type === "institution" ? activeCss : "border border-akgreener "
					} `}
					onClick={() => setType("institution")}
				>
					Institution
				</button>
			</div>

			<div className="mt-4">
				{(function () {
					switch (type) {
						case "individual":
							return <Individual />;
						case "institution":
							return <Institution />;
						default:
							return <Individual />;
					}
				})()}
			</div>
		</AuthLayout>
	);
};

export default Register;
