import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { FiEdit2 } from "react-icons/fi";
import { Dialog } from "primereact/dialog";
import { MAX_IMAGE_SIZE, MAX_PDF_SIZE, activeCurrencies } from "utils";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import useFetchCategories from "api/CategoryResource/useFetchCategories";
import useGenres from "api/GenreResource/useGenres";
import FormField from "components/v2/FormField";
import useEditBook from "api/BookResource/useEditBook";
import RichTextEditor from "components/RichTextEditor";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import DeleteBook from "./DeleteBook";
import useSetPickOfTheWeek from "api/BookResource/useSetPickOfTheWeek";
import { BsCheck2, BsX } from "react-icons/bs";
import useAuthStore from "store/authStore";
import useSetRecommended from "api/BookResource/useSetRecommended";
import useCurrencyStore from "store/currencyStore";
import { Role } from "utils/roles";

const BookCard = ({
	bookId,
	title,
	author,
	price,
	zwlPrice,
	isbn,
	bookCover,
	publisher,
	isActive,
	isFree,
	sellable,
	onSubscription,
	category,
	genres,
	description,
	className,
	hasFile,
	numberOfCopiesPurchased,
	copiesAssignable,
}) => {
	const [showEditBook, setshowEditBook] = useState(false);

	useEffect(() => {
		if (showEditBook) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}

		return () => {
			document.body.style.overflow = "auto";
		};
	}, [showEditBook]);

	const [showCoverField, setShowCoverField] = useState(false);
	const [showFileField, setShowFileField] = useState(false);

	// const location = useLocation();

	const { data: categories, isLoading: isCategoriesLoading } =
		useFetchCategories();

	const { data: collections, isLoading: isGenresLoading } = useGenres();

	const hasRoles = useAuthStore((state) => state.hasRoles);

	const { currency } = useCurrencyStore();

	function formattedPrice() {
		if (isFree) {
			return "";
		} else if (currency === activeCurrencies.USD.code) {
			return `$${price}`;
		} else {
			return `$${zwlPrice}`;
		}
	}

	const initialValues = {
		title: title || "",
		isbn: isbn || "",
		author: author || "",
		book_cover: null,
		book_file: null,
		price: price || "",
		zwl_price: zwlPrice || "",
		description: description || "",
		genres: genres?.id || "",
		category_id: category?.id || "",
		subscription: Boolean(onSubscription),
		sellable: Boolean(sellable),
		is_active: Boolean(isActive),
		isFree: Boolean(isFree),
	};

	const validate = Yup.object().shape({
		title: Yup.string().required("Book title is required"),
		author: Yup.string().required("Author is required"),
		price: Yup.number().when("isFree", {
			is: true,
			then: Yup.number().notRequired(),
			otherwise: Yup.number()
				.required("Price is required")
				.moreThan(0, "Price should be greater than 0"),
		}),

		description: Yup.mixed(),
		category_id: Yup.number().required("Book category is required"),
		genres: Yup.string().required("Book genre is required"),
		book_cover: Yup.mixed()
			.test({
				name: "fileSize",
				test: (value) => !value || value.size / 1024 / 1024 <= MAX_IMAGE_SIZE,
				message: `Image size should not exceed ${MAX_IMAGE_SIZE}MB`,
			})
			.test({
				name: "mimeType",
				test: (value) =>
					!value ||
					["image/jpeg", "image/png", "image/svg+xml", "image/webp"].includes(
						value.type
					),
				message:
					"Only images with these file extension are required: jpeg, jpg, png, svg, webp",
			}),

		book_file: Yup.mixed()
			.test({
				name: "fileSize",
				test: (value) => !value || value.size / 1024 / 1024 <= MAX_PDF_SIZE,
				message: `File size should not exceed ${MAX_PDF_SIZE}MB`,
			})
			.test({
				name: "mineType",
				test: (value) =>
					!value ||
					["application/pdf", "application/epub+zip"].includes(value.type),
				message: "Only Pdf or Epub documents are required",
			}),
	});

	const { mutateAsync, isLoading } = useEditBook(bookId, setshowEditBook);

	const handleSubmit = async (data) => {
		await mutateAsync({
			...data,
			is_active: data.is_active ? 1 : 0,
			subscription: data.subscription ? 1 : 0,
			sellable: data.sellable ? 1 : 0,
			isFree: data.isFree ? 1 : 0,
		});
	};

	const {
		mutateAsync: mutatePickOfTheWeek,
		isLoading: isSettingPickOfTheWeek,
	} = useSetPickOfTheWeek(bookId);

	const setAsPickofTheWeek = async () => {
		await mutatePickOfTheWeek();
	};

	const { mutateAsync: mutateSetRecommended, isLoading: isSettingRecommended } =
		useSetRecommended(bookId);

	const setAsRecommended = async () => {
		await mutateSetRecommended();
	};

	return (
		<Card
			title={title}
			className={`font-sans text-sm p-2 w-full ${className} `}
			style={{ width: "380px" }}>
			<div className="flex items-stretch w-full gap-2 ">
				<div
					className="bg-gray-200 flex justify-center items-center rounded"
					style={{ minHeight: "170px", width: "170px" }}>
					{bookCover ? (
						<img src={bookCover} alt={title} width="130" className="rounded" />
					) : (
						<span>150 x 230</span>
					)}
				</div>

				<div
					className="flex flex-col justify-between"
					style={{ width: "190px" }}>
					<div style={{ width: "180px" }}>
						<p className="truncate">ISBN: {isbn}</p>
						<p className="truncate" title={category?.name}>
							Category: {category?.name}
						</p>
						<p className="truncate" title={publisher}>
							Publisher: {publisher}
						</p>
						<p className="mt-2 text-xs truncate" title={genres?.name}>
							Genre: {genres?.name}
						</p>
						<p className="text-xs truncate" title={author}>
							Written by: {author}
						</p>

						{hasRoles([
							Role.SENIOR_PUBLISHER,
							Role.PUBLISHER,
							Role.SUPER_ADMIN,
							Role.ADMIN,
						]) && (
							<p
								className={`my-2 ${
									hasFile ? "bg-akgreen" : "bg-red-700"
								}  rounded-full pl-4 text-white flex items-center gap-2 max-w-max h-6`}>
								{hasFile ? (
									<span>Has book file</span>
								) : (
									<span className="line-through">No book file</span>
								)}
								{hasFile ? (
									<BsCheck2
										size={35}
										className="p-2 bg-white rounded-full text-akgreen border border-akgreen"
									/>
								) : (
									<BsX
										size={35}
										className="p-1 bg-white rounded-full text-red-700 border border-red-700"
									/>
								)}
							</p>
						)}
						{/* copiesAssignable */}

						{hasRoles([Role.INSTITUTION, Role.EDUCATOR]) && (
							<div className="pt-2 space-y-1">
								<p className="font-bold px-3 py-1 rounded-full bg-akgreen text-white max-w-max">
									Copies purchased: {numberOfCopiesPurchased}
								</p>
								<p className="font-bold px-3 py-1 rounded-full bg-akgreener text-white max-w-max flex items-center gap-1">
									<span>Available to assign:</span>
									<span>{copiesAssignable}</span>
								</p>
							</div>
						)}
					</div>

					<div className="flex gap-1 items-center">
						{hasRoles([
							Role.SUPER_ADMIN,
							Role.ADMIN,
							Role.SENIOR_PUBLISHER,
							Role.PUBLISHER,
						]) && (
							<button
								type="button"
								onClick={() => setshowEditBook(true)}
								className="pt-2 px-2 border-t bg-gradient-to-b from-gray-300 to-white flex items-center justify-center rounded-t-md">
								<FiEdit2 size={20} />
							</button>
						)}

						{hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) && (
							<DeleteBook elementId={`delete-${bookId}`} bookId={bookId} />
						)}

						{!hasRoles([Role.INSTITUTION, Role.EDUCATOR, Role.STUDENT]) && (
							<p className="text-lg ml-4 font-bold">{formattedPrice()}</p>
						)}
					</div>

					{hasRoles([Role.SUPER_ADMIN, Role.ADMIN]) && (
						<div className="mt-2 space-y-2">
							<button
								type="button"
								onClick={() => setAsPickofTheWeek()}
								className="text-xs text-akgreener flex justify-center py-1 px-2 rounded-b-md items-center gap-2 bg-gradient-to-t from-gray-300 to-white">
								<span>Set as pick of the week</span>
								{isSettingPickOfTheWeek ? (
									<AiOutlineLoading3Quarters
										size={20}
										className="animate-spin"
									/>
								) : (
									<IoIosArrowForward size={20} />
								)}
							</button>

							<button
								type="button"
								onClick={() => setAsRecommended()}
								className="text-xs text-akgreener flex justify-center py-1 px-2 rounded-b-md items-center gap-2 bg-gradient-to-t from-gray-300 to-white">
								<span>Set as recommended</span>
								{isSettingRecommended ? (
									<AiOutlineLoading3Quarters
										size={20}
										className="animate-spin"
									/>
								) : (
									<IoIosArrowForward size={20} />
								)}
							</button>
						</div>
					)}
				</div>
			</div>

			<Dialog
				visible={showEditBook}
				style={{ width: "50vw" }}
				header={
					<div className="text-xl font-sans font-bold sticky top-4 z-20 bg-white mr-2">
						<p className="">Edit Book</p>
					</div>
				}
				onHide={() => setshowEditBook(false)}
				position="top"
				draggable={false}
				contentClassName="rounded-b-xl "
				className="rounded-xl relative">
				<Formik
					initialValues={initialValues}
					validationSchema={validate}
					onSubmit={handleSubmit}>
					{({ setFieldValue, values, errors, touched }) => {
						return (
							<Form className="mt-6 space-y-6">
								<div className="flex gap-2">
									<div className="w-1/2">
										<FormField type="text" name="title" label="Title *" />
									</div>
									<div className="w-1/2">
										<FormField type="text" name="isbn" label="ISBN Number" />
									</div>
								</div>

								<div className="w-full">
									<FormField type="text" name="author" label="Author Name *" />
								</div>

								<div className="flex gap-2">
									<div className="w-1/2">
										<FormField
											type="number"
											min="0"
											step="0.01"
											name="price"
											label={`Price (${activeCurrencies.USD.label})`}
										/>
									</div>
									<div className="w-1/2">
										<FormField
											type="number"
											min="0"
											step="0.01"
											name="zwl_price"
											label={`Price (${activeCurrencies.ZWL.label})`}
										/>
									</div>
								</div>

								<div className="flex gap-2">
									<div className="w-1/2">
										{bookCover && !showCoverField ? (
											<div className="flex flex-col justify-center mb-2">
												<span>Book already as a cover</span>
												<button
													type="button"
													onClick={() => setShowCoverField(true)}
													className="text-blue-700 max-w-max">
													Update cover
												</button>
											</div>
										) : (
											<FormField
												type="file"
												name="book_cover"
												value={null}
												label="Book Cover"
												style={{
													paddingLeft: `${!values?.book_cover ? "90px" : ""} `,
												}}
												onChange={(event) =>
													setFieldValue("book_cover", event.target.files[0])
												}
											/>
										)}
									</div>
									<div className="w-1/2">
										{hasFile && !showFileField ? (
											<div className="flex flex-col justify-center mb-2">
												<span>Book already as a file</span>
												<button
													type="button"
													onClick={() => setShowFileField(true)}
													className="text-blue-700 max-w-max">
													Update book file (pdf)
												</button>
											</div>
										) : (
											<FormField
												type="file"
												name="book_file"
												value={null}
												label="Book File"
												style={{
													paddingLeft: `${!values?.book_file ? "90px" : ""} `,
												}}
												onChange={(event) =>
													setFieldValue("book_file", event.target.files[0])
												}
											/>
										)}
									</div>
								</div>

								<div className="flex gap-2">
									<div className="w-1/2">
										<FormField
											type="select"
											name="category_id"
											label="Book category"
											options={
												!isCategoriesLoading &&
												categories?.data?.map((category) => ({
													label: category.name,
													value: category.id,
												}))
											}
										/>
									</div>
									<div className="w-1/2">
										<FormField
											type="select"
											name="genres"
											label="Book Genre"
											options={
												!isGenresLoading &&
												collections?.data?.data?.map((genre) => ({
													label: genre.name,
													value: genre.id,
												}))
											}
										/>
									</div>
								</div>

								<div className="space-y-2">
									<p className="text-gray-500">Book summary</p>
									<RichTextEditor
										value={values.description}
										onTextChange={(e) =>
											setFieldValue("description", e.htmlValue)
										}
										error={
											!!(touched.description && errors.description) &&
											errors.description
										}
									/>
								</div>

								<div className=" space-y-2">
									<FormField
										type="switcher"
										name="subscription"
										label="Do you want people to subscribe to this book?"
									/>

									<FormField
										type="switcher"
										label="Can people buy this book?"
										name="sellable"
									/>

									<FormField
										type="switcher"
										label="Activate the book?"
										name="is_active"
									/>

									<FormField
										type="switcher"
										label="Make the book free?"
										name="isFree"
									/>
								</div>

								<div className=" flex justify-center border-t border-gray-300 pt-2">
									<button
										type="submit"
										className="px-4 py-2 rounded-full bg-akgreenest text-white flex gap-1 items-center justify-center">
										{isLoading ? (
											<>
												Updating{" "}
												<AiOutlineLoading3Quarters
													size={20}
													className="animate-spin"
												/>
											</>
										) : (
											<>
												Update <IoIosArrowForward size={20} />
											</>
										)}
									</button>
								</div>
							</Form>
						);
					}}
				</Formik>
			</Dialog>
		</Card>
	);
};

export default BookCard;
