import { ConfirmPopup } from "primereact/confirmpopup";
import React from "react";

const ViewAdmin = ({ elementId, firstName, lastName, email, phone }) => {
	const [visible, setVisible] = React.useState(false);
	return (
		<>
			<button
				type="button"
				onClick={() => setVisible(true)}
				id={elementId}
				className="text-blue-600">
				View admin
			</button>

			<ConfirmPopup
				target={document.getElementById(elementId)}
				className="font-sans text-sm"
				visible={visible}
				onHide={() => setVisible(false)}
				rejectLabel="Close"
				acceptClassName="hidden"
				dismissable={true}
				message={
					<div className="flex flex-col w-60">
						<p className="text-base font-bold">
							{firstName} {lastName}
						</p>
						<p className="flex flex-col">
							<span>{email}</span>
							<span>{phone}</span>
						</p>
					</div>
				}
			/>
		</>
	);
};

export default ViewAdmin;
