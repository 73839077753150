import useEditUserProfile from "api/UserResource/useEditUserProfile";
import useFetchUser from "api/UserResource/useFetchUser";
import {
	Button,
	ButtonLoader,
	DataLoader,
	SelectField,
	TextField,
} from "components";
import PhoneTextField from "components/PhoneTextField";
import { Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import React from "react";
import { FiEdit } from "react-icons/fi";
import { defaultPwd, phoneRegex } from "utils";
import * as Yup from "yup";

const EditUser = ({ elementId, userData }) => {
	const [displayBasic, setDisplayBasic] = React.useState(false);

	const { data: body, isLoading: userIsLoading } = useFetchUser(userData?.id);

	const initialValues = {
		first_name: body?.data?.data?.first_name,
		middle_name: body?.data?.data?.middle_name,
		last_name: body?.data?.data?.last_name || "",
		dob: body?.data?.data?.dob || "",
		sex: body?.data?.data?.sex || "",
		mobile_number: body?.data?.data?.mobile_number || "",
		telephone: body?.data?.data?.telephone || "",
		password: "",
	};

	const updateSchema = Yup.object().shape({
		first_name: Yup.string().required("First name is required"),
		last_name: Yup.string().required("Surname is required"),
		sex: Yup.string().required("Gender is required"),
		dob: Yup.string().matches(
			/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
			"Invalid date format"
		),
		password: Yup.string()
			.min(6, "At least 6 characters required")
			.matches(/[a-zA-Z]/, "Must have at least one alphabet character")
			.matches(/[0-9]/, "Must have at least one digit"),
		mobile_number: Yup.string()
			.matches(phoneRegex, "Invalid phone number")
			.min(10, "Mobile number must have at least characters")
			.max(20, "Mobile number cannot exceed 20 characters"),
		telephone: Yup.string()
			.matches(phoneRegex, "Invalid telephone number")
			.min(10, "Telephone number must have at least characters")
			.max(20, "Telephone number cannot exceed 20 characters"),
	});

	const { mutateAsync, isLoading } = useEditUserProfile(userData?.id);

	const handleUpdateProfile = async (data) => {
		await mutateAsync(data);
		setDisplayBasic(false);
	};

	return (
		<div>
			<button
				className="w-8 h-8 flex items-center p-2 text-white justify-center rounded-full bg-gray-700"
				onClick={() => setDisplayBasic(true)}
				id={elementId}
			>
				<FiEdit />
			</button>
			<Tooltip
				className="green-tooltip"
				target={`#${elementId}`}
				position="bottom"
				content="Edit User"
			/>

			{userIsLoading ? (
				<>
					<DataLoader />
					<DataLoader />
					<DataLoader />
				</>
			) : (
				<Dialog
					header="Edit user information"
					visible={displayBasic}
					style={{ minWidth: "450px" }}
					onHide={() => setDisplayBasic(false)}
					position="top"
				>
					<Formik
						initialValues={initialValues}
						validationSchema={updateSchema}
						onSubmit={(data) => handleUpdateProfile(data)}
					>
						{(formik) => {
							const fillDefault = () => {
								formik.setFieldValue("password", defaultPwd);
							};
							return (
								<Form className=" rounded-3xl py-3 border-akgreener w-full">
									<TextField
										type="text"
										name="first_name"
										className="mb-2"
										label="First Name"
										placeholder="e.g John"
									/>

									<TextField
										type="text"
										name="last_name"
										className="mb-2"
										label="Last Name"
										placeholder="e.g Doe"
									/>

									{/* <TextField
                    type="text"
                    name="mobile_number"
                    className="mb-3"
                    label="Mobile Number"
                    placeholder="e.g +263783471212"
                  /> */}

									<PhoneTextField
										value={initialValues.mobile_number}
										_onChange={(phone) => {
											if (phone !== "+")
												formik.values.mobile_number = "+" + phone;
											else formik.values.mobile_number = phone;
										}}
										error={Boolean(
											formik.errors.mobile_number &&
												formik.touched.mobile_number
										)}
										errorText={formik.errors.mobile_number}
										label="Mobile Number"
									/>

									<PhoneTextField
										value={initialValues.telephone}
										_onChange={(phone) => {
											if (phone !== "+") formik.values.telephone = "+" + phone;
											else formik.values.telephone = phone;
										}}
										label="Telephone"
										error={Boolean(
											formik.errors.telephone && formik.touched.telephone
										)}
										errorText={formik.errors.telephone}
									/>

									<SelectField name="sex" placeholder="- Sex-" label="Sex">
										<option value="Male">Male</option>
										<option value="Female">Female</option>
									</SelectField>

									<div className="py-3 px-5 border-2 border-gray-400 rounded-xl mt-4">
										<button
											className="text-blue-700"
											type="button"
											onClick={() => fillDefault()}
										>
											Fill in default password
										</button>
										<TextField
											type="password"
											name="password"
											placeholder="Password"
											label="Create new password"
											info={
												<ul className="divide-y space-y-1">
													<li>Must have at least 6 characters</li>
													<li>Must have at least one uppercase letter</li>
													<li>Must have at least one lowercase letter</li>
													<li>Must have at least one digit</li>
												</ul>
											}
										/>

										<p className="text-xs text-akgreenest">
											The password is: {formik.values.password}
										</p>
									</div>

									<div className="mt-4 flex justify-start items-center gap-2">
										<Button type="reset">Cancel</Button>
										{isLoading ? (
											<ButtonLoader size={40} />
										) : (
											<Button type="submit" className="flex items-center ">
												Update User
											</Button>
										)}
									</div>
								</Form>
							);
						}}
					</Formik>
				</Dialog>
			)}
		</div>
	);
};

export default EditUser;
