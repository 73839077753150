import React, { useState } from "react";
// import { FiMail } from "react-icons/fi";
import FormField from "components/v2/FormField";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import useSendPasswordResetOtp from "api/UserResource/useSendPasswordResetOtp";
import useResetPassword from "api/UserResource/useResetPassword";
import useAppStore from "store/appStore";
import FlashMessage from "components/v2/FlashMessage";

const ResetPasswordForm = ({ accountEmail, setAccountEmail }) => {
	const [actionIsSendingOtp, setActionIsSendingOtp] = useState(true);
	const [openPasswordFields, setOpenPasswordFields] = useState(false);

	const msg = useAppStore((state) => state.flashMessage);

	const initialValues = {
		username: accountEmail || "",
		otp: "",
		password: "",
		password_confirmation: "",
	};

	const validate = Yup.object().shape({
		username: Yup.string()
			.required("Email is required")
			.email("Invalid email address"),
		otp: openPasswordFields
			? Yup.string().required("One-Time-Passcode is required")
			: Yup.string().notRequired(),
		password: openPasswordFields
			? Yup.string()
					.required("Password is required")
					.min(6, "At least 6 characters required")
					.matches(/[A-Z]/, "Must have at least one uppercase letter")
					.matches(/[a-z]/, "Must have at least one lowercase letter")
					.matches(/[0-9]/, "Must have at least one digit")
			: Yup.string().notRequired(),

		// .when(["otp"], {
		// 	is: (otp) => otp !== null && otp !== undefined && otp.length > 0,
		// 	then: (schema) => schema.required(),
		// 	otherwise: (schema) => schema.notRequired(),
		// })
	});

	const { mutateAsync: mutatePasswordReset, isLoading: isResettingPassword } =
		useResetPassword();
	const { mutateAsync: mutateSendingOtp, isLoading: isSendingOtp } =
		useSendPasswordResetOtp();

	const handleSubmit = async (data) => {
		if (actionIsSendingOtp) {
			await mutateSendingOtp(data);
			setOpenPasswordFields(true);
			setActionIsSendingOtp(false);
			return;
		}

		await mutatePasswordReset({
			...data,
			password_confirmation: data.password,
		});
		setOpenPasswordFields(false);
		setActionIsSendingOtp(true);
		return;
	};

	const handleResentOtp = () => {
		setOpenPasswordFields(false);
		setActionIsSendingOtp(true);
	};

	return (
		<div>
			<Formik
				initialValues={initialValues}
				validationSchema={validate}
				onSubmit={(data) => handleSubmit(data)}
			>
				{() => {
					return (
						<Form className="mt-4">
							<p>
								Enter your registered email address and to receive a
								password reset OTP (One-Time-Passcode)
							</p>

							<div className="mt-8">
								<FormField
									name="username"
									type="text"
									label="Email address"
								/>
							</div>

							{openPasswordFields && (
								<div className="space-y-6 mt-6 pt-8 border-t">
									{msg && <FlashMessage />}

									<FormField
										name="otp"
										type="text"
										label="One Time Password (OTP)"
									/>

									<FormField
										name="password"
										type="password"
										label="New Password*"
									/>

									{/* <div> */}
									<button
										type="button"
										className="text-blue-700"
										onClick={() => handleResentOtp()}
									>
										OTP not received? Resend
									</button>
									{/* </div> */}
								</div>
							)}

							<button
								type="submit"
								className="px-4 mt-8 py-2 flex items-center justify-center gap-2 text-white w-full rounded bg-akgreener"
							>
								{isResettingPassword || isSendingOtp ? (
									<>
										{isSendingOtp ? "Sending" : "Resetting"}
										<AiOutlineLoading3Quarters
											size={20}
											className="animate-spin"
										/>
									</>
								) : (
									<>
										{openPasswordFields
											? "Reset password"
											: "Send OTP"}
										<IoIosArrowForward size={20} />
									</>
								)}
							</button>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default ResetPasswordForm;
