import useChangePassword from "api/UserResource/useChangePassword";
import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import * as Yup from "yup";

const ChangePassword = () => {
	const initialValues = {
		current_password: "",
		password: "",
		password_confirmation: "",
	};

	const updateSchema = Yup.object().shape({
		current_password: Yup.string().required("Current password is required"),
		password: Yup.string()
			.min(6, "At least 6 characters required")
			.matches(/[a-zA-Z]/, "Must have at least one alphabet character")
			.matches(/[0-9]/, "Must have at least one digit")
			.required("Password required"),
		password_confirmation: Yup.string()
			.oneOf([Yup.ref("password")], "Passwords didn't match")
			.required("Password confirmation required"),
	});

	const { mutateAsync, isLoading } = useChangePassword();

	const handleSave = async (data) => {
		await mutateAsync(data);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={updateSchema}
			onSubmit={(data) => handleSave(data)}
		>
			{() => (
				<Form className="space-y-6 mt-4">
					<FormField
						type="password"
						name="current_password"
						label="What's your current password? *"
					/>

					<FormField type="password" name="password" label="New Password *" />

					<FormField
						type="password"
						name="password_confirmation"
						label="Confirm New Password *"
					/>

					<div className=" flex justify-center border-t border-gray-300 pt-2">
						<button
							type="submit"
							className="px-4 py-2 rounded-full bg-akgreenest text-white flex gap-1 items-center justify-center"
						>
							{isLoading ? (
								<>
									Changing{" "}
									<AiOutlineLoading3Quarters
										size={20}
										className="animate-spin"
									/>
								</>
							) : (
								<>
									Change password <IoIosArrowForward size={20} />
								</>
							)}
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default ChangePassword;
