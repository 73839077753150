import Admin2 from "layouts/Admin2";
import React from "react";
import Aggregates from "./Aggregates";
import MostReadBooks from "./MostReadBooks";

const InstitutionDashboard = () => {
	return (
		<Admin2>
			<div className="space-y-8 my-4" style={{ minWidth: "80%" }}>
				<Aggregates />

				<MostReadBooks />
			</div>
		</Admin2>
	);
};

export default InstitutionDashboard;
