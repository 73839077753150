import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import useOverlayStore from "store/overlayStore";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import { defaultPwd } from "utils";
import useAddUser from "api/UserResource/useAddUser";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
// import useAuthStore from "store/authStore";
import useFetchSubjectGroups from "api/SubjectGroupResource/useFetchSubjectGroups";
import ButtonLoader from "components/ButtonLoader";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";
import useViewableRoles from "hooks/useViewableRoles";

const AddUser = () => {
	const { showCreateUser, setShowCreateUser, setShowCreateSubjectGroups } =
		useOverlayStore();

	// const hasRoles = useAuthStore((state) => state.hasRoles);

	const { data: subjectGroups, isLoading: isLoadingSubjectGroups } =
		useFetchSubjectGroups({
			no_pagination: true,
		});

	const subjectGroupsList = subjectGroups?.data?.map((subject) => ({
		label: subject.name,
		value: subject.id,
	}));

	const { roles } = useViewableRoles();

	const [captchaValue, setCaptchaValue] = useState("");
	const captchaHasValue = captchaValue?.length > 0;

	const initialValues = {
		username: "",
		email: "",
		mobile_number: "",
		password: defaultPwd,
		password_confirmation: defaultPwd,
		first_name: "",
		last_name: "",
		dob: "",
		sex: "",
		like_notifications: false,
		avatar: null,
		role: "",
		permissions: [],
		subject_group: [],
	};

	const validate = Yup.object().shape({
		username: Yup.string().required("Username is required"),
		email: Yup.string().email("Invalid email address"),
		// mobile_number: Yup.string()
		// 	.notRequired()
		// 	.matches(phoneRegex, "Invalid phone number")
		// 	.min(10, "Mobile number must have at least characters")
		// 	.max(20, "Mobile number cannot exceed 20 characters"),
		password: Yup.string()
			.min(6, "At least 6 characters required")
			.matches(/[a-zA-Z]/, "Must have at least one alphabet character")
			.matches(/[0-9]/, "Must have at least one digit")
			.required("Password required"),
		first_name: Yup.string().required("First name is required"),
		last_name: Yup.string().required("Surname is required"),
		dob: Yup.string().matches(
			/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
			"Invalid date format"
		),
		role: Yup.string().required("Select role"),
		// subject_group: Yup.array().when(["role"], {
		// 	is: (role) => role === "Student",
		// 	then: (schema) => schema.required("Subject group is required").nullable(),
		// 	otherwise: (schema) => schema.notRequired(),
		// }),
	});

	const { mutateAsync, isLoading } = useAddUser();
	const handleRegister = async (data) => {
		const emailAddress =
			data.email?.length > 0
				? data.email
				: `${data.first_name}.${data.last_name}@gmail.com`.toLowerCase();

		await mutateAsync({
			...data,
			email: emailAddress,
			role: [data.role],
			password_confirmation: data.password,
			like_notifications: data.like_notifications ? 1 : 0,
			captcha: captchaValue,
			client: "web",
		});
	};

	const [willProvideEmail, setWillProvideEmail] = React.useState(false);

	return (
		<Dialog
			visible={showCreateUser}
			style={{ width: "50vw" }}
			header={
				<div className="text-xl font-sans font-bold sticky top-4 z-20 bg-white mr-2">
					<p className="">Create User</p>
				</div>
			}
			onHide={() => setShowCreateUser(false)}
			position="top"
			draggable={false}
			contentClassName="rounded-b-xl "
			className="rounded-xl relative"
		>
			{(function () {
				if (isLoadingSubjectGroups) {
					return (
						<div className="flex items-center justify-center">
							<ButtonLoader size={60} />
						</div>
					);
				}

				return (
					<Formik
						initialValues={initialValues}
						validationSchema={validate}
						onSubmit={handleRegister}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							setFieldValue,
						}) => {
							function generateUsernameUsingName(e) {
								const inputIsName =
									e.target.name === "first_name" ||
									e.target.name === "last_name";
								if (inputIsName) {
									let username;
									if (e.target.name === "first_name") {
										username = `${e.target.value}.${values.last_name}`;
									} else {
										username = `${values.first_name}.${e.target.value}`;
									}
									return setFieldValue("username", username.toLowerCase());
								}
							}

							return (
								<Form>
									<div className="py-6 space-y-6">
										<FormField
											type="text"
											name="first_name"
											label="First Name *"
											onInput={(e) => generateUsernameUsingName(e)}
										/>

										<FormField
											type="text"
											name="last_name"
											label="Last Name *"
											onInput={(e) => generateUsernameUsingName(e)}
										/>

										<FormField name="username" type="text" label="Username *" />

										{!willProvideEmail && (
											<div>
												<div className="flex items-center gap-2">
													<p>
														We will associate this email (Gmail) with this
														account:{" "}
													</p>
													{values.first_name && values.last_name && (
														<span className="text-base font-bold">
															{values.first_name +
																"." +
																values.last_name +
																"@gmail.com".toLowerCase()}
														</span>
													)}
												</div>
												<button
													type="button"
													className="text-blue-700"
													onClick={() => setWillProvideEmail(true)}
												>
													If the user already has an email address, you can
													provide it by clicking here
												</button>
											</div>
										)}

										{willProvideEmail && (
											<div>
												<FormField
													type="text"
													name="email"
													label="Email Address *"
												/>
												<button
													type="button"
													className="text-blue-700 mt-1"
													onClick={() => setWillProvideEmail(false)}
												>
													The user does not have an email address, please
													provide one to be created later
												</button>
											</div>
										)}

										<FormField
											name="mobile_number"
											type="phone"
											label="Phone Number"
											placeholder="phone"
											value={values.mobile_number}
											_onChange={(phone) => {
												if (phone !== "+") values.mobile_number = "+" + phone;
												else values.mobile_number = phone;
											}}
											error={Boolean(
												errors.mobile_number && touched.mobile_number
											)}
											errorText={errors.mobile_number}
										/>
										<FormField
											name="dob"
											type="date"
											label="Date of Birth"
											value={values.dob}
											onChange={(e) =>
												setFieldValue(
													"dob",
													moment(e.target?.value).format("YYYY-MM-DD")
												)
											}
										/>

										<FormField
											type="select"
											name="role"
											label="Role *"
											options={roles?.map((role) => ({
												label: role.label,
												value: role.value,
											}))}
										/>

										{/* && subjectGroupsList?.length > 0 */}

										{(function () {
											if (values.role === "Student") {
												if (
													subjectGroupsList &&
													subjectGroupsList?.length > 0
												) {
													return (
														<FormField
															type="multiselect"
															name="subject_group"
															label="Subject group "
															options={
																subjectGroupsList?.map((subjectGroup) => ({
																	label: subjectGroup.label,
																	value: subjectGroup.value,
																})) || []
															}
														/>
													);
												} else {
													return (
														<div className="flex  gap-2 items-center p-2 rounded bg-gray-200">
															<p>
																Student may need to belong to a subject group
																(e.g Sciences, Grade 5, etc).
															</p>
															<button
																type="button"
																className="font-bold text-lg text-blue-800"
																onClick={() => setShowCreateSubjectGroups(true)}
															>
																Create one here
															</button>
														</div>
													);
												}
											}
										})()}

										<div>
											<ReCAPTCHA
												sitekey={
													process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
												}
												onChange={(val) => setCaptchaValue(val)}
											/>
										</div>

										<div className=" flex justify-center border-t border-gray-300 pt-2">
											<button
												type="submit"
												className={`px-4 py-2 rounded-full text-white flex gap-1 items-center justify-center ${
													captchaHasValue ? "bg-akgreenest" : "bg-gray-300"
												}`}
												disabled={!captchaHasValue}
											>
												{isLoading ? (
													<>
														Submitting{" "}
														<AiOutlineLoading3Quarters
															size={20}
															className="animate-spin"
														/>
													</>
												) : (
													<>
														Submit <IoIosArrowForward size={20} />
													</>
												)}
											</button>
										</div>
									</div>
								</Form>
							);
						}}
					</Formik>
				);
			})()}
		</Dialog>
	);
};

export default AddUser;
