import React from "react";
import ProfileLayout from "../ProfileLayout";
import useSearchParams from "hooks/useSearchParams";
import useCheckIfUserIsSubscribed from "api/SubscriptionsResource/useCheckIfUserIsSubscribed";
import useProfile from "api/UserResource/useProfile";
import { Link } from "react-router-dom";
import { MdOutlineSubscriptions } from "react-icons/md";
import Moment from "react-moment";

const MySubscription = () => {
	const today = new Date();

	const { getParam } = useSearchParams();
	const heading = getParam("heading") || "My subscription";

	const { data: isSubscribed, isLoading: isLoadingSubscriptionPackage } =
		useCheckIfUserIsSubscribed();

	const { data: profile, isLoading: isLoadingProfile } = useProfile();

	return (
		<ProfileLayout
			heading={heading}
			isLoading={isLoadingSubscriptionPackage || isLoadingProfile}
			profile={profile?.data}
		>
			<div>
				{(function () {
					if (!isSubscribed?.data) {
						return (
							<div
								className="flex flex-col justify-center items-center gap-1 w-full text-center"
								style={{ minHeight: 200 }}
							>
								<MdOutlineSubscriptions size={130} className="text-green-200" />

								<p className="pt-4">
									You currently don't have an active subscription plan
								</p>
								<Link
									to="/subscriptions"
									className="text-akgreener text-base flex flex-col items-end font-bold"
								>
									<span>You can subscribe here</span>
									<span className="w-20 border-b-4 border-akgreen"></span>
								</Link>
							</div>
						);
					}

					return (
						<div className="space-y-4">
							<div className={`mt-4 `}>
								<h4>Subscription details</h4>

								<p className="flex justify-between items-center">
									<span>Package name</span>
									<span>{isSubscribed?.data?.name}</span>
								</p>
								<p className="flex justify-between items-center">
									<span>Expiring</span>
									<Moment to={isSubscribed?.data?.expiry_date}>{today}</Moment>
								</p>
								<p className="flex justify-between items-center">
									<span>Picks allowed</span>
									<span>{isSubscribed?.data?.max_number_of_books}</span>
								</p>
								<p className="flex justify-between items-center">
									<span>Picks chosen</span>
									<span>{isSubscribed?.data?.current_premium_books_added}</span>
								</p>
								<p className="flex justify-between items-center">
									<span>Picks remaining</span>
									<span>{isSubscribed?.data?.remaining}</span>
								</p>
							</div>

							<p className="p-4 rounded-lg bg-green-100 border border-green-200">
								Under your current active subscription,{" "}
								<span className="font-bold">picks</span> represent the number of
								books you can add to your library for reading
							</p>
						</div>
					);
				})()}
			</div>
		</ProfileLayout>
	);
};

export default MySubscription;
