import InitApi from "api/InitApi";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { reactLocalStorage } from "reactjs-localstorage";
import useAuthStore from "store/authStore";
import useTokenStore from "store/tokenStore";

const useDeleteAccount = (id) => {
	const { accessToken, storeAccessToken } = useTokenStore();
	const endSession = useAuthStore((state) => state.endSession);

	const queryClient = useQueryClient();

	const history = useHistory();

	const destroy = async () => {
		const instance = await InitApi({ accessToken });

		const response = await instance.delete(`/delete-account`);
		return response;
	};

	return useMutation(destroy, {
		onSuccess: async ({ data: body }) => {
			queryClient.clear();
			storeAccessToken(null);
			endSession();
			reactLocalStorage.clear();
			sessionStorage.clear();
			toast.success("Account deleted successfully");
			history.push("/register");
		},
		onError: async (error) => {
			queryClient.clear();
			reactLocalStorage.clear();
			sessionStorage.clear();
			history.push("/register");
		},
	});
};

export default useDeleteAccount;
