import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import { BiCalendar } from "react-icons/bi";
import moment from "moment";
import { GiTakeMyMoney } from "react-icons/gi";
// import { AiOutlineNumber } from "react-icons/ai";
import useFetchPublisherAggregateRevenue from "api/AdminReports/useFetchPublisherAggregateRevenue";
import { activeCurrencies, getMoneyPrefix } from "utils";

const Card = ({ label, value, icon }) => {
	const isValueArray = Array.isArray(value);

	return (
		<div className="py-2 px-3  bg-white rounded shadow  w-full">
			<div className="h-12 w-12 rounded-full flex items-center justify-center border">
				{icon}
			</div>

			<div className="flex flex-col items-start">
				<h4>{label}</h4>

				<div className="mt-2 space-y-2">
					{isValueArray ? (
						value?.map((val, index) => (
							<p className="flex items-center text-lg font-bold" key={index}>
								{val}
							</p>
						))
					) : (
						<p className="flex items-center text-lg font-bold">{value}</p>
					)}
				</div>
			</div>
		</div>
	);
};

const PublisherRevenueReport = ({ userId }) => {
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");

	const { data: revenue } = useFetchPublisherAggregateRevenue(
		{
			from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
			to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
		},
		userId
	);

	return (
		<div className="w-full space-y-4">
			<div className="flex justify-between items-center w-full ">
				<h4 className="font-cera-black flex gap-2">
					<span>Earnings report</span>
				</h4>

				<div className="flex gap-4 items-center">
					<span className="p-float-label">
						<Calendar
							id={fromDate}
							className="font-sans"
							value={fromDate}
							onChange={(e) => setFromDate(e.target.value)}
							dateFormat="yy-mm-dd"
							mask="9999-99-99"
							panelClassName="font-sans text-xs"
							showIcon
							icon={<BiCalendar size={20} />}
							inputClassName="rounded-3xl pl-4 text-sm"
						/>
						<label htmlFor={fromDate}>From date -</label>
					</span>

					<span className="p-float-label">
						<Calendar
							id={toDate}
							className="font-sans"
							value={toDate}
							onChange={(e) => setToDate(e.target.value)}
							dateFormat="yy-mm-dd"
							mask="9999-99-99"
							panelClassName="font-sans text-xs"
							showIcon
							icon={<BiCalendar size={20} />}
							inputClassName="rounded-3xl pl-4 text-sm"
						/>
						<label htmlFor={toDate}>To date -</label>
					</span>
				</div>
			</div>

			<div>
				<div className="py-3 space-y-2">
					<p className="text-lg">{activeCurrencies.USD.label} Income</p>
					<div className="flex gap-6">
						<Card
							label="Total revenue (S + P)"
							value={[
								`Gross (${getMoneyPrefix(activeCurrencies.USD.code)} ${
									revenue?.data?.totalIncome || 0
								})`,
								`Received (${getMoneyPrefix(activeCurrencies.USD.code)} ${
									revenue?.data?.amountReceived || 0
								})`,
								`Net (${getMoneyPrefix(activeCurrencies.USD.code)} ${
									revenue?.data?.netTotalIncome || 0
								})`,
							]}
							icon={<GiTakeMyMoney size={30} className="text-gray-400" />}
						/>

						<Card
							label="From subscriptions (S)"
							value={`Gross (${getMoneyPrefix(activeCurrencies.USD.code)} ${
								revenue?.data?.incomeFromSubscription || 0
							})`}
							icon={<GiTakeMyMoney size={30} className="text-gray-400" />}
						/>

						<Card
							label="From purchases (P)"
							value={`Gross (${getMoneyPrefix(activeCurrencies.USD.code)} ${
								revenue?.data?.incomeFromPurchases || 0
							})`}
							icon={<GiTakeMyMoney size={30} className="text-gray-400" />}
						/>
					</div>
				</div>

				<div className="py-3 space-y-2">
					<p className="text-lg">{activeCurrencies.ZWL.label} Income</p>
					<div className="flex gap-6">
						<Card
							label="Total revenue (S + P)"
							value={[
								`Gross (${getMoneyPrefix(activeCurrencies.ZWL.code)} ${
									revenue?.data?.totalIncomeZwl || 0
								})`,
								`Received (${getMoneyPrefix(activeCurrencies.ZWL.code)} ${
									revenue?.data?.amountReceivedZwl || 0
								})`,
								`Net (${getMoneyPrefix(activeCurrencies.ZWL.code)} ${
									revenue?.data?.netTotalIncomeZwl || 0
								})`,
							]}
							icon={<GiTakeMyMoney size={30} className="text-gray-400" />}
						/>

						<Card
							label="From subscriptions (S)"
							value={`Gross (${getMoneyPrefix(activeCurrencies.ZWL.code)} ${
								revenue?.data?.incomeFromSubscriptionZwl || 0
							})`}
							icon={<GiTakeMyMoney size={30} className="text-gray-400" />}
						/>

						<Card
							label="From purchases (P)"
							value={`Gross (${getMoneyPrefix(activeCurrencies.ZWL.code)} ${
								revenue?.data?.incomeFromPurchasesZwl || 0
							})`}
							icon={<GiTakeMyMoney size={30} className="text-gray-400" />}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PublisherRevenueReport;
