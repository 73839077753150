import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useAuthStore from "store/authStore";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";

const useUserCartItems = () => {
	const { accessToken } = useTokenStore();
	const loggedIn = useAuthStore((state) => state.loggedIn);

	const fetch = async () => {
		if (!loggedIn) {
			return null;
		}

		const instance = await InitApi({ accessToken });
		return await instance.get("carts");
	};
	return useQuery("user_cart_items", () => fetch(), {
		...fetchConfig,
	});
};

export default useUserCartItems;
