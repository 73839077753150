// import useCreateOrder from "api/OrderResource/useCreateOrder";
import useCreateSubscriptionOrder from "api/OrderResource/useCreateSubscriptionOrder";
import useGetStars from "api/StarsResource/useGetStars";
import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { activeCurrencies } from "utils";
import * as Yup from "yup";

const StarsPay = ({ id, grandTotal, credits }) => {
	// const [voucherNumber, setVoucherNumber] = React.useState("");

	// const [paymentMethod, setPaymentMethod] = React.useState("Stars");

	const [stars, setStars] = React.useState(0);
	const [studentNumber, setStudentNumber] = React.useState("");

	const initialValues = {
		student_number: "",
		security_answer: "",
	};

	const validate = Yup.object().shape({
		student_number: Yup.string().required("Student number is required"),
		security_answer: Yup.string().required("Security answer is required"),
	});

	const {
		mutateAsync,
		isLoading,
		data: starsResponse,
		isSuccess,
	} = useGetStars(setStars);

	const handleRequestStars = async (data) => {
		await mutateAsync(data);
		setStudentNumber(data.student_number);
	};

	const { mutateAsync: mutateFreeOrder, isLoading: isCreatingOrder } =
		useCreateSubscriptionOrder();

	const placeOrder = async () => {
		await mutateFreeOrder({
			street_address: "",
			city: "",
			province: "",
			country: "",
			postcode: "",
			payment_method: "Stars Redemption",
			payment_type: "Subscription",
			subtotal: 0,
			grand_charges: 0,
			grand_total: 0,
			grand_total_zwl: grandTotal,
			reference:
				moment().format("YYYYMMDDHHmmss") +
				"-" +
				moment().subtract(1, "minutes").format("YYYYMMDDHHmmss"),
			status: "Completed",
			id: id,
			student_number: studentNumber,
			points_used: credits,
			currency: activeCurrencies.ZWL.code,
			total_amount: grandTotal,
		});
	};

	return (
		<div className="mt-4 space-y-4">
			<h3>Redeem stars</h3>

			{(function () {
				if (isSuccess && Number(stars) < Number(credits) && starsResponse) {
					return (
						<p>
							Unfortunately you have only &nbsp;
							<span className="font-bold text-gray-600">{stars} stars</span>
							&nbsp; which is not redeemable to complete this transaction. You
							have to proceed with other payment methods.
						</p>
					);
				}

				if (isSuccess && Number(stars) >= Number(credits)) {
					return (
						<div className="space-y-4">
							<p>
								You have enough stars to proceed with the transaction. Note that
								your "stars" balance on Akello Smart Learning will be reduced by{" "}
								<span className="font-bold text-gray-600">{credits} stars</span>
								. Press the button below to proceed.
							</p>

							<button
								type="button"
								onClick={() => placeOrder()}
								className="rounded-full px-3 py-2 text-white bg-akgreenest flex items-center justify-center gap-2 w-full"
							>
								Proceed with payment
								{isCreatingOrder ? (
									<AiOutlineLoading3Quarters
										size={20}
										className="animate-spin"
									/>
								) : undefined}
							</button>
						</div>
					);
				}

				return (
					<Formik
						initialValues={initialValues}
						validationSchema={validate}
						onSubmit={(data) => handleRequestStars(data)}
					>
						<Form>
							<div>
								<p>
									This payment of &nbsp;
									<span className="font-bold">
										{activeCurrencies.ZWL.label} ${grandTotal}{" "}
									</span>
									is equivalent to <span className="font-bold">{credits}</span>{" "}
									stars.
								</p>
								<p>
									As a student of &nbsp;
									<a
										href="https://smartlearning.akello.co/"
										className="text-blue-700"
										target="_blank"
										rel="noreferrer"
									>
										Akello Smart Learning
									</a>
									, you can redeem your stars to pay for your subscription by
									entering your student number and security answer as set in
									your Akello Smart Learning account.
								</p>
							</div>

							<div className="space-y-5 mt-6">
								<FormField
									name="student_number"
									label="Enter student number *"
								/>
								<FormField
									name="security_answer"
									label="Enter security answer *"
								/>

								<button
									type="submit"
									className="rounded-full px-3 py-2 text-white bg-akgreenest flex items-center justify-center gap-2 w-full"
								>
									Request stars
									{isLoading ? (
										<AiOutlineLoading3Quarters
											size={20}
											className="animate-spin"
										/>
									) : undefined}
								</button>
							</div>
						</Form>
					</Formik>
				);
			})()}
		</div>
	);
};

export default StarsPay;
