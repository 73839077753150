import React from "react";
import { Link } from "react-router-dom";

const StatisticsCard = ({
	label,
	statistic,
	icon: Icon,
	view = null,
	bgWhite = null,
}) => {
	return (
		<div
			className="rounded-lg border border-gray-300 py-4 px-8 flex items-center justify-between gap-2"
			style={{
				minWidth: 150,
				height: 130,
				backgroundColor: bgWhite ? "#fff" : "",
			}}
		>
			<div>
				<p>{label}</p>
				<div className="flex gap-2 items-center">
					<h2 className="font-bold">{statistic}</h2>
					{view && (
						<Link to={view} className="text-xs text-blue-900">
							(View more)
						</Link>
					)}
				</div>
			</div>
			<Icon size={40} className="text-green-300 " />
		</div>
	);
};

export default StatisticsCard;
