import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { BsArrowRight } from "react-icons/bs";
import { Sidebar } from "primereact/sidebar";
import { Column } from "primereact/column";

const UserLogins = ({ logins }) => {
	const [openLogins, setopenLogins] = useState(false);

	const data = logins?.map((login) => ({
		...login,
		osVersion: login?.osVersion || "N/A",
		browser: login?.browser || "N/A",
		os: login?.os || "N/A",
	}));

	return (
		<>
			<button
				type="button"
				onClick={() => setopenLogins(true)}
				className="text-akgreen flex items-center gap-1"
			>
				Logins <BsArrowRight size={20} />
			</button>

			<Sidebar
				visible={openLogins}
				icons={<h4 className="font-cera-black text-akgreenest text-2xl">Logins</h4>}
				blockScroll
				onHide={() => setopenLogins(false)}
				className="font-sans "
				fullScreen
			>
				<hr className="mb-4" />

				<div className="flex flex-col items-center ">
					<DataTable
						value={data}
						tableClassName="font-sans text-sm"
						autoLayout
						dataKey="id"
					>
						<Column field="device" header="Device" />
						<Column field="os" header="Operating system (OS)" />
						<Column field="osVersion" header="OS Version" />
						<Column field="browser" header="Web Browser" />
						<Column field="created_at" header="Date logged in" />
					</DataTable>
				</div>
			</Sidebar>
		</>
	);
};

export default UserLogins;
