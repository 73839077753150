import PaddingX from "layouts/PaddingX";
import { Link, NavLink } from "react-router-dom";
import boy from "../../../assets/images/boy.png";
import useAuthStore from "store/authStore";

const SlideItem1 = () => {
	const loggedIn = useAuthStore((state) => state.loggedIn);

	return (
		<div
			className="w-full  bg-slide-banner1 bg-no-repeat bg-cover slider-item "
			style={{ minHeight: "50vh" }}
		>
			<PaddingX>
				<div className="text-left slider-details">
					<div className="pl-8 pl:pl-0 ">
						<h1 className="text-white font-cera-black slider-title mb-4">
							Open the door to
						</h1>
						<h1 className="text-white font-cera-black slider-title mb-12">
							Knowledge
						</h1>

						<p className="text-white text-lg slider-text mb-10">
							Akello Library offers digital access to a wide range of
							up-to-date, carefully curated, academic and non-academic books
							targeted towards primary & secondary learners as well as avid
							readers
						</p>

						<div className="flex gap-2">
							<Link
								to="/downloads"
								className="rounded-md bg-akgreenest text-lg text-white px-3 py-4 hover:bg-white hover:text-akgreenest border border-transparent hover:border-akgreenest"
								style={{ transition: "0.5s" }}
							>
								Download reading app
							</Link>

							{!loggedIn && (
								<NavLink
									to="/register"
									className="bg-white text-akgreenest text-lg px-3 py-4  hover:bg-akgreenest hover:text-white rounded-md border border-transparent hover:border-white"
									style={{ transition: "0.5s" }}
								>
									Register now
								</NavLink>
							)}
						</div>
					</div>
				</div>
			</PaddingX>

			<img src={boy} className="slider-boy" alt="" />
		</div>
	);
};

export default SlideItem1;
