import useResetPassword from "api/UserResource/useResetPassword";
import { ButtonLoader } from "components";
import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import { useUrlQuery } from "hooks/useUrlQuery";
import AuthLayout from "layouts/AuthLayout";
import { Link } from "react-router-dom";
import * as Yup from "yup";

const ResetPassword = () => {
	// const logoWidth = 80;

	let query = useUrlQuery();

	const initialValues = {
		email: "",
		password: "",
		password_confirmation: "",
	};

	const validate = Yup.object().shape({
		email: Yup.string().required("Email is required"),
		password: Yup.string()
			.min(6, "At least 6 characters required")
			.matches(/[A-Z]/, "Must have at least one uppercase letter")
			.matches(/[a-z]/, "Must have at least one lowercase letter")
			.matches(/[0-9]/, "Must have at least one digit")
			.required("Password required"),
		password_confirmation: Yup.string()
			.oneOf([Yup.ref("password")], "Passwords didn't match")
			.required("Confirmation required"),
	});

	const { mutateAsync, isLoading } = useResetPassword();
	const handleSubmit = async (data) => {
		await mutateAsync({
			...data,
			token: query?.get("token"),
		});
	};

	return (
		<AuthLayout hideCamera>
			<h1 className="text-akgreen font-cera-black mt-2">Reset Password</h1>

			<p className="mt-2" style={{ width: 400 }}>
				Enter your current registered email address and the new password to reset password
			</p>

			<Formik
				initialValues={initialValues}
				validationSchema={validate}
				onSubmit={(data) => handleSubmit(data)}
			>
				{(formik) => {
					return (
						<Form className="flex flex-col gap-6 w-full mt-10" style={{ width: 250 }}>
							<FormField name="email" type="text" label="Current Email Address*" />

							<FormField name="password" type="password" label="New Password*" />

							<FormField
								name="password_confirmation"
								type="password"
								label="Confirm New Password*"
							/>

							<div>
								<button
									type="submit"
									className="px-4 py-1 flex items-center justify-center gap-1 text-white w-32 rounded-full bg-gradient-to-r from-akgreen to-akgreenest"
								>
									{isLoading ? (
										<>
											Resetting...
											<ButtonLoader
												size={25}
												color="rgba(255, 255, 255)"
											/>{" "}
										</>
									) : (
										"Reset"
									)}
								</button>
							</div>

							<p>
								<Link to="/login">Go to Login</Link>
							</p>
						</Form>
					);
				}}
			</Formik>
		</AuthLayout>
	);
};

export default ResetPassword;
