import useAddInstitutionUser from "api/UserResource/useAddInstitutionUser";
import { Button, ButtonLoader, SelectField, TextField } from "components";
import PhoneTextField from "components/PhoneTextField";
import { Form, Formik } from "formik";
import { Sidebar } from "primereact/sidebar";
import React from "react";
import { FiUserPlus } from "react-icons/fi";
import { defaultPwd, phoneRegex } from "utils";
import * as Yup from "yup";

const AddPublisherUser = () => {
	const [displayBasic, setDisplayBasic] = React.useState(false);

	//   const { data: rolesData, isLoading: rolesIsLoading } = useFetchRoles();

	const initialValues = {
		email: "",
		username: "",
		mobile_number: "",
		password: defaultPwd,
		password_confirmation: defaultPwd,
		first_name: "",
		last_name: "",
		dob: "",
		sex: "",
		like_notifications: false,
		role: "Publisher",
	};

	const registerSchema = Yup.object().shape({
		email: Yup.string()
			.email("Invalid email address")
			.required("Email is required"),
		mobile_number: Yup.string()
			.matches(phoneRegex, "Invalid phone number")
			.min(10, "Mobile number must have at least characters")
			.max(20, "Mobile number cannot exceed 20 characters"),
		role: Yup.string().required("Role is required"),
		password: Yup.string()
			.min(6, "At least 6 characters required")
			.matches(/[a-zA-Z]/, "Must have at least one alphabet character")
			.matches(/[0-9]/, "Must have at least one digit")
			.required("Password required"),
		first_name: Yup.string().required("First name is required"),
		last_name: Yup.string().required("Surname is required"),
		sex: Yup.string().required("Gender is required"),
		dob: Yup.string().matches(
			/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
			"Invalid date format"
		),
	});

	const { mutateAsync, isLoading } = useAddInstitutionUser("publisher");
	const handleRegister = async (data) => {
		await mutateAsync({
			...data,
			username: data.email,
			password_confirmation: data.password,
			like_notifications: data.like_notifications ? 1 : 0,
		});
		setDisplayBasic(false);
	};

	return (
		<>
			<button
				type="button"
				onClick={() => setDisplayBasic(true)}
				className="px-2 py-1 text-sm rounded bg-gradient-to-b from-akgreenest to-akgreen text-gray-100 border border-akgreen flex gap-2 items-center"
			>
				Add Publisher User <FiUserPlus />
			</button>

			<Sidebar
				position="right"
				visible={displayBasic}
				onHide={() => setDisplayBasic(false)}
				className="min-w-max"
				icons={
					<h2 className="border-b-2 border-gray-400 border-dashed mr-16">
						Add new publisher user
					</h2>
				}
				blockScroll
			>
				<Formik
					initialValues={initialValues}
					validationSchema={registerSchema}
					onSubmit={(data) => handleRegister(data)}
				>
					{(formik) => {
						return (
							<Form className=" rounded-3xl py-3">
								<TextField
									type="text"
									name="email"
									placeholder="Email..."
									label="Email Address"
								/>
								<TextField
									type="text"
									name="first_name"
									placeholder="First name..."
									label="First Name"
								/>

								<TextField
									type="text"
									name="last_name"
									placeholder="Last name..."
									label="Last Name"
								/>
								<PhoneTextField
									value={initialValues.mobile_number}
									_onChange={(phone) => {
										if (phone !== "+")
											formik.values.mobile_number = "+" + phone;
										else formik.values.mobile_number = phone;
									}}
									error={Boolean(
										formik.errors.mobile_number && formik.touched.mobile_number
									)}
									errorText={formik.errors.mobile_number}
									label="Mobile Number"
								/>
								<TextField
									type="date"
									name="dob"
									placeholder="e.g dd/mm/yyyy"
									label="Date of Birth"
								/>
								<SelectField name="sex" placeholder="- Gender- " label="Gender">
									<option value="Male">Male</option>
									<option value="Female">Female</option>
								</SelectField>

								<div className="mt-4 flex justify-end items-center">
									<Button type="reset">Clear</Button>
									{isLoading ? (
										<ButtonLoader size={40} />
									) : (
										<Button type="submit" className="ml-3 flex items-center ">
											Add
										</Button>
									)}
								</div>
							</Form>
						);
					}}
				</Formik>
			</Sidebar>
		</>
	);
};

export default AddPublisherUser;
