import { ButtonLoader, DesktopHeader } from "components";
import { Container } from "layouts";
import useFetchSubscriptions from "api/SubscriptionsResource/useFetchSubscriptions";
import useCheckIfUserIsSubscribed from "api/SubscriptionsResource/useCheckIfUserIsSubscribed";
import PaddingX from "layouts/PaddingX";
import useCurrencyStore from "store/currencyStore";
import React from "react";
import { Link } from "react-router-dom";
import { activeCurrencies, getMoneyPrefix } from "utils";
import { Tooltip } from "primereact/tooltip";
import { BsInfoCircle } from "react-icons/bs";

const Subscriptions = () => {
	//!

	const { currency } = useCurrencyStore();

	const { data: subscriptions, isLoading: isLoadingSubscriptions } =
		useFetchSubscriptions();

	const { data: isSubscribed, isLoading: isLoadingSubscribed } =
		useCheckIfUserIsSubscribed();

	return (
		<Container className="min-h-screen flex flex-col w-full ">
			<DesktopHeader shadow />

			<div className="mt-10">
				<h1 className="mb-8 text-center font-bold">Choose your subscription</h1>

				{(function () {
					if (isLoadingSubscribed || isLoadingSubscriptions) {
						return (
							<PaddingX>
								<div className="flex justify-center gap-8">
									<ButtonLoader size={100} />
								</div>
							</PaddingX>
						);
					}

					return (
						<PaddingX>
							<div className="grid grid-cols-1 lg:grid-cols-2 space-y-4 md:flex items-center justify-center md:space-y-0 gap-4 lg:gap-12">
								{subscriptions?.data?.map((subscription) => {
									let label = null;
									if (subscription?.name.toLowerCase() === "weekly") {
										label = "(7 days)";
									} else if (subscription?.name.toLowerCase() === "monthly") {
										label = "(30 days)";
									}

									const hasActiveSubscription =
										Boolean(isSubscribed) &&
										Boolean(isSubscribed?.data?.id === subscription?.id);

									return (
										<div
											className={`${
												isSubscribed?.data &&
												isSubscribed?.data?.id === subscription?.id
													? "border-4"
													: "border-2"
											}  rounded-2xl border-akgreenest text-center space-y-10 py-10 px-4 mx-auto md:mx-0`}
											style={{ width: 330 }}
										>
											<div>
												<h1 className="capitalize font-bold">
													{subscription.name} {label}
												</h1>
												{/* <p className="text-xl font-bold">
													{subscription?.category}
												</p> */}
											</div>

											<div className="flex flex-col justify-center items-center text-xl font-bold">
												<p className="flex items-center justify-center gap-2">
													<span className="text-center">
														{getMoneyPrefix(currency)}&nbsp;
														{currency === activeCurrencies.USD.code
															? subscription?.cost
															: subscription?.zwl_price}
													</span>
												</p>

												<span className="text-center">Or</span>
												<span className="text-center">
													{subscription?.credits} stars
												</span>
											</div>

											<div className="font-bold text-base">
												<p>
													Access to {subscription?.max_number_of_books} books
												</p>
												<p className="mb-2">Unlimited free titles</p>
												<Tooltip
													target={`#pr-info-${subscription.id}`}
													position="top"
													className="light-tooltip text-center"
													autoHide={false}
												>
													<span>
														You can filter and explore titles <br /> available
														on subscription{" "}
														<Link
															to={`/books/?pricing_model_is=has_subscription`}
															className="font-bold text-blue-700 underline"
														>
															here
														</Link>
													</span>
												</Tooltip>
												<button
													id={`pr-info-${subscription.id}`}
													type="button"
													className="flex pr-info items-center justify-center p-1 border rounded-full mx-auto relative border-gray-300 hover:scale-105 "
												>
													<BsInfoCircle
														size={40}
														className="text-blue-700 "
													/>
												</button>
											</div>

											<div
												className="mx-auto"
												style={{ height: 52 }}
											>
												{hasActiveSubscription ? (
													<span className="py-2 px-3 text-sm bg-akgreenest rounded-full text-white h-12 flex items-center justify-center mx-auto">
														Your are subscribed to this package
													</span>
												) : (
													<Link
														to={`/subscription/pay/${subscription.id}`}
														className="py-3 px-2 w-52 rounded-full bg-gradient-to-r from-akgreener to-akgreenest text-white text-base transition duration-150 block mx-auto"
													>
														Subscribe
													</Link>
												)}
											</div>
										</div>
									);
								})}
							</div>
						</PaddingX>
					);
				})()}
			</div>
		</Container>
	);
};

export default Subscriptions;
