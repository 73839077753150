import useChangePassword from "api/UserResource/useChangePassword";
import { Button, TextField } from "components";
import { Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { FcProcess } from "react-icons/fc";
import * as Yup from "yup";

const ChangePassword = () => {
	const [visible, setVisible] = useState(false);

	const initialValues = {
		current_password: "",
		password: "",
		password_confirmation: "",
	};

	const updateSchema = Yup.object().shape({
		current_password: Yup.string().required("Current password is required"),
		password: Yup.string()
			.min(6, "At least 6 characters required")
			.matches(/[a-zA-Z]/, "Must have at least one alphabet character")
			.matches(/[0-9]/, "Must have at least one digit")
			.required("Password required"),
		password_confirmation: Yup.string()
			.oneOf([Yup.ref("password")], "Passwords didn't match")
			.required("Password confirmation required"),
	});

	const { mutateAsync, isLoading } = useChangePassword(setVisible);

	const handleSave = async (data) => {
		await mutateAsync(data);
	};

	return (
		<>
			<button
				className="rounded-lg px-4 py-1 text-center bg-akgreener text-white"
				type="button"
				onClick={() => setVisible(true)}
			>
				Change password
			</button>

			<Dialog
				visible={visible}
				header={<span className="font-cera-black">Change Password</span>}
				onHide={() => setVisible(false)}
			>
				<Formik
					initialValues={initialValues}
					validationSchema={updateSchema}
					onSubmit={(data) => handleSave(data)}
				>
					{(formik) => {
						return (
							<Form>
								<TextField
									type="password"
									name="current_password"
									className="mb-2"
									label="Enter your current password"
								/>

								<TextField
									type="password"
									name="password"
									info={
										<ul className="divide-y space-y-1">
											<li>Must have at least 6 characters</li>
											<li>Must have at least one alphabet character</li>
											<li>Must have at least one digit</li>
										</ul>
									}
									className="mb-3"
									label="New password"
								/>

								<TextField
									type="password"
									name="password_confirmation"
									className="mb-3"
									label="Confirm new password"
								/>

								<div className="mt-4 flex justify-start items-center">
									<Button
										type="submit"
										className="ml-3 flex gap-2 items-center "
									>
										<span>Update password</span>
										{isLoading && (
											<FcProcess
												size={20}
												className="animate-spin bg-gray-200 rounded-full"
											/>
										)}
									</Button>
								</div>
							</Form>
						);
					}}
				</Formik>
			</Dialog>
		</>
	);
};

export default ChangePassword;
