import useFetchCategories from "api/CategoryResource/useFetchCategories";
import useGenres from "api/GenreResource/useGenres";
import useFetchPublishers from "api/PublisherResource/useFetchPublishers";
import { Form, Formik } from "formik";
import { BsFilter } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import useBooksFilterStore from "store/booksFilterStore";

const BooksFilter = () => {
  const { bookFilters, setBookFilters, setPage } = useBooksFilterStore();

  const { data: genres, isLoading: isLoadingGenres } = useGenres();
  const {
    data: categories,
    isLoading: isLoadingCategories,
  } = useFetchCategories();

  const {
    data: publishers,
    isLoading: isLoadingPublishers,
  } = useFetchPublishers();

  const initialValues = {
    author: "",
    title: "",
    isbn: "",
    category_is: "",
    genre_is: [],
    isFree: "",
    publisher_is: "",
  };

  const handleFilter = (data) => {
    setPage(1);
    let isFree;

    if (data.isFree === "free") {
      isFree = 1;
    } else if (data.isFree === "paid") {
      isFree = 0;
    } else {
      isFree = null;
    }
    setBookFilters({
      ...bookFilters,
      "filter[author]": data.author,
      "filter[title]": data.title,
      "filter[isbn]": data.isbn,
      "filter[category_is]": data.category_is,
      "filter[genre_is]": [data.genre_is],
      "filter[isFree]": isFree,
      "filter[publisher_is]": data.publisher_is,
    });
  };

  const handleReset = (resetForm) => {
    resetForm();
    setBookFilters({
      ...bookFilters,
      "filter[author]": "",
      "filter[title]": "",
      "filter[isbn]": "",
      "filter[category_is]": "",
      "filter[publisher_is]": "",
      "filter[genre_is]": [],
      "filter[isFree]": "",
    });
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(data) => handleFilter(data)}
      >
        {({ setFieldValue, values, resetForm }) => {
          return (
            <Form className="flex items-center gap-2 w-full text-xs">
              <input
                type="text"
                name="author"
                placeholder="Author"
                onChange={(e) => setFieldValue("author", e.target.value)}
                value={values.author}
                className="rounded border border-akgreen px-2 w-32"
              />
              <input
                type="text"
                name="title"
                placeholder="Book title"
                onChange={(e) => setFieldValue("title", e.target.value)}
                value={values.title}
                className="rounded border border-akgreen px-2 w-32"
              />
              <input
                type="text"
                name="isbn"
                placeholder="ISBN Number"
                onChange={(e) => setFieldValue("isbn", e.target.value)}
                value={values.isbn}
                className="rounded border border-akgreen px-2 w-24"
              />

              {!isLoadingPublishers && (
                <select
                  name="publisher_is"
                  id=""
                  className="rounded border border-akgreen px-2 w-32"
                  onChange={(e) =>
                    setFieldValue("publisher_is", e.target.value)
                  }
                  value={values.publisher_is}
                >
                  <option label="-Publisher-"></option>
                  {publishers?.data?.map((publisher) => (
                    <option key={publisher.id} value={publisher.id}>
                      {publisher.name}
                    </option>
                  ))}
                </select>
              )}

              {!isLoadingCategories && (
                <select
                  name="category_is"
                  id=""
                  className="rounded border border-akgreen px-2 w-32"
                  onChange={(e) => setFieldValue("category_is", e.target.value)}
                  value={values.category_is}
                >
                  <option label="-Category-"></option>
                  {categories?.data?.map((category) => (
                    <option key={category.id} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </select>
              )}

              {!isLoadingGenres && (
                <select
                  name="genre_is"
                  id=""
                  className="rounded border border-akgreen px-2 w-32"
                  onChange={(e) => setFieldValue("genre_is", e.target.value)}
                  value={values.genre_is}
                >
                  <option label="-Collection-"></option>
                  {genres?.data?.data?.map((genre) => (
                    <option key={genre.id} value={genre.name}>
                      {genre.name}
                    </option>
                  ))}
                </select>
              )}

              <select
                name="isFree"
                id=""
                className="rounded border border-akgreen px-2 w-24"
                onChange={(e) => setFieldValue("isFree", e.target.value)}
                value={values.isFree}
              >
                <option label="-Free?-"></option>
                <option value="free">Free</option>
                <option value="paid">Premium</option>
              </select>

              <button
                type="submit"
                className="flex gap-2 items-center bg-akgreener px-1 rounded text-white"
              >
                Filter <BsFilter size={20} />
              </button>

              <button type="button" onClick={() => handleReset(resetForm)}>
                <MdCancel className="text-akgreener" size={30} />
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BooksFilter;
