import React from "react";
import { FiUsers } from "react-icons/fi";
import StatisticsCard from "./StatisticsCard";
import { activeCurrencies } from "utils";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import { MdOutlinePayment } from "react-icons/md";

const Statistics = ({
	registeredUsers,
	usdCommission,
	zigCommission,
	usdPayout,
	zigPayout,
	netUsdCommission,
	netZigCommission,
	totalPaymentsMade,
	showPayments = true,
}) => {
	return (
		<div className="space-y-2">
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 justify-between gap-2 items-start px-3 py-3 rounded-lg bg-gray-200">
				<StatisticsCard
					label="Registered users"
					statistic={registeredUsers}
					icon={FiUsers}
					bgWhite={true}
				/>
				<StatisticsCard
					label={`Net ${activeCurrencies.USD.label} commission`}
					statistic={`${activeCurrencies.USD.symbol} ${netUsdCommission}`}
					icon={HiOutlineCurrencyDollar}
					bgWhite={true}
				/>
				<StatisticsCard
					label={`Net ${activeCurrencies.ZWL.label} commission`}
					statistic={`${activeCurrencies.ZWL.symbol} ${netZigCommission}`}
					icon={HiOutlineCurrencyDollar}
					bgWhite={true}
				/>
				<StatisticsCard
					label={`Total payments made`}
					statistic={totalPaymentsMade}
					icon={MdOutlinePayment}
					view={showPayments ? "/ba/client-payments" : null}
					bgWhite={true}
				/>
			</div>

			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 justify-between gap-2 items-start pt-4 ">
				<StatisticsCard
					label={`Gross ${activeCurrencies.USD.label} commission`}
					statistic={`${activeCurrencies.USD.symbol} ${usdCommission}`}
					icon={HiOutlineCurrencyDollar}
				/>
				<StatisticsCard
					label={`Gross ${activeCurrencies.ZWL.label} commission`}
					statistic={`${activeCurrencies.ZWL.symbol} ${zigCommission}`}
					icon={HiOutlineCurrencyDollar}
				/>

				<StatisticsCard
					label={`${activeCurrencies.USD.label} payout`}
					statistic={`${activeCurrencies.USD.symbol} ${usdPayout}`}
					icon={HiOutlineCurrencyDollar}
				/>
				<StatisticsCard
					label={`${activeCurrencies.ZWL.label} payout`}
					statistic={`${activeCurrencies.ZWL.symbol} ${zigPayout}`}
					icon={HiOutlineCurrencyDollar}
				/>
			</div>
		</div>
	);
};

export default Statistics;
