import useDeleteGenre from "api/GenreResource/useDeleteGenre";
import { ConfirmPopup } from "primereact/confirmpopup";
import React from "react";
import { AiOutlineDelete, AiOutlineLoading3Quarters } from "react-icons/ai";

const DeleteGenre = ({ elementId, genreId }) => {
  const [visible, setVisible] = React.useState(false);

  const { mutateAsync, isLoading: isDeletingGenre } = useDeleteGenre(genreId);

  const accept = async () => {
    await mutateAsync();
  };

  return (
    <>
      <button
        className="border-0 transform -rotate-3"
        onClick={() => setVisible(true)}
        id={elementId}
      >
        {isDeletingGenre ? (
          <AiOutlineLoading3Quarters size={20} className="animate-spin" />
        ) : (
          <AiOutlineDelete size={30} className="text-red-500" />
        )}
      </button>

      <ConfirmPopup
        target={document.getElementById(elementId)}
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete?"
        icon="pi pi-exclamation-triangle"
        accept={accept}
      />
    </>
  );
};

export default DeleteGenre;
