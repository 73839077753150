import InitApi from "api/InitApi";
import Axios from "axios";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import useTokenStore from "store/tokenStore";
import { activeCurrencies, deleteEmptyObjects } from "utils";

const useEcocash = (setPaymentIsSuccessful = null) => {
	const { accessToken } = useTokenStore();
	const queryClient = useQueryClient();

	const save = async (obj) => {
		const instance = await InitApi({ accessToken });

		const body = deleteEmptyObjects(obj);

		instance.interceptors.request.use(
			async (config) => {
				let headers = {
					Accept: "application/json",
					"Content-Type": "application/json",
				};

				const newInstance = Axios.create({
					baseURL: process.env.REACT_APP_API_BASE_URL,
					headers: {
						...headers,
						Authorization: `Bearer ${accessToken}`,
					},
				});

				const res = await newInstance.post("/ecocash-pay", {
					mobile_number: body.mobile_number,
					grand_total:
						body.currency === activeCurrencies.ZWL.code
							? body.grand_total_zwl
							: body.grand_total,
					currency: body.currency,
				});

				config.data = {
					...body,
					status:
						res?.data?.data?.transactionOperationStatus === "COMPLETED"
							? "Completed"
							: "Failed",
					reference: res?.data?.data?.clientCorrelator,
					subtotal: Number(body.subtotal).toFixed(2),
					grand_charges: Number(body.grand_charges).toFixed(2),
					grand_total: Number(body.grand_total).toFixed(2),
					grand_total_zwl: Number(body.grand_total_zwl).toFixed(2),
				};
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		if (body?.payment_type === "Subscription") {
			const response = await instance.patch(
				`/subscriptions/subscribe/${body?.id}`
			);
			return response?.data;
		}

		const response = await instance.post(`/orders/create`);
		return response?.data;
	};

	return useMutation(save, {
		onSuccess: async (body) => {
			queryClient.invalidateQueries("user_cart_items");
			queryClient.invalidateQueries("user_cart_count");
			queryClient.invalidateQueries("is_subscribed");
			queryClient.invalidateQueries("picks");
			queryClient.invalidateQueries("purchased");
			setPaymentIsSuccessful && setPaymentIsSuccessful(true);
		},
		onError: async (error) => {
			queryClient.invalidateQueries("user_cart_items");
			queryClient.invalidateQueries("user_cart_count");
			queryClient.invalidateQueries("is_subscribed");
			queryClient.invalidateQueries("picks");
			queryClient.invalidateQueries("purchased");
			setPaymentIsSuccessful && setPaymentIsSuccessful(true);
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);
		},
	});
};

export default useEcocash;
