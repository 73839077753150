import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
// import toast from "react-hot-toast";
import { reactLocalStorage } from "reactjs-localstorage";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useEditBook = (book, setshowEditBook) => {
	//
	const queryClient = useQueryClient();
	const { accessToken } = useTokenStore();

	const history = useHistory();

	const update = async (obj) => {
		const instance = await InitApi({ accessToken }, "multipart/form-data");
		const body = deleteEmptyObjects(obj);
		const formData = new FormData();
		Object.keys(body).forEach((key) => {
			formData.append(key, body[key]);
		});
		const response = await instance.post(
			`/books/${book}?_method=PATCH`,
			formData
		);
		return response;
	};

	return useMutation(update, {
		onSuccess: async ({ data: body }) => {
			await queryClient.invalidateQueries("books");
			await queryClient.invalidateQueries("myUploadedBooks");
			setshowEditBook(false);
			toast.success("Book updated successfully");
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);

			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
				history.push("/");
			}
		},
	});
};

export default useEditBook;
