import React from "react";

const BookPaginationLoader = () => {
	return (
		<div className="flex justify-between items-center ">
			<div className="flex flex-col gap-1 justify-center">
				<p
					className="bg-green-300 rounded-full animate-pulse"
					style={{ width: 200, height: 12 }}
				/>
				<p
					className="bg-green-300 rounded-full animate-pulse"
					style={{ width: 100, height: 8 }}
				/>
			</div>

			<div className="flex items-center justify-end gap-2">
				<p
					className="bg-green-300 rounded-full animate-pulse"
					style={{ width: 60, height: 35 }}
				/>
				<p
					className="bg-green-300 rounded-lg animate-pulse"
					style={{ width: 35, height: 35 }}
				/>
			</div>
		</div>
	);
};

export default BookPaginationLoader;
