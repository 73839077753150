import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { reactLocalStorage } from "reactjs-localstorage";
import useTokenStore from "store/tokenStore";

const useMigrateAndSeed = () => {
	const { accessToken } = useTokenStore();

	const queryClient = useQueryClient();

	const save = async (data) => {
		const instance = await InitApi({ accessToken });

		const res = instance.post("/migrate-and-seed", data);

		return res.data;
	};

	return useMutation(save, {
		onSuccess: async () => {
			// queryClient.invalidateQueries("users");
			// queryClient.invalidateQueries("roles");
			// queryClient.invalidateQueries("permissions");
			queryClient.invalidateQueries();
			toast.success("Migrations run successfully");
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);

			if (error?.response?.data.message === "Unauthenticated.") {
				reactLocalStorage.clear();
			}
		},
	});
};

export default useMigrateAndSeed;
