import InputError from "components/InputError";
import { useField } from "formik";
import PropTypes from "prop-types";

const CheckBox = ({ name, label, className, labelStyle, ...rest }) => {
  const [field, meta] = useField(name);

  return (
    <div className="mb-2">
      <div className="w-full flex items-center">
        <input
          type="checkbox"
          className={`mr-2 p-2 ${className ?? ""}`}
          id={rest.id || name}
          {...field}
          {...rest}
        />
        <label htmlFor={rest.id || name} className={`${labelStyle ?? ""}`}>
          {label}
        </label>
      </div>
      {meta.error && meta.touched && <InputError error={meta.error} />}
    </div>
  );
};

CheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
};

export default CheckBox;
