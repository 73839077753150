export const Permission = Object.freeze({
	CREATE_BOOK: "create book",
	DELETE_BOOK: "delete book",
	VIEW_BOOKS: "view book collection",
	VIEW_BOOK: "view book details",
	SET_WEEKLY_PICK: "set book as pick of the week",
	SET_RECOMMENDED: "set book as recommended",
	ACTIVATE_BOOK: "activate or deactivate books",
	CREATE_CATEGORY: "create book category",
	VIEW_CATEGORY: "view book category",
	UPDATE_CATEGORY: "update book category",
	DELETE_CATEGORY: "delete book category",
	CREATE_GENRE: "create book genre",
	VIEW_GENRE: "view book genre",
	UPDATE_GENRE: "update book genre",
	DELETE_GENRE: "delete book genre",
	CREATE_FX: "create exchange rate",
	VIEW_FX: "view exchange rate",
	UPDATE_FX: "update exchange rate",
	DELETE_FX: "delete exchange rate",
	CREATE_INSTITUTION: "create institution",
	VIEW_INSTITUTION: "view institution",
	UPDATE_INSTITUTION: "update institution",
	DELETE_INSTITUTION: "delete institution",
	CREATE_ORDER: "create order",
	VIEW_ORDER: "view order",
	UPDATE_ORDER: "update order",
	DELETE_ORDER: "delete order",
	CREATE_USER: "create user",
	VIEW_USER: "view user",
	UPDATE_USER: "update user",
	DELETE_USER: "delete user",
	CREATE_PUBLISHER: "create publisher",
	VIEW_PUBLISHER: "view publisher",
	UPDATE_PUBLISHER: "update publisher",
	DELETE_PUBLISHER: "delete publisher",
	CREATE_RATING: "create rating",
	VIEW_RATING: "view rating",
	UPDATE_RATING: "update rating",
	DELETE_RATING: "delete rating",
	PUBLISH_RATING: "publish rating",
	CREATE_SUBSCRIPTION: "create subscription",
	VIEW_SUBSCRIPTION: "view subscription",
	UPDATE_SUBSCRIPTION: "update subscription",
	DELETE_SUBSCRIPTION: "delete subscription",
	PUBLISH_SUBSCRIPTION: "publish subscription",
	VIEW_REGISTRATIONS: "view user registrations",
	VIEW_LIB_REVENUE: "view library revenue generated",
	VIEW_LIB_BOOKS_REPORT: "view library books report",
	VIEW_PUB_REVENUE: "view publisher's revenue report",
	VIEW_PUB_BOOKS_REPORT: "view publisher's books report",
	CREATE_VOUCHER: "create voucher",
	VIEW_VOUCHER: "view voucher",
	UPDATE_VOUCHER: "update voucher",
	RECORD_PUB_PAYOUTS: "record publisher payments",
	VIEW_ERRORS: "view error",
	RUN_CLI: "run cli",
	VIEW_SOLD_BOOKS: "view sold book",
	VIEW_BA_RECEIPTS: "view BA clients payment",
	RECORD_BA_PAYOUTS: "pay BA",
	VIEW_BA: "view BA",
	VIEW_BA_STATS: "view BA stats",
	CREATE_SUBJECT_GROUP: "create subject group",
	VIEW_SUBJECT_GROUP: "view subject group",
	UPDATE_SUBJECT_GROUP: "update subject group",
	DELETE_SUBJECT_GROUP: "delete subject group",
	CREATE_ROLES_AND_PERMISSIONS: "create role",
	VIEW_ROLES_AND_PERMISSIONS: "view role",
	UPDATE_ROLES_AND_PERMISSIONS: "update role",
});
