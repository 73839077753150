import React from "react";
import useFetchInstitutionAggregates from "api/InstitutionResource/useFetchInstitutionAggregates";
import { ButtonLoader } from "components";
import { FaSlackHash } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";

const Card = ({ label, value, value2, icon, className }) => (
	<div
		className={`p-2 flex justify-center items-start gap-1 h-24 bg-white rounded shadow ${className}`}
	>
		<div>{icon}</div>
		<div className="flex flex-col items-start">
			<h5>{label}</h5>
			<p className="flex justify-center items-center text-xl pl-2">
				- {value} -
			</p>
			{value2 && <p className="flex justify-center items-center ">{value2}</p>}
		</div>
	</div>
);

const Aggregates = () => {
	const { data: aggregate, isLoading } = useFetchInstitutionAggregates();
	if (isLoading) {
		return (
			<div className="w-full flex justify-center">
				<ButtonLoader size={80} />
			</div>
		);
	}

	return (
		<div className="w-full space-y-4">
			<div className="flex justify-between items-center w-full ">
				<h4 className="font-cera-black">Highlights</h4>
			</div>

			<div className="grid grid-cols-5 gap-2 ">
				<Card
					label="Total books purchased"
					value={aggregate?.data?.numberOfPurchasedBooks}
					icon={<FaSlackHash size={30} className="text-gray-400" />}
				/>

				<Card
					label="Total users"
					value={aggregate?.data?.allUsers}
					icon={<FaSlackHash size={30} className="text-gray-400" />}
					// className="ml-2"
				/>

				<Card
					label="Number of students"
					value={aggregate?.data?.numberOfStudents}
					icon={<FiUsers size={30} className="text-gray-400" />}
				/>

				<Card
					label="Number of educators"
					value={aggregate?.data?.numberOfEducators}
					icon={<FiUsers size={30} className="text-gray-400" />}
				/>

				<Card
					label="Number of admins"
					value={aggregate?.data?.numberOfAdmins}
					icon={<FiUsers size={30} className="text-gray-400" />}
				/>
			</div>
		</div>
	);
};

export default Aggregates;
