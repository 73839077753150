import accessDenied from "assets/images/access_denied.png";
import { DesktopHeader } from "components";
import { Container } from "layouts";
import { Link } from "react-router-dom";

const Unauthorized = () => {
  //!

  return (
    <Container>
      <div className="min-h-screen flex flex-col ">
        {/* <Header /> */}
        <DesktopHeader />
        <div className="flex space-y-5 flex-grow justify-center items-center flex-col">
          <img
            src={accessDenied}
            alt="Access Denied"
            className="animate-pulse"
          />

          <p className="text-xl w-80 text-center">
            Sorry! You do not have enough rights to access the requested
            resource.
          </p>

          <Link
            to="/books"
            className="rounded-2xl py-2 px-4 bg-akgreen text-white"
          >
            Browse books
          </Link>
        </div>
      </div>
    </Container>
  );

  //!
};

export default Unauthorized;
