import useSetPickOfTheWeek from "api/BookResource/useSetPickOfTheWeek";
import { OverlayPanel } from "primereact/overlaypanel";
import { FcProcess } from "react-icons/fc";
import tw from "tailwind-styled-components/dist/tailwind";

const CoverContainer = tw.div`
  bg-gradient-to-r from-gray-600 via-gray-400 to-gray-600
  flex justify-center
`;

const AdminBookItem = ({
  title,
  coverUrl,
  author,
  publisher,
  currencyCode,
  currencySymbol,
  price,
  category,
  genre,
  bookId,
  book,
  isbn,
  isActive,
  isAdmin,
  op,
  inBackend,
  className = "h-full",
}) => {
  //!

  const {
    mutateAsync: mutatePickOfTheWeek,
    isLoading: isSettingPickOfTheWeek,
  } = useSetPickOfTheWeek(bookId);

  const setAsPickofTheWeek = async () => {
    await mutatePickOfTheWeek();
  };

  return (
    <div className="border border-gray-300 p-4 text-sm rounded shadow-md">
      <div className={`flex ${inBackend ? "flex-col" : "flex-row"} gap-2 mb-2`}>
        <div style={{ width: 150, height: inBackend ? 100 : 230 }}>
          {coverUrl && (
            <img
              src={coverUrl}
              alt="Book Cover"
              width="150"
              className="bg-gray-600 object-cover w-36 h-full"
            />
          )}
        </div>

        <div style={{ width: 200 }}>
          <p className="text-sm font-cera-black mb-2">{title}</p>
          <p className="mb-2">{author}</p>

          {!inBackend && (
            <>
              <p className="text-xs">ISBN: {isbn || "N/A"}</p>
              <p className="mt-1 font-bold">Published By:</p>
              <p className="text-xs mb-2 ">{publisher}</p>
              <p>Category: {category}</p>
              <p className="text-xs">Collection: {genre}</p>

              <p className="text-md my-2 font-bold">
                {currencyCode}
                {currencySymbol}
                {price}
              </p>
            </>
          )}
        </div>
      </div>

      {!inBackend && (
        <div className={`flex justify-around my-2`}>
          {isActive && isAdmin && (
            <button
              type="button"
              onClick={() => setAsPickofTheWeek()}
              className="flex justify-center py-1 px-2 rounded-full border border-akgreen items-center gap-2"
            >
              <span>Set as pick of the week</span>
              {isSettingPickOfTheWeek && (
                <FcProcess
                  size={20}
                  className="animate-spin bg-gray-200 rounded-full"
                />
              )}
            </button>
          )}

          {isActive ? (
            <button
              type="button"
              className="rounded-full bg-akgreenest py-1 px-2 text-white"
            >
              Active
            </button>
          ) : (
            <button
              type="button"
              className="rounded-full bg-akgreen py-1 px-2 text-white"
            >
              Inactive
            </button>
          )}
        </div>
      )}

      <OverlayPanel
        ref={op}
        showCloseIcon
        id="overlay_panel"
        className="max-w-max"
      >
        <div className={`flex w-full gap-3 ${className}`}>
          <CoverContainer>
            {coverUrl && (
              <img
                src={coverUrl}
                alt="Book Cover"
                width="150"
                className="bg-gray-600 object-cover w-36 h-full"
              />
            )}
          </CoverContainer>

          <div className="flex flex-col justify-between pb-2">
            <div>
              <p className="text-sm">{title}</p>
              <p className="">{author}</p>
              <p>ISBN: {isbn || "N/A"}</p>
              <p className="mt-1 font-bold">Published By:</p>
              <p className="text-xs pb-2 border-b-2 mb-2 border-dotted border-white">
                {publisher}
              </p>
              <p className="mt-1">Category: {category}</p>
              <p>Collection: {genre}</p>
            </div>

            <p className="text-md my-2 font-bold">
              {currencyCode}
              {currencySymbol}
              {price}
            </p>

            {/* <div className="flex gap-1 items-center">
              {!isActive && isAdmin && (
                <button
                  type="button"
                  onClick={() => activate(bookId)}
                  className="border px-2 border-akgreen rounded"
                >
                  <span>Activate</span>
                  {isActivating && (
                    <FcProcess size={20} className="animate-spin inline" />
                  )}
                </button>
              )}

              {isActive && isAdmin && (
                <button
                  type="button"
                  onClick={() => deactivate()}
                  className="border px-2 border-akgreen rounded"
                >
                  <span>Deactivate</span>
                  {isDeactivating && (
                    <FcProcess size={20} className="animate-spin inline" />
                  )}
                </button>
              )}
            </div> */}
          </div>
        </div>
      </OverlayPanel>
    </div>
  );

  //!
};

export default AdminBookItem;
