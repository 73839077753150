import useCheckIfUserIsSubscribed from "api/SubscriptionsResource/useCheckIfUserIsSubscribed";
import ChangePassword from "./ChangePassword";
import avatar from "assets/images/avatar.jpg";
import Moment from "react-moment";

const ProfileCard = ({ user }) => {
	const { data: isSubscribed, isLoading } = useCheckIfUserIsSubscribed();

	const today = new Date();

	return (
		<div className="flex gap-4 items-center justify-center py-6 px-8 bg-gray-300 rounded-lg">
			<img
				src={user?.avatar || avatar}
				alt="Profile"
				className="rounded-full h-32 w-32"
			/>
			<div className="ml-3">
				<h4 className="font-cera-black">
					{user?.first_name} {user?.last_name}
				</h4>
				<p>{user?.email}</p>
				<p>Phone number: {user?.mobile_number || "N/A"}</p>
				<p>Date of Birth: {user?.dob || "N/A"}</p>

				{!isLoading && isSubscribed?.data && (
					<div className="py-4 ">
						<h4>Subscription details</h4>

						<div className="text-xs">
							<p>
								<span className="font-bold">Package:</span>{" "}
								{isSubscribed?.data?.name}
							</p>
							<p>
								<span className="font-bold">Expiring</span>{" "}
								<Moment to={isSubscribed?.data?.expiry_date}>{today}</Moment>{" "}
							</p>
							<p>
								<span className="font-bold">Picks allowed:</span>{" "}
								{isSubscribed?.data?.max_number_of_books} premium books
							</p>
							<p>
								<span className="font-bold">Picks chosen:</span>{" "}
								{isSubscribed?.data?.current_premium_books_added} premium books
							</p>
							<p>
								<span className="font-bold">Picks remaining:</span>{" "}
								{isSubscribed?.data?.remaining} premium books
							</p>
						</div>
					</div>
				)}

				<div className="mt-2">
					<ChangePassword />
				</div>
			</div>
		</div>
	);
};

export default ProfileCard;
