import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";

const useFetchUser = (id) => {
	const { accessToken } = useTokenStore();

	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		return await instance.get(`users/${id}`);
	};

	return useQuery(["users", id], () => fetch(), {
		...fetchConfig,
	});
};

export default useFetchUser;
