import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";

const useFetchConfigs = () => {
	const { accessToken } = useTokenStore();

	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const res = await instance.get("configurations");
		return res.data?.data;
	};

	return useQuery(["configs"], () => fetch(), {
		...fetchConfig,
	});
};

export default useFetchConfigs;
