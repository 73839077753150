import usePayPublisher from "api/PublisherPayments/usePayPublisher";
import useFetchPublishers from "api/PublisherResource/useFetchPublishers";
import FormField from "components/v2/FormField";
import { Form, Formik } from "formik";
import Admin2 from "layouts/Admin2";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { activeCurrencies, getActiveCurrencyCodeLabel } from "utils";
import * as Yup from "yup";

const PayPublisher = () => {
	const { data: publishers, isLoading: isLoadingPublishers } =
		useFetchPublishers();

	const initialValues = {
		publisher_id: "",
		amount: "",
		currency: activeCurrencies.USD.code,
		description: "",
		reference: "",
	};

	const validate = Yup.object().shape({
		publisher_id: Yup.string().required("Publisher is required"),
		amount: Yup.number()
			.required("Amount is required")
			.moreThan(0, "Amount should be greater than 0"),
		currency: Yup.string().required("Currency is required"),
	});

	const { mutateAsync, isLoading } = usePayPublisher();

	const onSubmit = async (values) => {
		await mutateAsync(values);
	};

	if (isLoadingPublishers) {
		return (
			<Admin2>
				<div className="flex items-center justify-center h-screen">
					<AiOutlineLoading3Quarters className="text-5xl animate-spin" />
				</div>
			</Admin2>
		);
	}

	return (
		<Admin2>
			<Formik
				initialValues={initialValues}
				validationSchema={validate}
				onSubmit={onSubmit}
			>
				{(formik) => {
					const currencyLabel = formik.values.currency
						? `(in ${getActiveCurrencyCodeLabel(formik.values.currency)})`
						: "";

					return (
						<Form className="mx-auto mt-10" style={{ width: 500 }}>
							<div className="mb-8">
								<h3> Please fill in the form below to pay a publisher </h3>
								<p>Required field are marked with (*)</p>
							</div>

							<div className="space-y-6">
								<FormField
									type="select"
									name="currency"
									label="Currency *"
									options={[
										{
											label: activeCurrencies.USD.label,
											value: activeCurrencies.USD.code,
										},
										{
											label: activeCurrencies.ZWL.label,
											value: activeCurrencies.ZWL.code,
										},
									]}
								/>

								<FormField
									name="amount"
									type="number"
									min="0"
									step="0.01"
									label={`Amount to be paid ${currencyLabel} *`}
								/>

								<FormField
									type="select"
									name="publisher_id"
									label="Select publisher to pay *"
									options={publishers?.data?.map((publisher) => ({
										label: publisher.name,
										value: publisher.id,
									}))}
								/>

								<FormField
									type="text"
									name="reference"
									label="Reference (e.g bank transaction reference)"
								/>

								<FormField
									type="textarea"
									name="description"
									label="Description"
								/>

								<div className=" flex ">
									<button
										type="submit"
										className="px-4 py-2 rounded-full bg-akgreenest text-white flex gap-1 items-center justify-center "
									>
										{isLoading ? (
											<>
												Processing payment{" "}
												<AiOutlineLoading3Quarters
													size={20}
													className="animate-spin"
												/>
											</>
										) : (
											<>
												Proceed with payment <IoIosArrowForward size={20} />
											</>
										)}
									</button>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</Admin2>
	);
};

export default PayPublisher;
