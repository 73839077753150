import useBook from "api/BookResource/useBook";
import { ButtonLoader, DesktopHeader } from "components";
import StarRating from "components/v2/BookItem/StarRating";
import { Container } from "layouts";
import PaddingX from "layouts/PaddingX";
import React from "react";
import { useParams } from "react-router-dom";
import useCurrencyStore from "store/currencyStore";
import useAddToPicks from "api/BookResource/useAddToPicks";
import useCheckIfUserIsSubscribed from "api/SubscriptionsResource/useCheckIfUserIsSubscribed";
import usePrice from "hooks/usePrice";
import { activeCurrencies, formatCurrency } from "utils";
import useAllowedBookActions from "hooks/useAllowedBookActions";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useRemoveFromLibrary from "api/BookResource/useRemoveFromLibrary";
import { BiPlus } from "react-icons/bi";
import useAddToCart from "api/BookResource/useAddToCart";
import ActionErrorMsg from "./ActionErrorMsg";
import AddToCartSuccessful from "components/v2/AddToCartSuccessful";

const Book = () => {
	const { bookId } = useParams();
	const { currency, setCurrency } = useCurrencyStore();
	const { data: book, isLoading: isLoadingBook } = useBook(bookId);
	const { data: isSubscribed, isLoading: isLoadingSubscribed } =
		useCheckIfUserIsSubscribed();
	const [cartQuantity, setCartQuantity] = React.useState(1);

	console.log({ book });

	const handleChangeCartQuantity = (value) => {
		if (value <= 0) return setCartQuantity(1);
		return setCartQuantity(value);
	};

	const price = usePrice({
		isFree: Boolean(book?.isFree),
		usdPrice: book?.price,
		zwlPrice: book?.zwl_price,
		sellable: Boolean(book?.sellable),
	});

	const { addToCart, addToPicks, removeFromPicks } = useAllowedBookActions({
		isFree: Boolean(book?.isFree),
		in_library: Boolean(book?.in_library),
		is_purchased: Boolean(book?.is_purchased),
		sellable: Boolean(book?.sellable),
		subscribable: Boolean(book?.subscription),
		hasSubscription: Boolean(isSubscribed?.data),
	});

	const { mutateAsync, isLoading: isAddingToPicks } = useAddToPicks();
	const handleAddToPicks = () => {
		mutateAsync({ booksToSubscribe: book?.id });
	};

	const {
		mutateAsync: mutateRemoveFromLibrary,
		isLoading: isRemovingFromLibrary,
	} = useRemoveFromLibrary(book?.id);
	const removeFromLibrary = async () => {
		await mutateRemoveFromLibrary();
	};

	const { mutateAsync: mutateAddToCart, isLoading: isAddingToCart } =
		useAddToCart();

	const handleAddToCart = async () => {
		await mutateAddToCart({
			quantity: cartQuantity,
			book_id: bookId,
		});
	};

	return (
		<Container>
			<DesktopHeader shadow />

			<PaddingX>
				{isLoadingBook || isLoadingSubscribed ? (
					<div className="flex justify-center mt-10">
						<ButtonLoader size={100} />
					</div>
				) : (
					<div className="flex flex-col sm:flex-row justify-center items-center sm:items-start gap-10 w-auto max-w-max mx-auto mt-14 ">
						<div style={{ width: 300 }}>
							<div
								style={{ height: 400 }}
								className="bg-gray-200 flex items-center justify-center p-2 relative"
							>
								<span className="font-cera-black  absolute -left-3 md:-left-10 -top-6 h-20 w-20 rounded-full bg-akgreener text-white flex items-center justify-center">
									{Boolean(book?.isFree) ? "Free" : "Premium"}
								</span>

								{book?.book_cover?.url && (
									<img
										src={book?.book_cover?.url}
										alt={book?.title}
										style={{
											width: 150,
											height: 230,
										}}
									/>
								)}
							</div>

							<div className="flex justify-center mt-2">
								<StarRating number={book?.rating} />
							</div>
						</div>

						<div
							className="flex-1 space-y-6 "
							style={{ width: 300 }}
						>
							<div className="space-y-2 ">
								<h2
									title={book?.title}
									className=" font-cera-black   "
								>
									{book?.title}
								</h2>

								{book?.author && (
									<p className="flex items-center gap-1 text-base">
										<span>Written by</span>
										<span className="font-bold"> {book?.author}</span>
									</p>
								)}

								{book?.publisher && (
									<p
										className="text-lg"
										style={{ maxWidth: 500 }}
									>
										Published by{" "}
										<span className="text-base font-bold text-akgreener">
											{book?.publisher}
										</span>
									</p>
								)}

								{book?.description && (
									<p
										style={{ maxWidth: 500 }}
										dangerouslySetInnerHTML={{ __html: book?.description }}
									/>
								)}

								{(!Boolean(book?.isFree) || !Boolean(book?.sellable)) && (
									<div className="flex items-center gap-2 ">
										<div className="flex items-center justify-center rounded-full bg-gray-200 px-2">
											<select
												onChange={(e) => setCurrency(e.target.value)}
												className="bg-transparent py-2 focus:outline-none text-lg"
											>
												<option
													value={activeCurrencies.USD.code}
													selected={currency === activeCurrencies.USD.code}
												>
													{activeCurrencies.USD.label}
												</option>

												<option
													value={activeCurrencies.ZWL.code}
													selected={currency === activeCurrencies.ZWL.code}
												>
													{activeCurrencies.ZWL.label}
												</option>
											</select>
										</div>

										<p className="text-2xl">{formatCurrency(price)}</p>
									</div>
								)}

								{Boolean(book?.subscription) && (
									<div className="flex items-center gap-1">
										{addToPicks.possible ? (
											<button
												type="button"
												onClick={handleAddToPicks}
												className="px-4 py-2 rounded-full border border-akgreener flex items-center gap-2"
											>
												Add to picks{" "}
												{isAddingToPicks && (
													<AiOutlineLoading3Quarters
														size={30}
														className=" animate-spin "
													/>
												)}
											</button>
										) : (
											<ActionErrorMsg
												heading="Add to picks"
												message={addToPicks.message}
												className="px-4 py-2 rounded-full border border-akgreener bg-gray-100 text-gray-400 "
												action={addToPicks.action}
											>
												Add to Picks
											</ActionErrorMsg>
										)}

										{removeFromPicks.possible && (
											<button
												type="button"
												onClick={removeFromLibrary}
												className="px-4 py-2 rounded-full border border-akgreener flex items-center gap-2"
											>
												Remove from picks{" "}
												{isRemovingFromLibrary && (
													<AiOutlineLoading3Quarters
														size={20}
														className=" animate-spin "
													/>
												)}
											</button>
										)}
									</div>
								)}

								<div className="pt-2">
									{!Boolean(book?.isFree) &&
										(addToCart.possible ? (
											<div className="rounded-full flex items-end gap-1 max-w-max ">
												<div className="flex flex-col gap-1">
													<p className="text-base">Quantity</p>
													<input
														type="number"
														min={1}
														value={cartQuantity}
														onChange={(e) =>
															handleChangeCartQuantity(e.target.value)
														}
														className="w-20 h-10 pl-4 pr-1 rounded-full border-transparent border border-akgreen"
													/>
												</div>

												<div>
													<button
														type="button"
														onClick={handleAddToCart}
														className="px-4 py-2 h-10 border border-transparent rounded-full bg-akgreener text-white flex items-center gap-1"
													>
														Add to cart{" "}
														{isAddingToCart ? (
															<AiOutlineLoading3Quarters
																size={20}
																className=" animate-spin "
															/>
														) : (
															<BiPlus size={20} />
														)}
													</button>
												</div>
											</div>
										) : (
											<ActionErrorMsg
												heading="Add to cart"
												message={addToCart.message}
												action={addToCart.action}
												className="px-4 py-2 rounded-full border border-akgreener bg-gray-100 text-gray-400 "
											>
												Add to cart
											</ActionErrorMsg>
										))}
								</div>
							</div>
						</div>
					</div>
				)}
			</PaddingX>

			<AddToCartSuccessful />
		</Container>
	);
};

export default Book;
