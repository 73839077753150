import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects, fetchConfig } from "utils";

const useInstitutionBooks = (queryParams) => {
	const { accessToken } = useTokenStore();
	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const params = queryParams ? deleteEmptyObjects(queryParams) : null;

		const parameters =
			params["filter[genre_is]"]?.length > 0 ||
			params["filter[genre_is]"] !== undefined
				? {
						...params,
						"filter[genre_is]": params["filter[genre_is]"]?.join("*"),
				  }
				: delete params["filter[genre_is]"];

		const res = await instance.get(`books/institution`, {
			params: { ...parameters },
		});
		return res.data;
	};
	return useQuery(["books", "institution", queryParams], () => fetch(), {
		...fetchConfig,
	});
};

export default useInstitutionBooks;
