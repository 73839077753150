import useUpdateSubscription from "api/SubscriptionsResource/useUpdateSubscription";
// import { CheckBox, SelectField, TextField } from "components";
import { Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import * as Yup from "yup";
import FormField from "components/v2/FormField";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";

const EditSubscription = ({
	id,
	category,
	cost,
	name,
	time_unit,
	credits,
	duration,
	max_number_of_books,
}) => {
	const [display, setDisplay] = useState(false);

	const initialValues = {
		category: category,
		name: name,
		cost: cost,
		time_unit: time_unit,
		credits: credits,
		duration: duration,
		max_number_of_books: max_number_of_books,
	};

	const validate = Yup.object().shape({
		name: Yup.string().required("Name of subscription plan is required"),
		category: Yup.string().required("Category is required"),
		cost: Yup.string().required("Price is required"),
		duration: Yup.string().required("Period/duration is required"),
		time_unit: Yup.string().required("Time unit is required"),
		credits: Yup.string().required("Equivalent stars is required"),
		max_number_of_books: Yup.string().required("Enter maximum number of books"),
		// available_items: Yup.array().required("Associated items is required"),
	});

	const { mutateAsync, isLoading } = useUpdateSubscription(id);

	const save = async (data) => {
		await mutateAsync(data);
		setDisplay(false);
	};

	return (
		<>
			<button
				type="button"
				onClick={() => setDisplay(true)}
				className="rounded-full p-2 hover:border-2"
			>
				<FiEdit size={25} className="text-akgreenest" />
			</button>

			<Dialog
				header={
					<>
						<p className="w-96">Edit subscription information</p>
						<p className="text-sm">
							{name} - {category}
						</p>
					</>
				}
				visible={display}
				onHide={() => setDisplay(false)}
				position="top"
				draggable={false}
			>
				<Formik
					initialValues={initialValues}
					validationSchema={validate}
					onSubmit={(data) => save(data)}
				>
					{(formik) => (
						<Form className="mt-7 space-y-7">
							<FormField
								type="text"
								name="name"
								label="Name of subscription plan *"
								helperText="e.g Free trial"
							/>

							<FormField
								type="select"
								name="category"
								label="Select category *"
								helperText="e.g Individual"
								options={[
									{
										label: "Individual",
										value: "individual",
									},
									{ label: "Institution", value: "institution" },
								]?.map((role) => ({
									label: role.label,
									value: role.value,
								}))}
							/>

							<FormField
								type="number"
								min="0"
								step="0.01"
								name="cost"
								label="Price"
							/>

							<FormField
								type="number"
								min="0"
								name="duration"
								label="Period"
								helperText="e.g 7"
							/>

							<FormField
								type="number"
								min="0"
								name="max_number_of_books"
								label="Maximum number of books for this package"
								helperText="e.g 7"
							/>

							<FormField
								type="select"
								name="time_unit"
								label="Time units *"
								helperText="e.g Days"
								options={[
									{ label: "Hour(s)", value: "hours" },
									{ label: "Day(s)", value: "days" },
									{ label: "Month(s)", value: "months" },
								]?.map((role) => ({
									label: role.label,
									value: role.value,
								}))}
							/>

							<div className=" flex justify-center border-t border-gray-300 pt-2">
								<button
									type="submit"
									className="px-4 py-2 rounded-full bg-akgreenest text-white flex gap-1 items-center justify-center"
								>
									{isLoading ? (
										<>
											Updating{" "}
											<AiOutlineLoading3Quarters
												size={20}
												className="animate-spin"
											/>
										</>
									) : (
										<>
											Update <IoIosArrowForward size={20} />
										</>
									)}
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</Dialog>
		</>
	);
};

export default EditSubscription;
