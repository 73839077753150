import useAssignToUser from "api/BookResource/useAssignBooksToUsers";
import useMyLibrary from "api/BookResource/useMyLibrary";
import { Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { BiErrorAlt } from "react-icons/bi";
import { BsPlusCircleDotted } from "react-icons/bs";
import * as Yup from "yup";

const AssignBooks = ({ elementId, user }) => {
	//!

	const [display, setDisplay] = useState(false);

	const { data: library, isLoading: isFetchLibrary } = useMyLibrary({
		unpaginated: true,
	});

	const initialValues = {
		userId: user.id,
		books: [],
	};

	const validate = Yup.object().shape({
		books: Yup.array().min(1, "Please select at least one book").required("Please select at least one book"),
	});

	const { mutateAsync, isLoading: isAssigning } = useAssignToUser();
	const handleAssignBooks = async (data) => {
		await mutateAsync(data);
		setDisplay(false);
	};

	return (
		<div>
			<button
				className="text-white text-sm py-1 px-2 bg-akgreener w-full justify-center rounded-full"
				onClick={() => setDisplay(true)}
				id={elementId}
			>
				Assign book(s)
			</button>
			<Dialog
				header={
					<p>
						Assign books to &nbsp;
						<span className="text-akgreener text-sm">
							{user.first_name} {user.last_name} ({user.email})
						</span>
					</p>
				}
				visible={display}
				onHide={() => setDisplay(false)}
				position="top"
				className="w-3/4"
			>
				<Formik
					initialValues={initialValues}
					validationSchema={validate}
					onSubmit={(data) => handleAssignBooks(data)}
				>
					{(formik) => {
						return (
							<Form>
								<div className="text-md flex justify-between items-center">
									<div>
										<p>Select books that you want to assign to the user below:</p>
										{formik.errors.books && (
											<p className="text-red-600">
												<BiErrorAlt className="inline" size="30" /> {formik.errors.books}
											</p>
										)}
									</div>

									<button
										type="submit"
										className="px-3 py-2 rounded border border-gray-400 bg-gradient-to-b from-akgreenest via-akgreen to-gray-300 text-white text-lg shadow-md"
									>
										{isAssigning ? (
											<BsPlusCircleDotted className="inline animate-spin" size="30" />
										) : (
											"Assign"
										)}
									</button>
								</div>
								<hr className="my-4 border-2" />

								{!isFetchLibrary && library?.data?.records.length > 0 ? (
									<div className="grid grid-cols-3 gap-2">
										{library?.data?.records?.map((book) => (
											<label
												htmlFor={`check-${book.id}`}
												className="flex gap-2 items-start mb-2 cursor-pointer"
												key={book.id}
											>
												<img
													src={book?.book_cover?.url}
													style={{ width: "110px", height: "160px" }}
													alt={book?.title}
												/>
												<div className="text-sm">
													<p className="text-akgreenest font-bold text-md">{book?.title}</p>
													<p>{book?.author}</p>
													<hr className="my-1 border-gray-300" />
													<p>{book?.category?.name}</p>
													<p>{book?.genres[0]?.name}</p>
													<input
														type="checkbox"
														name="books"
														id={`check-${book.id}`}
														onChange={formik.handleChange}
														value={book.id}
														className="text-akgreener w-10 h-10 ring-4 ring-akgreen  focus:ring-akgreener mt-2 ml-1 "
													/>
												</div>
											</label>
										))}
									</div>
								) : (
									<div className="text-center">No books have been bought</div>
								)}

								<hr className="my-4 border-2" />

								<div className="flex justify-end">
									<button
										type="submit"
										className="px-3 py-2 rounded border border-gray-400 bg-gradient-to-b from-akgreenest via-akgreen to-gray-300 text-white text-lg shadow-md"
									>
										{isAssigning ? (
											<BsPlusCircleDotted className="inline animate-spin" size="30" />
										) : (
											"Assign"
										)}
									</button>
								</div>
							</Form>
						);
					}}
				</Formik>
			</Dialog>
		</div>
	);

	//!
};

export default AssignBooks;
