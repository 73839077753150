import useBooksInSubscriptionCollection from "api/BookResource/useBooksInSubscriptionCollection";
import ButtonLoader from "components/ButtonLoader";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { FiX } from "react-icons/fi";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useFormik } from "formik";
import useRetainBooksInPicks from "api/BookResource/useRetainBooksInPicks";
import { IoArrowRedoOutline } from "react-icons/io5";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useHistory } from "react-router-dom";

const RetainBooksInPicks = ({
	showListOfPicksToRetain,
	setShowListOfPicksToRetain,
}) => {
	const history = useHistory();

	const { data, isLoading: isLoadingSubscribedBooks } =
		useBooksInSubscriptionCollection({
			select: "inactive",
			rows_per_page: 10,
		});

	const hasBooksInPicks = data?.data?.records?.length > 0;

	const books = data?.data?.records;

	const handleCheckSubscriptionStatus = () => {
		setShowListOfPicksToRetain(false);
		history.push("/books");
	};

	const [selectedBooks, setSelectedBooks] = useState([]);

	const { mutateAsync, isLoading } = useRetainBooksInPicks();

	const { values, setFieldValue, handleSubmit } = useFormik({
		initialValues: {
			booksToRetain: [],
		},
		onSubmit: async (data) => {
			await mutateAsync({
				books: [...data.booksToRetain],
			});
			setFieldValue("booksToRetain", []);
			setSelectedBooks([]);
			history.push("/my-library/picks");
		},
		enableReinitialize: true,
	});

	const renderModalContent = () => {
		if (isLoadingSubscribedBooks) {
			return <ButtonLoader size={60} />;
		}

		if (!hasBooksInPicks) {
			return (
				<div className="space-y-4">
					<h4 className="font-bold">
						Your payment for subscription has been recorded
					</h4>

					<p>
						If money was deducted from your wallet but subscription status
						hasn't changed. You can add books to your picks.
					</p>

					<button
						type="button"
						onClick={handleCheckSubscriptionStatus}
						className="rounded-lg px-4 py-4 bg-gray-300 flex flex-col items-center gap-1 max-w-max"
					>
						<span>
							Browse books and pick the ones you want to enlist in your
							subscription. When you find a book you like, click the "Add to
							picks" button to enlist it in your subscription.
						</span>
						<span className="text-base text-blue-900 font-bold">
							Browse books
						</span>
					</button>
				</div>
			);
		}

		return (
			<div className="space-y-2">
				<h4>Your payment for subscription has been recorded</h4>

				<p>
					You added the following books to picks from your previous
					subscription. Select books that you want to keep in this subscription
				</p>

				<form className="pt-4" onSubmit={handleSubmit}>
					<DataTable
						value={books}
						selection={selectedBooks}
						onSelectionChange={(e) => {
							setSelectedBooks(e.value);
							setFieldValue("booksToRetain", [
								...e.value.map((book) => book.id),
							]);
						}}
						tableClassName="font-sans text-sm"
						autoLayout
					>
						<Column
							selectionMode="multiple"
							headerStyle={{ width: "1em" }}
						></Column>
						<Column field="title" header="Title" />
						<Column field="publisher" header="Publisher" />
					</DataTable>

					<div className="mt-6">
						{values?.booksToRetain?.length > 0 ? (
							<>
								<p>Do you want to retain the selected books in your picks</p>
								<div className="pt-2 flex gap-3">
									<button
										type="submit"
										className="py-3 px-2 rounded-md bg-akgreenest text-white flex items-center justify-center gap-2"
									>
										Keep selected books
										{isLoading ? (
											<AiOutlineLoading3Quarters
												size={20}
												className="animate-spin"
											/>
										) : (
											<IoArrowRedoOutline size={20} />
										)}
									</button>

									<button
										type="button"
										onClick={() => setShowListOfPicksToRetain(false)}
										className="py-3 px-2 rounded-md border border-green-200"
									>
										No, I am not interested
									</button>
								</div>
							</>
						) : (
							<div>
								<button
									type="button"
									onClick={() => setShowListOfPicksToRetain(false)}
									className="py-3 px-2 rounded-xl border border-akgreener flex items-center gap-1"
								>
									Close <FiX size={20} />
								</button>
							</div>
						)}
					</div>
				</form>
			</div>
		);
	};

	return (
		<Dialog
			visible={showListOfPicksToRetain}
			onHide={() => setShowListOfPicksToRetain(false)}
			showHeader={false}
			contentClassName="rounded-xl relative"
		>
			{/* <button type="button" onClick={() => setShowListOfPicksToRetain(false)}>
				<FiX size={25} className="absolute right-4 top-4" />
			</button> */}

			<div
				className="py-6 my-8 px-2 mx-auto flex items-center justify-center"
				style={{ width: 400, minHeight: 100 }}
			>
				{renderModalContent()}
			</div>
		</Dialog>
	);
};

export default RetainBooksInPicks;
