import InitApi from "api/InitApi";
import { useQuery } from "react-query";
import useTokenStore from "store/tokenStore";
import { fetchConfig } from "utils";

const useFetchCurrencyByCode = (currencyCode) => {
	const { accessToken } = useTokenStore();
	const fetch = async () => {
		const instance = await InitApi({ accessToken });
		const response = await instance.get(`currency-rates/${currencyCode}`);
		return response.data;
	};

	return useQuery(["rate", currencyCode], () => fetch(), {
		...fetchConfig,
	});
};

export default useFetchCurrencyByCode;
