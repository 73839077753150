import { SpinnerCircularFixed } from "spinners-react";

const ButtonLoader = ({ size, color }) => {
  return (
    <SpinnerCircularFixed
      size={size || 90}
      thickness={100}
      speed={153}
      color={`${color || "rgba(172, 96, 57, 1)"}`}
      secondaryColor="rgba(111, 172, 57, 0.15)"
    />
  );
};

export default ButtonLoader;
