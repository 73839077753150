import InitApi from "api/InitApi";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects, fetchConfig } from "utils";

const useCheckEcocashStatus = (queryParams, shouldFetch) => {
	const { accessToken } = useTokenStore();

	const queryClient = useQueryClient();

	const location = useLocation();
	const history = useHistory();

	const fetch = async () => {
		const instance = await InitApi({ accessToken });

		const params = queryParams ? deleteEmptyObjects(queryParams) : null;
		const response = await instance.get("/orders/ecocash-status", {
			params,
		});

		if (response?.status === 200) {
			history.push(`${location.pathname}?status=Successful`);
		}

		const res = response?.data;

		queryClient.invalidateQueries("user_cart_items");
		queryClient.invalidateQueries("user_cart_count");
		queryClient.invalidateQueries("is_subscribed");
		queryClient.invalidateQueries("picks");
		queryClient.invalidateQueries("purchased");

		return res;
	};
	return useQuery("payment-response", () => fetch(), {
		...fetchConfig,
		retry: 1,
		staleTime: 0,
		enabled: shouldFetch,
	});
};

export default useCheckEcocashStatus;
