import InitApi from "api/InitApi";
import ServerErrors from "components/SeverErrors";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import useAppStore from "store/appStore";
import useTokenStore from "store/tokenStore";
import { deleteEmptyObjects } from "utils";

const useResetPassword = () => {
	const { accessToken } = useTokenStore();

	const clearFlashMsg = useAppStore((state) => state.clearFlashMessage);

	const resetPassword = async (obj) => {
		const instance = await InitApi({ accessToken });
		const body = deleteEmptyObjects(obj);
		// const response = await instance.post(`/auth/reset-password`, body);
		const response = await instance.post(`/auth/reset-password-otp`, body);

		return response;
	};

	return useMutation(resetPassword, {
		onSuccess: async ({ data: body }) => {
			toast.success(body?.message);
			clearFlashMsg();
		},
		onError: async (error) => {
			toast.error(
				<ServerErrors
					errorMessage={error?.response?.data.message}
					errors={error?.response?.data?.data}
				/>
			);
		},
	});
};

export default useResetPassword;
