import create from "zustand";

const booksFilterStore = (set) => ({
	bookFilters: {
		"filter[author]": "",
		"filter[title]": "",
		"filter[isbn]": "",
		"filter[is_active]": "",
		"filter[category_is]": "",
		"filter[genre_is]": [],
		"filter[isFree]": "",
		"filter[publisher_is]": "",
		"filter[pricing_model_is]": "",
		searchTerm: "",
		without_file_collection: "",
		with_file_collection: "",
	},

	page: 1,

	filterCleared: true,

	setBookFilters: (data) => set({ bookFilters: { ...data } }),

	setPage: (newPage) => set({ page: newPage }),

	setFilterCleared: (flag) => set({ filterCleared: flag }),
});

const useBooksFilterFrontStore = create(booksFilterStore);

export default useBooksFilterFrontStore;
