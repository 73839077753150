import { SpinnerDiamond } from "spinners-react";
import tw from "tailwind-styled-components/dist/tailwind";

const AkWaMain = tw.div`
    absolute z-10 top-2 left-2
    w-32
    opacity-90
    h-32
`;

const Waiting = () => {
  return (
    <AkWaMain>
      <div className="border-2  border-gray-300 rounded-full  w-full h-full flex justify-center items-center ">
        <SpinnerDiamond
          size={90}
          thickness={180}
          speed={100}
          color="rgba(172, 99, 57, 0.91)"
          secondaryColor="rgba(0, 0, 0, 0.44)"
        />
      </div>
    </AkWaMain>
  );
};

export default Waiting;
