import useFetchPermissions from "api/RoleResource/useFetchPermissions";
import useFetchRoles from "api/RoleResource/useFetchRoles";
import { ButtonLoader } from "components";
import Admin2 from "layouts/Admin2";
import React from "react";
import Permissions from "./Permissions";
import Roles from "./Roles";

const RolesPermissions = () => {
	const { data: permissions, isLoading: isLoadingPermissions } = useFetchPermissions();

	const { data: roles, isLoading: isLoadingRoles } = useFetchRoles();

	return (
		<Admin2 pageTitle="User Permissions">
			<div style={{ minWidth: "52%" }}>
				{isLoadingPermissions || isLoadingRoles ? (
					<div className="flex justify-center items-center">
						<ButtonLoader size={60} />
					</div>
				) : (
					<div className="space-y-8">
						<Roles roles={roles?.data?.data} />
						<Permissions permissions={permissions?.data} />
					</div>
				)}
			</div>
		</Admin2>
	);
};

export default RolesPermissions;
